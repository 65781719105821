import { Col, Form, Input } from "antd";
import { regExp } from "~utils/formRegex";
import { NovaventaInputCodeNumberCss } from "./NovaventaInputCodeNumberCss";

export const NovaventaInputCodeNumber = (props: any) => {
  const {
    positionNumber,
    size = 4,
    regex = true,
    id,
    formName = "changePassword",
  } = props;
  const CODE_REGEX = new RegExp(regex ? regExp.justNumber : "");

  return (
    <Col span={size}>
      <Form.Item
        name={`verCode${positionNumber}`}
        rules={[
          {
            required: true,
            pattern: CODE_REGEX,
            message: " ",
          },
        ]}
        noStyle
      >
        <Input
          maxLength={1}
          css={NovaventaInputCodeNumberCss}
          type="text"
          id={id}
          data-testid={id}
          pattern="\d*"
          onChange={(e: any) => {
            if (e.target.value && e.target.value?.length === 1) {
              const item = document.getElementById(
                `${formName}_verCode${positionNumber + 1}`
              );
              if (item) item.focus();
            } else {
              const position = positionNumber >= 1 ? positionNumber - 1 : positionNumber;
              const item2 = document.getElementById(`${formName}_verCode${position}`);
              if (item2) item2.focus();
            }
          }}
        />
      </Form.Item>
    </Col>
  );
};
