import React from "react";
import NovaventaTextAreaCss from "./NovaventaTextAreaCss";
import IconCheck from "~components/icons/IconCheck";

export const NovaventaTextArea = (props: any) => {
  const {
    extracss = {},
    placeholder,
    showiconcheck = "false", // showiconcheck's value can't be a boolean due to this bug https://github.com/ant-design/ant-design/issues/7798
    disabled = false,
    id = "",
    maxLength = 10,
    type = "text",
    value,
    onChange,
    addonbefore,
  } = props;

  return (
    <NovaventaTextAreaCss
      extracss={extracss}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      id={id}
      data-testid={id}
      maxLength={maxLength}
      type={type}
      suffix={showiconcheck === "true" ? <IconCheck /> : null}
      addonbefore={addonbefore}
    />
  );
};

export default NovaventaTextArea;
