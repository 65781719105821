import { RouteComponentProps } from "@reach/router";
import { Col, Form, Row } from "antd";
import React from "react";
import SectionTitle from "~components/SectionTitle";
import FooterButtons from "~components/digitalRegistration/FooterButtons";
import NameForm from "~components/forms/NameForm";
import EmailPhoneForm from "~components/forms/emailPhoneForm/EmailPhoneForm";
import { rowTitleGutter } from "~components/forms/formItemsLayout";
import { usePrismic } from "~hooks/usePrismic";
import { sendContactData, sendPersonalData } from "~services/digitalRegistrationServices";
import { useFormContext } from "~store/FormContext";
import { formData, uuidSolicitud } from "~store/formStore";
import { device } from "~utils/NovaventaMedia";
import { formatFormChangedValue } from "~utils/utils";

const ContactPage = (props: RouteComponentProps | any) => {
  const t = usePrismic();
  const { registrationType = null, ReferredByFormComponent, userInfo } = props;

  const {
    contactFormObj,
    updateMobileActiveTab,
    updateFormValues,
  }: any = useFormContext();

  const deviceType = device();

  const onContactFormFinish = (values: any) => {
    if (deviceType !== "desktop" && deviceType !== "desktopHD") {
      let nextTab = 3;
      if (registrationType === "phoneReg") {
        nextTab = 2;
      }
      updateMobileActiveTab(nextTab);
      const { contacto, usuario } = formData(values);
      const uuid = uuidSolicitud();
      sendContactData({ uuid, contacto });
      if (registrationType === "phoneReg") {
        sendPersonalData({ uuid, usuario });
      }
      setTimeout(() => {
        const elements = window.document.querySelector("#nav-mobile");
        if (elements) {
          elements.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 100);
    }
  };

  const onContactFormFinishFailed = () => {
    setTimeout(() => {
      const elements = window.document.querySelector(".ant-form-item-has-error");
      if (elements) {
        elements.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 100);
  };

  const onContactFormValuesChange = (value: any) => {
    const formattedVal = formatFormChangedValue(value);
    updateFormValues(formattedVal);
  };

  const userInfoInitial = () => {
    let initialValues;
    if (userInfo && userInfo.contacto) {
      initialValues = {
        correoElectronico: userInfo.contacto.correoElectronico,
        confirmEmail: userInfo.contacto.correoElectronico,
        telefonoPrincipal: userInfo.contacto.telefonoPrincipal,
        telefonoSecundario: userInfo.contacto.telefonoSecundario,
        telefonoFijo: userInfo.contacto.telefonoFijo,
      };
    }
    if (userInfo && userInfo.usuario) {
      const names = userInfo.usuario.nombres.split(" ");
      const separator = (name: string) => (name !== "UNDEFINED" || !name ? name : "");
      const primerNombre = separator(names.shift());
      const segundoNombre = separator(names.pop());
      initialValues = {
        ...initialValues,
        primerNombre: primerNombre,
        segundoNombre: segundoNombre,
        primerApellido: userInfo.usuario.primerApellido,
        segundoApellido: userInfo.usuario.segundoApellido,
      };
    }
    return initialValues;
  };

  return (
    <React.Fragment>
      <Form
        id="contactForm"
        name="contactForm"
        autoComplete="off"
        form={contactFormObj}
        scrollToFirstError={true}
        layout="vertical"
        onFinish={onContactFormFinish}
        onValuesChange={onContactFormValuesChange}
        onFinishFailed={onContactFormFinishFailed}
        initialValues={userInfoInitial()}
      >
        <Row gutter={rowTitleGutter} justify="start" align="top">
          <Col flex={"100%"}>
            <SectionTitle
              lightText={t.constants.CONTACT1}
              boldText={t.constants.CONTACT2}
            />
          </Col>
        </Row>

        {registrationType === "phoneReg" ? (
          <React.Fragment>
            <NameForm />
            <EmailPhoneForm registrationType={registrationType} />
            {ReferredByFormComponent}
          </React.Fragment>
        ) : (
          <EmailPhoneForm registrationType={registrationType} />
        )}
        {deviceType !== "desktop" && deviceType !== "desktopHD" && <FooterButtons />}
      </Form>
    </React.Fragment>
  );
};
export default ContactPage;
