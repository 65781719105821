import React from "react";
import NovaventaInputCss from "./NovaventaInputCss";
import IconCheck from "~components/icons/IconCheck";
import { device } from "~utils/NovaventaMedia";

export const NovaventaInput = (props: any) => {
  const {
    extracss = {},
    placeholder,
    showiconcheck = "false", // showiconcheck's value can't be a boolean due to this bug https://github.com/ant-design/ant-design/issues/7798
    id = "",
    maxLength = 50,
    type = "text",
    value,
    onChange,
    onBlur,
    addonBefore,
    disabled,
    prefix = null,
  } = props;

  const deviceType = device();
  const onValueChange = (changedValue: any) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const onInputBlur = (changedValue: any) => {
    if (onBlur) {
      onChange(changedValue);
    }
  };
  return (
    <NovaventaInputCss
      autoComplete="off"
      extracss={extracss}
      value={value}
      onChange={onValueChange}
      placeholder={placeholder}
      id={id}
      data-testid="nova-input"
      maxLength={maxLength}
      type={type}
      suffix={showiconcheck === "true" ? <IconCheck /> : null}
      addonBefore={addonBefore}
      device={deviceType}
      onBlur={onInputBlur}
      disabled={disabled}
      prefix={prefix}
    />
  );
};

export default NovaventaInputCss;
