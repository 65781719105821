import React from "react";
import { useTheme } from "emotion-theming";
import Contact from "~assets/icons/contact.svg";
import { InformationBoxCss } from "./InformationBoxCss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faExclamation } from "@fortawesome/free-solid-svg-icons";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";

const InformationBox = (props: any) => {
  const theme: any = useTheme();
  const {
    text,
    boldText = "",
    kind = "info",
    colorText = kind === "info" ? theme.colors.blueGray200Light : theme.colors.red700,
    backgColor = kind === "info" ? theme.colors.blue50 : theme.colors.red50,
    fill = kind === "info" ? theme.colors.blue400 : theme.colors.red700,
    position = "left",
    hide,
    icon,
    extraCss,
    children,
  } = props;
  const { device, sizeWidth, sizeHeight } = useWindowBreakPoints();

  const resolveChildren = (
    solutionChildren: any,
    solutionKind: any,
    solutionIcon: any
  ) => {
    if (solutionChildren) {
      return solutionChildren;
    } else if (solutionKind !== "info") {
      return (
        <div className="svgCircle">
          <FontAwesomeIcon icon={faExclamation} color={fill} />
        </div>
      );
    } else if (solutionKind === "info" && solutionIcon === "info") {
      return (
        <div className="svgCircle">
          <FontAwesomeIcon icon={faInfo} color={fill} />
        </div>
      );
    } else if (solutionIcon !== "info") {
      return (
        <div id="svgContact">
          |<Contact />
        </div>
      );
    }
  };

  return (
    <div
      data-testid="informationBoxContainer"
      hidden={!hide}
      css={InformationBoxCss({
        kind: kind,
        colorText: colorText,
        backgColor: backgColor,
        fill: fill,
        sizeWidth: sizeWidth,
        sizeHeight: sizeHeight,
        device: device,
        position: position,
        icon: icon,
        extraCss: extraCss,
      })}
    >
      <div data-testid="informationBox" className="container">
        <div data-testid="svgContainer" className="svgContainer">
          {resolveChildren(children, kind, icon)}
        </div>
        <div className="textontainer">
          <span>
            <b>{boldText}</b> {text}
          </span>
        </div>
      </div>
    </div>
  );
};

export default InformationBox;
