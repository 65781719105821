import { Form } from "antd";
import NovaventaInput from "~components/formControls/input/NovaventaInput";
import { usePrismic } from "~hooks/usePrismic";
import { sendEmailValidation } from "~services/selfRegistrationServices";
import { errorDictionary } from "~utils/hooks/HandleHttpErrors";

const ConfirmEmail = () => {
  const t = usePrismic();

  const validateConfirmEmail = ({ getFieldValue }: any) => ({
    async validator(_rule: any, value: any) {
      const email = getFieldValue("correoElectronico");
      if (email !== value) return Promise.reject(t.home.rules.sameEmails);
      const { data } = await sendEmailValidation(value).catch(() => {
        throw Error(t.errors.unexpectedError);
      });
      if (!data?.continuar) {
        if (!data?.codigo) return Promise.reject(t.errors.unexpectedError);
        const message = errorDictionary(`EMAIL${data.codigo}`, t.errors);
        return Promise.reject(message);
      }
      return Promise.resolve();
    },
  });

  return (
    <Form.Item
      name="confirmEmail"
      hasFeedback
      label={t.constants.CONFIRMEMAIL}
      rules={[validateConfirmEmail]}
    >
      <NovaventaInput
        type="email"
        showiconcheck="false"
        style={{ textTransform: "lowercase" }}
      />
    </Form.Item>
  );
};

export default ConfirmEmail;
