import styled from "@emotion/styled";

export const LayoutCss = styled.div`
  position: relative;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
`;

export const ContentCss = styled.main`
  --content-height: calc(
    ${(props: any) => props.windowHeight}px - ${(props: any) => props.headerHeight}px
  );
  display: block;
  width: 100vw;
  height: var(--content-height);
  min-height: var(--content-height);
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1;

  .appWrapper {
    width: 100vw;
    position: relative;
    min-height: calc(var(--content-height) - 110px);

    .center {
      min-height: calc(var(--content-height) - 110px);
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }
  }
`;

export const HeaderCss = styled.header`
  position: sticky;
  top: 0;
  display: block;
  z-index: 2;
`;

export const FooterCss = styled.footer`
  position: absolute;
  width: 100vw;
  z-index: 1;
  bottom: 0px;
`;

export const LoadingCss = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.7);
  z-index: 10;
`;

export const SpinnerCss = styled.div`
  text-align: center;
  background: white;
  border-radius: 15px;
  text-transform: uppercase;
  font-family: Proxima Nova Sbold It;
  width: fit-content;
  position: fixed;
  padding: 1rem;
  left: 45%;
  top: 40%;
  @media screen and (max-width: 600px) {
    top: 40%;
    left: 32%;
  }
`;

export const OutOfService = styled.div`
  background-color: #f0f3f8;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  h3 {
    padding-top: 1rem;
    color: #345eea;
    font-family: "Proxima Nova Sbold";
  }
  h1 {
    text-transform: uppercase;
  }
`;
