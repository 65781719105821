import { css } from "@emotion/core";
import styled from "@emotion/styled";

const commonStyles = (props: any) =>
  css`
    height: auto;
    span.registerText {
      border-bottom: 1px solid ${props.theme.colors.blueGray50};
      color: ${props.theme.colors.blueGray900};
      font-family: ${props.theme.fonts.ProximaNova};
      font-weight: normal;
      padding-bottom: 2px;

      span.novaventaText {
        color: ${props.theme.colors.blue400Light};
        font-family: ${props.theme.fonts.ProximaNova};
        font-weight: normal;
      }
    }

    span.activateAccountTxt {
      color: ${props.theme.colors.blueGray800};
      display: block;
      font-family: ${props.theme.fonts.ProximaNovaSbold};
      margin-top: 10px;
    }
  `;

export const HeaderTextMobileWrapper = styled.div`
  ${commonStyles}
  position: relative;
  width: auto;
  max-width: 100%;
  margin: 5%;
  align-self: center;

  span.registerText {
    font-size: 18px;
    display: block;

    span.novaventaText {
      font-size: 18px;
    }
  }

  span.activateAccountTxt {
    font-size: 16px;
  }
`;

export const HeaderTextTabletWrapper = styled.div`
  ${commonStyles}
  position: absolute;
  bottom: 3vh;
  left: 10%;
  margin: 0;
  text-align: left;
  width: auto;

  span.registerText {
    font-size: 24px;
    display: block;

    span.novaventaText {
      font-size: 24px;
    }
  }
  span.activateAccountTxt {
    color: ${(props: any) => props.theme.colors.blueGray200Light};
    font-size: 16px;
    width: 100%;
    margin-top: 5px;
  }
`;

export const HeaderTextDesktopWrapper = styled.div`
  ${commonStyles}
  position: absolute;
  bottom: 3vh;
  left: 10%;
  margin: 0;
  text-align: left;
  width: auto;

  span.registerText {
    font-size: 24px;
    display: block;
    span.novaventaText {
      font-size: 24px;
    }
  }
  span.activateAccountTxt {
    color: ${(props: any) => props.theme.colors.blueGray200Light};
    font-size: 16px;
    width: 100%;
  }
`;
