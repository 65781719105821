import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import UploadContainer from "~components/uploadImage/DropzoneImage/UploadContainer";
import { usePrismic } from "~hooks/usePrismic";

const Layout = ({ input, previews, type }: any) => {
  const t = usePrismic();
  const analyticsClassNameAdd = type === "front" ? "add1" : "add2";
  return previews.length <= 0 ? (
    <UploadContainer
      statusBox={<span>{t.constants.IMAGENBIBLIOTECA}</span>}
      removeCancelBtn={{ el: input, fn: null }}
      analyticsClassName={analyticsClassNameAdd}
    ></UploadContainer>
  ) : (
    previews
  );
};

export default Layout;
