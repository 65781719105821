import axios from "axios";
import { setObject } from "~utils/hooks/localStorage";
import { JWTDecode } from "~utils/utils";
const baseURL: any = process.env.API_DEVELOP;

export function getDocumentType(source: any = null) {
  const cancelToken = source ? { cancelToken: source.token } : {};
  return axios.get(`${baseURL}/tipos-documentos`, cancelToken);
}

export function sendUserRegistration(data: any) {
  let aux = {};
  if (localStorage.getItem("token")) {
    aux = {
      headers: {
        Authorization: localStorage.getItem("token"),
        UserJWT: JWTDecode(),
      },
    };
  }
  return axios
    .post(`${baseURL}/comandos/iniciar`, data, aux)
    .then((result: any) => {
      setObject("uuid", result.data.uuid);
      return result.data;
    })
    .catch((e) => console.log("sendUserRegistration catch:", e));
}

export function getReferredBy() {
  return axios.get(`${baseURL}/como-conociste-novaventa`);
}
export function sendReferredIdNumber(id: string) {
  return axios.post(`${baseURL}/comandos/validar/ml`, { cedula: id });
}

export function sendCellPhoneValidation(num: string) {
  return axios.post(`${baseURL}/comandos/validar/celular`, { celular: num });
}

export function whatsAppValidation(num: string, prefix: string) {
  return axios.post(`${baseURL}/comandos/validar/whatsapp`, {
    prefijo: prefix,
    celular: num,
  });
}

export function sendEmailValidation(email: string) {
  return axios.post(`${baseURL}/comandos/validar/email`, { email });
}

export function sendHomePhoneValidation(num: string) {
  return axios.post(`${baseURL}/comandos/validar/fijo`, { fijo: num });
}

export function sendAddressValidation(data: any) {
  let headers: any = { Authorization: localStorage.getItem("token") };

  if (location.pathname.search("auto-gestion") > 0) {
    headers = "";
  }
  return axios.post(
    `${baseURL}/comandos/validar/direccion/${localStorage.getItem("uuid")}`,
    data,
    {
      headers,
    }
  );
}
