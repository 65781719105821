import { Form } from "antd";
import NovaventaInput from "~components/formControls/input/NovaventaInput";
import {
  validateCellPhone,
  sendExpertCall,
} from "~components/forms/validations/formValidations";
import { usePrismic } from "~hooks/usePrismic";

const PrincipalCellPhone = ({ registrationType }: any) => {
  const t = usePrismic();

  return (
    <Form.Item
      hasFeedback
      name="telefonoPrincipal"
      label={
        registrationType === "ContactExpert"
          ? t.constants.CUALESTUCEL
          : t.constants.NUMEROCELULARPPAL
      }
      rules={[
        { required: true, message: t.home.rules.required },
        sendExpertCall,
        {
          validator: (_: any, value: string) => validateCellPhone(value, t.home.rules),
        },
      ]}
    >
      <NovaventaInput type="tel" showiconcheck="false" />
    </Form.Item>
  );
};

export default PrincipalCellPhone;
