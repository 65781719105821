import { Form } from "antd";
import NovaventaInput from "~components/formControls/input/NovaventaInput";
import { usePrismic } from "~hooks/usePrismic";
import { validateEmail } from "../validations/formValidations";

const Email = () => {
  const t = usePrismic();
  return (
    <Form.Item
      name="correoElectronico"
      hasFeedback
      label={t.constants.EMAIL}
      data-testid="email-input"
      rules={[
        { validator: (_: any, value: string) => validateEmail(value, t.home.rules) },
      ]}
    >
      <NovaventaInput
        type="email"
        showiconcheck="false"
        style={{ textTransform: "lowercase" }}
      />
    </Form.Item>
  );
};

export default Email;
