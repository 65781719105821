import { useEffect, useState } from "react";
import theme from "~styles/theme";
import useWindowSize from "./useWindowSize";
const useWindowBreakPoints = () => {
  const [sizeWidth, setSizeWidth] = useState(theme.breakPoints.tablet);
  const [sizeHeight, setSizeHeight] = useState(theme.breakPoints.maxTabletHeight);
  const [device, setDevice] = useState("");
  const { width } = useWindowSize();

  useEffect(() => {
    if (width)
      if (width < theme.breakPoints.desktop && width > theme.breakPoints.mobile) {
        setDevice("tablet");
        setSizeWidth(theme.breakPoints.maxTabletWith);
        setSizeHeight(theme.breakPoints.maxTabletHeight);
      } else if (width >= theme.breakPoints.desktop) {
        setDevice("desktop");
        setSizeWidth(theme.breakPoints.maxDesktopWith);
        setSizeHeight(theme.breakPoints.maxDesktopHeight);
      } else if (width <= theme.breakPoints.mobile) {
        setDevice("mobile");
        setSizeWidth(theme.breakPoints.maxMobileWith);
        setSizeHeight(theme.breakPoints.maxMobileHeight);
      }
  }, [width, theme]);

  return { device, sizeWidth, sizeHeight };
};
export default useWindowBreakPoints;
