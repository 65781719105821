const novaventaColors = {
  gray50: "#F0F3F8",
  gray100: "#DAE2EA",

  gray600: "#707070",
  gray700: "#5B5B5B",
  blue50: "#EAEFFD",
  blue100: "#C9D4F5",
  blue400: "#345EEA",
  blue400Light: "#335FEA",
  blue400Lighter: "#355FEA",
  blue300: "#819BEF",
  blue300Light: "#7B97EF",
  blue300Lighter: "#8BA3F0",
  blueGray50: "#DBE0F0",
  blueGray100: "#D1D9E4",
  blueGray200Light: "#879AB9",
  blueGray200: "#869AB8",
  blueGray800: "#3F4B5F",
  blueGray900: "#161C2D",
  black900: "#00000029",
  green50: "#edf8f5",
  green300: "#42BA96",
  white50: "#FFFFFF",
  red50: "#E53E3E",
  red700: "#ff0000",
  indigo50: "#E9EEFC",
};

export default novaventaColors;
