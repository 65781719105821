export const setObject: any = (key: string, value: any = "") => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error("setObject:" + e);
  }
};
export const getObject: any = (key: string) => {
  try {
    const result: any = localStorage.getItem(key);
    if (result) {
      try {
        return JSON.parse(result.value);
      } catch {
        return result;
      }
    }
  } catch (e) {
    console.error("getObject:" + e);
  }
};
export const removeObject: any = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error("removeObject:" + e);
  }
};
export const clearAll: any = () => {
  try {
    localStorage.clear();
  } catch (e) {
    console.error("clearAll:" + e);
  }
};
