import { RouteComponentProps } from "@reach/router";
import React from "react";
import NovaventaLayout from "~components/NovaventaLayout";
import RegistrationChannel from "~components/RegistrationChannel";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";

const RegistrationChannelPage: React.FC<RouteComponentProps> = () => {
  const { device, sizeWidth, sizeHeight } = useWindowBreakPoints();

  return (
    <NovaventaLayout isLogged={true} deviceType={device} percent={0} showBackBtn={false}>
      <div>
        <RegistrationChannel
          sizeWidth={sizeWidth}
          sizeHeight={sizeHeight}
          device={device}
        />
      </div>
    </NovaventaLayout>
  );
};
export default RegistrationChannelPage;
