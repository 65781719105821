import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { usePrismic } from "~hooks/usePrismic";
import DropzoneImage from "~components/uploadImage/DropzoneImage/DropzoneImage";
import IdIcon from "~components/uploadImage/IdIcon";
import HelpLink from "~components/helpLink/HelpLink";

const DesktopTabletUploadImageRow = ({
  type,
  document,
  handleHelpLinkClick,
  showUserImageData,
  onDataChange,
}: any) => {
  const t = usePrismic();
  const [data, setData] = useState("");

  useEffect(() => {
    setData(onDataChange);
  }, [onDataChange]);
  return (
    <Row gutter={[48, 48]} justify="start" align="middle">
      <Col flex="0 1 auto">
        <IdIcon type={type} />
        <HelpLink
          text={t.constants.NECESITASAYUDA}
          handleClick={handleHelpLinkClick}
          gray={true}
          analyticsClassName={type === "front" ? "help2" : "help3"}
        />
      </Col>

      <Col flex="4">
        <Row>
          <Col flex="auto">
            <p>
              {type === "front"
                ? t.constants.ADJUNTARIMAGENFRONTAL
                : t.constants.ADJUNTARIMAGENPOSTERIOR}
            </p>
          </Col>
        </Row>
        <DropzoneImage
          type={type}
          document={document}
          showUserImageData={showUserImageData}
          onDataChange={data}
        />
      </Col>
    </Row>
  );
};

export default DesktopTabletUploadImageRow;
