import { datadogRum } from "@datadog/browser-rum";
import { css } from "@emotion/core";
import { navigate } from "@reach/router";
import { Button, Col, Form, Row } from "antd";
import { useTheme } from "emotion-theming";
import { useEffect, useState } from "react";
import InformationBox from "~components/InformationBox/InformationBox";
import ModalMessage from "~components/ModalMessage";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { NovaventaInput } from "~components/formControls/input/NovaventaInput";
import { NovaventaInputPassword } from "~components/formControls/input/inputPassword/NovaventaInputPassword";
import { usePrismic } from "~hooks/usePrismic";
import { sendLeaderMomLogin } from "~services/LeaderMomLoginServices";
import { errorDictionary } from "~utils/hooks/HandleHttpErrors";
import { login } from "~utils/hooks/cognitoAws";
import { setObject } from "~utils/hooks/localStorage";

const fomCss = css`
  display: table;
  margin: 0 auto;
  input,
  select {
    max-width: 381px;
    width: 100%;
  }
`;

const UserPasswordForm = (props: any) => {
  const t = usePrismic();
  const theme: any = useTheme();
  const { registrationType, navigationPath } = props;
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalKindText, setModalKindText] = useState("success");
  const [modalBtnText, setModalBtnText] = useState("Continuar");
  const [errorMessage, setErrorMessage] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    setModalKindText("success");
    setModalBtnText("Continuar");
  });

  const onFinish = (values: any) => {
    datadogRum.setUser({
      id: btoa(values.username),
      funcionario: values.username,
      canal: navigationPath.split("/")[1],
      prospecto: null,
    });
    if (registrationType === "inPerson") {
      submitLeaderMomLogin({
        customerId: values.username,
        credencial: values.password,
      });
    } else {
      submitUserLogin({ username: values.username, password: values.password });
    }
  };
  const onhandleEnd = () => {
    navigate("/registro-telefonico/login-usuario");
  };
  const submitUserLogin = async (data: any) => {
    await login(data.username, data.password)
      .then((result: any) => {
        if (
          navigationPath.includes("admin") &&
          (!result.accessToken.payload["cognito:groups"] ||
            !result.accessToken.payload["cognito:groups"].includes("Administrador"))
        ) {
          setShowModal(true);
        } else {
          const token = result.accessToken.jwtToken;
          setObject("username", data.username);
          setObject("token", token);
          navigate(navigationPath);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.redirect) {
          navigate("/registro-telefonico/login-usuario/activar-pass");
        }
        setShowError(true);
        setErrorMessage(errorDictionary(err.message, t.errors));
      });
  };

  const submitLeaderMomLogin = (data: any) => {
    sendLeaderMomLogin(data)
      .then((result: any) => {
        if (result.data.code === "200") {
          setObject("token", result.data.token);
          navigate(navigationPath);
        } else if (result.data.code === "401") {
          setShowError(true);
          setErrorMessage(errorDictionary(result.data.message, t.errors));
        } else {
          setShowError(true);
          setErrorMessage(t.errors.generic);
        }
      })
      .catch((e) => {
        setShowError(true);
        setErrorMessage(t.errors.generic);
        console.error("sendLeaderMomLogin catch:", e);
      });
  };

  return (
    <div className="center">
      <Form
        autoComplete="off"
        form={form}
        name="userPasswordForm"
        layout="vertical"
        onChange={() => setShowError(false)}
        onFinish={onFinish}
        onFinishFailed={() => {
          console.log("onFinishFailed");
        }}
        css={fomCss}
      >
        <Form.Item label={t.constants.USUARIO} wrapperCol={{ span: 24 }}>
          <Form.Item
            wrapperCol={{ span: 24 }}
            name="username"
            validateFirst={true}
            rules={[{ required: true, message: t.home.rules.required }]}
            noStyle
          >
            <NovaventaInput maxLength={30} />
          </Form.Item>
        </Form.Item>
        <NovaventaInputPassword label={t.constants.CONTRASEÑA}>
          <Button
            css={css`
              color: ${theme.colors.blueGray200Light};
              font-family: ${theme.fonts.ProximaNovaSbold};
              padding-left: 0px;
            `}
            type="link"
            htmlType="button"
          >
            <u onClick={() => navigate(t.home.linkCommerce)}>
              {t.constants.OLVIDEMICONTRA}
            </u>
          </Button>
        </NovaventaInputPassword>
        <InformationBox
          hide={showError}
          text={errorMessage}
          kind={"error"}
          extraCss={css`
            margin: 1% auto 5% auto;
            width: 100%;
            .container {
              max-width: 381px;
            }
          `}
        />

        <Row align="middle" justify="center">
          <Col>
            <Form.Item wrapperCol={{ span: 24 }}>
              <NovaventaButton showArrow={true} htmlType="submit" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <ModalMessage
        refresh={(e: boolean) => setShowModal(e)}
        showModal={showModal}
        text={t.constants.NOERESADMIN}
        alertText={""}
        btnText={modalBtnText}
        handleClickBtn={modalKindText === "success" ? onhandleEnd : null}
      />
    </div>
  );
};
export default UserPasswordForm;
