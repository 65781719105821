import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { device } from "~utils/NovaventaMedia";
import NovaventaLayout from "~components/NovaventaLayout";

type Props = RouteComponentProps & {
  children: React.ReactNode;
};
const ExpertCall = ({ children }: Props) => {
  const deviceType = device();
  const [isLogged, setIsLogged] = useState(false);
  const [percent, setPercent] = useState(0);
  const [showBackBtn, setShowBackBtn] = useState(false);

  useEffect(() => {
    if (location.pathname.search("login-documento") > 0) {
      setIsLogged(true);
      setPercent(0);
      setShowBackBtn(true);
    } else if (location.pathname.search("datos-prospecto") > 0) {
      setIsLogged(true);
      setPercent(5);
      setShowBackBtn(true);
    } else {
      setIsLogged(false);
    }
  }, [children]);

  return (
    <NovaventaLayout
      isLogged={isLogged}
      deviceType={deviceType}
      percent={percent}
      showBackBtn={showBackBtn}
    >
      {children}
    </NovaventaLayout>
  );
};

export default ExpertCall;
