import * as React from "react";

import { RouteComponentProps } from "@reach/router";
import { Row, Col } from "antd";
import { Content } from "antd/lib/layout/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import novaventaColors from "~styles/colors";
import {
  faCloud,
  faList,
  faDownload,
  faHistory,
  faTools,
  faFileExport,
  faExclamationCircle,
  faUpload,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import { CardsCss } from "./MenuAdminCss";
import { navigate } from "@reach/router";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { usePrismic } from "~hooks/usePrismic";

const MenuAdmin = (_props: RouteComponentProps) => {
  const t = usePrismic();
  const menuItems = [
    {
      icono: faList,
      url: "gestion/listas-internas",
    },
    {
      icono: faDownload,
      url: "gestion/descargar-documentos",
    },
    {
      icono: faUser,
      url: "/registro-telefonico/login-documento",
    },
    {
      icono: faHistory,
      url: "gestion/historial-proceso",
    },
    {
      icono: faTools,
      url: "gestion/integraciones",
    },
    {
      icono: faFileExport,
      url: "gestion/consultar-proceso",
    },
    {
      icono: faExclamationCircle,
      url: "gestion/lista-negra-correos",
    },
    {
      icono: faUpload,
      url: "gestion/carga-documentos",
    },
    {
      icono: faHeadset,
      url: "gestion/llamadas",
    },
    {
      icono: faCloud,
      url: "gestion/integraciones",
    },
  ];
  return (
    <Content style={{ padding: "1rem" }}>
      <Row gutter={[16, 16]}>
        {menuItems.map((e, i) => {
          return (
            <Col
              onClick={() => navigate(e.url)}
              key={`mi${i}`}
              xs={24}
              sm={12}
              md={8}
              className="gutter-row"
            >
              <CardsCss>
                <FontAwesomeIcon
                  icon={e.icono}
                  color={novaventaColors.white50}
                  size="3x"
                />
                <h3>{t.home.menuAdminItems[i]}</h3>
              </CardsCss>
            </Col>
          );
        })}
      </Row>
    </Content>
  );
};

export default MenuAdmin;
