import { navigate } from "@reach/router";
import { Col, Form, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import ModalMessage from "~components/ModalMessage";
import SectionTitle from "~components/SectionTitle";
import FooterButtons from "~components/digitalRegistration/FooterButtons";
import ConfirmImageDataForm from "~components/forms/ConfirmImageDataForm";
import IdBirthdayForm from "~components/forms/IdBirthdayForm";
import TaxRegimenForm from "~components/forms/TaxRegimenForm";
import UploadImageForm from "~components/forms/UploadImageForm";
import { rowTitleGutter } from "~components/forms/formItemsLayout";
import type { DP } from "~hooks/usePrismic";
import { usePrismic } from "~hooks/usePrismic";
import { sendRegistrationForm } from "~services/digitalRegistrationServices";
import { useFormContext } from "~store/FormContext";
import { formData } from "~store/formStore";
import { device } from "~utils/NovaventaMedia";
import { getObject } from "~utils/hooks/localStorage";
import { formatFormChangedValue, mapToObject } from "~utils/utils";

type ShowUserImageData = (props: { key: string; val: boolean }) => void;

type BasicDataComponentProps = {
  showUserImageData: ShowUserImageData;
  registrationType: string;
  setShowModal: (v: boolean) => void;
  showModal: boolean;
  t: DP;
};

const BasicDataComponent = ({
  showUserImageData,
  t,
  registrationType,
  setShowModal,
  showModal,
}: BasicDataComponentProps) => {
  return (
    <React.Fragment>
      <Row gutter={rowTitleGutter} justify="start" align="middle">
        <Col flex={"100%"}>
          <SectionTitle
            lightText={t.constants.BASICDATA1}
            boldText={t.constants.BASICDATA2}
          />
        </Col>
      </Row>
      <TaxRegimenForm />
      {registrationType === "phoneReg" ? (
        <IdBirthdayForm showHelpMessage={true} />
      ) : (
        <UploadImageForm showUserImageData={showUserImageData} />
      )}

      <ModalMessage
        refresh={setShowModal}
        showModal={showModal}
        text={""}
        alertText={t.constants.errorProcess}
        btnText={t.constants.CERRAR}
        kind={"Error"}
      />
    </React.Fragment>
  );
};

const BasicDataPage = (props: any) => {
  const { registrationType, userInfo, uri } = props;
  const t = usePrismic();
  const deviceType = device();
  const {
    basicDataFormObj,
    addressFormObj,
    contactFormObj,
    formValues,
    updateFormValues,
  } = useFormContext();
  const [showUserImageForm, setShowUserImageForm] = useState(false);
  const confirmFormRef = React.useRef(null);
  const idType = getObject("idType");
  const documentSent: any = [];
  const [showModal, setShowModal] = useState(false);
  const [showModalErrors, setShowModalErrors] = useState(false);

  const updateConfirm = (value: any) => {
    confirmFormRef.current = value;
  };

  const showUserImageData = (update: any) => {
    const exists = documentSent.find((val: any) => {
      return val.key === update.key;
    });

    if (!exists) {
      documentSent.push(update);
    } else if (!update.val && exists) {
      const imgIndex = documentSent.findIndex((obj: any) => obj.key === update.key);
      if (imgIndex > -1) {
        documentSent.splice(imgIndex, 1);
      }
    }

    if (idType === "PEP" && documentSent.find((val: any) => val.key === "idImageBack")) {
      updateConfirm(true);
    } else if (idType !== "PEP" && documentSent.length > 1) {
      updateConfirm(true);
    } else {
      updateConfirm(false);
    }
  };

  const sendUserFormData = () => {
    Promise.all([
      addressFormObj.validateFields(),
      contactFormObj.validateFields(),
      basicDataFormObj.validateFields(),
    ])
      .then(() => {
        const formValuesObj = mapToObject(formValues);
        const formObject = formData(formValuesObj, userInfo);

        sendRegistrationForm(formObject)
          .then((response: any) => {
            if (response.data) {
              if (registrationType === "phoneReg") {
                navigate("subir-documento");
              } else {
                navigate("metodo-pago");
              }
            }
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  };

  const reloadImage = () => {
    setShowUserImageForm(false);
    addressFormObj.resetFields();
  };

  const userInfoInitial = () => {
    if (userInfo && userInfo.usuario) {
      return {
        regimenTributario: userInfo.usuario.regimenTributario,
        fechaNacimiento: userInfo.usuario.fechaNacimiento
          ? moment(userInfo.usuario.fechaNacimiento)
          : undefined,
        lugarNacimiento: userInfo.usuario.lugarNacimiento,
        lugarExpedicionDocumento: userInfo.usuario.lugarExpedicionDocumento,
        sexo: userInfo.usuario.sexo,
      };
    }
    return {};
  };

  const onBasicDataFormFinish = () => {
    Promise.all([
      addressFormObj.validateFields(),
      contactFormObj.validateFields(),
      basicDataFormObj.validateFields(),
    ])
      .then(() => {
        const formValuesObj = mapToObject(formValues);
        const formObject = formData(formValuesObj, userInfo);
        if (registrationType === "phoneReg") {
          sendRegistrationForm(formObject)
            .then((response: any) => {
              if (response.data) {
                navigate("subir-documento");
              }
            })
            .catch((error) => console.error(error));
        }

        if (confirmFormRef.current) {
          setShowUserImageForm(true);
        } else {
          setShowUserImageForm(false);
        }
        if (showUserImageForm) {
          sendUserFormData();
        }
      })
      .catch((error) => {
        console.error(error);
        setShowModalErrors(true);
      });
  };

  const onBasicDataFormValuesChange = (value: any) => {
    const formattedVal = formatFormChangedValue(value);
    updateFormValues(formattedVal);
  };

  const onBasicDataFormFinishFailed = () => {
    setShowModalErrors(true);
    if (deviceType === "desktop" || deviceType === "desktopHD") {
      addressFormObj.submit();
      contactFormObj.submit();
    }
  };

  return (
    <React.Fragment>
      <Form
        data-testid="basicDataForm"
        name="basicDataForm"
        autoComplete="off"
        form={basicDataFormObj}
        scrollToFirstError={true}
        layout="vertical"
        preserve={true}
        onFinish={onBasicDataFormFinish}
        onValuesChange={onBasicDataFormValuesChange}
        onFinishFailed={onBasicDataFormFinishFailed}
        initialValues={userInfoInitial()}
      >
        {showUserImageForm ? (
          <ConfirmImageDataForm reloadImage={reloadImage} />
        ) : (
          <BasicDataComponent
            showUserImageData={showUserImageData}
            t={t}
            registrationType={registrationType}
            setShowModal={(flag: boolean) => setShowModal(flag)}
            showModal={showModal}
          />
        )}
        <Row>
          <Col flex={"1 0 100%"}>
            <FooterButtons buttonType="submit" uri={uri} />
          </Col>
        </Row>
      </Form>
      <ModalMessage
        refresh={(flag: boolean) => setShowModalErrors(flag)}
        showModal={showModalErrors}
        alertText={t.constants.errorOperation}
        text={t.constants.errorForm}
        btnText={t.constants.CERRAR}
        kind={"Error"}
      />
    </React.Fragment>
  );
};

export default BasicDataPage;
