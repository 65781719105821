import { datadogRum } from "@datadog/browser-rum";
import css from "@emotion/css";
import { useNavigate } from "@reach/router";
import { Form } from "antd";
import { RichText } from "prismic-reactjs";
import { useEffect, useState } from "react";
import ModalMessage from "~components/ModalMessage";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import IdFormItems from "~components/forms/idForm/idFormItems";
import TermsAndConditions from "~components/termAndConditions/TermsAndConditions";
import { usePrismic } from "~hooks/usePrismic";
import { getUserInfo } from "~services/faceToFaceRegistraionService";
import { fetchPrismicDataTermsAndConditions } from "~services/prismicService";
import { sendUserRegistration } from "~services/selfRegistrationServices";
import { errorDictionary } from "~utils/hooks/HandleHttpErrors";
import { removeObject, setObject } from "~utils/hooks/localStorage";
import { fomCss } from "./IdFormCss";

const IdForm = (props: any) => {
  const {
    center = "center",
    phoneRegister,
    quitLink,
    navigationPath,
    isProspectCustomer = null,
    registrationType,
    test = false,
  } = props;

  const t = usePrismic();

  const navigate = useNavigate();
  const [termAnConditionsText, setTermAndConditionsText] = useState<any>(null);
  const [termAnConditionsVersion, setTermAndConditionsVersion] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);
  const [modalKindText, setModalKindText] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [modalAlertText, setModalAlertText] = useState("");
  const [modalTextModal, setModalTextModal] = useState("");
  const [uuid, setUuid] = useState("");
  const [userInfo, setUserInfo] = useState({});
  let idType = "";
  let idNumber = "";

  useEffect(() => {
    removeObject("autocomplete");
    if (!test) {
      fetchPrismicDataTermsAndConditions().then((response: any) => {
        setTermAndConditionsVersion(response.version);
        setTermAndConditionsText(
          <RichText
            render={
              registrationType == "phoneReg"
                ? response["texto-canal-telefonico"]
                : response.texto
            }
          />
        );
      });
    }
  }, []);

  const onFinish = (values: any) => {
    idType = values.idTipoDocumento;
    idNumber = values.numeroDocumento;
    const canal = navigationPath.split("/")[1];
    const { id, funcionario } = datadogRum.getUser();
    datadogRum.setUser({
      id: btoa(String(id ?? idType + idNumber)),
      funcionario: funcionario ?? "user",
      prospecto: idNumber,
      canal: navigationPath.split("/")[1],
    });
    const postData = {
      prospecto: {
        documento: {
          idTipoDocumento: values.idTipoDocumento,
          registro: values.numeroDocumento,
        },
        canal,
        versionTyC: termAnConditionsVersion,
      },
    };
    submitUserRegistration(postData);
  };

  const submitUserRegistration = (data: any) => {
    sendUserRegistration(data)
      .then(function (response: any) {
        setObject("idType", idType);
        setObject("idNumber", idNumber);
        if (response.continuar) {
          onHandleNavigate();
        } else {
          if (response.codigo == "E-13") {
            removeObject("token");
            removeObject("idType");
            removeObject("idNumber");
            window.location.reload();
          } else if (
            (location.pathname.includes("/auto-gestion") ||
              location.pathname.includes("/llamada-experto")) &&
            response.codigo == "E-4"
          ) {
            console.error("cancel Autocomplete");
            onHandleNavigate();
          } else {
            setModalTextModal(errorDictionary(`MSG${response.codigo}`, t.errors));
            setModalAlertText(errorDictionary(`ART${response.codigo}`, t.errors));
            setModalBtnText(errorDictionary(`BTN${response.codigo}`, t.errors));
            setModalKindText(errorDictionary(`KND${response.codigo}`, t.errors));
            setUuid(response.uuid);
            setShowModal(true);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onHandleNavigate = () => {
    if (modalAlertText.includes("iniciada")) {
      setObject("autocomplete", true);
    }
    navigate(navigationPath, { state: { userInfo } });
  };

  useEffect(() => {
    if ((registrationType === "phoneReg" || registrationType === "otherReg") && uuid) {
      getUserInfo(uuid)
        .then(({ data }) => setUserInfo(data))
        .catch((error) => console.error(error));
    }
  }, [uuid]);

  return (
    <div className={center}>
      <div css={css``}>
        <Form
          data-testid="idForm"
          autoComplete="off"
          name="idForm"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          css={fomCss}
        >
          <IdFormItems isProspectCustomer={isProspectCustomer} />

          <TermsAndConditions
            hide={phoneRegister}
            termAnConditionsText={termAnConditionsText}
          />

          <Form.Item>
            <NovaventaButton showArrow={true} htmlType="submit" />
          </Form.Item>

          {quitLink}
        </Form>
      </div>
      <ModalMessage
        refresh={(e: boolean) => setShowModal(e)}
        showModal={showModal}
        text={modalTextModal}
        alertText={modalAlertText}
        btnText={modalBtnText}
        kind={modalKindText}
        handleClickBtn={modalAlertText.includes("iniciada") ? onHandleNavigate : null}
      />
    </div>
  );
};

export default IdForm;
