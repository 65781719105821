import React from "react";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { PxToVx } from "~utils/hooks/VH&VWConvert";

const H3Novaventa = (props: any) => {
  const theme: any = useTheme();
  const { text, sizeWidth = theme.breakPoints.maxDesktopWith, extraCss } = props;
  const headerCss = css`
    color: ${theme.colors.blueGray900};
    font-size: clamp(
      ${theme.vars.h3FontSizeMin},
      ${PxToVx(28, sizeWidth, "vw")},
      ${theme.vars.h3FontSizeMax}
    );
    font-family: ${theme.fonts.ProximaNovaSbold};
    ${extraCss}
  `;

  return <h3 css={headerCss}>{text}</h3>;
};

export default H3Novaventa;
