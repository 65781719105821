import { css } from "@emotion/core";
import { Col, Form, Row, Switch } from "antd";
import React, { useEffect } from "react";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { NovaventaTextArea } from "~components/formControls/textArea/NovaventaTextArea";
import { usePrismic } from "~hooks/usePrismic";
import { UpdateCallRegister } from "~services/callsServices";

const WasCalled = () => {
  return (
    <Col span={10} xs={20} md={10}>
      <Form.Item label="Se realizó la llamada?">
        <Form.Item name="llamadaRealizada" valuePropName="checked" noStyle>
          <Switch checkedChildren="Si" unCheckedChildren="No" />
        </Form.Item>
      </Form.Item>
    </Col>
  );
};

const Comments = ({ message }: { message: string }) => {
  return (
    <Col span={10} xs={20} md={10}>
      <Form.Item
        name="observaciones"
        label="Observaciones"
        hasFeedback
        rules={[{ required: true, message }]}
      >
        <NovaventaTextArea showiconcheck="false" maxLength={50} />
      </Form.Item>
    </Col>
  );
};

const CallsForm = (props: any) => {
  const t = usePrismic();
  const { hidden, refresh, handleClick, showTable, showForm, data } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      observaciones: data?.observaciones || "",
      llamadaRealizada: data?.llamadaRealizada || false,
    });
  }, [data?.llamadaRealizada, data?.observaciones, form]);

  const onFinish = (values: any) => {
    submitCallUpdate(values);
  };
  const submitCallUpdate = (dataform: any) => {
    UpdateCallRegister(data.id, dataform)
      .then(() => {
        refresh(true);
        form.resetFields();
        refresh(false);
        showTable();
        showForm();
      })
      .catch((error) => console.error(error));
  };
  return (
    <React.Fragment>
      <Form form={form} onFinish={onFinish} name="InternalList" layout="vertical">
        <Row gutter={24} justify="center" align="middle" hidden={hidden}>
          <WasCalled />
          <Comments message={t.home.rules.required} />
          <Col span={10} xs={20} md={10}>
            <NovaventaButton
              btnText={t.constants.CANCELAR}
              extracss={css`
                margin: 21px auto 34px auto;
                float: right;
              `}
              handleClick={handleClick}
            />
          </Col>
          <Col span={10} xs={20} md={10}>
            <NovaventaButton
              btnText={t.constants.ENVIAR}
              htmlType="submit"
              extracss={css`
                margin: 21px auto 34px auto;
              `}
            />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};
export default CallsForm;
