import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: process.env.DATADOG_APP_ID ?? "",
  clientToken: process.env.DATADOG_CLIENT_TOKEN ?? "",
  site: "datadoghq.com",
  service: process.env.DATADOG_SERVICE_NAME ?? "",
  env: process.env.NODE_ENV ?? "production",
  version: "1.0.0",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [process.env.API_DEVELOP ?? ""],
});
