import { css } from "@emotion/core";
import { Form } from "antd";
import { useState } from "react";
import { usePrismic } from "~hooks/usePrismic";
import novaventaTheme from "~styles/theme";
import ModalMessage from "./ModalMessage";

type Props = { analyticsClassName?: string };

const QuitRegistration = ({ analyticsClassName }: Props) => {
  const t = usePrismic();
  const [showModal, setShowModal] = useState(false);
  const quitCss = css`
    text-align: center;
    font-size: 19px;
    a {
      color: ${novaventaTheme.colors.blue400Lighter};
      font-weight: 100;
      text-decoration: underline;
    }
  `;

  return (
    <div className={analyticsClassName}>
      <Form.Item css={quitCss}>
        <a onClick={() => setShowModal(true)}>{t.constants.ABANDONARPROCESO}</a>
      </Form.Item>
      <ModalMessage
        refresh={(flag: boolean) => setShowModal(flag)}
        showModal={showModal}
        text={t.constants.ESTASEGURO}
        alertText={t.constants.ABANDONARINS}
        btnText={t.constants.SIABANDONAR}
        kind={"information"}
      />
    </div>
  );
};

export default QuitRegistration;
