import { Form } from "antd";
import { useState } from "react";
import NovaventaInput from "~components/formControls/input/NovaventaInput";
import { validateCellPhone } from "~components/forms/validations/formValidations";
import { usePrismic } from "~hooks/usePrismic";
import { whatsAppValidation } from "~services/selfRegistrationServices";

export const WhatsAppNumber = () => {
  const t = usePrismic();
  const [message] = useState("");

  const validateWhatsAppNumber = ({ getFieldValue }: any) => ({
    async validator(_rule: any, value: any) {
      const prefix = getFieldValue("prefixCellPhone");
      if (!prefix) return Promise.reject(t.home.rules.selectPrefix);
      await validateCellPhone(value, t.home.rules);
      const { data: dataW } = await whatsAppValidation(value, prefix).catch(() => {
        throw Error(t.errors.unexpectedError);
      });
      if (!dataW?.continuar) return Promise.reject(t.home.rules.whatsAppExists);
      return Promise.resolve();
    },
  });

  return (
    <Form.Item
      name="telefonoSecundario"
      hasFeedback
      label={t.constants.CUALESTUNUMEROCELULARWHATSAPP}
      rules={[validateWhatsAppNumber]}
    >
      <NovaventaInput
        id="telefonoSecundario"
        value={message}
        type="tel"
        showiconcheck="false"
      />
    </Form.Item>
  );
};
