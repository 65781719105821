import { createContext } from "react";
import { DocumentState, Selfie } from "./interfaceSelfie";

export type DocumentContextProps = {
  documentState: DocumentState;
  addDocument: (selfie: Selfie) => void;
  resetDocument: () => void;
};

export const DocumentContext = createContext<DocumentContextProps>(
  {} as DocumentContextProps
);
