import React, { useState, useEffect } from "react";
import { css } from "@emotion/core";
import { Modal } from "antd-mobile";
import { useTheme } from "emotion-theming";
import { CheckCircleTwoTone, InfoCircleFilled } from "@ant-design/icons";
import { PxToVx } from "~utils/hooks/VH&VWConvert";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import InformationBox from "~components/InformationBox/InformationBox";
import { usePrismic } from "~hooks/usePrismic";
import { Form, Row, Col } from "antd";
import ModalForm from "./forms/ModalForm";
import { navigate } from "@reach/router";
import { sendWalletLockRegistration } from "~services/walletLockService";

const ModalMessage = (props: any) => {
  const t = usePrismic();
  const theme: any = useTheme();
  const {
    ModalMessageCss = {},
    text,
    alertText,
    btnText,
    sizeWidth = theme.breakPoints.maxDesktopWith,
    sizeHeigth = theme.breakPoints.maxDesktopHeight,
    showModal = false,
    handleClickBtn,
    kind = "error",
    ComponentCss,
    refresh,
    disabled,
  } = props;

  const [visible, setVisible] = useState(showModal);
  const [modalText, setModalText] = useState(text);
  const [btnModalText, setBtnModalText] = useState(btnText);
  const [alertModalText, setAlertModalText] = useState(alertText);
  const [flagInicio, setflagInicio] = useState(false);
  useEffect(() => {
    setModalText(text);
    setBtnModalText(btnText);
    setAlertModalText(alertText);
  }, [text, alertText, btnText]);
  useEffect(() => {
    if (showModal) {
      openModal();
    }
  }, [showModal]);
  useEffect(() => {
    if (refresh) {
      refresh(visible);
    }
  }, [visible]);
  const closeModal = () => {
    setVisible(false);
    if (flagInicio) {
      if (location.pathname.search("auto-gestion") > 0) {
        navigate("/");
      } else if (
        location.pathname.search("registro-telefonico") > 0 ||
        location.pathname.search("registro-presencial") > 0
      ) {
        navigate("login-usuario");
        location.reload();
      }
    }
  };

  const openModal = () => {
    setVisible(true);
  };
  function closest(el: any, selector: any) {
    const matchesSelector =
      el.matches ||
      el.webkitMatchesSelector ||
      el.mozMatchesSelector ||
      el.msMatchesSelector;
    while (el) {
      if (matchesSelector.call(el, selector)) {
        return el;
      }
      el = el.parentElement;
    }
    return null;
  }
  const onFinish = (values: any) => {
    const postData = {
      fullName: values.fullName,
      cell: values.cell1,
      email: values.correoElectronico,
    };
    submitUserRegistration(postData);
  };
  const submitUserRegistration = (data: any) => {
    sendWalletLockRegistration(data)
      .then(() => {
        closeModal();
        navigate("/");
      })
      .catch((error) => console.error(error));
  };
  const onWrapTouchStart = (e: any) => {
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
      e.preventDefault();
    }
  };

  const onLeave = () => {
    setModalText(t.constants.LAMENTAMOSABANDONO);
    setAlertModalText(t.constants.HASABANDONADO);
    setBtnModalText(t.constants.CERRAR);
    setflagInicio(true);
  };

  const resolveHandleClick = () => {
    if (handleClickBtn) {
      return () => {
        closeModal();
        handleClickBtn();
      };
    } else if (btnModalText === t.constants.SIABANDONAR) {
      onLeave();
    } else if (btnModalText === t.constants.ENVIAR) {
      return false;
    } else {
      return closeModal;
    }
  };

  const resolveTwoToneColor = () => {
    if (alertText === t.constants.REGISTROEXITOSO) {
      return theme.colors.green300;
    } else {
      return theme.colors.blue400;
    }
  };

  const resolveKind = () => {
    if (kind === "information") {
      return (
        <div className="title">
          <InfoCircleFilled
            style={{
              fontSize: `clamp(
              ${theme.vars.boxInfoIconSizeMin},
              ${PxToVx(56, theme.breakPoints.maxDesktopWith, "vw")},
              ${theme.vars.boxInfoIconSizeMax})`,
            }}
            twoToneColor={`${theme.colors.blue400}`}
          />
          <h1>{alertModalText}</h1>
        </div>
      );
    } else {
      return (
        <InformationBox
          hide
          text={alertText}
          kind={kind}
          position="center"
          extraCss={css`
            min-width: clamp(280px, ${PxToVx(435, sizeWidth, "vw")}, 435px);
            margin-top: ${PxToVx(34, sizeHeigth, "vh")};
          `}
        />
      );
    }
  };

  const exCssModal = css`
    border-radius: 14px;
    max-width: clamp(280px, ${PxToVx(435, sizeWidth, "vw")}, 435px);
    min-width: clamp(280px, ${PxToVx(435, sizeWidth, "vw")}, 435px);
    height: clamp(70px, ${PxToVx(78, sizeHeigth, "vh")}, 78px);
    margin: ${PxToVx(21, sizeHeigth, "vh")} auto ${PxToVx(34, sizeHeigth, "vh")} auto;
    span {
      font-size: clamp(
        ${theme.vars.h1FontSizeMin},
        ${PxToVx(24, theme.breakPoints.maxDesktopWith, "vw")},
        ${theme.vars.h1FontSizeMax}
      );
    }
  `;

  return (
    <React.Fragment>
      <div
        css={css`
          ${ComponentCss}
        `}
      >
        <Modal
          closable={true}
          css={css`
            width: 90vw;
            max-width: 872px;
            min-width: 320px;
            overflow: hidden;
            border-radius: 14px;
            font-family: "Proxima Nova";
            .am-modal-close-x {
              display: none;
              filter: invert(30%) sepia(56%) saturate(5049%) hue-rotate(222deg)
                brightness(97%) contrast(90%);
            }

            .textContent p {
              text-align: center;
              opacity: 1;
              margin: ${PxToVx(21, sizeHeigth, "vh")} auto 2% auto;
              padding: 0% 5% 0% 5%;
              font-size: clamp(
                ${theme.vars.h2FontSizeMin},
                ${PxToVx(28, theme.breakPoints.maxDesktopWith, "vw")},
                ${theme.vars.h2FontSizeMax}
              );
            }
            .title {
              margin: 3% 20px;
              display: flex;
              flex-direction: column;
              color: ${theme.colors.blue400};
              font-size: clamp(
                ${theme.vars.h1FontSizeMin},
                ${PxToVx(28, theme.breakPoints.maxDesktopWith, "vw")},
                ${theme.vars.h1FontSizeMax}
              );
              align-items: center;
              margin-bottom: 0px;
            }
            h1 {
              margin-top: 3%;
              color: ${theme.colors.blue400};
              font-size: clamp(
                ${theme.vars.h1FontSizeMin},
                ${PxToVx(36, theme.breakPoints.maxDesktopWith, "vw")},
                ${theme.vars.h1FontSizeMax}
              );
            }
            a {
              color: ${theme.colors.blue400Lighter};
              font-weight: 100;
              text-decoration: underline;
              font-size: clamp(
                ${theme.vars.h1FontSizeMin},
                ${PxToVx(24, theme.breakPoints.maxDesktopWith, "vw")},
                ${theme.vars.h1FontSizeMax}
              );
            }

            ${ModalMessageCss}
          `}
          visible={visible}
          transparent={true}
          onClose={closeModal}
          wrapProps={{ onTouchStart: onWrapTouchStart }}
        >
          {kind === "success" ? (
            <div className="title">
              <CheckCircleTwoTone
                style={{
                  fontSize: `clamp(
                ${theme.vars.boxInfoIconSizeMin},
                ${PxToVx(56, theme.breakPoints.maxDesktopWith, "vw")},
                ${theme.vars.boxInfoIconSizeMax})`,
                }}
                twoToneColor={resolveTwoToneColor()}
              />
              <span>{alertModalText}</span>
            </div>
          ) : (
            resolveKind()
          )}
          <div className={"textContent"}>
            <p>{modalText}</p>
          </div>
          <Form layout="vertical" onFinish={onFinish} data-testid="ModalForm">
            <ModalForm hidden={btnModalText === t.constants.ENVIAR ? false : true} />

            {btnModalText === t.constants.SIABANDONAR ? (
              <Row gutter={24} justify="center" align="middle">
                <Col span={13} xs={20} md={13}>
                  <NovaventaButton
                    disabled={disabled}
                    data-testid="test-modal-message-btn"
                    btnText={btnModalText}
                    htmlType="submit"
                    handleClick={resolveHandleClick()}
                    extracss={exCssModal}
                  />
                </Col>
                <Col span={10} xs={20} md={10}>
                  <a onClick={closeModal}>{t.constants.REGRESARINS}</a>
                </Col>
              </Row>
            ) : (
              <NovaventaButton
                disabled={disabled}
                data-testid="test-modal-message-btn"
                btnText={btnModalText}
                htmlType="submit"
                handleClick={resolveHandleClick()}
                extracss={exCssModal}
              />
            )}
          </Form>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ModalMessage;
