import { Row } from "antd";
import React from "react";
import { usePrismic } from "~hooks/usePrismic";
import { regExp } from "~utils/formRegex";
import { InputText } from "./InputText";

const ModalForm = ({ hidden }: { hidden?: boolean }) => {
  const t = usePrismic();

  return (
    <React.Fragment>
      <Row gutter={24} justify="center" align="middle" hidden={hidden}>
        <InputText
          name="fullName"
          label={t.constants.NOMBREYAPELLIDO}
          placeholder={t.constants.PLACEHOLDERFULLNAME}
          rules={[
            { required: true, message: t.home.rules.required },
            {
              pattern: regExp.alphabetWhiteSpaces,
              message: t.home.rules.invalid,
            },
          ]}
        />
        <InputText
          type="tel"
          name="cell1"
          label={t.constants.CELULAR}
          placeholder={t.constants.PLACEHOLDERCEL}
          rules={[
            { required: true, message: t.home.rules.required },
            { pattern: regExp.cellPhone, message: t.home.rules.number },
          ]}
        />
        <InputText
          name="correoElectronico"
          label={t.constants.CORREO}
          type="email"
          placeholder={t.constants.PLACEHOLDEREMAIL2}
          rules={[
            { required: true, message: t.home.rules.required },
            {
              pattern: regExp.email,
              message: t.home.rules.invalidEmail,
            },
          ]}
        />
      </Row>
    </React.Fragment>
  );
};
export default ModalForm;
