import { css } from "@emotion/core";
import { faLaptop, faMobileAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Link } from "@reach/router";
import { useTheme } from "emotion-theming";
import { useEffect, useState } from "react";
import RegistrationChannelCard from "~components/RegistrationChannelCard";
import { usePrismic } from "~hooks/usePrismic";
import { fetchPrismicDataLandingOptions } from "~services/prismicService";
import { PxToVx } from "~utils/hooks/VH&VWConvert";
import { fontSizeClamp, margin } from "~utils/hooks/useBreakPointTools";

const RegistrationChannel = (props: any) => {
  const t = usePrismic();
  const { sizeWidth, sizeHeight, CardCss, device, ComponentCss } = props;
  const [digital, setDigital] = useState<boolean>(false);
  const [presential, setPresential] = useState<boolean>(false);
  const [phone, setPhone] = useState<boolean>(false);

  const theme: any = useTheme();

  const renderDigital = () => {
    if (digital) {
      return (
        <Link to="/auto-gestion/login-documento">
          <h3>{digital}</h3>
          <RegistrationChannelCard
            CardCss={css`
              ${CardCss}
            `}
            headerText={t.constants.INSDIGITALHEADER}
            text={t.constants.INSDIGITALTEXT}
            icon={faLaptop}
            sizeHeight={sizeHeight}
            sizeWidth={sizeWidth}
            device={device}
          />
        </Link>
      );
    } else {
      return "";
    }
  };
  const renderPresential = () => {
    if (presential) {
      return (
        <Link to="/llamada-experto/login-documento">
          <RegistrationChannelCard
            CardCss={css`
              ${CardCss}
            `}
            headerText={t.constants.CONTUNEXPERTHEADER}
            text={t.constants.CONTUNEXPERTTEXT}
            icon={faUsers}
            sizeHeight={sizeHeight}
            sizeWidth={sizeWidth}
            device={device}
          />
        </Link>
      );
    } else {
      return "";
    }
  };
  const renderPhone = () => {
    if (phone) {
      return (
        <Link to="/llamada-telefonica/datos-telefonicos">
          <RegistrationChannelCard
            CardCss={css`
              ${CardCss}
            `}
            headerText={t.constants.LLAMADATELHEADER}
            text={t.constants.LLAMADATELTEXT}
            icon={faMobileAlt}
            sizeHeight={sizeHeight}
            sizeWidth={sizeWidth}
            device={device}
          />
        </Link>
      );
    } else {
      return "";
    }
  };
  useEffect(() => {
    fetchPrismicDataLandingOptions()
      .then((resp: any) => {
        setDigital(resp["digital"]);
        setPresential(resp["presencial"]);
        setPhone(resp["telefonico"]);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }, [digital]);
  return (
    <div
      className="center"
      id="registrationDiv"
      data-testid="registrationDiv"
      css={css`
        ${ComponentCss}
      `}
    >
      <div
        css={css`
          display: table;
          margin: 0 auto;
        `}
      >
        <header
          css={css`
            font-family: ${theme.fonts.ProximaNovaLight};
            color: ${theme.colors.blue400};
            font-size: ${fontSizeClamp(device, sizeWidth, 36, 24, 24, 20)};
            margin: ${margin({
              device: device,
              sizeHeight: sizeHeight,
              sizeWidth: sizeWidth,
              deskTopM: 80,
              deskLeftM: 0,
              deskBottomM: 40,
              deskRightM: 0,
              tablTopM: 0,
              tablLeftM: 0,
              tablBottomM: 60,
              tablRightM: 0,
              mobTopM: 0,
              mobLeftM: 0,
              mobBottomM: 23,
              mobRightM: 0,
            })};
            ${device === "desktop" || device === "desktopHD"
              ? `margin-bottom:clamp(40px,${PxToVx(27, sizeWidth, "vw")},47px);`
              : ""}
            b {
              font-family: ${theme.fonts.ProximaNovaSbold};
            }
          `}
        >
          <b>{t.constants.COMO}</b> {t.constants.DESEASINS}
        </header>
        {renderDigital()}
        {renderPresential()}
        {renderPhone()}
      </div>
    </div>
  );
};

export default RegistrationChannel;
