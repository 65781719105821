import axios from "axios";
const baseURL: any = process.env.API_DEVELOP;

export function getBlackList() {
  return axios.get(`${baseURL}/lista-negra-correos`, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
}

export function sendBlackListRegister(data: any) {
  return axios
    .post(`${baseURL}/comandos/registrar/bloqueo-correo`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((result: any) => {
      return result.data;
    })
    .catch((e) => console.log("sendBlackListRegister catch:", e));
}
export function deleteBlackListRegister(id: string) {
  return axios
    .delete(`${baseURL}/comandos/eliminar/lista-negra-correos/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((result: any) => {
      try {
        return result.data;
      } catch (e) {
        console.error("deleteBlackListRegister:", e);
      }
    })
    .catch((e) => console.log("deleteBlackListRegister catch:", e));
}
