import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import NovaventaSelect from "~components/formControls/select/NovaventaSelect";
import { getContactCenter } from "~services/PhoneCallService";
import novaventaColors from "~styles/colors";
import { fomCss, phoneCss } from "./PhoneCallCss";
import { usePrismic } from "~hooks/usePrismic";

interface ContactOption {
  id: number;
  ciudad: string;
  telefono: string;
}

const FormOurServiceLines = (_props: any) => {
  const [form] = Form.useForm();
  const [contactOption, setContactOption] = useState<ContactOption[]>([]);
  const [phoneSelected, setPhoneSelected] = useState("");
  const t = usePrismic();

  useEffect(() => {
    getAllContactCenter();
  }, []);

  const getAllContactCenter = () => {
    getContactCenter()
      .then((response) => {
        setContactOption(response.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const onCiudadChange = (id: number) => {
    const ciudadSelected: ContactOption = contactOption.find(
      (ciudad: ContactOption) => ciudad.id == id
    )!;
    form.setFieldsValue({ telefonoOurServiceLines: ciudadSelected.telefono });
    setPhoneSelected(ciudadSelected ? ciudadSelected.telefono : "");
  };

  const handleFinish = () => {
    form.resetFields();
  };

  return (
    <Form
      form={form}
      data-testid="idFormOurServiceLines"
      autoComplete="off"
      name="idFormOurServiceLines"
      layout="vertical"
      initialValues={{}}
      onFinish={handleFinish}
      css={fomCss}
    >
      <Row gutter={24} justify="start" align="top">
        <Col className="gutter-row" span={24}>
          <Form.Item name="ciudad" label={t.home.labels.selectCity}>
            <NovaventaSelect
              options={contactOption}
              placeholder={t.home.labels.selectCity}
              optionsProp={{ label: "ciudad", id: "id" }}
              onChange={onCiudadChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} justify="start" align="top">
        <Col className="gutter-row" span={24}>
          <Form.Item name="telefonoOurServiceLines">
            <div css={phoneCss}>
              <FontAwesomeIcon icon={faPhone} color={novaventaColors.blue400} size="lg" />
              <span>{phoneSelected}</span>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} justify="start" align="top">
        <Col className="gutter-row" span={24}>
          <Form.Item>
            <NovaventaButton btnText={t.constants.call} htmlType="submit" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FormOurServiceLines;
