const novaventaFonts = {
  ProximaNova: "Proxima Nova",
  ProximaNovaLight: "Proxima Nova Light",
  ProximaNovaThin: "Proxima Nova Thin",
  ProximaNovaSbold: "Proxima Nova Sbold",
  ProximaNovaXbold: "Proxima Nova Xbold",
  ProximaNovaBold: "Proxima Nova Bold",
  ProximaNovaBlack: "Proxima Nova Black",
  ProximaNovaSboldIt: "Proxima Nova Sbold It",
};

export default novaventaFonts;
