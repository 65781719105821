import React from "react";
import { css } from "@emotion/core";
import novaventaTheme from "~styles/theme";
import { PxToVx } from "~utils/hooks/VH&VWConvert";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";
import { fontSizeClamp, margin } from "~utils/hooks/useBreakPointTools";

const SectionTitle = (props: any) => {
  const { lightText, boldText, headerCss } = props;
  const { device, sizeWidth, sizeHeight } = useWindowBreakPoints();

  return (
    <span
      css={css`
        display: flex;
        flex-wrap: wrap;
        font-family: ${novaventaTheme.fonts.ProximaNovaLight};
        color: ${novaventaTheme.colors.blue400};
        font-size: ${fontSizeClamp(device, sizeWidth, 34, 24, 24, 24)};
        border-bottom: solid 1px #d1d9e4;
        margin: ${margin({
          device: device,
          sizeHeight: sizeHeight,
          sizeWidth: sizeWidth,
          deskTopM: 0,
          deskLeftM: 0,
          deskBottomM: 34,
          deskRightM: 0,
          tablTopM: 0,
          tablLeftM: 0,
          tablBottomM: 48,
          tablRightM: 0,
          mobTopM: 0,
          mobLeftM: 0,
          mobBottomM: 48,
          mobRightM: 0,
        })};
        margin-top: 26px;
        b {
          font-family: ${novaventaTheme.fonts.ProximaNovaSbold};
          margin-left: ${PxToVx(10, sizeHeight, "vh")};
          font-size: ${fontSizeClamp(device, sizeWidth, 34, 24, 24, 24)};
        }
        ${headerCss}
      `}
    >
      {lightText}
      <b>{boldText}</b>
    </span>
  );
};

export default SectionTitle;
