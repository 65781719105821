export const regExp = {
  alphanumeric: /^[0-9a-zA-ZñÑ]+$/,
  alphanumericWhiteSpace: /^[A-Za-z0-9ñÑ\s]+$/, //Alphanumeric with spaces
  alphanumericSpacesDotHyphen: /^[A-Za-z0-9ñÑ.#-\s]+$/, //Alphanumeric with dot, hyphen and spaces
  number: /^\d+$/, //Only numbers
  maxLength10: /^\d{5,10}$/,
  passport: /^(?!^0+$)[a-zA-Z0-9]{6,9}$/,
  alphabet: /^[A-Za-zñÑ]+$/,
  alphabetWhiteSpaces: /^[A-Za-zñÑ'\-0Ð\s]+$/, //Alphabet with spaces
  alphabetOneSpace: /^[A-z)]+$/, //1 space allowed
  email: /^[\w\-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  cellPhone: /^(?=\d{10}$)(3)\d+/,
  telefonoFijo: /^(?=\d{10}$)(6)\d+|^\d{10}$/,
  telefonoCelFijo: /^(?=\d{10}$)(3-6)\d+/,
  justNumber: /^\d/,
  cognitoPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/,
};
