import React, { useState } from "react";
import { css } from "@emotion/core";
import { usePrismic } from "~hooks/usePrismic";
import { Form, Row } from "antd";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { navigate, RouteComponentProps } from "@reach/router";
import { changePassword, emailUser } from "~utils/hooks/cognitoAws";
import { errorDictionary } from "~utils/hooks/HandleHttpErrors";
import ModalMessage from "~components/ModalMessage";
import { NovaventaInputPassword } from "~components/formControls/input/inputPassword/NovaventaInputPassword";
import { NovaventaInputCodeNumber } from "~components/formControls/input/inputCodeNumber/NovaventaInputCodeNumber";
import ParagraphNova from "~components/ParagraphNova";

const fomCss = css`
  display: table;
  margin: 0 auto;
  input,
  select {
    max-width: 450px;
    width: 100%;
  }
`;

const ChangePasswordForm = (props: RouteComponentProps | any) => {
  const { navigationPath } = props;
  const t = usePrismic();
  const [showModal, setShowModal] = useState(false);
  const [modalKindText, setModalKindText] = useState<string>();
  const [modalBtnText, setModalBtnText] = useState<string>(t.constants.CERRAR);
  const [modalAlertText, setModalAlertText] = useState<string>();
  const [modalText, setModalText] = useState<string>();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const postData = {
      code: `${values.verCode1}${values.verCode2}${values.verCode3}${values.verCode4}${values.verCode5}${values.verCode6}`,
      confirmpassword: values.confirmpassword,
    };
    submitUserChangePassword(postData);
  };

  const submitUserChangePassword = async (data: any) => {
    await changePassword(data.code, data.confirmpassword)
      .then(() => {
        setModalText(t.constants.YAPUEDESINICIARSESION);
        setModalBtnText(t.constants.IRINICIO);
        setModalAlertText(t.constants.LACONTRASEÑAACTUALIZADA);
        setModalKindText("success");
        setShowModal(true);
      })
      .catch(onhandleError);

    form.resetFields();
  };
  const onhandleEnd = () => {
    navigate(navigationPath);
  };
  const onhandleError = (error: any) => {
    console.error("submitUserChangePassword:", error);
    setModalKindText("error");
    setModalText(errorDictionary(error.message, t.errors));
    setModalAlertText(t.constants.LOSENTIMOSERROR);
    setShowModal(true);
  };

  return (
    <div className="center">
      <Form
        autoComplete="off"
        form={form}
        name="changePassword"
        layout="vertical"
        initialValues={{ remember: true }}
        onChange={() => setShowModal(false)}
        onFinish={onFinish}
        onFinishFailed={onhandleError}
        css={fomCss}
        scrollToFirstError
      >
        <Form.Item
          label={`${t.constants.INGRESATUCODIGO} ${emailUser}`}
          css={css`
            margin: 32px 0;
            .ant-form-item-explain {
              margin-top: 9%;
              display: flex;
              flex-wrap: nowrap;
            }
          `}
        >
          <div
            css={css`
              position: absolute;
            `}
          >
            <Row gutter={[16, 10]}>
              <NovaventaInputCodeNumber positionNumber={1} />
              <NovaventaInputCodeNumber positionNumber={2} />
              <NovaventaInputCodeNumber positionNumber={3} />
              <NovaventaInputCodeNumber positionNumber={4} />
              <NovaventaInputCodeNumber positionNumber={5} />
              <NovaventaInputCodeNumber positionNumber={6} />
            </Row>
          </div>
        </Form.Item>

        <NovaventaInputPassword label={t.constants.INGRESETUNUEVACONTRA} regex={true} />
        <NovaventaInputPassword
          label={t.constants.CONFIRMNUEVACONTRA}
          name="confirmpassword"
          confirmPassword={true}
          regex={true}
        />
        <Form.Item>
          <NovaventaButton btnText={t.constants.GUARDARPASSWORD} htmlType="submit" />
        </Form.Item>
        <Form.Item>
          <ParagraphNova
            extraCss={css`
              line-height: 0.4;
            `}
          >
            <p>
              <b>{t.constants.VALIDACIONCONTRASEÑA0}</b>
            </p>
            <p>
              <b>*</b>
              {t.constants.VALIDACIONCONTRASEÑA1}
            </p>
            <p>
              <b>*</b>
              {t.constants.VALIDACIONCONTRASEÑA2}
            </p>
            <p>
              <b>*</b>
              {t.constants.VALIDACIONCONTRASEÑA3}
            </p>
            <p>
              <b>*</b>
              {t.constants.VALIDACIONCONTRASEÑA4}
            </p>
          </ParagraphNova>
        </Form.Item>
      </Form>

      <ModalMessage
        refresh={(e: boolean) => setShowModal(e)}
        showModal={showModal}
        text={modalText}
        alertText={modalAlertText}
        btnText={modalBtnText}
        kind={modalKindText}
        handleClickBtn={modalKindText === "success" ? onhandleEnd : null}
      />
    </div>
  );
};
export default ChangePasswordForm;
