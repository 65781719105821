import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePrismic } from "~hooks/usePrismic";
import novaventaColors from "~styles/colors";

const HelpCss = styled.div`
  text-decoration: underline;
`;

const HelpLink = (props: any) => {
  const t = usePrismic();
  const {
    handleClick,
    helpIcon = true,
    gray = false,
    text = t.constants.NECESITASAYUDA,
    extracss,
    analyticsClassName,
  } = props;

  return (
    <div
      className={analyticsClassName}
      onClick={handleClick}
      css={css`
        color: ${gray ? novaventaColors.blueGray800 : novaventaColors.blue400};
        cursor: pointer;
        div {
          display: inline-block;
          margin: 10px 0;
        }
        svg {
          margin-left: 5px;
        }
        ${extracss}
      `}
    >
      <HelpCss>{text}</HelpCss>
      {helpIcon && <FontAwesomeIcon icon={faQuestionCircle} />}
    </div>
  );
};

export default HelpLink;
