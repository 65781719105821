import { css } from "@emotion/core";
import { Checkbox, Col, Form, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddressSummary from "~components/AddressSummary";
import SectionTitle from "~components/SectionTitle";
import NovaventaInput from "~components/formControls/input/NovaventaInput";
import NovaventaSelect from "~components/formControls/select/NovaventaSelect";
import {
  addressLG,
  addressMD,
  addressXS,
  addressXXL,
  rowGutter,
  rowTitleGutter,
  xs,
} from "~components/forms/formItemsLayout";
import { usePrismic } from "~hooks/usePrismic";
import { getCity, getDepartment, getDistrict } from "~services/addressServices";
import { useFormContext } from "~store/FormContext";
import { regExp } from "~utils/formRegex";

const AddressForm = ({
  showSectionTitle = true,
  deliveryAddressSuffix = "",
  addressSummaryText,
  deliveryAddressSummary,
  addressSummaryWithoutComplement,
  isRequiredValidate = true,
  initialValues,
}: any) => {
  const t = usePrismic();
  const { addressFormObj, cityValues, updateFormValues } = useFormContext();

  const [addressSummaryVal, setAddressSummaryVal] = useState("");
  const [department, setDepartment] = useState<any[]>([]);
  const [city, setCity] = useState([]);
  const [district, setDistrict]: any = useState([]);
  const [isRural, setIsRural] = useState(false);
  const [triggerAddValidation, setTriggerAddValidation]: any = useState();

  useEffect(() => {
    if (!isRural) {
      setAddressSummaryVal(addressSummaryText);
    }
  }, [addressSummaryText, isRural]);

  useEffect(() => {
    if (initialValues) {
      const departament = initialValues[`idDepartamento${deliveryAddressSuffix}`];
      const cityUseEffect = initialValues[`idCiudad${deliveryAddressSuffix}`];
      const direccionOtros = initialValues[`direccionOtros1${deliveryAddressSuffix}`];
      if (departament) {
        getCities(departament);
      }
      if (cityUseEffect) {
        getDistricts(cityUseEffect);
        setTriggerAddValidation(cityUseEffect);
      }
      if (direccionOtros) {
        const event = { target: { checked: true } };
        onOtherOptionsChange(event);
      }
    }
  }, [department]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    getDepartment(source.token).then(({ data }: { data: any[] }) => setDepartment(data));
    return () => source.cancel();
  }, []);

  const onOtherOptionsChange = (e: any) => {
    const selected = e.target.checked;
    setIsRural(selected);
    setAddressSummaryVal("");
    if (selected) {
      const updateFields = [
        `direccion`,
        `direccion1${deliveryAddressSuffix}`,
        `numero1${deliveryAddressSuffix}`,
        `zona1${deliveryAddressSuffix}`,
        `direccion2${deliveryAddressSuffix}`,
        `numero2${deliveryAddressSuffix}`,
        `zona2${deliveryAddressSuffix}`,
        `numero3${deliveryAddressSuffix}`,
        `tipoVivienda${deliveryAddressSuffix}`,
        `interior${deliveryAddressSuffix}`,
        `datosAdicionales${deliveryAddressSuffix}`,
      ];

      addressFormObj.resetFields(updateFields);

      updateFields.forEach((value: any) => {
        updateFormValues({ key: value, val: "" });
      });
      updateFormValues({ key: "addressSummary", val: "" });
    } else {
      addressFormObj.resetFields([`direccionOtros1${deliveryAddressSuffix}`]);
    }
  };

  const onDepartmentChange = (dep: any) => {
    setCity([]);
    setDistrict([]);

    addressFormObj.setFieldsValue({
      [`idCiudad${deliveryAddressSuffix}`]: undefined,
      [`idBarrio${deliveryAddressSuffix}`]: undefined,
    });

    if (dep) {
      getCities(dep);
    }
  };

  const getCities = (dep: any) => {
    getCity(dep)
      .then(({ data }) => setCity(data))
      .catch((error) => console.error(error));
  };

  const onCityChange = (cityChange: any) => {
    setDistrict([]);
    addressFormObj.setFieldsValue({
      [`idBarrio${deliveryAddressSuffix}`]: undefined,
    });
    if (cityChange) {
      getDistricts(cityChange);
    }
  };

  const getDistricts = (cityDistricts: any) => {
    getDistrict(cityDistricts)
      .then(function (response: any) {
        setDistrict(response.data);
      })
      .catch(function (error: any) {
        console.log({ error });
      });
  };

  const onDistrictChange = (val: any) => {
    setAddressSummaryVal(addressSummaryText);
    setTriggerAddValidation(val);
  };

  const onOtrosBlur = () => {
    if (addressFormObj.getFieldValue(`idBarrio${deliveryAddressSuffix}`)) {
      setAddressSummaryVal(addressSummaryText);
    }
  };

  return (
    <React.Fragment>
      {showSectionTitle && (
        <Row gutter={rowTitleGutter} justify="start" align="middle">
          <Col flex={"100%"}>
            <SectionTitle
              lightText={t.constants.ADDRESS1}
              boldText={t.constants.ADDRESS2}
            />
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter} justify="start" align="top">
        <Col xs={addressXS} md={addressMD} lg={addressLG} xxl={addressXXL}>
          <Form.Item
            name={`idDepartamento${deliveryAddressSuffix}`}
            label={t.constants.DEPARTAMENTO}
            rules={[{ required: true, message: t.home.rules.required }]}
          >
            <NovaventaSelect
              id="selectDepartamento"
              loading={!department}
              options={department}
              notFoundContent={null}
              placeholder={t.constants.SELECCIONAR}
              onChange={onDepartmentChange}
              optionsProp={{ label: "nombre", id: "id" }}
              showSearch={true}
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Col>

        <Col xs={addressXS} md={addressMD} lg={addressLG} xxl={addressXXL}>
          <Form.Item
            name={`idCiudad${deliveryAddressSuffix}`}
            label={t.constants.CIUDAD}
            rules={[{ required: true, message: t.home.rules.required }]}
          >
            <NovaventaSelect
              loading={!cityValues}
              options={city}
              placeholder={t.constants.SELECCIONAR}
              onChange={onCityChange}
              optionsProp={{ label: "nombre", id: "id" }}
              showSearch={true}
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Col>

        <Col xs={addressXS} md={addressMD} lg={addressLG} xxl={addressXXL}>
          <Form.Item
            name={`idBarrio${deliveryAddressSuffix}`}
            label={t.constants.BARRIO}
            rules={[{ required: true, message: t.home.rules.required }]}
          >
            <NovaventaSelect
              id="selectBarrio"
              loading={!district}
              options={district}
              placeholder={t.constants.SELECCIONAR}
              onChange={onDistrictChange}
              optionsProp={{ label: "nombre", id: "id" }}
              showSearch={true}
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowTitleGutter} justify="start" align="middle">
        <Col flex={"100%"}>
          <Form.Item
            name={`otros${deliveryAddressSuffix}`}
            valuePropName="checked"
            rules={[{ required: false, message: t.home.rules.required }]}
          >
            <Checkbox onChange={onOtherOptionsChange}>
              {t.constants.DIRECCIONRURAL}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      {isRural ? (
        <Row gutter={rowTitleGutter} justify="start" align="middle">
          <Col flex={"100%"}>
            <Form.Item
              name={`direccionOtros1${deliveryAddressSuffix}`}
              label={t.constants.DIRECCION}
              rules={[
                { required: true, message: t.home.rules.required },
                {
                  pattern: regExp.alphanumericSpacesDotHyphen,
                  message: t.home.rules.address,
                },
              ]}
            >
              <NovaventaInput
                placeholder={t.constants.DIRECCION}
                showiconcheck="false"
                onBlur={onOtrosBlur}
              />
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Row gutter={rowGutter} justify="start" align="top">
          <Col xs={xs} flex={"1 0 150px"}>
            <Form.Item
              name={`direccion1${deliveryAddressSuffix}`}
              label={t.constants.DIRECCION}
              rules={[{ required: true, message: t.home.rules.required }]}
            >
              <NovaventaSelect
                options={t.options.addressStructure.address}
                placeholder={t.constants.DIRPLACEHOLDER}
                optionsProp={{ label: "nombre", id: "id" }}
              />
            </Form.Item>
          </Col>

          <Col xs={xs} flex={"1 0 100px"}>
            <Form.Item
              name={`numero1${deliveryAddressSuffix}`}
              hasFeedback
              label={t.home.labels.number}
              rules={[
                { required: true, message: t.home.rules.required },
                {
                  pattern: regExp.alphanumericWhiteSpace,
                  message: t.home.rules.number,
                },
              ]}
            >
              <NovaventaInput
                placeholder={t.constants.NUMPLACEHOLDER}
                showiconcheck="false"
              />
            </Form.Item>
          </Col>

          <Col xs={xs} flex={"auto"}>
            <Form.Item
              name={`zona1${deliveryAddressSuffix}`}
              label={t.home.labels.zoneOp}
            >
              <NovaventaSelect
                options={t.options.addressStructure.zone}
                placeholder={t.constants.ZONAPLACEHOLDER}
                optionsProp={{ label: "nombre", id: "id" }}
              />
            </Form.Item>
          </Col>

          <Col xs={xs} flex={"1 0 auto"}>
            <Form.Item
              name={`direccion2${deliveryAddressSuffix}`}
              label={t.home.labels.addressOp}
            >
              <NovaventaSelect
                options={t.options.addressStructure.address}
                placeholder={t.constants.DIRPLACEHOLDER}
                optionsProp={{ label: "nombre", id: "id" }}
              />
            </Form.Item>
          </Col>

          <Col
            xs={xs}
            flex={"1 0 100px"}
            css={css`
              margin-left: 50px;
            `}
          >
            <div
              css={css`
                display: block;
                font-size: 25px;
                width: 20px;
                text-align: center;
                position: absolute;
                top: 46px;
                left: -30px;
              `}
            >
              #
            </div>
            <Form.Item
              name={`numero2${deliveryAddressSuffix}`}
              label={t.constants.NUMERO}
              hasFeedback
              rules={[
                { required: true, message: t.home.rules.required },
                {
                  pattern: regExp.alphanumericWhiteSpace,
                  message: t.home.rules.number,
                },
              ]}
            >
              <NovaventaInput
                placeholder={t.constants.NUMPLACEHOLDER}
                showiconcheck="false"
              />
            </Form.Item>
          </Col>

          <Col xs={xs} flex={"auto"}>
            <Form.Item
              name={`zona2${deliveryAddressSuffix}`}
              label={t.home.labels.zoneOp}
            >
              <NovaventaSelect
                options={t.options.addressStructure.zone}
                placeholder={t.constants.ZONAPLACEHOLDER}
                optionsProp={{ label: "nombre", id: "id" }}
              />
            </Form.Item>
          </Col>

          <Col xs={xs} flex={"1 0 100px"}>
            <Form.Item
              name={`numero3${deliveryAddressSuffix}`}
              label={t.constants.NUMERO3}
              hasFeedback
              rules={[
                { required: true, message: t.home.rules.required },
                {
                  pattern: regExp.alphanumericWhiteSpace,
                  message: t.home.rules.number,
                },
              ]}
            >
              <NovaventaInput
                placeholder={t.constants.NUMPLACEHOLDER}
                showiconcheck="false"
              />
            </Form.Item>
          </Col>

          <Col xs={xs} flex={"0 1 auto"}>
            <Form.Item
              name={`tipoVivienda${deliveryAddressSuffix}`}
              label={t.constants.TIPODEVIVIENDA}
              rules={[
                {
                  required: true,
                  message: t.home.rules.required,
                },
              ]}
            >
              <NovaventaSelect
                options={t.options.addressStructure.houseType}
                placeholder={t.constants.TIPOVIVIENDAPLACEHOLDER}
                optionsProp={{ label: "nombre", id: "id" }}
              />
            </Form.Item>
          </Col>

          <Col xs={xs} flex={"0 0 auto"}>
            <Form.Item
              name={`interior${deliveryAddressSuffix}`}
              hasFeedback
              label={t.home.labels.inside}
              rules={[
                {
                  pattern: regExp.alphanumeric,
                  message: t.home.rules.invalid,
                },
              ]}
            >
              <NovaventaInput
                placeholder={t.constants.INTERIORPLACEHOLDER}
                showiconcheck="false"
              />
            </Form.Item>
          </Col>

          <Col xs={xs} flex={"1 0 auto"}>
            <Form.Item
              name={`datosAdicionales${deliveryAddressSuffix}`}
              hasFeedback
              label={t.home.labels.additional}
              rules={[
                {
                  pattern: regExp.alphanumericSpacesDotHyphen,
                  message: t.home.rules.invalid,
                },
              ]}
            >
              <NovaventaInput
                placeholder={t.constants.EXTRAINFOPLACEHOLDER}
                showiconcheck="false"
                maxLength={90}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter} justify="start" align="middle">
        <Col>
          <AddressSummary
            isRequiredValidate={isRequiredValidate}
            addressSummaryText={addressSummaryVal}
            deliveryAddressSummary={deliveryAddressSummary}
            addressSummaryWithoutComplement={addressSummaryWithoutComplement}
            deliveryAddressSuffix={deliveryAddressSuffix}
            triggerAddressValidation={triggerAddValidation}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default AddressForm;
