import { css } from "@emotion/core";
import { Form } from "antd";
import { Modal } from "antd-mobile";
import React, { useEffect, useState } from "react";
import InformationBox from "~components/InformationBox/InformationBox";
import CheckboxTC from "~components/formControls/CheckBoxTC";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { usePrismic } from "~hooks/usePrismic";
import { fontSizeClamp, margin, width } from "~utils/hooks/useBreakPointTools";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";
import { btnTermsCss, modalTermsCss } from "./TermsAndConditionCss";

const TermsAndConditions = (props: any) => {
  const { hide, termAnConditionsText } = props;
  const t = usePrismic();
  const [visible, setVisible] = useState(false);
  const [acept, setAcept] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { device, sizeWidth, sizeHeight } = useWindowBreakPoints();
  const showModal = () => {
    setAcept(false);
    setVisible(true);
  };

  const resolveCondition = (
    condition: boolean,
    trueCondition: any,
    falseCondition: any
  ) => (condition ? trueCondition : falseCondition);

  useEffect(() => {
    if (hide) {
      setVisible(true);
    }
  }, [hide]);

  const closeModal = () => {
    setVisible(false);
  };
  const notcloseModal = () => {
    console.warn("debe aceptar terminos");
  };
  const AceptTerms = (e: any) => {
    setAcept(e.target.checked);
  };
  const btnContinue = () => {
    if ((hide && acept) || !hide) {
      setVisible(false);
    }
  };

  function closest(el: any, selector: any) {
    const matchesSelector =
      el.matches ||
      el.webkitMatchesSelector ||
      el.mozMatchesSelector ||
      el.msMatchesSelector;
    while (el) {
      if (matchesSelector.call(el, selector)) {
        return el;
      }
      el = el.parentElement;
    }
    return null;
  }

  const onWrapTouchStart = (e: any) => {
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
      e.preventDefault();
    }
  };

  return (
    <div>
      {hide ? null : (
        <React.Fragment>
          <Form.Item
            name="terminosCondiciones"
            valuePropName="checked"
            rules={[
              {
                validator: () => {
                  if (acept) {
                    setShowAlert(false);
                    return Promise.resolve();
                  } else {
                    setShowAlert(true);
                    return Promise.reject("");
                  }
                },
              },
            ]}
          >
            <CheckboxTC
              hide={hide}
              size={resolveCondition(
                device === "desktop",
                1.5,
                resolveCondition(device === "tablet", 1.3, 1.1)
              )}
              text={t.constants.ACEPTOLOS}
              boldTex={t.constants.TERMCONDICION}
              showModalCheck={showModal}
              check={acept}
              onChangeCheck={AceptTerms}
              device={device}
              sizeWidth={sizeWidth}
              extraCss={css`
                font-size: ${fontSizeClamp(device, sizeWidth, 18, 14, 14, 14)};
                margin-bottom: 0;
              `}
            />
          </Form.Item>
          <InformationBox
            hide={!acept && showAlert}
            text={t.constants.DEBESACEPTAR}
            kind="alert"
            extraCss={css`
              margin-bottom: 1vh;
              width: ${width(device, sizeWidth, 350, 350, 350, 328)};
            `}
          />
        </React.Fragment>
      )}

      <Modal
        css={css`
          ${modalTermsCss(device, sizeWidth, sizeHeight)}
        `}
        visible={visible}
        transparent={true}
        closable={hide ? false : true}
        onClose={hide ? notcloseModal : closeModal}
        title={<header className="termsHeader">{t.constants.TERMCONDICION}</header>}
        wrapProps={{ onTouchStart: onWrapTouchStart }}
      >
        <InformationBox
          text={t.constants.ANTESDARACONOCERTC}
          boldText={t.constants.TENPRESENTE}
          hide={hide}
        />
        <div style={{ overflow: "scroll" }}>
          <div className="termsParagraph">{termAnConditionsText}</div>
        </div>
        <div onClick={() => (acept ? setAcept(false) : setAcept(true))}>
          <CheckboxTC
            size={
              device === "desktop" ? 1.5 : resolveCondition(device === "tablet", 1.3, 1.1)
            }
            text={t.constants.ACEPTARLOS}
            boldTex={t.constants.TERMCONDICION}
            onChangeCheck={AceptTerms}
            check={acept}
            device={device}
            sizeWidth={sizeWidth}
            extraCss={css`
              margin: ${margin({
                device: device,
                sizeHeight: sizeHeight,
                sizeWidth: sizeWidth,
                deskTopM: 70,
                deskLeftM: 0,
                deskBottomM: 30,
                deskRightM: 60,
                tablTopM: 33,
                tablLeftM: 0,
                tablBottomM: 20,
                tablRightM: 48,
                mobTopM: 21,
                mobLeftM: 0,
                mobBottomM: 15,
                mobRightM: 24,
              })};
            `}
          />
        </div>
        <InformationBox hide={!acept} text={t.constants.DEBESACEPTAR} kind="alert" />
        <NovaventaButton
          showArrow={true}
          handleClick={btnContinue}
          extracss={btnTermsCss(device, sizeWidth, sizeHeight, acept)}
        />
      </Modal>
    </div>
  );
};

export default TermsAndConditions;
