import React, { useState } from "react";
import type { RouteComponentProps } from "@reach/router";
import H1Novaventa from "~components/header/H1Novaventa";
import css from "@emotion/css";
import { usePrismic } from "~hooks/usePrismic";
import { Row, Form, DatePicker, Switch } from "antd";
import novaventaColors from "~styles/colors";
import { NovaventaInput } from "~components/formControls/input/NovaventaInput";
import NovaventaRadio from "~components/formControls/radio/NovaventaRadio";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { regExp } from "~utils/formRegex";
import { Content } from "antd/lib/layout/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { excelProcesos } from "~services/historyServices";
import { apiFormattedDate } from "~store/formStore";
import ModalMessage from "~components/ModalMessage";

const ConsultProcess = (_props: RouteComponentProps) => {
  const t = usePrismic();
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const [typeConsult, setTypeConsult] = useState<number>(1);
  const [optionConsult, setOptionConsult] = useState<boolean>(true);
  const [showModalNotFindUser, setShowModalNotFindUser] = useState<boolean>(false);

  const onFinish = (values: any) => {
    // queryconsultar procesos
    let query = `?type=${values.optionConsult ? "PR" : "CG"}&`;
    if (values.typeConsult == 1 || !values.optionConsult) {
      query += `cedula=${values.numeroDocumento}`;
    } else {
      const fechaIni = apiFormattedDate(values.fechaInicial);
      const fechaFin = apiFormattedDate(values.fechaFinal);
      query += `fechaini=${fechaIni} 00:00:00&fechafin=${fechaFin} 23:59:00`;
    }
    excelProcesos(query, `${optionConsult ? "Procesos" : "Consulta Gremial"}.xlsx`);
  };

  const handleTypeConsult = (ev: any) => {
    setTypeConsult(ev.target.value);
  };

  const handleOptionConsult = (checked: boolean) => {
    setOptionConsult(checked);
    setTypeConsult(1);
    form.setFieldsValue({ typeConsult: 1 });
  };

  return (
    <React.Fragment>
      <H1Novaventa
        text={t.constants.CONSULTAR}
        boldText={t.constants.process}
        boldFirst={false}
        extraCss={css`
          margin-bottom: 2vh;
          margin-left: 3vw;
        `}
        showBack={false}
      />
      <Content style={{ padding: "0 50px" }}>
        <Row gutter={24} justify="center" align="middle">
          <Form
            name="ConsultProcess"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{ optionConsult: true, typeConsult: 1 }}
          >
            <Form.Item
              name="optionConsult"
              label={t.constants.OPTIONCONSULT}
              rules={[
                {
                  required: true,
                  message: t.home.rules.optionConsult,
                },
              ]}
            >
              <Switch
                checkedChildren={t.constants.consultProcess}
                unCheckedChildren={t.constants.consultGremial}
                onChange={handleOptionConsult}
                defaultChecked
              />
            </Form.Item>
            {optionConsult === true && (
              <Form.Item
                name="typeConsult"
                label={t.constants.TYPECONSULT}
                rules={[
                  {
                    required: true,
                    message: t.home.rules.typeConsult,
                  },
                ]}
              >
                <NovaventaRadio
                  optionsProps={{ label: "label", id: "id" }}
                  options={t.options.consultProcess}
                  value={typeConsult}
                  onChange={handleTypeConsult}
                ></NovaventaRadio>
              </Form.Item>
            )}
            {typeConsult === 1 && (
              <Form.Item
                name="numeroDocumento"
                label={t.constants.NUMERODOCUMENTO2}
                hasFeedback
                rules={[
                  { required: true, message: t.home.rules.required },
                  {
                    pattern: regExp.justNumber,
                    message: t.home.rules.invalid,
                  },
                ]}
              >
                <NovaventaInput showiconcheck="false" maxLength={50} />
              </Form.Item>
            )}
            {typeConsult === 2 && optionConsult === true && (
              <React.Fragment>
                <Form.Item
                  name="fechaInicial"
                  label={t.constants.FECHAINICIAL}
                  rules={[
                    {
                      required: true,
                      message: t.home.rules.required,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder={t.constants.FECHAINICIAL}
                    style={{
                      minWidth: "300px",
                      width: "100%",
                      height: "48px",
                      background: "white 0% 0 % no - repeat padding- box",
                      border: "0.5px solid #879AB9",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                    format={dateFormat}
                    size={"large"}
                    suffixIcon={
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        color={novaventaColors.blue400}
                        size="lg"
                      />
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="fechaFinal"
                  label={t.constants.FECHAFINAL}
                  rules={[
                    {
                      required: true,
                      message: t.home.rules.required,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder={t.constants.FECHAFINAL}
                    style={{
                      minWidth: "300px",
                      width: "100%",
                      height: "48px",
                      background: "white 0% 0 % no - repeat padding- box",
                      border: "0.5px solid #879AB9",
                      borderRadius: "6px",
                      opacity: "1",
                    }}
                    format={dateFormat}
                    size={"large"}
                    suffixIcon={
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        color={novaventaColors.blue400}
                        size="lg"
                      />
                    }
                  />
                </Form.Item>
              </React.Fragment>
            )}
            <NovaventaButton
              btnText={t.constants.CONSULTAR}
              htmlType="submit"
              extracss={css`
                margin: 21px auto 34px auto;
              `}
            />
          </Form>
          <ModalMessage
            refresh={(e: boolean) => setShowModalNotFindUser(e)}
            showModal={showModalNotFindUser}
            alertText={t.constants.documentsNotFound}
            text={t.constants.documentsNotFound2}
            btnText={t.constants.CONTINUAR}
            kind="error"
          />
        </Row>
      </Content>
    </React.Fragment>
  );
};
export default ConsultProcess;
