import { css } from "@emotion/core";
import React from "react";
import { usePrismic } from "~hooks/usePrismic";
import novaventaTheme from "~styles/theme";
import { PxToVx } from "~utils/hooks/VH&VWConvert";

const requiredCss = css`
  font-family: ${novaventaTheme.fonts.ProximaNovaSboldIt};
  color: ${novaventaTheme.colors.blueGray800};
  font-size: clamp(
    ${novaventaTheme.vars.labelFontSizeMin},
    ${PxToVx(14, novaventaTheme.breakPoints.maxDesktopWith, "vw")},
    ${novaventaTheme.vars.labelFontSizeMax}
  );
  margin: ${PxToVx(2, novaventaTheme.breakPoints.maxDesktopHeight, "vh")} auto 0 0px;
`;

const RequiredFieldsLabel = () => {
  const t = usePrismic();
  return (
    <React.Fragment>
      <div css={requiredCss}>
        <span
          css={css`
            font-family: ${novaventaTheme.fonts.ProximaNovaSboldIt};
            color: ${novaventaTheme.colors.blueGray800};
            font-size: clamp(
              ${novaventaTheme.vars.labelFontSizeMin},
              ${PxToVx(14, novaventaTheme.breakPoints.maxDesktopWith, "vw")},
              ${novaventaTheme.vars.labelFontSizeMax}
            );
            margin: ${PxToVx(2, novaventaTheme.breakPoints.maxDesktopHeight, "vh")} auto
              ${PxToVx(20, novaventaTheme.breakPoints.maxDesktopHeight, "vh")} 0px;
          `}
        >
          {t.constants.LOSCAMPOSMARCADOS}
        </span>
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};

export default RequiredFieldsLabel;
