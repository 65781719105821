import styled from "@emotion/styled";
import { Button } from "antd";
import { fontSizeClamp } from "~utils/hooks/useBreakPointTools";

const NovaventaButtonCss = styled(Button)`
  background-color: ${(props: any) => props.theme.colors.blue400};
  border-radius: 6px;
  width: 100%;
  min-width: ${(props: any) => props.theme.vars.fieldSize.defaultMinWidth};
  height: 63px;
  span {
    font-family: ${(props: any) => props.theme.fonts.ProximaNovaSbold};
    font-size: ${(props: any) =>
      fontSizeClamp(props.device, props.sizewidth, 20, 16, 16, 16)};
  }
  svg,
  svg.svg-inline--fa.fa-arrow-right.fa-w-14 {
    vertical-align: middle;
    font-size: 22px;
    padding-bottom: 2px;
    margin-left: 10px;
  }
  :hover,
  :active,
  :focus {
    background-color: ${(props: any) => props.theme.colors.blue400};
  }
  ${(props: any) => props.extracss}
`;

export default NovaventaButtonCss;
