import React, { useEffect, useRef, useState } from "react";
import { LayoutCss as Layout, ContentCss as Content, HeaderCss as Header } from "~AppCss";
import HeaderNovaventa from "~components/header/HeaderNovaventa";
import HeaderText from "~components/header/HeaderText";
import useDimension from "~utils/hooks/useDimension";
import useWindowSize from "~utils/hooks/useWindowSize";

const NovaventaLayout = (props: any) => {
  const {
    children,
    deviceType,
    isLogged,
    percent,
    showBackBtn,
    loginBanner,
    headerStr,
    center = false,
  } = props;
  const headerRef = useRef(null);
  const { height } = useDimension(headerRef);
  const [headerHeight, setHeaderHeight] = useState(height);
  const windowHeight = useWindowSize().height;
  const [windHeight, setWindHeight] = useState(windowHeight);
  const [showQa, setShowQa] = useState(false);
  const URL_API = process.env.API_DEVELOP ?? "";

  useEffect(() => {
    if (URL_API.includes("inscripcionqa")) {
      setShowQa(true);
    }
    if (document.querySelector("#chat-bots-webchat-container")) {
      if (window.location.pathname.includes("auto-gestion")) {
        window.CBWebChat.show();
      } else {
        window.CBWebChat.hide();
      }
    }
  });

  useEffect(() => {
    setHeaderHeight(height);
  }, [height]);

  useEffect(() => {
    setWindHeight(windowHeight);
  }, [windowHeight]);

  return (
    <Layout>
      <Header ref={headerRef}>
        <div>
          <HeaderNovaventa
            device={deviceType}
            isLogged={isLogged}
            percent={percent}
            showBackBtn={showBackBtn}
            loginBanner={loginBanner}
            headerStr={headerStr}
          />
          {deviceType !== "mobile" && !isLogged && (
            <HeaderText headerStr={headerStr} device={deviceType} />
          )}
          {showQa && (
            <div
              style={{
                backgroundColor: "yellow",
                position: "relative",
                zIndex: 100,
                width: "100%",
                textAlign: "center",
              }}
            >
              Estas trabajando en un ambiente de pruebas.
            </div>
          )}
        </div>
      </Header>
      <Content headerHeight={headerHeight} windowHeight={windHeight} center={center}>
        <div className="appWrapper">
          {deviceType === "mobile" && !isLogged && (
            <HeaderText headerStr={headerStr} device={deviceType} />
          )}
          {children}
        </div>
      </Content>
    </Layout>
  );
};

export default NovaventaLayout;
