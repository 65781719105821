import React, { useEffect, useState } from "react";
import { device } from "~utils/NovaventaMedia";
import MobileUploadImageRow from "~components/uploadImage/MobileUploadImageRow";
import DesktopTabletUploadImageRow from "~components/uploadImage/DesktopTabletUploadImageRow";
import HelpModal from "~components/helpLink/HelpModal";
import UploadImgHelpModalContent from "~components/uploadImage/UploadImgHelpModalContent";

const UploadImageRow = ({ type, document, showUserImageData, onDataChange }: any) => {
  const deviceType = device();
  const modalWidth = deviceType === "mobile" ? "90%" : "800px";
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setData(onDataChange);
  }, [onDataChange]);

  const handleHelpClick = (visible: any) => {
    setOpenModal(visible);
  };

  return (
    <React.Fragment>
      {deviceType === "mobile" ? (
        <MobileUploadImageRow
          type={type}
          document={document}
          handleHelpLinkClick={handleHelpClick}
          showUserImageData={showUserImageData}
        />
      ) : (
        <DesktopTabletUploadImageRow
          type={type}
          document={document}
          handleHelpLinkClick={handleHelpClick}
          showUserImageData={showUserImageData}
          onDataChange={data}
        />
      )}
      <HelpModal
        modalWidth={modalWidth}
        ModalContent={<UploadImgHelpModalContent type={type} />}
        visible={openModal}
        handleModalClick={handleHelpClick}
      />
    </React.Fragment>
  );
};

export default UploadImageRow;
