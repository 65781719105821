import React from "react";
import { Col, Row } from "antd";
import { usePrismic } from "~hooks/usePrismic";
import DropzoneImage from "~components/uploadImage/DropzoneImage/DropzoneImage";
import HelpLink from "~components/helpLink/HelpLink";
import IdIcon from "./IdIcon";

const MobileUploadImageRow = ({
  type,
  document,
  handleHelpLinkClick,
  showUserImageData,
}: any) => {
  const t = usePrismic();
  return (
    <Row gutter={[0, 48]} justify="center" align="middle">
      <Col>
        <Row gutter={[16, 32]} justify="start" align="middle">
          <Col flex="150px">
            <IdIcon type={type} />
          </Col>
          <Col flex="2">
            <p>
              {type === "front"
                ? t.constants.ADJUNTARIMAGENFRONTAL
                : t.constants.ADJUNTARIMAGENPOSTERIOR}
            </p>
            <HelpLink
              text={t.constants.NECESITASAYUDA}
              handleClick={handleHelpLinkClick}
              analyticsClassName={type === "front" ? "help2" : "help3"}
            />
          </Col>
        </Row>
        <Row gutter={[0, 24]} justify="center" align="middle">
          <DropzoneImage
            type={type}
            document={document}
            showUserImageData={showUserImageData}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default MobileUploadImageRow;
