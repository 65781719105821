import React, { useEffect, useState } from "react";
import { Col, Form, Row, DatePicker } from "antd";
import type { Moment } from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { usePrismic } from "~hooks/usePrismic";
import NovaventaRadio from "~components/formControls/radio/NovaventaRadio";
import novaventaColors from "~styles/colors";
import { NovaventaInput } from "~components/formControls/input/NovaventaInput";
import { xs, xxl, cellMD, cellLG, rowGutter } from "~components/forms/formItemsLayout";
import { useFormContext } from "~store/FormContext";
import moment from "moment";
import { regExp } from "~utils/formRegex";

const dateFormat = "DD/MM/YYYY";
const datePlaceHolder = "día/mes/año";

enum inputName {
  birthDate = "fechaNacimiento",
  birthPlace = "lugarNacimiento",
  expedDate = "fechaExpedicionDocumento",
  expedPlace = "lugarExpedicionDocumento",
  sex = "sexo",
}

type ErrorDate = {
  [input in inputName.birthDate | inputName.expedDate]: {
    status: "error" | "";
    message: string;
  };
};

const initialErrors: ErrorDate = {
  [inputName.birthDate]: {
    status: "",
    message: "",
  },
  [inputName.expedDate]: {
    status: "",
    message: "",
  },
};

const IdBirthdayForm = ({ showHelpMessage, isdisabled, test }: any) => {
  const t = usePrismic();
  const [gender, setGender] = useState("");
  const [errors, setErrors] = useState<ErrorDate>({ ...initialErrors });
  const [expdPicker, setExpdPicker] = useState(moment(Date.now()));
  const { updateFormValues, formValues } = useFormContext();

  const errorDates = {
    [inputName.birthDate]: t.constants.errorDateN,
    [inputName.expedDate]: t.constants.errorDateE,
  };

  useEffect(() => {
    if (formValues) {
      const value = formValues.get(inputName.birthDate);
      if (value) {
        const birthDate = moment(value, dateFormat);
        setExpdPicker(moment(birthDate).add(18, "years"));
      }
    }
  }, [formValues]);

  const onGenderChange = (e: any) => {
    setGender(e.target.value);
  };

  const setStateErrors = (
    key: inputName.birthDate | inputName.expedDate,
    isError: boolean
  ) => {
    setErrors((s) => ({
      ...s,
      [key]: {
        status: isError ? "error" : "",
        message: isError ? errorDates[key] : "",
      },
    }));
  };

  const onChangeDates = (
    date: Moment,
    key: inputName.birthDate | inputName.expedDate
  ) => {
    if (test) return;
    const changeDate = moment(date, dateFormat).format(dateFormat);

    updateFormValues({
      key: key,
      val: changeDate,
    });

    const birthDate = moment(formValues.get(inputName.birthDate), dateFormat);
    const expedDate = moment(formValues.get(inputName.expedDate), dateFormat);

    if (birthDate.isValid()) {
      setStateErrors(
        inputName.birthDate,
        birthDate > moment(Date.now()).subtract(18, "years")
      );
      setExpdPicker(moment(birthDate).add(18, "years"));
    }

    if (birthDate.isValid() && expedDate.isValid()) {
      setStateErrors(inputName.expedDate, expedDate < moment(birthDate).add(18, "years"));
    }
  };

  const disabledFechaNacimiento = (current: any) => {
    const minDate = moment(Date.now()).subtract(18, "years");
    return current && current > minDate;
  };

  const disabledFechaExpedicionDocumento = (current: any) => {
    const birthDate = moment(formValues.get(inputName.birthDate), dateFormat);
    return current && current < birthDate.add(18, "years");
  };

  return (
    <React.Fragment>
      {showHelpMessage && (
        <Row gutter={rowGutter} justify="start" align="middle">
          <Col flex={"100%"}>
            <p>{t.constants.INGRESADATOSID}</p>
          </Col>
        </Row>
      )}
      <Row gutter={rowGutter} justify="start" align="top">
        <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
          <Form.Item
            name={inputName.birthDate}
            label={t.constants.FECHANACIMIENTO}
            validateStatus={errors[inputName.birthDate].status}
            help={errors[inputName.birthDate].message}
            rules={[{ required: true, message: t.home.rules.required }]}
          >
            <DatePicker
              disabled={isdisabled && isdisabled.isdisabled}
              placeholder={datePlaceHolder}
              disabledDate={disabledFechaNacimiento}
              onChange={(date: Moment | null) => {
                if (date) onChangeDates(date, inputName.birthDate);
              }}
              format={dateFormat}
              size={"large"}
              defaultPickerValue={moment(Date.now()).subtract(18, "years")}
              style={{
                minWidth: "300px",
                width: "100%",
                height: "48px",
                background: "white 0% 0 % no - repeat padding- box",
                border: "0.5px solid #879AB9",
                borderRadius: "6px",
                opacity: "1",
              }}
              suffixIcon={
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  color={novaventaColors.blue400}
                  size="lg"
                />
              }
            />
          </Form.Item>
        </Col>

        <Col
          xs={{ span: xs, offset: 0 }}
          md={{ span: cellMD, offset: 1 }}
          lg={{ span: 9, offset: 0 }}
          xxl={{ span: 9, offset: 1 }}
        >
          <Form.Item
            name={inputName.birthPlace}
            label={t.constants.LUGARNACIMIENTO}
            rules={[{ required: true, message: t.home.rules.required }]}
          >
            <NovaventaInput
              type="text"
              showiconcheck="false"
              placeholder={t.constants.LUGARNACIMIENTO}
            />
          </Form.Item>
        </Col>

        <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
          <Form.Item
            name={inputName.expedDate}
            label={t.constants.FECHAEXPEDICION}
            rules={[{ required: true, message: t.home.rules.required }]}
            validateStatus={errors[inputName.expedDate].status}
            help={errors[inputName.expedDate].message}
          >
            <DatePicker
              format={dateFormat}
              onChange={(date: Moment | null) => {
                if (date) onChangeDates(date, inputName.expedDate);
              }}
              placeholder={datePlaceHolder}
              disabledDate={disabledFechaExpedicionDocumento}
              defaultPickerValue={expdPicker}
              style={{
                minWidth: "300px",
                width: "100%",
                height: "48px",
                background: "white 0% 0 % no - repeat padding- box",
                border: "0.5px solid #879AB9",
                borderRadius: "6px",
                opacity: "1",
              }}
              suffixIcon={
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  color={novaventaColors.blue400}
                  size="lg"
                />
              }
            />
          </Form.Item>
        </Col>

        <Col
          xs={{ span: xs, offset: 0 }}
          md={{ span: cellMD, offset: 1 }}
          lg={{ span: 9, offset: 0 }}
          xxl={{ span: 9, offset: 1 }}
        >
          <Form.Item
            name={inputName.expedPlace}
            label={t.constants.LUGAREXPEDICION}
            rules={[
              { required: true, message: t.home.rules.required },
              {
                pattern: regExp.alphabetWhiteSpaces,
                message: t.home.rules.invalid,
              },
            ]}
          >
            <NovaventaInput
              type="text"
              showiconcheck="false"
              placeholder={t.constants.LUGAREXPEDICION}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} justify="start" align="top">
        <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
          <Form.Item
            name={inputName.sex}
            label={t.constants.SEXO}
            rules={[{ required: true, message: t.home.rules.required }]}
          >
            <NovaventaRadio
              data-testid="idRadioSexo"
              optionsProps={{ label: "label", id: "id" }}
              options={t.options.GENERO}
              onChange={onGenderChange}
              value={gender}
            />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default IdBirthdayForm;
