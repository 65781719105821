import React from "react";
import type { SVGProps } from "react";

import { IconMex } from "./IconMex";
import { IconCol } from "./IconCol";
import { IconVen } from "./IconVen";

interface IconNovaProps extends SVGProps<SVGSVGElement> {
  code: string;
}

export const IconNova = (props: IconNovaProps) => {
  switch (props.code) {
    case "52":
      return <IconMex {...props} />;
    case "57":
      return <IconCol {...props} />;
    case "58":
      return <IconVen {...props} />;
    default:
      return <></>;
  }
};

export default IconNova;
