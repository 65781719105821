import axios from "axios";
const baseURL: any = process.env.API_DEVELOP;

export function getUserInfo(uuid: any) {
  return axios.get(`${baseURL}/recuperar/${uuid}`, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
}
