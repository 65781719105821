import { Col, Form } from "antd";
import type { Rule } from "antd/lib/form";
import NovaventaTextArea from "~components/formControls/textArea/NovaventaTextArea";

type InputTextAreaProps = {
  name: string;
  label: string;
  rules: Rule[];
};

export const InputTextArea = ({ name, label, rules }: InputTextAreaProps) => {
  return (
    <Col span={10} xs={20} md={10}>
      <Form.Item name={name} label={label} hasFeedback rules={rules}>
        <NovaventaTextArea showiconcheck="false" maxLength={50} />
      </Form.Item>
    </Col>
  );
};
