import styled from "@emotion/styled";
import { Input } from "antd";

const NovaventaInputCss = styled(Input)`
  height: 48px;
  background: white 0% 0% no-repeat padding-box;
  border: 0.5px solid ${(props: any) => props.theme.colors.blueGray200Light};
  border-radius: 6px;
  opacity: 1;
  .ant-input-group .ant-input {
    height: 48px;
    border: none;
    background-color: transparent;
  }
  .ant-input-group .ant-input-group-addon {
    background-color: transparent;
    border: none;
    font-size: 25px;
  }
  .ant-input:focus {
    box-shadow: none;
  }
  ${(props: any) => props.extracss}
`;

export default NovaventaInputCss;
