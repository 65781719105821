import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import H1Novaventa from "~components/header/H1Novaventa";
import css from "@emotion/css";
import { usePrismic } from "~hooks/usePrismic";
import { Row, Col, Form, Modal } from "antd";
import { NovaventaInput } from "~components/formControls/input/NovaventaInput";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { regExp } from "~utils/formRegex";
import { Content } from "antd/lib/layout/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { CardStateCss, TableCss } from "./HistoryProcessCss";
import { getHistorial } from "~services/historyServices";
import moment from "moment";

type Estado =
  | "DATOS_CONTACTO"
  | "DATOS_VIVIENDA"
  | "ACEPTA_TERMINOS"
  | "SELECCION_METODO_PAGO"
  | "DATOS_PERSONALES";

interface stateStory {
  id: number;
  fecha: number;
  estado: Estado;
}

interface responseExperian {
  id: number;
  idSolicitud: number;
  respuesta: string;
  urlGenerada: string;
}

const HistoryProcess = (_props: RouteComponentProps) => {
  const t = usePrismic();
  const [form] = Form.useForm();
  const [history, setHistory] = useState<stateStory[]>([]);
  const [datosContacto, setDatosContacto] = useState({});
  const [direccionPrincipal, setDireccionPrincipal] = useState({});
  const [direccionSecundaria, setDireccionSecundaria] = useState({});
  const [datosUsuario, setDatosUsuario] = useState({});
  const [datosFirma, setDatosFirma] = useState({});
  const [listExperian, setListExperian] = useState<responseExperian[]>([]);
  const [showData, setShowData] = useState({});

  const validateField = [
    "NI2",
    "DEC_1_CUPO",
    "DEC_1_DECISION",
    "TEV_RESULT",
    "decision",
    "TRA_STATE_NAME",
    "FCC_mensaje",
    "DEC_1_CAUSAL_R1",
    "DEC_1_CAUSAL_R2",
    "DEC_1_CAUSAL_R3",
    "DEC_1_CAUSAL_AC1",
    "DEC_1_CAUSAL_AC2",
    "DEC_1_CAUSAL_AC3",
    "SSTRX",
  ];

  const aux = {
    DATOS_CONTACTO: datosContacto,
    DATOS_VIVIENDA: direccionPrincipal,
    DATOS_PERSONALES: datosUsuario,
    ACEPTA_TERMINOS: datosFirma,
    SELECCION_METODO_PAGO: listExperian,
  };
  const onFinish = (values: any) => {
    const documento = values["numeroDocumento"];
    getHistorial(documento).then((response) => {
      if (Array.isArray(response.historico)) setHistory(response.historico);
      if (response.datosRegistro) {
        setDatosContacto(response.datosRegistro.contacto);
        setDireccionPrincipal(response.datosRegistro.principal);
        setDatosUsuario(response.datosRegistro.usuario);
        setDireccionSecundaria(response.datosRegistro.secundario);
        setDatosFirma({
          version: response.datosFirma.versionTyC,
          tipoDoc: response.datosFirma.documento.idTipoDocumento,
          numero: response.datosFirma.documento.registro,
          direccionIp: response.datosFirma.ipAddress,
          fechaFirma: response.datosFirma.fechaFirma,
        });
        setListExperian(response.respuestasExperian);
      }
    });
  };

  const camelToWords = (test: string) => {
    return test.replace(/([A-Z])/g, " $1").replace(/^./, (str) => {
      return str.toUpperCase();
    });
  };

  const mapEntries = (data: any) =>
    Object.entries(data).map((e) => {
      return (
        <tr key={e[0]}>
          <th>{camelToWords(e[0])}</th>
          <td>{`${e[0].includes("fecha") ? formatDate(e[1]) : e[1]}`}</td>
        </tr>
      );
    });

  const getData = (body: string, value: string) => {
    if (body != "PENDIENTE") {
      const json = JSON.parse(body);
      const res = json.find((e: any) => e.id == value);
      return res ? res.value : "";
    }
    return "PENDIENTE";
  };

  const showDetailState = (state: Estado) => {
    setShowData(aux[state]);
  };

  const formatDate = (value: any) => {
    return moment(value).format("DD/MM/YYYY");
  };

  const formatDateTime = (value: any) => {
    return moment(value).format("DD/MM/YYYY HH:mm:ss");
  };

  const showDataUrl = (data: string) => {
    Modal.info({
      title: t.constants.infoSend,
      content: <div>{data}</div>,
      width: 700,
    });
  };

  return (
    <React.Fragment>
      <H1Novaventa
        text={t.constants.CONSULTARESTADO}
        boldText={t.constants.process}
        boldFirst={false}
        extraCss={css`
          margin-bottom: 2vh;
          margin-left: 3vw;
        `}
        showBack={false}
      />
      <Content style={{ padding: "0 50px" }}>
        <Row gutter={24} justify="center" align="middle">
          <Col span={10} xs={20} md={10}>
            <Form name="historyDoc" layout="vertical" form={form} onFinish={onFinish}>
              <Form.Item
                name="numeroDocumento"
                label={t.constants.NUMERODOCUMENTO2}
                hasFeedback
                rules={[
                  { required: true, message: t.home.rules.required },
                  {
                    pattern: regExp.justNumber,
                    message: t.home.rules.invalid,
                  },
                ]}
              >
                <NovaventaInput showiconcheck="false" maxLength={50} />
              </Form.Item>
              <NovaventaButton
                btnText={t.constants.CONSULTAR}
                htmlType="submit"
                extracss={css`
                  margin: 21px auto 34px auto;
                `}
              />
            </Form>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={10} xs={24} sm={12} md={8}>
            {history.map((e) => {
              return (
                <CardStateCss
                  key={e.id}
                  onClick={() => {
                    showDetailState(e.estado);
                  }}
                >
                  <section
                    css={css`
                      float: left;
                    `}
                  >
                    <h3>{e.estado}</h3>
                    <small>{formatDateTime(e.fecha)}</small>
                  </section>
                  <FontAwesomeIcon
                    css={css`
                      float: right;
                    `}
                    icon={faArrowRight}
                    size="3x"
                  />
                </CardStateCss>
              );
            })}
          </Col>
          <Col span={10} xs={24} sm={12} md={14}>
            <Row>
              {showData == listExperian && listExperian ? (
                listExperian.map((item) => {
                  return (
                    <Col
                      key={item.id}
                      span={8}
                      xs={8}
                      css={css`
                        padding: 5px;
                      `}
                    >
                      <section
                        onClick={() => {
                          Modal.info({
                            width: 1000,
                            title: t.constants.validatingResponses,
                            content: (
                              <div>
                                <TableCss>
                                  {validateField.map((e) => {
                                    return (
                                      <tr key={getData(item.respuesta, e)}>
                                        <th>{e}: </th>
                                        <td>{getData(item.respuesta, e)}</td>
                                      </tr>
                                    );
                                  })}
                                  <tr>
                                    <th>{t.constants.url}</th>
                                    <td>
                                      <em
                                        css={css`
                                          color: #6a87e8;
                                          margin-right: 10px;
                                          cursor: pointer;
                                        `}
                                        onClick={() => {
                                          showDataUrl(item.urlGenerada);
                                        }}
                                      >
                                        {t.constants.see}
                                      </em>
                                    </td>
                                  </tr>
                                </TableCss>
                              </div>
                            ),
                          });
                        }}
                        css={css`
                          cursor: pointer;
                          border-radius: 5px;
                          padding: 10px;
                          background-color: #6a87e8;
                          color: white;
                        `}
                      >
                        <strong>{t.home.labels.ValidatingResponses[0]}</strong>
                        <em>{item.id}</em>
                        <br />
                        <strong>{t.home.labels.ValidatingResponses[1]}</strong>
                        <em>{item.idSolicitud}</em>
                        <br />
                        <strong>{t.home.labels.ValidatingResponses[2]}</strong>
                        <em>{getData(item.respuesta, "TRA_STATE_NAME")}</em>
                      </section>
                    </Col>
                  );
                })
              ) : (
                <TableCss>{showData && mapEntries(showData)}</TableCss>
              )}
              {showData && showData == direccionPrincipal && (
                <TableCss>
                  {direccionSecundaria && mapEntries(direccionSecundaria)}
                </TableCss>
              )}
            </Row>
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default HistoryProcess;
