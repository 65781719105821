import { css } from "@emotion/core";

export const integrationCss = css`
  .itemService {
    display: flex;
    align-items: center;
    label,
    button,
    span {
      margin: 0 10px;
    }
  }
  .ant-btn {
    background-color: #345eea;
    color: white;
  }
`;
