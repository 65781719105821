import type { RouteComponentProps } from "@reach/router";
import { Form } from "antd";
import { useState } from "react";
import FooterButtons from "~components/digitalRegistration/FooterButtons";
import AddressForm from "~components/forms/AddressForm";
import DeliveryAddressOptions from "~components/forms/DeliveryAddressOptions";
import ReferredByForm from "~components/forms/ReferredByForm";
import {
  sendAddressForm,
  sendReferredByForm,
} from "~services/digitalRegistrationServices";
import { useFormContext } from "~store/FormContext";
import { formData, uuidSolicitud as uuid } from "~store/formStore";
import { device } from "~utils/NovaventaMedia";
import { formatFormChangedValue } from "~utils/utils";
import addressFormValuesHandler from "../../utils/addressFormValuesHandler";

type PropsAddressPage = RouteComponentProps & {
  registrationType: string;
  userInfo: any;
};

const AddressPage = (props: PropsAddressPage) => {
  const { registrationType, userInfo } = props;
  const [showDeliveryAddressForm, setShowDeliveryAddressForm] = useState(false);
  const [addressSummary, setAddressSummary] = useState("");
  const [deliveryAddressSummary, setDeliveryAddressSummary] = useState("");
  const [
    addressSummaryWithoutComplement,
    setAddressSummaryWithoutComplement,
  ]: any = useState("");
  const deviceType = device();
  const {
    addressFormObj,
    updateMobileActiveTab,
    updateFormValues,
  }: any = useFormContext();

  const onAddressFormFinish = (values: any) => {
    if (deviceType !== "desktop" && deviceType !== "desktopHD") {
      let nextTab = 2;
      const { referencia, principal, secundario } = formData(values);
      const referredByValues = {
        uuid: uuid(),
        referencia: referencia,
      };
      sendReferredByForm(referredByValues);

      sendAddressForm({
        uuid: uuid(),
        vivienda: { ...principal, direccion: addressSummary },
      });

      if (deliveryAddressSummary) {
        sendAddressForm({
          uuid: uuid(),
          vivienda: { ...secundario, direccion: deliveryAddressSummary },
        });
      }

      if (registrationType === "phoneReg") {
        nextTab = 3;
      }
      setTimeout(() => {
        const elements = window.document.querySelector("#nav-mobile");
        if (elements) {
          elements.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 100);
      updateMobileActiveTab(nextTab);
    }
  };

  const onAddressFormFinishFailed = () => {
    setTimeout(() => {
      const elements = window.document.querySelector(".ant-form-item-has-error");
      if (elements) {
        elements.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 100);
  };

  const onAddressFormValuesChange = (value: any) => {
    const formattedVal = formatFormChangedValue(value);
    const isDA = formattedVal.key.endsWith("DA");
    let deliverySummaryAddressData = null;
    let addressSummaryData = null;

    const otrosDireccionValue = addressFormObj.getFieldValue(
      `direccionOtros1${isDA ? "DA" : ""}`
    );
    const otrosValue = addressFormObj.getFieldValue(`otros${isDA ? "DA" : ""}`);
    if (otrosValue) {
      if (isDA) {
        deliverySummaryAddressData = otrosDireccionValue;
      } else {
        addressSummaryData = otrosDireccionValue;
      }
    } else {
      if (isDA) {
        deliverySummaryAddressData = addressFormValuesHandler(
          addressFormObj.getFieldsValue()
        ).deliveryAddressSummary;
      } else {
        addressSummaryData = addressFormValuesHandler(addressFormObj.getFieldsValue())
          .addressSummary;
      }
    }

    if (addressSummaryData || addressSummaryData === "") {
      setAddressSummary(addressSummaryData);
      updateFormValues({ key: "addressSummary", val: addressSummaryData });
    } else if (deliverySummaryAddressData || deliverySummaryAddressData === "") {
      setDeliveryAddressSummary(deliverySummaryAddressData);
      updateFormValues({
        key: "deliveryAddressSummary",
        val: deliverySummaryAddressData,
      });
    }
    setAddressSummaryWithoutComplement(
      addressFormValuesHandler(addressFormObj.getFieldsValue())
        .addressSummaryWithoutComplement
    );
    updateFormValues(formattedVal);
  };

  const onDeliveryAddressChange = (ev: any) => {
    setShowDeliveryAddressForm(false);
    if (ev && ev.target.value === "1") {
      setShowDeliveryAddressForm(true);
    }
  };

  const userInfoInitial = () => {
    if (userInfo && userInfo.principal) {
      const deliveryAddressSuffix = showDeliveryAddressForm ? "DA" : "";
      return {
        [`direccionOtros1${deliveryAddressSuffix}`]: userInfo.principal.direccion,
        [`idDepartamento${deliveryAddressSuffix}`]: userInfo.principal.idDepartamento,
        [`idCiudad${deliveryAddressSuffix}`]: userInfo.principal.idCiudad,
        [`idBarrio${deliveryAddressSuffix}`]: userInfo.principal.idBarrio,
        [`otros${deliveryAddressSuffix}`]: !!userInfo.principal.direccion,
      };
    }
    return {};
  };

  return (
    <Form
      data-testid="addressForm"
      id="addressForm"
      autoComplete="off"
      name="addressForm"
      form={addressFormObj}
      layout="vertical"
      onFinish={onAddressFormFinish}
      onValuesChange={onAddressFormValuesChange}
      onFinishFailed={onAddressFormFinishFailed}
      scrollToFirstError={true}
      initialValues={userInfoInitial()}
    >
      {registrationType === "otherReg" && <ReferredByForm />}
      <AddressForm
        registrationType={registrationType}
        addressSummaryText={addressSummary}
        addressSummaryWithoutComplement={addressSummaryWithoutComplement}
        initialValues={userInfoInitial()}
      />

      {registrationType !== "ContactExpert" && (
        <DeliveryAddressOptions isSameAddress={onDeliveryAddressChange} />
      )}

      {showDeliveryAddressForm ? (
        <AddressForm
          showSectionTitle={false}
          deliveryAddressSuffix={"DA"}
          addressSummaryText={deliveryAddressSummary}
          deliveryAddressSummary={deliveryAddressSummary}
          addressSummaryWithoutComplement={addressSummaryWithoutComplement}
          initialValues={userInfoInitial()}
        />
      ) : null}
      {deviceType !== "desktop" && deviceType !== "desktopHD" && <FooterButtons />}
    </Form>
  );
};
export default AddressPage;
