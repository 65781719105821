import React from "react";
import { Col, Form, Row } from "antd";
import QuitRegistration from "~components/QuitProcess";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { css } from "@emotion/core";
import { usePrismic } from "~hooks/usePrismic";

const FooterButtons = ({ buttonType = "submit", handleClick = null, uri }: any) => {
  const t = usePrismic();
  const analyticsClassName =
    uri === "/registro-presencial/inscripcion-digital" ? "leave1" : "leave";

  return (
    <Row
      gutter={[24, 16]}
      justify="center"
      align="middle"
      css={css`
        margin-top: 24px !important;
      `}
    >
      <Col xs={{ span: 0, offset: 0 }}></Col>

      <Col xs={{ offset: 1 }} md={{ offset: 1 }} lg={{ offset: 2 }} xxl={{ offset: 2 }}>
        <Form.Item
          css={css`
            text-align: center;
          `}
        >
          <NovaventaButton
            showArrow={true}
            htmlType={buttonType}
            btnText={t.constants.CONTINUAR}
            extracss={{ width: "100%" }}
            handleClick={handleClick}
          />
        </Form.Item>
      </Col>
      <Col>
        <QuitRegistration analyticsClassName={analyticsClassName} />
      </Col>
    </Row>
  );
};

export default FooterButtons;
