import { css } from "@emotion/core";
import { RouteComponentProps, navigate } from "@reach/router";
import { Col, Form, Row } from "antd";
import { useState } from "react";
import ModalMessage from "~components/ModalMessage";
import QuitRegistration from "~components/QuitProcess";
import RequiredFieldsLabel from "~components/RequiredFieldsLabel";
import SectionTitle from "~components/SectionTitle";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import AddressForm from "~components/forms/AddressForm";
import NameForm from "~components/forms/NameForm";
import EmailPhoneForm from "~components/forms/emailPhoneForm/EmailPhoneForm";
import { rowTitleGutter } from "~components/forms/formItemsLayout";
import H1Novaventa from "~components/header/H1Novaventa";
import { usePrismic } from "~hooks/usePrismic";
import { sendProspectRegistration } from "~services/prospectService";
import { FormProvider } from "~store/FormContext";
import { errorDictionary } from "~utils/hooks/HandleHttpErrors";
import { getObject, removeObject } from "~utils/hooks/localStorage";

type AlertKind = "close" | "information" | "success" | "error" | "pending";

const ProspectCustomerForm = (_props: RouteComponentProps) => {
  const t = usePrismic();
  const [addressSum, setAddressSum]: any = useState("");
  const [kind, setKind] = useState<AlertKind>("close");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [form] = Form.useForm();

  const isEmpty = function (entity: string | undefined) {
    return entity || "";
  };

  const resolveCondition = (
    condition: boolean,
    trueCondition: any,
    falseCondition: any
  ) => (condition ? trueCondition : falseCondition);

  const onFormAddressValuesChange = () => {
    const addressSummaryEntries = Object.entries(form.getFieldsValue());
    const addressSummaryMap = new Map(addressSummaryEntries);
    const addressSummaryArray: any = [];
    for (const [key, value] of addressSummaryMap) {
      if (
        value &&
        key !== "idDepartamento" &&
        key !== "idDepartamentoDA" &&
        key !== "idCiudad" &&
        key !== "idCiudadDA" &&
        key !== "idBarrio" &&
        key !== "idBarrioDA" &&
        key !== "deliveryAddress" &&
        key !== "idRelacion" &&
        key !== "complemento" &&
        key !== "correoElectronico" &&
        key !== "primerNombre" &&
        key !== "segundoNombre" &&
        key !== "primerApellido" &&
        key !== "segundoApellido" &&
        key !== "telefonoFijo" &&
        key !== "telefonoPrincipal"
      ) {
        const strValue = key.includes("numero2")
          ? `#${value}`
          : resolveCondition(key.includes("numero3"), `- ${value}`, value);
        addressSummaryArray.push(strValue);
      }
    }
    setAddressSum(addressSummaryArray.join(" "));
  };
  const onFinish = (values: any) => {
    setKind("pending");
    setAlertTitle(t.constants.PORFAVORESPERE);
    setAlertMessage(t.constants.CARGANDO);
    const data = {
      uuidSolicitud: getObject("uuid"),
      principal: {
        tipoDireccion: 1,
        idDepartamento: values.idDepartamento,
        idCiudad: values.idCiudad,
        idBarrio: values.idBarrio,
        direccion: addressSum,
        datosAdicionales: `${values.tipoVivienda} ${isEmpty(values.interior)} ${isEmpty(
          values.datosAdicionales
        )}`,
      },
      contacto: {
        correoElectronico: values.correoElectronico,
        telefonoPrincipal: values.telefonoPrincipal,
        telefonoFijo: isEmpty(values.telefonoFijo),
      },
      usuario: {
        nombres: `${values.primerNombre} ${isEmpty(values.segundoNombre)}`,
        primerApellido: values.primerApellido,
        segundoApellido: isEmpty(values.segundoApellido),
      },
    };
    sendProspectRegistration(data).then(({ continuar, message }) => {
      if (continuar) {
        removeObject("uuid");
        setKind("success");
        setAlertTitle(t.constants.REGISTROEXITOSO);
        setAlertMessage(t.constants.HEMOSENVIADO);
      } else if (message === t.constants.HAVE_REGISTER) {
        setKind("information");
        setAlertTitle(t.constants.REGISTRO_NO);
        setAlertMessage(errorDictionary(message, t.errors));
      } else {
        setKind("error");
        setAlertTitle(t.constants.REGISTROERROR);
        setAlertMessage(t.constants.ERRORSUGAR);
      }
    });
  };
  const onhandleClickBtnModal = () => {
    setKind("close");
    form.resetFields();
    navigate("/");
  };

  return (
    <FormProvider>
      <div
        css={css`
          display: block;
          margin: 2% 8%;
          max-width: 100vw;
          pointer-events: auto;
        `}
      >
        <RequiredFieldsLabel />
        <H1Novaventa showBack={false} extraCss={`margin-bottom: 30px;`} />
        <Form
          data-testid="test-form-prospect"
          autoComplete="off"
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={(e) => {
            console.log("Error e", e);
          }}
          onValuesChange={onFormAddressValuesChange}
        >
          <Row gutter={rowTitleGutter} justify="start" align="middle">
            <Col flex={"100%"}>
              <SectionTitle
                lightText={t.constants.DATOS}
                boldText={t.constants.BASICOS}
              />
            </Col>
          </Row>
          <NameForm />
          <AddressForm
            registrationType={"ContactExpert"}
            addresForm={form}
            addressSummaryText={addressSum}
            isRequiredValidate={false}
          />
          <Row gutter={rowTitleGutter} justify="start" align="middle">
            <Col flex={"100%"}>
              <SectionTitle
                lightText={t.constants.CONTACT1}
                boldText={t.constants.CONTACT2}
              />
            </Col>
          </Row>

          <EmailPhoneForm registrationType={"ContactExpert"} />

          <Row gutter={24} justify="start" align="middle">
            <Col xs={24} lg={10}>
              <QuitRegistration analyticsClassName={"leave7"} />
            </Col>
            <Col xs={24} lg={10}>
              <Form.Item>
                <NovaventaButton
                  showArrow={true}
                  htmlType="submit"
                  btnText={t.constants.ENVIAR}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {kind !== "close" && (
          <ModalMessage
            showModal={true}
            kind={kind === "pending" ? "information" : kind}
            alertText={alertTitle}
            text={alertMessage}
            btnText={t.constants.CERRAR}
            disabled={kind === "pending"}
            handleClickBtn={onhandleClickBtnModal}
          />
        )}
      </div>
    </FormProvider>
  );
};

export default ProspectCustomerForm;
