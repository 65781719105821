import { PxToVx } from "~utils/hooks/VH&VWConvert";

let marginPxToVxClampParam: {
  sizeHeight: any;
  sizeWidth: any;
  topMaxM: any;
  leftMaxM: any;
  bottomMaxM: any;
  rightMaxM: any;
  topMinM: any;
  leftMinM: any;
  bottomMinM: any;
  rightMinM: any;
};

let marginParam: {
  device: string;
  sizeHeight: number;
  sizeWidth: number;
  deskTopM: number;
  deskLeftM: number;
  deskBottomM: number;
  deskRightM: number;
  tablTopM: number;
  tablLeftM: number;
  tablBottomM: number;
  tablRightM: number;
  mobTopM: number;
  mobLeftM: number;
  mobBottomM: number;
  mobRightM: number;
};

export function width(
  device: string,
  sizeWidth: number,
  maxDesktopSize: number,
  maxTabletSize: number,
  maxMobileSize: number,
  minSize: number
) {
  return `${
    device === "desktop"
      ? `clamp(${resolveCondition(
          maxTabletSize <= maxDesktopSize,
          maxTabletSize,
          maxDesktopSize
        )}px,
    ${PxToVx(maxDesktopSize, sizeWidth, "vw")},
    ${maxDesktopSize}px)`
      : resolveCondition(
          device === "tablet",
          `clamp(${resolveCondition(
            maxMobileSize <= maxTabletSize,
            maxMobileSize,
            maxTabletSize
          )}px,
      ${PxToVx(maxTabletSize, sizeWidth, "vw")},
      ${maxTabletSize}px)`,
          `clamp(${resolveCondition(minSize <= maxMobileSize, minSize, maxMobileSize)}px,
        ${PxToVx(maxMobileSize, sizeWidth, "vw")},
        ${maxMobileSize}px)`
        )
  }`;
}

const resolveCondition = (condition: boolean, trueCondition: any, falseCondition: any) =>
  condition ? trueCondition : falseCondition;

export function marginPxToVx(
  sizeHeight: number,
  sizeWidth: number,
  topM: number,
  leftM: number,
  bottomM: number,
  rightM: number
) {
  return `${topM > 0 ? PxToVx(topM, sizeHeight, "vh") : "auto"} 
  ${leftM > 0 ? PxToVx(leftM, sizeWidth, "vw") : "auto"}
  ${bottomM > 0 ? PxToVx(bottomM, sizeHeight, "vh") : "auto"} 
  ${rightM > 0 ? PxToVx(rightM, sizeWidth, "vw") : "auto"}`;
}
export const marginPxToVxClamp = ({
  sizeHeight,
  sizeWidth,
  topMaxM,
  leftMaxM,
  bottomMaxM,
  rightMaxM,
  topMinM,
  leftMinM,
  bottomMinM,
  rightMinM,
}: typeof marginPxToVxClampParam) => {
  return `${
    topMaxM > 0
      ? `clamp(${resolveCondition(topMinM <= topMaxM, topMinM, topMaxM)}px,${PxToVx(
          topMaxM,
          sizeHeight,
          "vh"
        )},${topMaxM}px)`
      : "auto"
  } 
  ${
    leftMaxM > 0
      ? `clamp(${resolveCondition(leftMinM <= leftMaxM, leftMinM, leftMaxM)}px,${PxToVx(
          leftMaxM,
          sizeWidth,
          "vw"
        )},${leftMaxM}px)`
      : "auto"
  }
  ${
    bottomMaxM > 0
      ? `clamp(${resolveCondition(
          bottomMinM <= bottomMaxM,
          bottomMinM,
          bottomMaxM
        )}px,${PxToVx(bottomMaxM, sizeHeight, "vh")},${bottomMaxM}px)`
      : "auto"
  } 
  ${
    rightMaxM > 0
      ? `clamp(${resolveCondition(
          rightMinM <= rightMaxM,
          rightMinM,
          rightMaxM
        )}px,${PxToVx(rightMaxM, sizeWidth, "vw")},${rightMaxM}px)`
      : "auto"
  }`;
};
export function margin({
  device,
  sizeHeight,
  sizeWidth,
  deskTopM,
  deskLeftM,
  deskBottomM,
  deskRightM,
  tablTopM,
  tablLeftM,
  tablBottomM,
  tablRightM,
  mobTopM,
  mobLeftM,
  mobBottomM,
  mobRightM,
}: typeof marginParam) {
  marginPxToVxClampParam = {
    sizeHeight: sizeHeight,
    sizeWidth: sizeWidth,
    topMaxM: deskTopM,
    leftMaxM: deskLeftM,
    bottomMaxM: deskBottomM,
    rightMaxM: deskRightM,
    topMinM: tablTopM,
    leftMinM: tablLeftM,
    bottomMinM: tablBottomM,
    rightMinM: tablRightM,
  };

  return resolveCondition(
    device === "desktop",
    marginPxToVxClamp(marginPxToVxClampParam),
    resolveCondition(
      device === "tablet",
      marginPxToVxClamp(marginPxToVxClampParam),
      marginPxToVx(sizeHeight, sizeWidth, mobTopM, mobLeftM, mobBottomM, mobRightM)
    )
  );
}
export function fontSizeClamp(
  device: string,
  sizeWidth: number,
  desktopFontSize: number,
  tabletFontSize: number,
  mobileFontSize: number,
  minMobileFontSize: number
) {
  return `
 ${resolveCondition(
   device === "desktop",
   `clamp(${tabletFontSize}px,${PxToVx(
     desktopFontSize,
     sizeWidth,
     "vw"
   )},${desktopFontSize}px);`,
   resolveCondition(
     device === "tablet",
     `clamp(${mobileFontSize}px,${PxToVx(
       tabletFontSize,
       sizeWidth,
       "vw"
     )},${tabletFontSize}px);`,
     `clamp(${minMobileFontSize}px,${PxToVx(
       mobileFontSize,
       sizeWidth,
       "vw"
     )},${mobileFontSize}px);`
   )
 )}`;
}
