import { RouteComponentProps, navigate } from "@reach/router";
import { Form } from "antd";
import { css } from "@emotion/core";
import { usePrismic } from "~hooks/usePrismic";
import { useState } from "react";
import { NovaventaInputPassword } from "~components/formControls/input/inputPassword/NovaventaInputPassword";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import ParagraphNova from "~components/ParagraphNova";
import { activatePassword } from "~utils/hooks/cognitoAws";
import ModalMessage from "~components/ModalMessage";

const fomCss = css`
  display: table;
  margin: 0 auto;
  input,
  select {
    max-width: 450px;
    width: 100%;
  }
`;
const ActivePasswordForm = (_props: RouteComponentProps | any) => {
  const t = usePrismic();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [modalKindText, setModalKindText] = useState<string>("success");
  const [modalAlertText, setModalAlertText] = useState<string>();
  const modalBtnText = useState<string>("Continuar");
  const modalTextModal = useState<string>();
  const onFinish = async (values: any) => {
    await activatePassword(values.confirmpassword)
      .then(() => {
        setShowModal(true);
        setModalKindText("success");
        setModalAlertText(t.constants.PASSACTIVADO);
      })
      .catch((error) => console.log("error", error));
  };
  const onhandleError = () => {
    setModalKindText("error");
    setModalAlertText(t.constants.LOSENTIMOSERROR);
    setShowModal(true);
  };
  const onhandleEnd = () => {
    navigate("/registro-telefonico/login-usuario/");
  };
  return (
    <div className="center">
      <Form
        autoComplete="off"
        form={form}
        data-testid="changePassword"
        id="changePassword"
        name="changePassword"
        layout="vertical"
        initialValues={{ remember: true }}
        onChange={() => setShowModal(false)}
        onFinish={onFinish}
        onFinishFailed={onhandleError}
        css={fomCss}
        scrollToFirstError
      >
        <NovaventaInputPassword label={t.constants.INGRESETUNUEVACONTRA} regex={true} />
        <NovaventaInputPassword
          label={t.constants.CONFIRMNUEVACONTRA}
          name="confirmpassword"
          confirmPassword={true}
          regex={true}
        />
        <Form.Item>
          <NovaventaButton btnText={t.constants.GUARDARPASSWORD} htmlType="submit" />
        </Form.Item>
        <Form.Item>
          <ParagraphNova
            extraCss={css`
              line-height: 0.4;
            `}
          >
            <p>
              <b>{t.constants.VALIDACIONCONTRASEÑA0}.</b>
            </p>
            <p>
              <b>*</b>
              {t.constants.VALIDACIONCONTRASEÑA1}.
            </p>
            <p>
              <b>*</b>
              {t.constants.VALIDACIONCONTRASEÑA2}.
            </p>
            <p>
              <b>*</b>
              {t.constants.VALIDACIONCONTRASEÑA3}.
            </p>
            <p>
              <b>*</b>
              {t.constants.VALIDACIONCONTRASEÑA4}.
            </p>
          </ParagraphNova>
        </Form.Item>
      </Form>
      <ModalMessage
        refresh={(e: boolean) => setShowModal(e)}
        showModal={showModal}
        text={modalTextModal}
        alertText={modalAlertText}
        btnText={modalBtnText}
        kind={modalKindText}
        handleClickBtn={modalKindText === "success" ? onhandleEnd : null}
      />
    </div>
  );
};

export default ActivePasswordForm;
