import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { usePrismic } from "~hooks/usePrismic";
import { regExp } from "~utils/formRegex";
import { Row, Col, Form } from "antd";
import { downloadDocuments } from "~services/managementServices";
import { css } from "@emotion/core";
import { NovaventaInput } from "~components/formControls/input/NovaventaInput";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import ModalMessage from "~components/ModalMessage";
import H1Novaventa from "~components/header/H1Novaventa";

const DownloadDocuments = (_props: RouteComponentProps) => {
  const [showModalNotFindUser, setShowModalNotFindUser] = useState(false);
  const [form] = Form.useForm();
  const t = usePrismic();

  const onFinish = ({ numeroDocumento }: { numeroDocumento: string }) => {
    downloadDocuments(numeroDocumento)
      .then(() => form.resetFields())
      .catch((error) => {
        setShowModalNotFindUser(true);
        console.error(error);
      });
  };

  return (
    <div>
      <H1Novaventa
        text={t.constants.DESCARGARARARCHIVOSTITULO}
        boldText={"cliente"}
        boldFirst={false}
        extraCss={css`
          margin: 1.5rem 2rem 1rem 2rem;
        `}
        showBack={false}
      />
      <Form name="s3FormDownload" layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={24} justify="center" align="middle">
          <Col span={10} xs={20} md={10}>
            <Form.Item
              name="numeroDocumento"
              label={t.constants.NUMERODOCUMENTO2}
              hasFeedback
              rules={[
                { required: true, message: t.home.rules.required },
                {
                  pattern: regExp.alphanumeric,
                  message: t.home.rules.invalid,
                },
              ]}
            >
              <NovaventaInput showiconcheck="false" maxLength={50} />
            </Form.Item>
            <NovaventaButton
              btnText={t.constants.DESCARGARARCHIVO}
              htmlType="submit"
              extracss={css`
                margin: 21px auto 34px auto;
              `}
            />
          </Col>
        </Row>
      </Form>
      <ModalMessage
        refresh={(e: boolean) => setShowModalNotFindUser(e)}
        showModal={showModalNotFindUser}
        alertText={t.constants.documentsNotFound}
        text={t.constants.documentsNotFound2}
        btnText={t.constants.CONTINUAR}
        kind="error"
      />
    </div>
  );
};

export default DownloadDocuments;
