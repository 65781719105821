import React from "react";
import { css } from "@emotion/core";
import IdForm from "~components/forms/idForm/IdForm";
import H1Novaventa from "~components/header/H1Novaventa";

const ExpertCallLogin = (props: any) => {
  const { path, navigationPath } = props;
  return (
    <div
      css={css`
        max-width: 100vw;
      `}
      className="center"
    >
      <H1Novaventa
        showBack={false}
        extraCss={`padding-bottom: 81px; max-width: 90vw; margin: 0 auto;`}
      />
      <IdForm center={null} path={path} navigationPath={navigationPath} />
    </div>
  );
};

export default ExpertCallLogin;
