import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import RegistrationChannelPage from "~pages/RegistrationChannelPage";
import DigitalRegistrationPage from "~pages/DigitalRegistrationPage";
import UserPasswordForm from "~components/forms/UserPasswordForm";
import IdForm from "~components/forms/idForm/IdForm";
import Registration from "~modules/Registration/Registration";
import ExpertCall from "~modules/ExpertCall/ExpertCall";
import ExpertCallLogin from "~pages/expertCall/expertCallLogin";
import PhoneCall from "~modules/PhoneCall/PhoneCall";
import PhoneCallPage from "~pages/phoneCall/phoneCallPage";
import ChoosePaymentMethod from "~pages/ChoosePaymentMethod";
import ProspectCustomerForm from "~pages/expertCall/ProspectCustomerForm";
import WelcomeToNovaventa from "~pages/WelcomeToNovaventa";
import SelfRegistration from "~modules/selfRegistration/SelfRegistration";
import SelfRegistrationLogin from "../components/SelfRegistrationLogin";
import UploadImagePage from "~pages/UploadImagePage";
import Management from "~modules/management/Management";
import MenuAdmin from "~modules/management/MenuAdmin";
import InternalList from "~components/InternalList";
import ForgotPassword from "~components/forms/ForgotPasswordForm";
import ActivePasswordForm from "~components/forms/ActivePasswordForm";
import ChangePasswordForm from "~components/forms/ChangePasswordForm";
import { RoutesCss } from "./RoutesCss";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";
import { fetchPrismicDataLandingOptions } from "~services/prismicService";
import DownloadDocuments from "~components/admin/DownloadDocuments";
import HistoryProcess from "~components/admin/HistoryProcess";
import ConsultProcess from "~components/admin/ConsultProcess";
import EmailBlacklist from "~components/admin/EmailBlacklist";
import Calls from "~components/admin/Calls";
import UploadDocuments from "~components/admin/UploadDocuments";
import Integrations from "~components/admin/Integrations";
import RetakesCredit from "~pages/retakesCredit/RetakesCredit";
import CreditOnDemand from "~pages/creditOnDemand/CreditOnDemand";
import IncreseCapacity from "~pages/creditOnDemand/IncreaseCapacity";

export const Routes = () => {
  const { device, sizeWidth } = useWindowBreakPoints();
  const [digital, setDigital] = useState(false);
  useEffect(() => {
    fetchPrismicDataLandingOptions()
      .then((resp: any) => setDigital(resp["digital"]))
      .catch(console.error);
  }, [digital]);

  return (
    <Router css={RoutesCss(device, sizeWidth)}>
      <RegistrationChannelPage path="/" />

      <ExpertCall path="llamada-experto">
        <ExpertCallLogin
          path="login-documento"
          navigationPath={"/llamada-experto/datos-prospecto"}
        />
        <ProspectCustomerForm path="datos-prospecto" />
      </ExpertCall>
      <PhoneCall path="llamada-telefonica">
        <PhoneCallPage
          path="datos-telefonicos"
          navigationPath={"/llamada-telefonica/datos-telefonicos"}
        />
      </PhoneCall>

      <Registration path="registro-telefonico">
        <UserPasswordForm
          path="login-usuario"
          navigationPath={"/registro-telefonico/login-documento"}
        />
        <ForgotPassword
          path="login-usuario/olvide-contrasena"
          navigationPath={"/registro-telefonico/login-usuario/cambio-contrasena"}
        />
        <ActivePasswordForm
          path="login-usuario/activar-pass"
          navigationPath={"/registro-telefonico/login-usuario/cambio-contrasena"}
        />
        <ChangePasswordForm
          path="login-usuario/cambio-contrasena"
          navigationPath={"/registro-telefonico/login-usuario"}
        />
        <IdForm
          path="login-documento"
          phoneRegister={true}
          navigationPath={"/registro-telefonico/inscripcion-digital"}
          registrationType="phoneReg"
        />
        <DigitalRegistrationPage
          path={"/inscripcion-digital"}
          registrationType="phoneReg"
        />
        <UploadImagePage path="subir-documento" />

        <ChoosePaymentMethod path="metodo-pago" navigationPath="bienvenida" />
        <WelcomeToNovaventa path="bienvenida" />
      </Registration>

      <Registration path="contado-credito">
        <RetakesCredit path="retoma/:base64" />
      </Registration>

      <Registration path="cupo-demanda">
        <CreditOnDemand path="validar/:cicloB64/:base64" />
        <IncreseCapacity path="cupo-ampliado" />
      </Registration>

      <Registration path="admin">
        <UserPasswordForm
          path="login-usuario"
          navigationPath={"/admin/gestion"}
          registrationType="admin"
        />
        <ForgotPassword
          path="login-usuario/olvide-contrasena"
          navigationPath={"/registro-telefonico/login-usuario/cambio-contrasena"}
        />
        <Management path="gestion">
          <MenuAdmin path="/" />
          <InternalList path="listas-internas" />
          <DownloadDocuments path="descargar-documentos" />
          <HistoryProcess path="historial-proceso" />
          <ConsultProcess path="consultar-proceso" />
          <EmailBlacklist path="lista-negra-correos" />
          <UploadDocuments path="carga-documentos" />
          <Calls path="llamadas" />
          <Integrations path="integraciones" />
        </Management>
      </Registration>

      {digital && (
        <SelfRegistration path="auto-gestion">
          <SelfRegistrationLogin path="login-documento" />
          <DigitalRegistrationPage
            path={"/inscripcion-digital"}
            registrationType="otherReg"
          />
          <ChoosePaymentMethod path="metodo-pago" navigationPath="bienvenida" />
          <WelcomeToNovaventa path="bienvenida" />
        </SelfRegistration>
      )}

      <Registration path="registro-presencial">
        <UserPasswordForm
          path="login-usuario"
          registrationType="inPerson"
          navigationPath={"/registro-presencial/login-documento"}
        />
        <IdForm
          path="login-documento"
          phoneRegister={true}
          isProspectCustomer={true}
          navigationPath={"/registro-presencial/inscripcion-digital"}
          registrationType="otherReg"
        />

        <DigitalRegistrationPage path="inscripcion-digital" registrationType="otherReg" />
        <ChoosePaymentMethod path="metodo-pago" navigationPath="bienvenida" />
        <WelcomeToNovaventa path="bienvenida" />
      </Registration>
    </Router>
  );
};
