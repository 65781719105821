import React from "react";
import { css } from "@emotion/core";
import { Progress } from "antd";
import "react-dropzone-uploader/dist/styles.css";
import { usePrismic } from "~hooks/usePrismic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faWindowClose } from "@fortawesome/free-regular-svg-icons";
import IconCheck from "~components/icons/IconCheck";
import UploadContainer from "~components/uploadImage/DropzoneImage/UploadContainer";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const UploadProgressTemplate = ({ meta, fileWithMeta, type, uploadStatus }: any) => {
  const t = usePrismic();
  const { name, percent, status } = meta;
  const { remove } = fileWithMeta;
  const analyticsClassNameAdd = type === "front" ? "add1" : "add2";
  const analyticsClassNameRemove = type === "front" ? "remove1" : "remove2";
  const percentValue = Math.round(percent);

  return (
    <React.Fragment>
      {status !== "done" && (
        <UploadContainer
          statusBox={
            <Progress
              status={status === "done" ? "success" : "active"}
              percent={percentValue}
              showInfo={true}
              strokeWidth={1}
              size="small"
            />
          }
          removeCancelBtn={{
            el: <FontAwesomeIcon icon={faWindowClose} />,
            fn: remove,
          }}
          analyticsClassName={analyticsClassNameAdd}
        ></UploadContainer>
      )}

      {status === "done" && (
        <UploadContainer
          statusBox={
            <span
              css={css`
                svg {
                  vertical-align: middle;
                  margin-left: 5px;
                }
              `}
            >
              {uploadStatus == "success"
                ? t.constants.ARCHIVOOK.replace("${0}", name)
                : ""}
              {uploadStatus == "success" ? (
                <IconCheck />
              ) : (
                <FontAwesomeIcon icon={faSpinner} spin />
              )}
            </span>
          }
          removeCancelBtn={{
            el: <FontAwesomeIcon icon={faTrashAlt} />,
            fn: remove,
          }}
          analyticsClassName={analyticsClassNameRemove}
        ></UploadContainer>
      )}
    </React.Fragment>
  );
};

export default UploadProgressTemplate;
