import React from "react";
import styled from "@emotion/styled";
import "react-dropzone-uploader/dist/styles.css";

const StatusBoxCss = styled.div`
  /* align-items: center; */
  background-color: transparent;
  background: white 0% 0% no-repeat padding-box;
  border: 1px dashed ${(props: any) => props.theme.colors.blueGray200Light};
  border-radius: 6px;
  color: ${(props: any) => props.theme.colors.blueGray200Light};
  /* display: inline-block; */
  /* justify-content: center; */
  height: auto;
  /* margin: 0 auto; */
  min-width: 350px;
  max-width: 500px;
  min-height: 46px;
  opacity: 1;
  text-align: center;
  padding: 10px;
  /* width: 100%; */
`;

const StatusBox = ({ children }: any) => {
  return <StatusBoxCss>{children}</StatusBoxCss>;
};

export default StatusBox;
