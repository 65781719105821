import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import React from "react";
import { usePrismic } from "~hooks/usePrismic";
import { PxToVx } from "~utils/hooks/VH&VWConvert";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";

const ParagraphNova = (props: any) => {
  const t = usePrismic();
  const { paymentMethod = "PayMethod", text, children, extraCss } = props;
  const CREDITAMOUNT = 0;
  const theme: any = useTheme();
  const { device, sizeHeight } = useWindowBreakPoints();

  const resolveLabel = (_children: any, _paymentMethod: any) => {
    if (_children) {
      return _children;
    } else if (_paymentMethod === "PayMethod") {
      return (
        <div data-testid="PayMethod">
          <p>
            {t.constants.YAERES}
            <b>{t.constants.MAMAEMPRE}</b>
            {t.constants.YPUEDES}
            <b>{t.constants.NOVAVENTA}</b>
            {t.constants.TENEMOSPARATI}
          </p>
          <p>{t.constants.CUPODECREDITO}</p>
        </div>
      );
    } else if (_paymentMethod === "CO") {
      return (
        <div data-testid="CO">
          <p>
            {t.constants.YAERES}
            <b>{t.constants.MAMAEMPRE}</b>
            {t.constants.APARTIRPSE}
          </p>
          <p>
            <b>{t.constants.ACTTUCONTRASEÑA}</b>
            {t.constants.INGRESANDOALLINK}
          </p>
        </div>
      );
    } else {
      return (
        <div data-testid="CR">
          <p>
            {t.constants.YAERES}
            <b>{t.constants.MAMAEMPRE}</b>
            {t.constants.APARTIR}
            <b>{t.constants.TIENESCUPO.replace("${0}", String(CREDITAMOUNT))}</b>
            {t.constants.forInit}
          </p>
          <p>
            <b>{t.constants.ACTTUCONTRASEÑA}</b>
            {t.constants.INGRESANDOALLINK}
          </p>
        </div>
      );
    }
  };

  const pCss = css`
    p {
      line-height: ${device === "mobile"
        ? "16px"
        : resolveCondition(
            device === "tablet",
            "24px",
            `clamp(24px, ${PxToVx(34, sizeHeight, "vh")},34px)`
          )};
      color: ${theme.colors.blueGray800};
      font-size: clamp(
        ${theme.vars.pFontSizeMin},
        ${PxToVx(35, theme.breakPoints.maxDesktopWith, "vw")},
        ${theme.vars.pFontSizeMax}
      );
      text-align: ${paymentMethod === "PayMethod" ? "left" : "center"};
    }
    p b {
      font-family: ${theme.fonts.ProximaNovaSbold};
    }
    margin: auto;

    ${extraCss}
  `;

  return (
    <React.Fragment>
      <div css={pCss}>{text ? <p>{text}</p> : resolveLabel(children, paymentMethod)}</div>
    </React.Fragment>
  );
};

const resolveCondition = (condition: boolean, trueCondition: any, falseCondition: any) =>
  condition ? trueCondition : falseCondition;

export default ParagraphNova;
