import { css } from "@emotion/core";

export const DesktopDigitalRegistrationCss = css`
  width: 100vw;
  min-height: calc(100vh - 86px);
  height: 100%;
  position: relative;
  background-color: #f0f3f8;
`;

export const DesktopDigInscriptionContentCss = css`
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  overflow-x: hidden;
`;

export const marginLeftSectionCss = css`
  @media (min-width: 52.125em) {
    margin-left: 300px;
  }

  @media (min-width: 85.375em) {
    margin-left: 350px;
  }
  @media (min-width: 90em) {
    margin-left: 500px;
  }
`;

export const sectionCss = css`
  width: auto;
  height: 100%;
  section {
    padding: 0 64px;
    @media screen and (max-width: 600px) {
      padding: 0 24px;
    }
  }
`;

export const subTitleCss = css`
  font-size: 14px;
  font-style: italic;
  color: #616c95;
`;

export const whiteSectionCss = css`
  ${sectionCss};
  padding: 10px 0;
  background-color: white;
`;
