import styled from "@emotion/styled";
import { css } from "@emotion/core";
export const MainPhoneCallCss = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentPhoneCallCss = styled.div`
  //background: rgb(0, 146, 255);
  font-family: Proxima Nova Sbold It;
  width: 100%;
  max-width: 90vw;
  margin: 0 auto;

  @media (min-width: 1200px) {
    max-width: 1175px;
  }

  h3 {
    color: white;
    margin-top: 15px;
  }

  .collapse {
    background-color: #f0f3f8;
    font-family: "Proxima Nova";
    .collapse-item {
      margin-bottom: 30px;
      border-bottom: none;
      background-color: #fff;
      box-shadow: 0px 0px 50px #0000001a;
      border-radius: 14px;

      @media (min-width: 1200px) {
        padding: 42px 37px;
      }
    }

    .collapse-item-header {
      display: flex;
      h2 {
        color: #161c2d;
        font-weight: bold;
        font: normal normal 600 24px/29px Proxima Nova Sbold;
        @media (min-width: 1200px) {
          font: normal normal 600 36px/43px Proxima Nova Sbold;
        }
        padding: 5px 12px 0;
      }
      p {
        color: #3f4b5f;
        font: normal normal 300 24px/29px Proxima Nova Light;
        @media (min-width: 1200px) {
          font: normal normal 300 28px/34px Proxima Nova Light;
        }
        padding: 5px 12px;
      }
      svg {
        margin: 5px 12px 0;
        width: 32px;
        height: 37px;
        transition: transform 0.3s ease-in-out;
      }
    }

    .rotation {
      transform: rotate(180deg);
    }

    .no-rotation {
      transform: rotate(0);
    }
  }
  .horarios {
    text-align: center;
    font-size: 24px;
    font-family: Proxima Nova;
    font-weight: normal;
    letter-spacing: 0px;
    color: #3f4b5f;
    margin: 0 auto 20px;

    @media (min-width: 1200px) {
      width: 60%;
      margin: 0 auto 50px;
    }
  }
`;

export const fomCss = css`
  font: normal normal 300 24px/29px Proxima Nova Light;
  width: 90%;
  margin: 0 auto;
  @media (min-width: 1200px) {
    width: 435px;
  }
`;

export const phoneCss = css`
  width: 100%;
  height: 60px;
  border: 0.5px solid #707070;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal 600 20px/26px Proxima Nova;
  span {
    margin-left: 10px;
  }
`;
