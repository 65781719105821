import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import React from "react";
import InformationBox from "~components/InformationBox/InformationBox";
import BackArrow from "~components/icons/BackArrow";
import { usePrismic } from "~hooks/usePrismic";
import { PxToVx } from "~utils/hooks/VH&VWConvert";
import { margin } from "~utils/hooks/useBreakPointTools";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";

const H1Novaventa = (props: any) => {
  const t = usePrismic();
  const { device, sizeWidth, sizeHeight } = useWindowBreakPoints();

  const theme: any = useTheme();
  const {
    showBack = true,
    text = t.constants.PARAQUECONTACTE,
    boldText = t.constants.COMPLETEESTOSCAMP,
    boldFirst = true,
    color = false,
    underline = true,
    hidden = false,
    extraCss,
  } = props;

  const headerCss = css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    h1 {
      left: 10%;
      text-align: left;
      width: auto;
      height: 20px;
      font-size: 10%;

      color: ${boldFirst && !color ? theme.colors.blueGray900 : theme.colors.blue400};
      font-size: clamp(
        ${theme.vars.h1FontSizeMin},
        ${PxToVx(35, sizeWidth, "vw")},
        ${theme.vars.h1FontSizeMax}
      );
      margin-top: 0.5vh;
    }
    h1 .underline {
      ${underline
        ? " border-bottom: `1px solid ${theme.colors.gray100}`; display: inline-block; line-height:  1.8 : 0; }"
        : ""}
    }
    h1 b {
      font-family: ${theme.fonts.ProximaNovaSbold};
    }
    .back {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      color: ${theme.colors.blue400};
      font-size: 10px;
      align-items: flex-start;
      margin-bottom: 0px;
    }
    .back svg {
      padding-top: 8px;
      fill: ${theme.colors.blue400};
      width: 36px;
      height: 36px;
    }
    .back span.anticon-arrow-left {
      padding: 0px;
      margin: 0px;
    }
    h1 {
      left: 10%;
      text-align: left;
      width: auto;
      height: 20px;
      font-size: 16px;
    }
    ${extraCss}
  `;

  return (
    <React.Fragment>
      <div css={headerCss} hidden={hidden}>
        {showBack ? (
          <div className="back">
            <BackArrow />
            <span>{t.constants.ATRAS}</span>
          </div>
        ) : null}
        <h1>
          {boldFirst ? (
            <span className="underline">
              <b>{boldText}</b>
              {text}
            </span>
          ) : (
            <span>
              {text}
              <b> {boldText}</b>
            </span>
          )}
        </h1>
      </div>
      <div>
        <InformationBox
          hide={true}
          boldText={t.constants.TENPRESENTE}
          text={t.constants.ADVERTENCIALOGIN}
          icon="info"
          extraCss={css`
            padding: 2rem 0 1rem 0;
            margin: ${margin({
              device: device,
              sizeHeight: sizeHeight,
              sizeWidth: sizeWidth,
              deskTopM: 45,
              deskLeftM: 0,
              deskBottomM: 60,
              deskRightM: 0,
              tablTopM: 32,
              tablLeftM: 0,
              tablBottomM: 32,
              tablRightM: 0,
              mobTopM: 0,
              mobLeftM: 0,
              mobBottomM: 51,
              mobRightM: 0,
            })};
            .svgContainer {
              margin: ${margin({
                device: device,
                sizeHeight: sizeHeight,
                sizeWidth: sizeWidth,
                deskTopM: 2,
                deskLeftM: 22,
                deskBottomM: 0,
                deskRightM: 0,
                tablTopM: 2,
                tablLeftM: 16,
                tablBottomM: 0,
                tablRightM: 0,
                mobTopM: 2,
                mobLeftM: 8,
                mobBottomM: 0,
                mobRightM: 0,
              })};
            }
          `}
        />
      </div>
    </React.Fragment>
  );
};
export default H1Novaventa;
