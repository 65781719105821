import { getObject } from "~utils/hooks/localStorage";

export const apiFormattedDate = (date: any) => {
  if (date) {
    if (typeof date !== "string") {
      date = new Date(date);
      return date.toISOString().substring(0, 10);
    }
    const splitedDate = date.split("/");
    return [splitedDate[2], splitedDate[1], splitedDate[0]].join("-");
  }
};

export const uuidSolicitud = () => {
  return getObject("uuid");
};

export const formDataBarrio = (formValues: any = {}, userInfo: any = {}) => {
  formValues = userInfoToFormValues(formValues, userInfo);
  const formFields = {
    idBarrio: formatValues(formValues.idBarrio).trim(),
    direccion: formatValues(formValues.direccion).trim(),
  };
  return formFields;
};

export const formData = (formValues: any = {}, userInfo: any = {}) => {
  formValues = userInfoToFormValues(formValues, userInfo);
  const uuid = getObject("uuid");
  const nombres = `${formValues.primerNombre} ${
    formValues.segundoNombre === undefined ? "" : formValues.segundoNombre
  }`.trim();
  const formFields = {
    uuid: uuid,
    referencia: {
      idRelacion: formatValues(formValues.idRelacion),
      complemento: formatValues(formValues.complemento).trim(),
    },
    principal: {
      tipoDireccion: 1,
      idDepartamento: formatValues(formValues.idDepartamento).trim(),
      idCiudad: formatValues(formValues.idCiudad).trim(),
      idBarrio: formatValues(formValues.idBarrio).trim(),
      direccion: formatValues(formValues.direccion).trim(),
      datosAdicionales: formatValues(formValues.datosAdicionales).trim(),
      uuiBarrio: formatValues(formValues.idBarrio).trim(),
    },
    secundario: {
      tipoDireccion: 2,
      idDepartamento: formatValues(formValues.idDepartamentoDA).trim(),
      idCiudad: formatValues(formValues.idCiudadDA).trim(),
      idBarrio: formatValues(formValues.idBarrioDA).trim(),
      direccion: formatValues(formValues.direccionEnvio).trim(),
      datosAdicionales: formatValues(formValues.datosAdicionalesDA).trim(),
    },
    contacto: {
      correoElectronico: formatValues(formValues.correoElectronico).trim(),
      telefonoPrincipal: formatValues(formValues.telefonoPrincipal).trim(),
      telefonoSecundario: formatValues(formValues.telefonoSecundario).trim(),
      prefijo: formatValues(formValues.prefixCellPhone).trim(),
      telefonoFijo: formatValues(formValues.telefonoFijo).trim(),
    },
    usuario: {
      idTipoDocumento: formatValues(formValues.idTipoDocumento).trim(),
      numeroDocumento: formatValues(formValues.numeroDocumento).trim(),
      nombres: (nombres ?? "").trim(),
      primerApellido: formatValues(formValues.primerApellido).trim(),
      segundoApellido: formatValues(formValues.segundoApellido).trim(),
      fechaNacimiento: apiFormattedDate(formValues.fechaNacimiento) || "",
      lugarNacimiento: formatValues(formValues.lugarNacimiento).trim(),
      fechaExpedicionDocumento:
        apiFormattedDate(formValues.fechaExpedicionDocumento) || "",
      lugarExpedicionDocumento: formatValues(formValues.lugarExpedicionDocumento).trim(),
      sexo: formatValues(formValues.sexo).trim(),
      regimenTributario: formatValues(formValues.regimenTributario).trim(),
    },
  };
  return formFields;
};

const assignmentsUserInfoPrincipal = (userInfo: any, formValues: any) => {
  const principal = userInfo.principal;
  formValues.idDepartamento = formValues.idDepartamento
    ? formValues.idDepartamento
    : formatValues(principal.idDepartamento);
  formValues.idCiudad = formValues.idCiudad
    ? formValues.idCiudad
    : formatValues(principal.idCiudad);
  formValues.idBarrio = formValues.idBarrio
    ? formValues.idBarrio
    : formatValues(principal.idBarrio);
  formValues.direccion = formValues.direccion
    ? formValues.direccion
    : formatValues(principal.direccion);
  formValues.datosAdicionales = formValues.datosAdicionales
    ? principal.datosAdicionales
    : formatValues(principal.datosAdicionales);
  formValues.uuiBarrio = formValues.uuiBarrio
    ? formValues.uuiBarrio
    : formatValues(principal.idBarrio);
};

const assignmentsUserInfoSecundario = (userInfo: any, formValues: any) => {
  const secundario = userInfo.secundario;
  formValues.idDepartamentoDA = formValues.idDepartamentoDA
    ? formValues.idDepartamentoDA
    : formatValues(secundario.idDepartamento);
  formValues.idCiudadDA = formValues.idCiudadDA
    ? formValues.idCiudadDA
    : formatValues(secundario.idCiudad);
  formValues.idBarrioDA = formValues.idBarrioDA
    ? formValues.idBarrioDA
    : formatValues(secundario.idBarrio);
  formValues.deliveryAddressSummary = formValues.deliveryAddressSummary
    ? formValues.deliveryAddressSummary
    : formatValues(secundario.direccion);
  formValues.datosAdicionalesDA = formValues.datosAdicionalesDA
    ? formValues.datosAdicionalesDA
    : formatValues(secundario.datosAdicionales);
};

const assignmentsUserInfoContacto = (userInfo: any, formValues: any) => {
  const contacto = userInfo.contacto;
  formValues.correoElectronico = formValues.correoElectronico
    ? formValues.correoElectronico
    : formatValues(contacto.correoElectronico);
  formValues.telefonoPrincipal = formValues.telefonoPrincipal
    ? formValues.telefonoPrincipal
    : formatValues(contacto.telefonoPrincipal);
  formValues.telefonoSecundario = formValues.telefonoSecundario
    ? formValues.telefonoSecundario
    : formatValues(contacto.telefonoSecundario);
  formValues.telefonoFijo = formValues.telefonoFijo
    ? formValues.telefonoFijo
    : formatValues(contacto.telefonoFijo);
};

const assignmentsUserInfoReferencia = (userInfo: any, formValues: any) => {
  const referencia = userInfo.referencia;
  formValues.idRelacion = formValues.idRelacion
    ? formValues.idRelacion
    : formatValues(referencia.idRelacion);
  formValues.complemento = formValues.complemento
    ? formValues.complemento
    : formatValues(referencia.complemento);
};

const assignmentsUserInfoUsuario = (userInfo: any, formValues: any) => {
  const usuario = userInfo.usuario;
  formValues.lugarNacimiento = formValues.lugarNacimiento
    ? formValues.lugarNacimiento
    : formatValues(usuario.lugarNacimiento);
  formValues.lugarExpedicionDocumento = formValues.lugarExpedicionDocumento
    ? formValues.lugarExpedicionDocumento
    : formatValues(usuario.lugarExpedicionDocumento);
  formValues.sexo = formValues.sexo ? formValues.sexo : formatValues(usuario.sexo);
  formValues.regimenTributario = formValues.regimenTributario
    ? formValues.regimenTributario
    : formatValues(usuario.regimenTributario);
  formValues.fechaExpedicionDocumento = formValues.fechaExpedicionDocumento
    ? formValues.fechaExpedicionDocumento
    : "";
  formValues.fechaNacimiento = formValues.fechaNacimiento
    ? formValues.fechaNacimiento
    : formatValues(usuario.fechaNacimiento);
  formValues.primerNombre = formValues.primerNombre
    ? formValues.primerNombre
    : formatValues(usuario.nombres.split(" ")[0]);

  let validateSecondName = "";
  if (formValues.segundoNombre) {
    validateSecondName = formValues.segundoNombre;
  } else {
    validateSecondName = usuario.nombres.split(" ")[1];
  }
  formValues.segundoNombre = validateSecondName;

  formValues.primerApellido = formValues.primerApellido
    ? formValues.primerApellido
    : usuario.primerApellido;
  formValues.segundoApellido = formValues.segundoApellido
    ? formValues.segundoApellido
    : usuario.segundoApellido;
  formValues.regimenTributario = formValues.regimenTributario
    ? formValues.regimenTributario
    : usuario.regimenTributario;
};

export const userInfoToFormValues = (formValues: any, userInfo: any) => {
  if (userInfo) {
    if (formValues.direccionOtros1) {
      formValues.direccion = formValues.direccionOtros1.substring(0, 90);
      formValues.datosAdicionales = formValues.direccionOtros1.substring(
        90,
        formValues.direccionOtros1.length
      );
    }
    if (formValues.direccionOtros1DA) {
      formValues.direccionEnvio = formValues.direccionOtros1DA.substring(0, 90);
      formValues.datosAdicionalesDA = formValues.direccionOtros1DA.substring(
        90,
        formValues.direccionOtros1DA.length
      );
    }
    if (userInfo.principal) {
      assignmentsUserInfoPrincipal(userInfo, formValues);
    }
    if (userInfo.secundario) {
      assignmentsUserInfoSecundario(userInfo, formValues);
    }
    if (userInfo.contacto) {
      assignmentsUserInfoContacto(userInfo, formValues);
    }
    if (userInfo.referencia) {
      assignmentsUserInfoReferencia(userInfo, formValues);
    }
    if (userInfo.usuario) {
      assignmentsUserInfoUsuario(userInfo, formValues);
    }
  }
  return formValues;
};

const formatValues = (value: any) => value || "";

export const formDataList: any = {
  uuid: uuidSolicitud,
  idRelacion: "",
  complemento: "",
  tipoDireccion: "",
  idDepartamento: "",
  idCiudad: "",
  idBarrio: "",
  direccion: "",
  datosAdicionales: "",
  correoElectronico: "",
  telefonoPrincipal: "",
  telefonoSecundario: "",
  telefonoFijo: "",
  idTipoDocumento: "",
  numeroDocumento: "",
  nombres: "",
  primerApellido: "",
  segundoApellido: "",
  fechaNacimiento: "",
  lugarNacimiento: "",
  fechaExpedicionDocumento: "",
  lugarExpedicionDocumento: "",
  sexo: "",
  regimenTributario: "",
};
