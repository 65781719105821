import type { CancelToken } from "axios";
import axios from "axios";
const baseURL: any = process.env.API_DEVELOP;

export function sendAddressForm(data: any) {
  return axios
    .post(`${baseURL}/comandos/registrar/datos-vivienda`, data, {})
    .then((response) => {
      return response?.data;
    })
    .catch((e) => console.error({ sendAddressForm: e }));
}

export function sendContactData(values: any) {
  return axios
    .post(`${baseURL}/comandos/registrar/datos-contacto`, values, {})
    .then(({ data }) => data)
    .catch((e) => console.error({ sendContactForm: e }));
}

export function sendReferredByForm(data: any) {
  return axios
    .post(`${baseURL}/comandos/registrar/datos-referencia`, data, {})
    .then((response) => {
      return response?.data;
    })
    .catch((e) => console.log({ sendReferredByForm: e }));
}

export function sendCallRequest(data: any) {
  return axios
    .post(`${baseURL}/comandos/registrar/datos-llamada`, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => console.log({ sendReferredByForm: e }));
}

export function sendPersonalData(data: any) {
  return axios
    .post(`${baseURL}/comandos/registrar/datos-personales`, data)
    .then((response) => {
      return response?.data;
    })
    .catch((e) => console.log({ sendPersonalData: e }));
}

export function sendIDImage(
  file: any,
  dniType: string,
  cara: string,
  document: string,
  uuid: string,
  cancelToken: CancelToken
) {
  cara = cara === "front" ? "frontal" : "posterior";
  cara = document ? `${cara}AD` : cara;
  const data: any = new FormData();
  const id = document || uuid;
  data.append("file", file);
  return axios({
    method: "post",
    timeout: 100000,
    url: `${baseURL}/comandos/registrar-documento/${dniType}/${cara}/${id}`,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    cancelToken,
  });
}

export function sendRegistrationForm(data: any) {
  return axios.post(`${baseURL}/comandos/registrar`, data, {
    headers: {
      //Authorization: localStorage.getItem("token"),
    },
  });
}
