import React from "react";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Checkbox } from "antd";
import { fontSizeClamp } from "~utils/hooks/useBreakPointTools";
const CheckboxTC = (props: any) => {
  const {
    extraCss = {},
    size,
    text,
    boldTex,
    onChangeCheck,
    showModalCheck,
    check,
    device,
    sizeWidth,
  } = props;
  const theme: any = useTheme();

  return (
    <div
      css={css`
        font-size: ${fontSizeClamp(device, sizeWidth, 22, 16, 16, 14)};
        b {
          font-family: ${theme.fonts.ProximaNovaSbold};
          color: ${theme.colors.blueGray800};
          text-decoration: underline;
          margin-left: 1%;
        }

        b :hover {
          cursor: pointer;
        }
        label {
          margin: 0px;
        }
        span {
          font-family: ${theme.fonts.ProximaNovaLight};
        }
        .ant-checkbox {
          transform: scale(${size});
          padding-right: 10px;
        }
        .ant-checkbox-checked::after {
          width: auto;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: ${theme.colors.blue400};
          border-color: ${theme.colors.blue400};
        }
        display: flex;
        flex-wrap: wrap;
        color: ${theme.colors.blueGray800};
        margin-left: 2%;
        align-self: center;
        #checkBoxNova {
          align-self: stretch;
          ::after {
            width: auto;
          }
        }
        ${extraCss}
      `}
    >
      <Checkbox
        data-testid="my-checkbox"
        id="checkBoxNova"
        checked={check}
        onChange={onChangeCheck}
      ></Checkbox>
      <span onClick={onChangeCheck}>{`${text} `} </span>
      {boldTex ? <b onClick={showModalCheck}>{` ${boldTex}`}</b> : null}
    </div>
  );
};

export default CheckboxTC;
