import React from "react";
import { css } from "@emotion/core";
import "react-dropzone-uploader/dist/styles.css";
import { usePrismic } from "~hooks/usePrismic";
import { PaperClipOutlined } from "@ant-design/icons";

const UploadBtn = ({ accept, onFiles, getFilesFromEvent }: any) => {
  const t = usePrismic();
  return (
    <label
      id="txtAdjuntar"
      css={css`
        color: #345eea !important;
      `}
    >
      {t.constants.ADJUNTAR}
      <input
        data-testid="upload-btn"
        id="upload-btn"
        style={{ display: "none" }}
        type="file"
        accept={accept}
        multiple
        onChange={(e) => {
          getFilesFromEvent(e).then((chosenFiles: any) => {
            onFiles(chosenFiles);
          });
        }}
      />
      <PaperClipOutlined
        css={css`
          margin-left: 10px;
        `}
      />
    </label>
  );
};

export default UploadBtn;
