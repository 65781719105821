import { css } from "@emotion/core";
import { RouteComponentProps, navigate } from "@reach/router";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import bannerDesktop from "~assets/img/banner-5-desktop.png";
import bannerMobile from "~assets/img/banner-5-mobile.png";
import Estrellas from "~assets/img/estrellas.svg";
import ParagraphNova from "~components/ParagraphNova";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import H1Novaventa from "~components/header/H1Novaventa";
import { usePrismic } from "~hooks/usePrismic";
import novaventaTheme from "~styles/theme";
import { PxToVx } from "~utils/hooks/VH&VWConvert";
import { getObject, removeObject } from "~utils/hooks/localStorage";
import { fontSizeClamp, margin } from "~utils/hooks/useBreakPointTools";
import useImageSize from "~utils/hooks/useImageSize";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";
import useWindowSize from "~utils/hooks/useWindowSize";
import { capitalizaFirstLetter } from "~utils/utils";

type Props = RouteComponentProps & {
  path: string;
};

const WelcomeToNovaventa = ({ path }: Props) => {
  const t = usePrismic();
  const { width, height } = useWindowSize();
  const [refresh, setRefresh] = useState<boolean>();
  const passwordUrl: any = process.env.ACTIVATE_PASSWORD_URL;
  const { imgSizeHeight, imgSizeWidth } = useImageSize(
    "imgWelcome",
    height,
    width,
    refresh
  );
  const [name, setname] = useState("");
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const { device, sizeWidth, sizeHeight } = useWindowBreakPoints();
  const [sizeColHeight, setSizeColHeight] = useState<number>();

  useEffect(() => {
    const prename: string = getObject("name");
    const prePaymentMethod: string = getObject("PaymentMethod");
    if (prename) {
      setname(capitalizaFirstLetter(prename));
    }
    if (prePaymentMethod) {
      setPaymentMethod(prePaymentMethod);
    }
  }, []);

  useEffect(() => {
    if (path === "bienvenida") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function () {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  }, []);

  useEffect(() => {
    if (device === "desktop" && height && imgSizeHeight && height - 190 < imgSizeHeight) {
      setSizeColHeight(height - 190);
    } else {
      setSizeColHeight(imgSizeHeight);
    }
  }, [device, height, imgSizeHeight]);

  let validation1 = "";
  if (width && width >= novaventaTheme.breakPoints.desktop) {
    validation1 = bannerDesktop;
  } else {
    validation1 = bannerMobile;
  }

  let validation3 = "";
  let validation4 = "";
  if (device && device === "desktop") {
    validation3 = `clamp(44px,${PxToVx(
      63,
      novaventaTheme.breakPoints.maxDesktopHeight,
      "vh"
    )},63px)`;
    validation4 = "0";
  } else {
    validation3 = "44px";
    validation4 = "9vh";
  }

  let validation5 = "";
  if (height && device === "desktop") {
    validation5 = `${height - 200}px`;
  } else {
    validation5 = "auto";
  }
  return (
    <div
      className="center"
      id="welcomeSection"
      css={css`
        height: ${validation5};
      `}
    >
      {width ? (
        <Row gutter={[8, 8]} justify="space-between" align="stretch">
          <Col
            id="colW"
            xs={24}
            sm={24}
            md={24}
            lg={7}
            css={css`
              height: ${sizeColHeight}px;
              img {
                ${resolvePositionDevice(device, "position:absolute", "")}
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                z-index: 0;
              }
              div {
                background: ${novaventaTheme.colors.blue400} 0% 0% no-repeat padding-box;
                mix-blend-mode: multiply;
                position: absolute;
                opacity: 0.1;
                width: ${imgSizeWidth}px;
                height: ${imgSizeHeight}px;
                z-index: 1;
              }
            `}
          >
            <div></div>
            <img
              id="imgWelcome"
              data-testid="imgWelcome"
              src={validation1}
              width="100%"
              height="345"
              onLoad={() => setRefresh(!refresh)}
            ></img>
          </Col>

          <Col xs={24} sm={24} md={24} lg={16}>
            <Estrellas
              css={css`
                display: inline-table;
                width: 100vw;
                float: right;
                height: auto;
                opacity: 0.5;
              `}
            />
            <H1Novaventa
              sizeWidth={sizeWidth}
              color={true}
              showBack={false}
              boldText={t.constants.BIENVENIDA.replace("${0}", name)}
              text={t.constants.ALAFAMNOVA.replace("${0}", name)}
              extraCss={css`
                display: table;
                margin: ${margin({
                  device: device,
                  sizeHeight: sizeHeight,
                  sizeWidth: sizeWidth,
                  deskTopM: 201,
                  deskLeftM: 0,
                  deskBottomM: 64,
                  deskRightM: 0,
                  tablTopM: 135,
                  tablLeftM: 0,
                  tablBottomM: 48,
                  tablRightM: 0,
                  mobTopM: 65,
                  mobLeftM: 0,
                  mobBottomM: 16,
                  mobRightM: 10,
                })};
                h1 {
                  text-align: center;
                  font-size: ${fontSizeClamp(device, sizeWidth, 46, 34, 24, 20)};
                }
              `}
            />
            <div>
              <ParagraphNova
                paymentMethod={paymentMethod}
                extraCss={css`
                  ${resolvePositionDevice(
                    device,
                    " max-width: 811px;",
                    "max-width: 90vw;"
                  )}
                  ${resolvePositionDevice(
                    device,
                    "min-width:720px;",
                    ""
                  )}
                    p {
                    text-align: center;
                    font-size: ${fontSizeClamp(device, sizeWidth, 28, 24, 16, 14)};
                    font-family: ${novaventaTheme.fonts.ProximaNovaLight};
                  }
                `}
              />
            </div>
            <NovaventaButton
              handleClick={() => {
                navigate(passwordUrl);
                removeObject("name");
              }}
              showArrow={true}
              htmlType="submit"
              btnText={t.constants.ACTTUCONTRASEÑA}
              extracss={css`
                display: table;
                width: clamp(
                  328px,
                  ${PxToVx(435, novaventaTheme.breakPoints.maxDesktopWith, "vw")},
                  435px
                );

                height: ${validation3};

                margin: 0 auto ${validation4} auto;
              `}
            />

            <ParagraphNova
              text={t.constants.TODOSLOSTERMINOS}
              extraCss={css`
                bottom: 0px;
                margin-left: 5vw;
                margin-right: 5vw;

                p {
                  text-align: center;
                  font-size: ${fontSizeClamp(device, sizeWidth, 16, 14, 12, 10)};
                  font-family: ${novaventaTheme.fonts.ProximaNovaLight};
                }
              `}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

const resolvePositionDevice = (
  device: any,
  truePorperty: string,
  falseProperty: string
) => (device === "desktop" ? truePorperty : falseProperty);

export default WelcomeToNovaventa;
