export const mergeObjects = <T extends Record<string, any>>(local: T, prismic: T): T => {
  return Object.keys(local).reduce((result, key) => {
    if (local[key] && prismic[key] === undefined) {
      result[key] = local[key];
      return result;
    }

    if (Array.isArray(local[key])) {
      if (Array.isArray(prismic[key])) result[key] = prismic[key];
      else result[key] = local[key];
      return result;
    }

    if (typeof local[key] === "object") {
      if (prismic[key] === null) result[key] = null;
      else if (typeof prismic[key] === "object") {
        result[key] = mergeObjects(local[key], prismic[key]);
      } else result[key] = local[key];
      return result;
    }

    result[key] = prismic[key];
    return result;
  }, {} as any);
};
