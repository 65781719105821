const novaventabreakPoints = {
  mobile: 412, //xs
  tablet: 800, //md
  desktop: 1366, //lg
  maxMobileWith: 412,
  maxMobileHeight: 1134,
  maxTabletWith: 800,
  maxTabletHeight: 1366,
  maxDesktopWith: 1920,
  maxDesktopHeight: 1024,
};

export default novaventabreakPoints;
