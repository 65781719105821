import { css } from "@emotion/core";
import { Modal } from "antd";
import CloseIcon from "~assets/icons/close.svg";
import { usePrismic } from "~hooks/usePrismic";
import novaventaTheme from "~styles/theme";

const CloseElement = () => {
  return (
    <div
      css={css`
        svg {
          width: 20px;
        }
      `}
    >
      <CloseIcon />
    </div>
  );
};

const DefaultHelpTitle = () => {
  const t = usePrismic();
  return (
    <h2
      css={css`
        color: ${novaventaTheme.colors.blue400};
        font-family: ${novaventaTheme.fonts.ProximaNova};
        margin: 0;
      `}
    >
      {t.constants.AYUDA}
    </h2>
  );
};

const HelpModal = ({
  modalWidth,
  ModalContent,
  title,
  footer = null,
  visible = false,
  handleModalClick,
}: any) => {
  return (
    <Modal
      css={css`
        .ant-modal-header {
          border-radius: 14px;
        }
        .ant-modal-content {
          border-radius: 14px;
        }
      `}
      title={title || <DefaultHelpTitle />}
      centered
      visible={visible}
      onOk={() => handleModalClick(false)}
      onCancel={() => handleModalClick(false)}
      width={modalWidth}
      footer={footer}
      destroyOnClose={false}
      closeIcon={<CloseElement />}
    >
      {ModalContent}
    </Modal>
  );
};

export default HelpModal;
