import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { setObject } from "./localStorage";

const poolData = {
  UserPoolId: String(process.env.USER_POOL_ID),
  ClientId: String(process.env.CLIENT_ID),
};
export let emailUser = "";
const UserPool = new CognitoUserPool(poolData);
let principalUser: CognitoUser;
try {
  principalUser = new CognitoUser({
    Username: emailUser,
    Pool: UserPool,
  });
} catch (error) {
  console.error(error);
}

export const login = async (email: string, password: string) => {
  principalUser = new CognitoUser({
    Username: email,
    Pool: UserPool,
  });
  const autDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  return await new Promise((resolve: any, reject: any) => {
    principalUser.authenticateUser(autDetails, {
      onSuccess: (data: any) => {
        resolve(data);
      },
      onFailure: (err: any) => {
        console.error("onFailure:", err);
        reject(err);
      },
      newPasswordRequired: () => {
        const activate = { redirect: true };
        setObject("session");
        reject(activate);
      },
    });
  });
};
export const changePassword = (verificationCode: string, newPassword: string) => {
  const user = new CognitoUser({
    Username: emailUser,
    Pool: UserPool,
  });
  return new Promise((resolve, reject) => {
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        emailUser = "";
        resolve(true);
      },
      onFailure: (error) => {
        console.error("onFailure:", error);
        reject(error);
      },
    });
  });
};
export const activatePassword = async (newPassword: string) => {
  return await new Promise((resolve, reject) => {
    principalUser.completeNewPasswordChallenge(newPassword, [], {
      onSuccess: (data: any) => {
        resolve(data);
      },
      onFailure: (err: any) => {
        console.error("onFailure", err);
        reject(err);
      },
    });
  });
};

export const forgotPassword = async (email: string) => {
  const user = new CognitoUser({
    Username: email,
    Pool: UserPool,
  });
  emailUser = email;
  return await new Promise((resolve: any, reject: any) => {
    user.forgotPassword({
      onSuccess: (data: any) => {
        resolve(data);
      },
      onFailure: (err: any) => {
        console.error("onFailure:", err);
        reject(err);
      },
      inputVerificationCode: function (data) {
        resolve(data);
      },
    });
  });
};
export const signUp = async (email: string, password: string) => {
  return await new Promise((resolve: any, reject: any) => {
    UserPool.signUp(email, password, [], [], (err: any, data: any) => {
      if (err) reject(err);
      resolve(data);
    });
  });
};
