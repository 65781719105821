import React, { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { device } from "~utils/NovaventaMedia";
import MobileDigitalRegistration from "~components/digitalRegistration/MobileDigitalRegistration";
import DesktopDigitalRegistration from "~components/digitalRegistration/DesktopDigitalRegistration";
import { FormProvider } from "~store/FormContext";
import { DocumentProvider } from "~utils/context/document.provider";

type Props = RouteComponentProps & {
  registrationType: string;
};

const DigitalRegistrationPage = ({ registrationType, path, uri }: Props) => {
  const deviceType = device();

  useEffect(() => {
    if (
      document.querySelector("#chat-bots-webchat-container") &&
      window.location.href.includes("auto-gestion")
    ) {
      window.CBWebChat.setChatVariable("seccion", "3");
    }
  });

  return (
    <FormProvider>
      <DocumentProvider>
        {deviceType === "desktop" || deviceType === "desktopHD" ? (
          <DesktopDigitalRegistration
            registrationType={registrationType}
            path={path}
            uri={uri}
          />
        ) : (
          <MobileDigitalRegistration registrationType={registrationType} />
        )}
      </DocumentProvider>
    </FormProvider>
  );
};
export default DigitalRegistrationPage;
