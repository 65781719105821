export default {
  home: {
    loading: "CARGANDO...",
    userTransUnion: "1007430509",
    compoundSurnames: ["DE"],
    doNotDeleteCharacters: "[^0-9A-ZÑÁÉÍÓÚÜ]",
    replaceName: [["0", "O"]],
    linkCommerce:
      "https://comercio.novaventa.com.co/nautilusb2bstorefront/nautilus/es/COP/login",
    notFun: {
      h1: "Servidor temporalmente fuera de servicio",
      h3: "Estamos trabajando para ti, disculpa las molestias",
    },
    menuAdminItems: [
      "Listas Internas",
      "Descarga documentos cliente",
      "Iniciar inscripción",
      "Consultar Históricos",
      "Consultar Integraciones",
      "Consultar Procesos",
      "Bloqueo de correos",
      "Carga de documentos",
      "Llamadas",
      "Probador de integraciones",
    ],
    placeholders: {
      reference: "Número de documento de quien te refirió",
    },
    rules: {
      required: "Este campo no puede estar vacío",
      minLength: "Ingrese mínimo ${0} caracteres",
      maxLength: "Ingrese máximo ${0} caracteres",
      onlyNumber: "ingrese solo caracteres numéricos",
      startWithLetter: "Debe iniciar con una letra",
      contains: "Debe contener el caracter '${0}'",
      invalidEmail: "Correo electrónico inválido",
      invalidCellPhone: "Numero de celular inválido",
      invalidAddress: "Dirección no valida",
      selectPrefix: "Debe seleccionar el prefijo del país",
      sameEmails: "Los correos electrónicos ingresados no coinciden",
      invalid: "Campo inválido",
      errorAddress: "Error validando dirección",
      unexpectedError: "Error validando dirección",
      cannotRefer: "Novalíder no habilitada para referir nuevos usuarios",
      numberExists: "Este número ya se encuentra registrado",
      cellPhoneExists: "Este número de celular ya se encuentra registrado",
      whatsAppExists: "Este número de whatsApp ya se encuentra registrado",
      max90Chars: "La dirección supera los 90 caracteres permitidos",
      dniTypeNotMatch:
        "La imagen del documento no concuerda con el tipo del documento ingresado previamente",
      dniNumberNotMatch:
        "La imagen del documento no concuerda con el número del documento ingresado previamente",
      PaymentMethod: "Por favor selecciona si Deseas solicitar tu crédito",
      typeConsult: "Por favor selecciona el tipo de consulta",
      optionConsult: "Por favor selecciona la opción a consultar",
      lockedStatus: "Por favor selecciona el estado de cartera del cliente",
      idRelation: "El medio de conocimiento es obligatorio",
      name: "Nombre inválido",
      surname: "Apellido inválido",
      number: "Número inválido",
      dniNumber: "Número de documento inválido",
      address: "Dirección inválida",
      prefixCity: "debe iniciar por el prefijo de su ciudad",
      length: "ingrese ${0} caracteres",
      dniType: "Por favor ingrese un tipo de documento válido",
    },
    labels: {
      zoneOp: "Zona (Opcional)",
      number: "Número",
      addressOp: "Dirección (Opcional)",
      inside: "Interior (Opcional)",
      additional: "Información adicional (Opcional)",
      modelBusiness: "¿Cual es modelo de negocio?",
      name: "¿Cual es nombre?",
      secondNameOp: "Segundo nombre (Opcional)",
      surname: "¿Cual es apellido?",
      dniType: "¿Cual es el tipo de documento?",
      dniNumber: "Numero Identificación",
      lockedReason: "Motivo de Bloqueo",
      lockedStatus: "Estado de Bloqueo",
      lockedType: "Tipo de Bloqueo",
      whatFullname: "¿Cuál es tu nombre completo?",
      contactNumber: "¿Cuál es tu número de celular o fijo?",
      scheduleCall: "Programar la llamada",
      selectCity: "Elige tu ciudad",
      callTel: "Llamada >|< telefónica",
      call: [
        "Id",
        "Nombre completo",
        "Numero de contacto",
        "Observaciones",
        "Fecha de llamada",
        "Llamada realizada",
        "Acción",
      ],
      emailList: ["", "Tipo de bloqueo", "Dato", "Acción"],
      ValidatingResponses: ["ID: ", "No. Solicitud", "Estado transacción: "],
    },
    error: {
      unexpectedError: "Error inesperado",
      loadImage: "Ha ocurrido un error al enviar la imagen",
      weightLimit: "Por favor seleccione una imagen menor a 3 megas",
      pending: "Enviando imagen...",
      lowQuality:
        "El documento de identidad no pudo ser leído por la baja calidad de la imagen. Por favor intenta cargarlo nuevamente.",
    },
  },
  errors: {
    "Incorrect username or password.":
      "Tu nombre de usuario o contraseña son incorrectos, por favor verifícalos.",
    "Attempt limit exceeded, please try after some time.":
      "Límite de intentos excedido, inténtelo después de un tiempo",
    "Invalid code provided, please request a code again.":
      "Se proporcionó un código no válido. Vuelva a solicitar un código.",
    "We have a register with this parameters": "Ya existe una solicitud en proceso.",
    invalidFile: "Archivo Invalido",
    loadFile: "Error al cargar el archivo",
    unexpectedError: "Error inesperado",
    loadImage: "Ha ocurrido un error al enviar la imagen",
    weightLimit: "Por favor seleccione una imagen menor a 3 megas",
    pending: "Enviando imagen...",
    lowQuality:
      "El documento de identidad no pudo ser leído por la baja calidad de la imagen. Por favor intenta cargarlo nuevamente.",
    "MSGE-1":
      "No cumples con nuestras políticas de inscripción. Agradecemos tu interés en ser parte de Novaventa.",
    "ARTE-1": "Lo sentimos, no podemos continuar con tu inscripción ",
    "BTNE-1": "Cerrar ventana",
    "KNDE-1": "error",
    "MSGE-2":
      "Por favor diligencia los siguientes datos y pronto nos pondremos en contacto.",
    "ARTE-2":
      "Lo sentimos, ya se encuentra registrado este número de cédula. No es posible continuar con la inscripción en este momento. ",
    "BTNE-2": "Enviar",
    "KNDE-2": "error",
    "MSGE-3":
      "En este momento tu cuenta se encuentra activa y no presenta ningún problema. Te invitamos a ingresar a tu Oficina Virtual.",
    "ARTE-3": "Hola Novaempresario",
    "BTNE-3": "Ir a la oficina virtual",
    "KNDE-3": "information",
    "MSGE-4":
      "En este momento la inscripción se encuentra iniciada. Te invitamos a continuar con el proceso de inscripción.",
    "ARTE-4": "Inscripción iniciada",
    "BTNE-4": "Continuar inscripción",
    "KNDE-4": "information",
    generic: "Lo sentimos, ha ocurrido un error y no podemos continuar con el proceso",
    "E-15":
      "Tu nombre de usuario o contraseña no son correctos. Por favor vuelve a intentarlo",
    "E-16":
      "Lo sentimos pero no tienes una zona sección asignada. Por favor comunícate con tu gerente de zona",
    "E-22": "El usuario y la contraseña que ingresaste no coinciden, intenta nuevamente.",
    "E-23":
      "Lo sentimos. Has bloqueado tu cuenta por múltiples intentos fallidos. Para poder ingresar haz clic en Recuperar contraseña y realiza el proceso indicado.",
    "E-24": "El cliente no pertenece a la fuerza de ventas.",
    "EMAILE-5": "Email registrado previamente",
    "EMAILE-1": "Email no cumple con nuestras políticas de inscripción.",
    "MSGE-17":
      "No cumples con nuestras políticas para acceder a un crédito. Serás registrada con pagos de contado",
    "ARTE-17": "Lo sentimos no puedes solicitar un crédito",
    "BTNE-17": "Entendido!",
    "KNDE-17": "information",
    "MSGBE-17": "No cumples con nuestras políticas para acceder a un crédito.",
    "BTNBE-17": "Volver al comercio",
    "ARTE-19": "El cupo no fue ampliado",
    "BTNE-19": "Ir a la oficina virtual",
    "KNDE-19": "error",
    "MSGBE-19": "Ya excediste el numero de intentos para solicitar un nuevo credito",
    "ARTE-20": "El cupo no fue ampliado",
    "BTNE-20": "Ir a la oficina virtual",
    "KNDE-20": "error",
    "MSGBE-20": "Ocurrió un error procesando tu petición, por favor intenta más tarde",
    "ARTE-21": "El cupo no fue ampliado",
    "BTNE-21": "Ir a la oficina virtual",
    "KNDE-21": "error",
    "MSGBE-21": "Lo sentimos, no cumples con las condiciones para ampliar tu cupo.",
    "ARTE-T1": "Tu cupo fue ampliado",
    "BTNE-T1": "Ir a la oficina virtual",
    "KNDE-T1": "information",
    "MSGBE-T1": "Tu ampliacion de credito se realizo correctamente",
  },
  options: {
    GENERO: [
      { id: "M", label: "Masculino" },
      { id: "F", label: "Femenino" },
    ],
    TIPOREGIMENOP: [
      {
        id: "0",
        label: "Persona natural no responsable",
      },
      {
        id: "1",
        label: "Régimen simple de tributación",
      },
    ],
    MENSAJESDIRECCION: [
      {
        codigo: "E-8",
        mensaje:
          "La dirección del prospecto que estás ingresando ya se encuentra registrado en la base de datos de datos. Por políticas sólo puede estar registrado una persona por vivienda",
      },
      {
        codigo: "E-10",
        mensaje:
          "La dirección del prospecto que estás ingresando no corresponde a tu zona asignada.\nPor favor verifica o remítelo a la línea de servicio al Cliente para asignarle una nueva Novalíder.",
      },
      {
        codigo: "E-12",
        mensaje: "No tienes permitido realizar registros por este canal",
      },
    ],
    addressStructure: {
      address: [
        {
          id: "AUT",
          nombre: "Autopista",
        },
        {
          id: "AV",
          nombre: "Avenida",
        },
        {
          id: "CA",
          nombre: "Casa",
        },
        {
          id: "CL",
          nombre: "Calle",
        },
        {
          id: "CR",
          nombre: "Carrera",
        },
        {
          id: "CIR",
          nombre: "Circular",
        },
        {
          id: "CRV",
          nombre: "Circunvalar",
        },
        {
          id: "DG",
          nombre: "Diagonal",
        },
        {
          id: "GT",
          nombre: "Glorieta",
        },
        {
          id: "MZ",
          nombre: "Manzana",
        },
        {
          id: "PJ",
          nombre: "Pasaje",
        },
        {
          id: "TV",
          nombre: "Transversal",
        },
        {
          id: "VTE",
          nombre: "Variante",
        },
      ],
      zone: [
        {
          id: "NORTE",
          nombre: "Norte",
        },
        {
          id: "SUR",
          nombre: "Sur",
        },
        {
          id: "ESTE",
          nombre: "Este",
        },
        {
          id: "OESTE",
          nombre: "Oeste",
        },
      ],
      houseType: [
        {
          id: "CA",
          nombre: "Casa",
        },
        {
          id: "AP",
          nombre: "Apartamento",
        },
        {
          id: "IN",
          nombre: "Interior",
        },
        {
          id: "TO",
          nombre: "Torre",
        },
      ],
    },
    lockedStatus: [
      {
        id: true,
        label: "Bloqueado",
      },
      {
        id: false,
        label: "Sin Bloqueo",
      },
    ],
    deliveryAddress: [
      {
        id: "0",
        label: "Utilizar la dirección ingresada",
      },
      {
        id: "1",
        label: "Agregar una nueva dirección",
      },
    ],
    modelBusiness: [
      {
        id: "VD",
        nombre: "Venta Directa",
      },
      {
        id: "VCD",
        nombre: "Venta Directa Consumidor",
      },
      {
        id: "NAP",
        nombre: "Novaventa al paso",
      },
      {
        id: "NA",
        nombre: "No aplica",
      },
    ],
    consultProcess: [
      {
        id: 1,
        label: "Cédula",
      },
      {
        id: 2,
        label: "Rango de fechas",
      },
    ],
    blackList: [
      {
        id: 1,
        label: "Cédula",
      },
      {
        id: 2,
        label: "Rango de fechas",
      },
    ],
    paymentMethod: [
      {
        id: "CR",
        label: "Sí, solicitar el crédito",
      },
      {
        id: "CO",
        label: "No gracias, prefiero pagar de contado",
      },
    ],
    countryPrefixe: [
      {
        nombre: " Colombia (+57)",
        idValue: "57",
      },
      {
        nombre: " México (+52)",
        idValue: "52",
      },
      {
        nombre: " Venezuela (+58)",
        idValue: "58",
      },
    ],
    helpTexts: {
      front: [
        {
          title: "Adjunta la foto original de tu documento de identidad.",
        },
        {
          title: "Recuerda que el documento debe quedar completo.",
        },
        {
          title: "Recuerda que la fotografía debe quedar totalmente nítida.",
        },
      ],
      back: [
        {
          title: "Escanea la imagen de tu documento de identidad.",
        },
        {
          title: "Recuerda que el documento debe quedar completo.",
        },
        {
          title: "Recuerda que la fotografía debe quedar totalmente nítida.",
        },
      ],
    },
    taxRegimen: {
      left: {
        title: ["No responsable"],
        data: ["Simple de tributación."],
      },
      right: {
        title: ["Simple de tributación"],
        data: [
          "Personas naturales o personas jurídicas en la que sus socios o accionistas sean personas naturales, nacionales o extranjeras residentes en Colombia. ",
          "Ingresos brutos o extraordinarios sean iguales o superiores a 1.400 UVT e inferiores a 80.000 UVT. ",
          "Contar con la inscripción en el Registro Único Tributario (RUT)",
        ],
      },
    },
  },
  constants: {
    COPYRIGHT: "Copyright © 2020 Novaventa S.A.S.",
    RIGHTSRESERVED: "Todos los derechos reservados.",
    REGISTROFULL: "Regístrate para ser parte de Novaventa",
    REGISTRO: "Regístrate para ser parte de",
    ACTIVATUCUENTA:
      "Activa tu cuenta en pocos pasos y disfruta todo lo que tenemos para ti y tu familia",
    NOVAVENTA: "Novaventa",
    NOVAVENTA2: "Novaventa?",
    SIGUENOS: "Síguenos",
    TIPODOCUMENTO: "¿Cuál es tu tipo de documento?",
    TIPODOCUMENTOPROSPECTO: "Tipo de documento del prospecto",
    NUMERODOCUMENTOPROSPECTO: "Número de documento del prospecto",
    NUMERODOCUMENTO: "¿Cuál es tu número de documento?",
    NUMERODOCUMENTO2: "Número de documento",
    TIPODOCUMENTO2: "Tipo de documento",
    CONTINUAR: "Continuar",
    TENPRESENTE: "Ten presente: ",
    ADVERTENCIALOGIN:
      "Antes de iniciar tu registro asegúrate de tener a la mano tu documento de identidad en formato digital (JPG, PNG).",
    ACEPTOLOS: "Acepto los",
    ACEPTARLOS: "Aceptar los",
    TERMCONDICION: "Términos y condiciones",
    DEBESACEPTAR:
      "Para continuar con la solicitud de crédito debes aceptar los términos y condiciones",
    INSDIGITALHEADER: "Inscripción digital",
    INSDIGITALTEXT:
      "Inscríbete tú mismo y comienza de inmediato con catálogo digital, recibirás herramientas virtuales e información por WhatsApp para manejar tu negocio.",
    CONTUNEXPERTHEADER: "Inscripción con NovaLíder",
    CONTUNEXPERTTEXT:
      " Inscríbete con una NovaLíder con experiencia, te entregará el catálogo físico y enseñará a manejar tu negocio.",
    LLAMADATELHEADER: "Llamada telefónica",
    LLAMADATELTEXT:
      "Agenda una llamada con un asesor para ayudarte con tu inscripción y enseñarte a manejar tu negocio.",
    COMO: "¿Cómo",
    DESEASINS: "deseas inscribirte?",
    ABANDONARPROCESO: "Abandonar el proceso",
    LOSENTIMOS: "Lo sentimos, no podemos continuar con tu inscripción",
    NOCUMPLES:
      "No cumples con nuestras políticas de inscripción. Agradecemos tu interés en ser parte de Novaventa.",
    LOSDATOSBASICOS:
      "Los datos básicos ingresados son incorrectos. Por favor inicie de nuevo la inscripción.",
    CERRARVENTANA: "Cerrar ventana",
    REINICIARINS: "Reiniciar inscripción",
    PORCUALMEDIO: " ¿Por cuál medio conociste a Novaventa?",
    NUMERODOCUMENTOMAMAEMP: "Número de documento del Novaempresario",
    COMOCONOCISTE: "¿Cómo conociste a ",
    LOSCAMPOSMARCADOS: "Los campos marcados con * son obligatorios.",
    CONTACT: "¿Cómo te contactamos?",
    CONTACT1: "¿Cómo te",
    CONTACT2: "contactamos?",
    ADDRESS: "¿Dónde vives?",
    ADDRESS1: "¿Dónde",
    ADDRESS2: "vives?",
    BASICDATA: "Datos Básicos",
    BASICDATA1: "Datos",
    BASICDATA2: "Básicos",
    BASICDATA3: "Verifica tus datos",
    OPCIONAL: "Opcional",
    PRIMERNOMBRE: "Primer nombre",
    SEGUNDONOMBRE: "Segundo nombre",
    PRIMERAPELLIDO: "Primer apellido",
    SEGUNDOAPELLIDO: "Segundo apellido",
    NUMEROCELULARPPAL: "Número de Celular principal",
    CELULARSECUNDARIO: "Celular secundario",
    CUALESTUNUMEROCELULARWHATSAPP: "¿Cuál es tu número de WhatsApp?",
    TELEFONOFIJO: "Teléfono fijo",
    EMAIL: "¿Cuál es tu correo electrónico?",
    CONFIRMEMAIL: "Confirma tu correo electrónico",
    CONFIRMECELSECUNDARIO: "Confirma tu WhatsApp",
    DEPARTAMENTO: "¿Cuál es tu departamento?",
    CIUDAD: "¿En qué ciudad vives?",
    BARRIO: "¿Cuál es tu barrio?",
    DIRECCION: "Dirección",
    NUMERO: "Número",
    NUMERO3: "Nro",
    ZONA: "Zona",
    TIPODEVIVIENDA: "Tipo de vivienda",
    INT: "Interior",
    INFOADICIONAL: "Información adicional",
    HASH: "#",
    SELECCIONAR: "Seleccionar",
    SELECCIONAPREFIJOPAIS: "Selecciona el prefijo del país",
    DONDERECIBIRAS: "¿Dónde recibirás",
    TUPEDIDO: "tu pedido?",
    INICIOSESION: "Inicio de sesión",
    INICIOSESIONOFICINA: "Iniciar sesión en la oficina virtual",
    INGRESACOMOASESOR: "Ingresa como asesor y podrás registrar tu nuevo Novaempresario",
    INGRESACOMOMAMALIDER: "Ingresa como administrador para gestionar el portal",
    USUARIO: "Usuario",
    CONTRASEÑA: "Contraseña",
    OLVIDEMICONTRA: "Olvidé mi contraseña",
    ACTIVARMICONTRA: "Es necesario que configure una nueva contraseña",
    DIRECCIONINGRESADA: "Dirección Ingresada",
    VALIDAR_DIRECCION: "Click aquí para validar dirección",
    ANTESDARACONOCERTC:
      " Antes de iniciar el registro solicita al prospecto tener a la mano el documento de identidad. y darle a conocer los términos y condiciones.",
    COMPLETEESTOSCAMP: "Completa estos campos",
    PARAQUECONTACTE: "para que te contacte una experta del negocio",
    CUALESTUCEL: "¿Cuál es tu número de celular?",
    CUALESTUFIJO: "¿Cuál es tu número fijo?",
    ENVIAR: "Enviar",
    CANCELAR: "Cancelar",
    YAERES: "¡Ya eres un ",
    MAMAEMPRE: " Novaempresario! ",
    SOLICITUDDE: "Solicitud de",
    CREDITO: "Crédito",
    YPUEDES: " y puedes disfrutar de todos los beneficios que en ",
    TENEMOSPARATI: " tenemos para ti.",
    CUPODECREDITO:
      "Uno de ellos es solicitar un cupo de crédito para que inicies tu negocio y puedas pagar tus pedidos.",
    DESEASOLICITAR: "¿Deseas solicitar tu crédito?",
    HOLA: "Hola,",
    BIENVENIDA: "Hola !${0}, te damos la bienvenida¡",
    ALAFAMNOVA: ", a la familia Novaventa",
    APARTIR: "A partir de este momento",
    PUEDESEMPEZAR: "puedes empezar a hacer tus pedidos con",
    PAGOCONTADO: "Pago de contado",
    TIENESCUPO: "tienes un cupo de ${0}",
    YDISFRUTAR: " y disfrutar de",
    YDISFRUTES: " y disfrutes de",
    PARAQUEEMPIECES: "para que empieces a hacer tus pedidos",
    TODOSLOSBENEFICIOS: "todos los beneficios que tenemos para ti",
    ACTTUCONTRASEÑA: "Activa tu contraseña",
    ACTTUCUENTA: "Activa tu cuenta",
    ENLAOFICINA:
      "en tu Oficina Virtual ingresando los datos de acceso que hemos enviado a tu correo electrónico y celular",
    INGRESANDOALLINK: " ingresando al siguiente link.",
    INICIARNUEVAINS: "Iniciar una nueva inscripción",
    DATOS: "Datos",
    BASICOS: "Básicos",
    ATRAS: "Atrás",
    HEMOSENVIADO:
      "Hemos enviado tu solicitud en máximo de 3 días te contactará una experto del negocio para programar tu visita.",
    ERRORSUGAR: "Error registrando datos, intente de nuevo en unos minutos.",
    HAVE_REGISTER: "We have a register with this parameters",
    EXISTE_SUGAR: "Ya hay una solicitud en proceso.",
    REGISTROERROR: "Error en Registro",
    REGISTRO_NO: "No se pudo completar el registro",
    CERRAR: "Cerrar",
    REGISTROEXITOSO: "Registro exitoso",
    CELULAR: "Teléfono celular",
    NOMBREYAPELLIDO: "Nombres y apellidos",
    CORREO: "Correo electrónico",
    ABANDONARINS: "Abandonar la inscripción",
    ESTASEGURO: "¿Estás seguro que deseas abandonar la inscripción?",
    SIABANDONAR: "Si, abandonar",
    REGRESARINS: "Regresar a la inscripción",
    HASABANDONADO: "Has abandonado tu inscripción",
    LAMENTAMOSABANDONO:
      "Lamentamos que hayas abandonado tu inscripción, recuerda que puedes regresar en cualquier momento.",
    ADJUNTARIMAGENFRONTAL:
      "Adjunta la imagen de la cédula en formato JPG o PNG por el lado de la fotografía",
    ADJUNTARIMAGENPOSTERIOR:
      "Adjunta la imagen de la cédula en formato JPG o PNG por el lado de la huella",
    ADJUNTARFOTOSELFIE:
      "Para poder tomar la foto, por favor asegúrate de permitir el acceso a tu cámara.",
    NOIMAGEN: "No haz adjuntado imagen",
    IMAGENADJUNTADA: "Se adjuntó una imagen correctamente",
    IMAGENBIBLIOTECA: "Adjunta una imagen de tu biblioteca",
    FECHAINICIAL: "Fecha inicial",
    FECHAFINAL: "Fecha final",
    FECHANACIMIENTO: "Fecha de nacimiento",
    LUGARNACIMIENTO: "Lugar de nacimiento",
    FECHAEXPEDICION: "Fecha de expedición",
    LUGAREXPEDICION: "Lugar de expedición",
    SEXO: "Sexo",
    TIPOREGIMEN: "¿A cuál regimen perteneces?",
    ADJUNTAR: "Adjuntar",
    ARCHIVOOK: "Se adjuntó el archivo ${0} correctamente",
    PASOS: "Paso ${0}",
    NECESITASAYUDA: "¿Necesitas ayuda?",
    AYUDA: "Ayuda",
    AYUDAREGIMENTRIBUTARIO: "¿Cómo saber a que régimen tributario perteneces?",
    ELIGE: "Elige el indicativo",
    ELIMINAR: "Eliminar",
    ACTUALIZAR: "Actualizar",
    SEGUROELIMINAR: "¿Está seguro que desea eliminar el registro?",
    FECHABLOQUEO: "Fecha de Bloqueo",
    ESTADOBLOQUEO: "Estado de Bloqueo",
    TYPECONSULT: "Tipo de consulta",
    OPTIONCONSULT: "Opción de consulta",
    NUMERODEID: "Numero Identificación",
    APELLIDO: "Apellido",
    NOMBRE: "Nombre",
    MODELONEGOCIO: "Modelo de negocio",
    TIPOBLOCK: "Tipo de bloqueo",
    MOTIVOBLOQ: "Motivo de Bloqueo",
    ACCION: "Acción",
    AGREGARREG: "Agregar registro",
    DESCARGARARCHIVO: "Descargar Archivo .CSV",
    CONSULTARESTADO: "Consultar estado",
    SUBIRDOCUMENTO: "Subir",
    CONSULTAR: "Consultar",
    CARGANDO: "Cargando...",
    DESCARGARARARCHIVOSTITULO: "Descargar documentos del ",
    BUSCAR: "Buscar",
    CREARNUEVO: "Crear nuevo",
    LLAMADA: "Llamada",
    REGISTRO2: "Registro",
    LISTA: "Lista",
    NEGRACORREOS: "Negra de correos",
    LLAMADAS: "de llamadas",
    TIPOBLOQUEO: "Tipo de bloqueo",
    DATOBLOQUEO: "Dato",
    GESTIONCLIENTES: "gestión de clientes",
    NUMPLACEHOLDER: "Ej: 99A",
    INTERIORPLACEHOLDER: "Ej: 301",
    EXTRAINFOPLACEHOLDER: "Ej: Kilometro 5",
    ZONAPLACEHOLDER: "Ej: Sur",
    DIRPLACEHOLDER: "Ej: Carrera",
    TIPOVIVIENDAPLACEHOLDER: "Ej: Apartamento",
    AVISONUEVOPASS: "Recuerda respetar las reglas indicadas para la contraseña",
    INGRESATUCORREOPCREAR: "Ingresa tu correo electrónico para recuperar tu contraseña",
    INGRESATUCORREO: "Ingresa tu correo electrónico",
    RECUPERARCONTRASEÑA: "Recuperar contraseña",
    HEMOSENVIADOCORREO:
      "Hemos enviado un correo electrónico con las instrucciones para cambiar tu contraseña",
    IRINICIO: "Ir al inicio de sesión",
    MENSAJEENVIADO: "Mensaje enviado",
    PASSACTIVADO: "Contraseña activada correctamente",
    LOSENTIMOSERROR: "Lo sentimos, no podemos continuar se ha presentado un problema",
    INGRESECODIGO: "Ingrese el codigo de verificación",
    INGRESENUEVACONTRA: "Ingrese la nueva contraseña",
    INGRESETUNUEVACONTRA: "Ingresa tu nueva contraseña",
    CONFIRMNUEVACONTRA: "Confirma tu nueva contraseña",
    GUARDARPASSWORD: "Guardar contraseña",
    INGRESATUCODIGO: "Ingresa el codigo de verificación enviado a tu correo",
    NOERESADMIN:
      "Lo sentimos, no tienes permisos para ingresar en esta sección, por favor ingresa por tu canal asignado",
    OBLIGATORIO: "Obligatorio",
    VALIDACIONCONTRASEÑA0: "La contraseña debe contener:",
    VALIDACIONCONTRASEÑA1: "Debe tener al menos 8 caracteres. ",
    VALIDACIONCONTRASEÑA2: "Requiere números.",
    VALIDACIONCONTRASEÑA3: "Requiere un carácter especial.",
    VALIDACIONCONTRASEÑA4: "Requiere letras mayúsculas y letras minúsculas.",
    NOCUMPLECONTRASEÑA: "La contraseña no es segura.",
    CONTRASEÑANSNOCOINCIDEN: "¡Las dos contraseñas que ingresó no coinciden!",
    NUEVACONTRASEÑA: "Nueva contraseña",
    LACONTRASEÑAACTUALIZADA: "La contraseña fue actualizada",
    YAPUEDESINICIARSESION: "Ya puedes usar esta contraseña para iniciar sesión",
    PORFAVORESPERE: "Por favor, Espere",
    PLACEHOLDEREMAIL: "rosita234@gmail.com",
    PLACEHOLDEREMAIL2: "Escribe tu correo electrónico",
    PLACEHOLDERCEL: "Escribe tu número de celular",
    PLACEHOLDERFULLNAME: "Escribe tu nombre y apellido",
    TODOSLOSTERMINOS:
      "*Todos los términos, condiciones te llegarán por correo electrónico. Para abrir el documento ingresa tu número de identificación como contraseña.",
    INGRESADATOSID: "Ingresa los datos del documento de identidad",
    APARTIRPSE:
      "A partir de este momento puedes hacer tus pedidos de contado y pagarlos en línea por PSE con tarjetas débito/crédito en los principales bancos, corresponsales bancarios y Efecty. Comienza a disfrutar de todos los beneficios que tenemos para ti.",
    DIRECCIONRURAL: "Dirección Rural",
    CAMPOINVALIDO: "${0} inválido",
    completeData: "Completa los datos necesarios para iniciar con tu solicitud.",
    errorDateN: "Por favor ingrese una fecha de nacimiento válida",
    errorDateE: "Por favor ingrese una fecha de expedición válida",
    errorReference: "Novaempresario no habilitado para referir nuevos usuarios",
    errorProcess:
      "Lo sentimos, ha ocurrido un error y no podemos continuar con el proceso",
    errorOperation: "Error al continuar la operación: ",
    errorForm: "Por favor verifique que no contenga errores en el formulario",
    errorImages: "Por favor adjunte imágenes del documento válidas",
    locked: "Bloqueado",
    unlocked: "Sin Bloqueo",
    pleaseWait: "Estamos validando tu información, por favor espera un momento.",
    wasExpanded: "Tu cupo >|< fue ampliado",
    youHaveQuota: "Ahora cuentas con ${0} para montar el pedido de esta campaña",
    call: "Llamar",
    call2: "Llamada",
    call3: "Telefónica",
    weCall: "Te llamamos",
    canContactUs:
      "Te puedes comunicar con nosotros a nuestras líneas de atención de tu ciudad o a la línea nacional gratuita.",
    leavePhones:
      "Déjanos tu número telefónico y nos comunicaremos contigo lo antes posible o cuando lo prefieras programando la llamada para ayudarte con el registro.",
    callUs: "Llámanos a nuestras líneas de atención",
    openingHours:
      "Nuestro horario de atención: de lunes a viernes de 7:30 a.m. a 10:00 p.m. y sábados de 8:00 a.m. a 6:00 p.m",
    documentsNotFound: "Documentos no encontrados",
    documentsNotFound2: "Documentos para el usuario ingresado, no encontrados",
    url: "URL:",
    see: "Ver",
    id: "Id: ",
    solicitudeNumber: "No. Solicitud",
    transactionStatus: "Estado transacción: ",
    expecting: "esperando",
    processing: "procesando",
    error: "error",
    success: "success",
    trasnUService: "Servicio Trans Unión",
    execute: "Ejecutar",
    documentService: "Servicio documentos",
    loadDocument: "Cargar nuevo Documento",
    reloadDocument: "Volver a cargar el documento",
    wereSeeing: "Estamos validando tu información, por favor espera un momento.",
    notCredit: "No es posible realizar tu estudio de crédito",
    goVirtualOffice: "Ir a la oficina virtual",
    notContinue:
      "No podemos continuar con el proceso debido a que no cumples con los requisitos para efectuar el cambio de modalidad de pago",
    mandatoryCompos: "Los campos marcados con * son obligatorios.",
    dateExp: "Fecha de expedición (Año-Mes-Dia)",
    consultProcess: "Consultar proceso",
    consultGremial: "Consulta gremial",
    process: "proceso",
    records: "Registros",
    yes: "Sí",
    not: "No",
    infoSend: "Data enviada por URL",
    validatingResponses: "Validando respuestas",
    forInit:
      "para que empieces a hacer tus pedidos  y disfrutes de todos los beneficios que tenemos para ti.",
  },
};
