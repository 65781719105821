import axios from "axios";
const baseURL = process.env.API_DEVELOP ?? "";

export function getCallList() {
  return axios.get(`${baseURL}/lista/agendamiento-llamadas`, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
}

export function UpdateCallRegister(id: any, data: any) {
  return axios
    .put(`${baseURL}/comandos/registrar/datos-llamada/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((result: any) => {
      return result.data;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
}
