import axios from "axios";
const baseURL: any = process.env.API_DEVELOP;
import { getObject } from "~utils/hooks/localStorage";

export function sendUserPaymentMethod(body: any) {
  const uuid = getObject("uuid");
  return axios
    .put(`${baseURL}/comandos/registrar/metodo-pago/${uuid}`, body)
    .then(({ data }) => data)
    .catch((err) => {
      console.error("sendUserPaymentMethod catch:", err);
      return err;
    });
}

export const validateTransUnion = (cedula: string) => {
  return axios
    .post(`${baseURL}/auth/trans`, { cedula })
    .then(({ data }) => data)
    .catch((err) => {
      console.error("Algo salio mal", err);
      return Promise.reject(err);
    });
};
