import type { RulesMessages } from "~hooks/usePrismic";
import { sendContactData, sendPersonalData } from "~services/digitalRegistrationServices";
import { sendCellPhoneValidation } from "~services/selfRegistrationServices";
import { regExp } from "~utils/formRegex";
import { getObject } from "~utils/hooks/localStorage";

export const validateDocumentNumber = (value: string, rules: RulesMessages) => {
  if (!value) return Promise.reject(rules.required);
  if (!/^\d+$/.test(value)) return Promise.reject(rules.onlyNumber);
  if (value.length < 5) return Promise.reject(rules.minLength.replace("${0}", "5"));
  if (value.length > 10) return Promise.reject(rules.maxLength.replace("${0}", "10"));
  return Promise.resolve();
};

export const validateEmail = (value: string, rules: RulesMessages) => {
  if (!value) return Promise.reject(rules.required);
  if (!/^\w/i.test(value)) return Promise.reject(rules.startWithLetter);
  if (!/@/i.test(value)) return Promise.reject(rules.contains.replace("${0}", "@"));
  if (value.length < 5) return Promise.reject(rules.minLength.replace("${0}", "4"));
  if (!regExp.email.test(value)) Promise.reject(rules.invalidEmail);
  if (value.length > 60) return Promise.reject(rules.maxLength.replace("${0}", "60"));
  return Promise.resolve();
};

export const validateCellPhone = async (value: string, rules: RulesMessages) => {
  if (!value) return Promise.reject(rules.required);
  if (!/^\d+$/.test(value)) return Promise.reject(rules.onlyNumber);
  if (value.length < 10) return Promise.reject(rules.minLength.replace("${0}", "10"));
  if (value.length > 10) return Promise.reject(rules.maxLength.replace("${0}", "10"));
  if (!/^3\d{9}/.test(value)) Promise.reject(rules.invalidCellPhone);
  const { data: dataC } = await sendCellPhoneValidation(value).catch(() => {
    throw Error(rules.unexpectedError);
  });
  if (!dataC?.continuar) return Promise.reject(rules.cellPhoneExists);
  return Promise.resolve();
};

export const sendExpertCall = ({ getFieldValue }: any) => ({
  async validator() {
    const path = window.location.href;
    if (!path.includes("llamada-experto")) return Promise.resolve();
    const telefonoPrincipal = getFieldValue("telefonoPrincipal") ?? "";
    if (telefonoPrincipal.length !== 10) return Promise.resolve();
    const correoElectronico = getFieldValue("correoElectronico") ?? "";
    const uuid = getObject("uuid");
    const nombres = `${getFieldValue("primerNombre") ?? ""} ${
      getFieldValue("segundoNombre") ?? ""
    }`
      .replace(/\s+/g, " ")
      .trim();
    const contactFormValues = {
      uuid,
      contacto: { telefonoPrincipal, correoElectronico },
    };

    const PersonalData = {
      uuid,
      usuario: {
        nombres: nombres.trim(),
        primerApellido: getFieldValue("primerApellido") ?? "",
        segundoApellido: getFieldValue("segundoApellido") ?? "",
      },
    };
    sendContactData(contactFormValues);
    sendPersonalData(PersonalData);
    return Promise.resolve();
  },
});
