import React from "react";
import {
  sidebarCss,
  sidebarContentCss,
} from "~components/digitalRegistration/DesktopDigitalRegistrationCss";
import TabTitle from "~components/TabTitle";
import { Col, Row } from "antd";
import { usePrismic } from "~hooks/usePrismic";

const DesktopSidebar = ({ sectionOrder, scrollToSection, activeTab }: any) => {
  const t = usePrismic();
  return (
    <div data-testid="sideBar" id="sideBar" css={sidebarCss}>
      <div css={sidebarContentCss}>
        <Row gutter={24}>
          <Col
            data-testid="scrollToSection1"
            id="addressLinkSection"
            flex={"100%"}
            order={sectionOrder[0]}
          >
            <div onClick={() => scrollToSection(sectionOrder[0])}>
              <TabTitle
                tabNumber={sectionOrder[0]}
                activeTab={activeTab}
                tabTitleTxt={t.constants.ADDRESS}
                flex={"row nowrap"}
              />
            </div>
          </Col>

          <Col
            data-testid="scrollToSection2"
            id="contactLinkSection"
            flex={"100%"}
            order={sectionOrder[1]}
          >
            <div onClick={() => scrollToSection(sectionOrder[1])}>
              <TabTitle
                tabNumber={sectionOrder[1]}
                activeTab={activeTab}
                tabTitleTxt={t.constants.CONTACT}
                flex={"row nowrap"}
              />
            </div>
          </Col>

          <Col
            data-testid="scrollToSection3"
            id="basicDataLinkSection"
            flex={"100%"}
            order={sectionOrder[2]}
          >
            <div onClick={() => scrollToSection(sectionOrder[2])}>
              <TabTitle
                tabNumber={sectionOrder[2]}
                activeTab={activeTab}
                tabTitleTxt={t.constants.BASICDATA}
                flex={"row nowrap"}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DesktopSidebar;
