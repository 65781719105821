import type { CancelToken } from "axios";
import axios from "axios";
const baseURL: any = process.env.API_DEVELOP;

export function getDepartment(cancelToken: CancelToken) {
  return axios
    .get(`${baseURL}/departamentos`, {
      cancelToken,
    })
    .catch((error) => {
      console.error(error);
      return { data: [] };
    });
}

export function getCity(id: string) {
  return axios.get(`${baseURL}/departamento/${id}/ciudades`);
}

export function getDistrict(id: string) {
  return axios.get(`${baseURL}/ciudad/${id}/barrios`);
}
