import { Input, Form } from "antd";
import React, { useState } from "react";
import { usePrismic } from "~hooks/usePrismic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NovaventaInputPasswordCss } from "./NovaventaInputPasswordCss";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import novaventaTheme from "~styles/theme";
import { regExp } from "~utils/formRegex";

export const NovaventaInputPassword = (props: any) => {
  const t = usePrismic();
  const {
    children,
    label,
    name = "password",
    regex = false,
    confirmPassword = false,
  } = props;

  const [icon, setIcon] = useState(faEye);
  const PASSWORD_REGEX = new RegExp(regex ? regExp.cognitoPassword : "");
  return (
    <React.Fragment>
      <Form.Item label={label} wrapperCol={{ span: 24 }}>
        <Form.Item
          label="Password"
          wrapperCol={{ span: 24 }}
          name={name}
          rules={[
            {
              required: true,
              pattern: PASSWORD_REGEX,
              message: `${t.constants.NOCUMPLECONTRASEÑA}`,
            },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                if (confirmPassword) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t.constants.CONTRASEÑANSNOCOINCIDEN);
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          hasFeedback
          noStyle
        >
          <Input
            id={`Id${name}`}
            data-testid={`Id${name}`}
            autoComplete="on"
            type="password"
            suffix={
              <FontAwesomeIcon
                size={"lg"}
                id={`icon${name}`}
                data-testid={`icon${name}`}
                icon={icon}
                color={novaventaTheme.colors.blue400}
                onClick={() => {
                  const x: any = document.getElementById(`Id${name}`);
                  if (x)
                    if (x.type === "password") {
                      x.type = "text";
                      setIcon(faEyeSlash);
                    } else {
                      setIcon(faEye);
                      x.type = "password";
                    }
                }}
              />
            }
            css={NovaventaInputPasswordCss}
          />
        </Form.Item>
        {children}
      </Form.Item>
    </React.Fragment>
  );
};
