import { css } from "@emotion/core";

export const fomCss = css`
  display: table;
  margin: 0 auto;
`;

export const quitCss = css`
  text-align: center;
  font-family: "Proxima Nova" !important;
  font-size: 19px;
  a {
    color: #345eea;
    font-weight: 100;
    text-decoration: underline;
  }
`;
