import { css } from "@emotion/core";

export const emailBlackListCss = css`
  margin: 0% 3% 0% 3%;
  .ant-table-wrapper {
    width: 95vw;
    max-width: 94vw;
  }
  table {
    width: 100%;
  }
  th {
    font-family: Proxima Nova Sbold;
    padding: 10px;
    text-align: center;
  }
  thead {
    background: #dadada;
    color: black;
  }
  th span {
    font-family: Proxima Nova Sbold;
  }
  tbody > tr {
    background: white;
  }
  tbody > tr > td {
    text-align: center;
    padding: 10px;
  }
  a {
    color: #355fea;
    font-family: Proxima Nova Sbold;
  }
  button {
    background-color: #345eea;
  }
  button span {
    font-family: Proxima Nova Sbold;
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: #345eea;
  }
`;
