import { DocumentState, INITIAL_STATE_DOCUMENT, Selfie } from "./interfaceSelfie";

type DocumentAction =
  | { type: "addDocument"; payload: Selfie }
  | { type: "resetDocument" };
export const documentReducer = (
  state: DocumentState,
  action: DocumentAction
): DocumentState => {
  switch (action.type) {
    case "addDocument":
      return {
        ...state,
        setSelfie: {
          ...state.setSelfie,
          cc: action.payload.cc,
          disabledButton: action.payload.disabledButton,
        },
      };
    case "resetDocument":
      return INITIAL_STATE_DOCUMENT; // Regresa al estado inicial

    default:
      return state;
  }
};
