import { datadogRum } from "@datadog/browser-rum";
import css from "@emotion/css";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import ModalMessage from "~components/ModalMessage";
import { DesktopDigInscriptionContentCss } from "~components/digitalRegistration/DesktopDigitalRegistrationCss";
import { usePrismic } from "~hooks/usePrismic";
import { validarCupoDemanda } from "~services/cashToCredit";
import { errorDictionary } from "~utils/hooks/HandleHttpErrors";
import { DesktopDigitalRegistrationCss } from "./CreditOnDemandCss";
import IncreaseCapacity from "./IncreaseCapacity";

const CreditOnDemandDesktop = (props: any) => {
  const { data, ciclo } = props;
  const [validando, setValidando] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalBtnText, setModalBtnText] = useState("");
  const [modalKind, setModalKind] = useState("information");
  const [modalTittle, setModalTittle] = useState("");
  const [modalTextModal, setModalTextModal] = useState("");
  const [nuevoCupo, setNuevoCupo] = useState("0");
  const t = usePrismic();

  const validar = () => {
    validarCupoDemanda({ data, ciclo }).then((response) => {
      datadogRum.setUser({
        id: data,
        funcionario: "user",
        prospecto: atob(data),
        canal: "cupo-demanda",
      });

      setValidando(response.continuar);
      setNuevoCupo(response.body);
      setShowModal(!response.continuar);
      setModalTextModal(errorDictionary(`MSGB${response.codigo}`, t.errors));
      setModalKind(errorDictionary(`KND${response.codigo}`, t.errors));
      setModalBtnText(errorDictionary(`BTN${response.codigo}`, t.errors));
      setModalTittle(errorDictionary(`ART${response.codigo}`, t.errors));
    });
  };

  useEffect(validar, []);

  const redirect = () => {
    window.location.assign(t.home.linkCommerce);
  };
  return (
    <div css={DesktopDigitalRegistrationCss}>
      <div id="content" css={DesktopDigInscriptionContentCss}>
        <div
          css={css`
            font-size: 23px;
            text-align: center;
          `}
        >
          {validando ? (
            <IncreaseCapacity redirect={redirect} cupo={nuevoCupo} />
          ) : (
            <section>
              {t.constants.pleaseWait}
              <br />
              <Spin size="large" />
            </section>
          )}
          <br />
        </div>
        <ModalMessage
          refresh={(flag: boolean) => setShowModal(flag)}
          showModal={showModal}
          alertText={modalTittle}
          text={modalTextModal}
          btnText={modalBtnText}
          kind={modalKind}
          handleClickBtn={() => {
            redirect();
          }}
        />
      </div>
    </div>
  );
};

export default CreditOnDemandDesktop;
