import { css } from "@emotion/core";
import { faCalendarAlt, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, DatePicker, Form, Row, TimePicker } from "antd";
import { useState } from "react";
import CheckboxTC from "~components/formControls/CheckBoxTC";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import NovaventaInput from "~components/formControls/input/NovaventaInput";
import { usePrismic } from "~hooks/usePrismic";
import novaventaColors from "~styles/colors";
import { regExp } from "~utils/formRegex";
import { fontSizeClamp } from "~utils/hooks/useBreakPointTools";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";
import { fomCss } from "./PhoneCallCss";

const FormCall = (props: any) => {
  const t = usePrismic();
  const { onFinish } = props;
  const { device, sizeWidth } = useWindowBreakPoints();
  const dateFormat = "DD/MM/YYYY";
  const timeFormat = "HH:mm";
  const [programarLlamada, setProgramarLlamada] = useState(false);
  const [form] = Form.useForm();

  const resolveCondition = (deviceCondition: any) => {
    if (deviceCondition == "desktop") {
      return 1.5;
    } else if (deviceCondition == "tablet") {
      return 1.3;
    } else {
      return 1.1;
    }
  };
  const changeProgramarLlamada = (e: any) => {
    setProgramarLlamada(e.target.checked);
  };

  const handleFinish = (values: any) => {
    onFinish(values);
    form.resetFields();
  };

  return (
    <Form
      form={form}
      data-testid="idFormPhoneCall"
      autoComplete="off"
      name="idFormPhoneCall"
      layout="vertical"
      initialValues={{}}
      onFinish={handleFinish}
      css={fomCss}
    >
      <Row gutter={24} justify="start" align="top">
        <Col className="gutter-row" span={24}>
          <Form.Item
            hasFeedback
            name="nombreCompleto"
            label={t.home.labels.whatFullname}
            rules={[
              { required: true, message: t.home.rules.required },
              {
                pattern: regExp.alphabetWhiteSpaces,
                message: t.home.rules.invalid,
              },
            ]}
          >
            <NovaventaInput type="text" showiconcheck="false" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} justify="start" align="top">
        <Col className="gutter-row" span={24}>
          <Form.Item
            hasFeedback
            name="numeroContacto"
            label={t.home.labels.contactNumber}
            rules={[
              { required: true, message: t.home.rules.required },
              { pattern: regExp.telefonoCelFijo, message: t.home.rules.number },
            ]}
          >
            <NovaventaInput type="tel" showiconcheck="false" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} justify="start" align="top">
        <Col className="gutter-row" span={24}>
          <Form.Item name="programarLlamada">
            <CheckboxTC
              size={resolveCondition(device)}
              text={t.home.labels.scheduleCall}
              check={programarLlamada}
              onChangeCheck={changeProgramarLlamada}
              device={device}
              sizeWidth={sizeWidth}
              extraCss={css`
                font-size: ${fontSizeClamp(device, sizeWidth, 18, 14, 14, 14)};
                margin-bottom: 0;
              `}
            />
          </Form.Item>
        </Col>
      </Row>
      {programarLlamada && (
        <Row gutter={24} justify="start" align="top">
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="fechaCall"
              label="Fecha"
              rules={[{ required: true, message: t.home.rules.required }]}
            >
              <DatePicker
                style={{
                  width: "100%",
                  height: "48px",
                  background: "white 0% 0 % no - repeat padding- box",
                  border: "0.5px solid #879AB9",
                  borderRadius: "6px",
                  opacity: "1",
                }}
                format={dateFormat}
                size={"large"}
                suffixIcon={
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    color={novaventaColors.blue400}
                    size="lg"
                  />
                }
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="horaCall"
              label="Hora"
              rules={[
                {
                  required: true,
                  message: t.home.rules.required,
                },
              ]}
            >
              <TimePicker
                style={{
                  width: "100%",
                  height: "48px",
                  background: "white 0% 0 % no - repeat padding- box",
                  border: "0.5px solid #879AB9",
                  borderRadius: "6px",
                  opacity: "1",
                }}
                format={timeFormat}
                size={"large"}
                suffixIcon={
                  <FontAwesomeIcon
                    icon={faClock}
                    color={novaventaColors.blue400}
                    size="lg"
                  />
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={24} justify="start" align="top">
        <Col className="gutter-row" span={24}>
          <Form.Item>
            <NovaventaButton btnText={t.constants.ENVIAR} htmlType="submit" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FormCall;
