import { Col, Row } from "antd";
import React from "react";
import SectionTitle from "~components/SectionTitle";
import { usePrismic } from "~hooks/usePrismic";
import TaxRegimenCard from "./TaxRegimenCard";

const TaxRegimenModalContent = () => {
  const t = usePrismic();
  return (
    <React.Fragment>
      <Row justify="center" align="middle" data-testid="text-regimen">
        <Col span={24}>
          <SectionTitle
            boldText={t.constants.AYUDAREGIMENTRIBUTARIO}
            headerCss={{ justifyContent: "center", border: "none" }}
          />
        </Col>
      </Row>

      <Row gutter={[32, 48]} justify="center" align="middle">
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <TaxRegimenCard
            hasAvatar={false}
            textAligment={"center"}
            data={t.options.taxRegimen.left.data}
            header={t.options.taxRegimen.left.title}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <TaxRegimenCard
            hasAvatar={true}
            textAligment={"start"}
            data={t.options.taxRegimen.right.data}
            header={t.options.taxRegimen.right.title}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default TaxRegimenModalContent;
