import jwtDecode from "jwt-decode";

export const mapToObject = (map: any) => {
  const obj: any = Array.from(map).reduce(
    (paramObj: any, [key, value]: any) => Object.assign(paramObj, { [key]: value }),
    {}
  );

  return obj;
};

export const formatFormChangedValue = (value: any) => {
  const newValue = { key: "", val: "" };
  for (const key in value) {
    newValue.key = key;
    newValue.val = value[key];
  }
  return newValue;
};

export const capitalizaFirstLetter = (str: string) => {
  return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const JWTDecode = () => {
  // (key) here is the JWT
  const nameJWT = localStorage.getItem("token");

  try {
    //we decode to get the user
    const decoded: any = jwtDecode(nameJWT as string);
    //we get the value of the key
    return decoded["username"] ? decoded["username"] : "";
  } catch (e) {
    return "";
  }
  //Only use cognito:username
  //send the user to the backend java (incripcionCommand)
};
