import { css } from "@emotion/core";
import { PxToVx } from "~utils/hooks/VH&VWConvert";
import novaventaTheme from "~styles/theme";
import { margin, fontSizeClamp } from "~utils/hooks/useBreakPointTools";
const theme = novaventaTheme;

const resolveCondition = (condition: boolean, trueCondition: any, falseCondition: any) =>
  condition ? trueCondition : falseCondition;

export const btnTermsCss = (
  device: string,
  sizeWidth: number,
  sizeHeight: number,
  acept: boolean
) => css`
  ${device === "mobile"
    ? `max-width: clamp(200px,${PxToVx(528, sizeWidth, "vw")},100%);
      min-width: clamp(200px,${PxToVx(528, sizeWidth, "vw")},100%):`
    : `max-width: 30%`};
  margin: ${margin({
    device: device,
    sizeHeight: sizeHeight,
    sizeWidth: sizeWidth,
    deskTopM: acept ? 15 : 10,
    deskLeftM: 0,
    deskBottomM: 43,
    deskRightM: 48,
    tablTopM: acept ? 31 : 10,
    tablLeftM: 0,
    tablBottomM: 52,
    tablRightM: 48,
    mobTopM: acept ? 21 : 10,
    mobLeftM: 0,
    mobBottomM: 42,
    mobRightM: 0,
  })};
  height: clamp(
    35px,
    ${PxToVx(78, sizeHeight, "vh")},
    ${device === "tablet" ? 64 : resolveCondition(device === "mobile", 78, 63)}px
  );
  ${device === "mobile" ? "" : "float:left"};
  span {
    font-size: ${fontSizeClamp(device, sizeWidth, 20, 16, 16, 14)};
  }
  svg,
  svg.svg-inline--fa.fa-arrow-right.fa-w-14 {
    margin-left: 2%;
    font-size: ${fontSizeClamp(device, sizeWidth, 20, 16, 16, 14)};
  }
`;
export const modalTermsCss = (
  device: string,
  sizeWidth: number,
  sizeHeight: number
) => css`
  width: ${device === "tablet"
    ? PxToVx(721, sizeWidth, "vw")
    : resolveCondition(device === "mobile", "100%", PxToVx(1340, sizeWidth, "vw"))};
  overflow: hidden;
  border-radius: 14px;
  font-family: ${theme.fonts.ProximaNova};
  .am-modal-close {
    margin: ${margin({
      device: device,
      sizeHeight: sizeHeight,
      sizeWidth: sizeWidth,
      deskTopM: 20,
      deskLeftM: 25,
      deskBottomM: 15,
      deskRightM: 0,
      tablTopM: 16,
      tablLeftM: 20,
      tablBottomM: 10,
      tablRightM: 0,
      mobTopM: 8,
      mobLeftM: 6,
      mobBottomM: 6,
      mobRightM: 0,
    })};
  }
  .am-modal-close-x {
    transform: scale(
      ${device === "desktop" ? 2 : resolveCondition(device === "tablet", 1.6, 1.2)}
    );
    filter: invert(30%) sepia(56%) saturate(5049%) hue-rotate(222deg) brightness(97%)
      contrast(90%);
  }
  .termsHeader {
    font-family: ${theme.fonts.ProximaNovaLight};
    color: ${theme.colors.blue400};
    width: ${PxToVx(
      device === "desktop" ? 729 : resolveCondition(device === "tablet", 591, 492),
      sizeWidth,
      "vw"
    )};
    height: ${PxToVx(
      device === "desktop" ? 46 : resolveCondition(device === "tablet", 28, 49),
      sizeHeight,
      "vh"
    )};
    text-align: left;
    margin: ${margin({
      device: device,
      sizeHeight: sizeHeight,
      sizeWidth: sizeWidth,
      deskTopM: 52,
      deskLeftM: 0,
      deskBottomM: 0,
      deskRightM: 61,
      tablTopM: 29,
      tablLeftM: 35,
      tablBottomM: 0,
      tablRightM: 48,
      mobTopM: 27,
      mobLeftM: 57,
      mobBottomM: 0,
      mobRightM: 20,
    })};
    font-size: ${fontSizeClamp(device, sizeWidth, 36, 24, 20, 18)};
  }
  .termsParagraph {
    color: ${theme.colors.blueGray800};
    text-align: left;
    margin: ${margin({
      device: device,
      sizeHeight: sizeHeight,
      sizeWidth: sizeWidth,
      deskTopM: 0,
      deskLeftM: 60,
      deskBottomM: 50,
      deskRightM: 60,
      tablTopM: 23,
      tablLeftM: 48,
      tablBottomM: 34,
      tablRightM: 48,
      mobTopM: 21,
      mobLeftM: 58,
      mobBottomM: 33,
      mobRightM: 24,
    })};
    height: ${PxToVx(
      device === "desktop" ? 420 : resolveCondition(device === "tablet", 322, 260),
      sizeHeight,
      "vh"
    )};
    font-size: ${fontSizeClamp(device, sizeWidth, 22, 16, 16, 14)};
  }
`;
