import { css } from "@emotion/core";
import { Progress } from "antd";
import { useTheme } from "emotion-theming";
import React, { forwardRef } from "react";
import HeaderBanner from "~components/header/HeaderBanner";
import HeaderLogo from "~components/header/HeaderLogo";

const HeaderNovaventa = forwardRef(
  ({ device, isLogged, percent, showBackBtn, loginBanner }: any, ref: any) => {
    const theme: any = useTheme();
    return (
      <div
        ref={ref}
        css={css`
          position: relative;
        `}
      >
        {!isLogged && (
          <React.Fragment>
            <HeaderBanner loginBanner={loginBanner} device={device} />
            <HeaderLogo device={device} showBackBtn={showBackBtn} />
          </React.Fragment>
        )}
        {isLogged && (
          <React.Fragment>
            <div
              css={css`
                width: 100%;
                height: 80px;
                background-color: white;
                display: flex;
                align-items: center;
              `}
            >
              <HeaderLogo device={device} showBackBtn={showBackBtn} />
            </div>

            <Progress
              showInfo={false}
              size="small"
              strokeColor={{
                "0%": `${theme.colors.green300}`,
                "100%": `${theme.colors.green300}`,
              }}
              percent={percent}
              css={css`
                margin: 0 !important;
                padding: 0 !important;
                line-height: 0 !important;
                display: block;
              `}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
);

export default HeaderNovaventa;
