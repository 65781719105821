import { useReducer } from "react";
import { DocumentContext } from "./document.context";
import { documentReducer } from "./document.reducer";
import { INITIAL_STATE_DOCUMENT, Selfie } from "./interfaceSelfie";

interface props {
  children: JSX.Element | JSX.Element[];
}

export const DocumentProvider = ({ children }: props) => {
  const [documentState, dispatch] = useReducer(documentReducer, INITIAL_STATE_DOCUMENT);

  const addDocument = (selfie: Selfie) => {
    dispatch({ type: "addDocument", payload: selfie });
  };

  const resetDocument = () => {
    dispatch({ type: "resetDocument" });
  };

  return (
    <DocumentContext.Provider value={{ documentState, addDocument, resetDocument }}>
      {children}
    </DocumentContext.Provider>
  );
};
