import { Form } from "antd";
import axios, { AxiosResponse } from "axios";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import moment from "moment";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import Layout from "~components/uploadImage/DropzoneImage/Layout";
import UploadBtn from "~components/uploadImage/DropzoneImage/UploadBtn";
import UploadProgressTemplate from "~components/uploadImage/DropzoneImage/UploadProgressTemplate";
import { usePrismic } from "~hooks/usePrismic";
import { sendIDImage } from "~services/digitalRegistrationServices";
import { useFormContext } from "~store/FormContext";
import { getObject, setObject } from "~utils/hooks/localStorage";

const LayoutComponent = (props: any, type: string) => {
  return <Layout {...props} type={type} />;
};

const PreviewComponent = (props: any, type: string, uploadStatus: string) => {
  return <UploadProgressTemplate {...props} type={type} uploadStatus={uploadStatus} />;
};

const DropzoneImage = ({ type, document, showUserImageData }: any) => {
  const [fileValidateStatus, setFileValidateStatus] = useState(null);
  const [savingImage, setSavingImage] = useState("error");
  const [imageName, setImageName] = useState("idImageFront");
  const { basicDataFormObj, updateFormValues } = useFormContext();
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [errorState, setErrorState] = useState<{
    errorStatus: "error" | "success" | "validating";
    errorMsg: string | null;
  }>({
    errorStatus: "error",
    errorMsg: null,
  });
  const t = usePrismic();
  const dniType = getObject("idType");
  const uuidSolicitude = getObject("uuid");
  const dniNumber = getObject("idNumber");

  useEffect(() => {
    if (savingImage === "enviando") {
      setErrorState({
        errorStatus: "validating",
        errorMsg: t.errors.pending,
      });
    } else if (savingImage === "enviado" || savingImage === "error") {
      setErrorState({
        errorStatus: savingImage === "enviado" ? "success" : "error",
        errorMsg: savingImage === "enviado" ? "" : null,
      });
    }
  }, [savingImage]);

  useEffect(() => {
    validateImageUpload(fileValidateStatus);
  }, [fileValidateStatus]);

  useEffect(() => {
    if (type && type === "back") {
      setImageName(document ? "idImageBackDoc" : "idImageBack");
    }
  }, [type, document]);

  const getFilesFromEvent: any = (e: any) => {
    return new Promise((resolve: any) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles: any) => {
        resolve(
          chosenFiles.map((f: any) => {
            return f.fileObject;
          })
        );
      });
    });
  };

  function validateImageUpload(status: any) {
    if (status === "done" && !savingImage) {
      setErrorState({
        errorStatus: "success",
        errorMsg: null,
      });
    } else if (status === "rejected_file_type") {
      setErrorState({
        errorStatus: "error",
        errorMsg: t.errors.invalidFile,
      });
    } else if (status === "exception_upload") {
      setErrorState({
        errorStatus: "error",
        errorMsg: t.errors.loadFile,
      });
    } else if (status === "error_file_size") {
      setErrorState({
        errorStatus: "error",
        errorMsg: t.errors.weightLimit,
      });
    } else if (status === "removed" && uploadError) {
      setErrorState({
        errorStatus: "error",
        errorMsg: uploadErrorMessage || t.errors.loadImage,
      });
    }
  }

  const saveFormValuesData = (name: string, data: any) => {
    basicDataFormObj.setFieldsValue({
      [name]: data || "",
    });
    updateFormValues({ key: name, val: data });
  };

  const resolveName = (value: string) => {
    if (!value) return "";
    value = value.toUpperCase().trim().replace(/\s+/g, " ");
    value = t.home.replaceName.reduce((v, [w, r]) => v.replace(w, r), value);
    value = value.replace(new RegExp(t.home.doNotDeleteCharacters, "g"), "");
    return value;
  };

  const handleChangeStatus = ({ file, remove }: any, status: any) => {
    setFileValidateStatus(status);

    if (status === "removed") {
      setUploadError(false);
      showUserImageData({ key: imageName, val: false });
      setSavingImage("error");
      setUploadErrorMessage("");
      basicDataFormObj.setFieldsValue({ [imageName]: "" });
      return;
    }

    if (status !== "done") return;

    const rejectUploadImage = (message: string) => {
      setUploadErrorMessage(message);
      showUserImageData({ key: imageName, val: false });
      basicDataFormObj.setFieldsValue({ [imageName]: "" });
      setSavingImage("error");
      setUploadError(true);
      remove();
    };

    const aprovedUploadImage = () => {
      basicDataFormObj.setFieldsValue({ [imageName]: file });
      showUserImageData({ key: imageName, val: true });
      setSavingImage("enviado");
      setUploadErrorMessage("");
      setUploadError(false);
    };

    setSavingImage("enviando");
    sendIDImage(
      file,
      dniType,
      type,
      document,
      uuidSolicitude,
      axios.CancelToken.source()?.token ?? {}
    )
      .then(({ data }: AxiosResponse) => {
        if (type === "front") {
          return aprovedUploadImage();
        }

        if (data?.Result !== 1) {
          return rejectUploadImage(t.errors.lowQuality);
        }
        if (data.Barcode?.Tipo && dniType !== data.Barcode.Tipo) {
          return rejectUploadImage(t.home.rules.dniTypeNotMatch);
        }
        if (dniNumber !== data.Barcode.Numero) {
          return rejectUploadImage(t.home.rules.dniNumberNotMatch);
        }

        if (t.home.compoundSurnames.includes(data.Barcode.Apellido1)) {
          const [sn1, ...sn2] = data.Barcode.Apellido2.split(" ");
          data.Barcode.Apellido1 = `${data.Barcode.Apellido1} ${sn1}`;
          data.Barcode.Apellido2 = sn2.join(" ");
        }

        saveFormValuesData("idTipoDocumento", dniType);
        saveFormValuesData("numeroDocumento", data.Barcode.Numero.replace(/\D/g, ""));
        saveFormValuesData("primerApellido", resolveName(data.Barcode.Apellido1));
        saveFormValuesData("segundoApellido", resolveName(data.Barcode.Apellido2));
        saveFormValuesData("primerNombre", resolveName(data.Barcode.Nombre1));
        saveFormValuesData("segundoNombre", resolveName(data.Barcode.Nombre2));
        saveFormValuesData("sexo", resolveName(data.Barcode.Sexo || ""));
        setObject("name", resolveName(data.Barcode.Nombre1));
        basicDataFormObj.setFieldsValue({
          fechaNacimiento: moment(data.Barcode.Fecha_Nacimiento, "DD/MM/YYYY"),
        });
        updateFormValues({
          key: "fechaNacimiento",
          val: moment(data.Barcode.Fecha_Nacimiento, "DD/MM/YYYY").format("DD/MM/YYYY"),
        });

        return aprovedUploadImage();
      })
      .catch((error) => {
        console.error("then sendIDImage", error);
        return rejectUploadImage(t.errors.generic);
      });
  };

  return (
    <Form.Item
      data-testid="form-dropzone"
      trigger="onLoad"
      name={imageName}
      rules={[{ required: true, message: t.home.rules.required }]}
      required
      validateStatus={errorState.errorStatus}
      help={errorState.errorMsg}
    >
      <Dropzone
        accept=".jpg,.jpeg,.png,.bmp"
        InputComponent={UploadBtn}
        LayoutComponent={(props) => LayoutComponent(props, type)}
        PreviewComponent={(props) =>
          PreviewComponent(props, type, errorState.errorStatus)
        }
        canCancel={true}
        canRemove={true}
        maxFiles={1}
        maxSizeBytes={10485760}
        onChangeStatus={handleChangeStatus}
        getFilesFromEvent={getFilesFromEvent}
      />
    </Form.Item>
  );
};

export default DropzoneImage;
