import { css } from "@emotion/core";
import novaventaTheme from "~styles/theme";
import { fontSizeClamp } from "~utils/hooks/useBreakPointTools";

export const RoutesCss = (device: string, sizeWidth: number) => css`
  label {
    color: ${novaventaTheme.colors.blueGray800};
    font-family: ${novaventaTheme.fonts.ProximaNovaSbold};
    font-size: ${fontSizeClamp(device, sizeWidth, 20, 16, 14, 14)};
  }
  .ant-select {
    font-size: ${fontSizeClamp(device, sizeWidth, 18, 16, 16, 16)};
  }
  .ant-input {
    font-size: ${fontSizeClamp(device, sizeWidth, 18, 16, 16, 16)};
  }
  .ant-form-item-label {
    margin-bottom: 5px;
  }
`;
