import axios from "axios";
import { downloadLink } from "~utils/downloadLink";

const baseURL: any = process.env.API_DEVELOP;

export function getIntenalList() {
  return axios.get(`${baseURL}/listas-internas`, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
}

export function getByCedula(cedula: string) {
  return axios.get(`${baseURL}/listas-internas/buscar/${cedula}`, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
}

export function sendInternalListRegister(data: any) {
  return axios
    .post(`${baseURL}/comandos/registrar/listas-internas`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((result: any) => {
      return result.data;
    })
    .catch((e) => console.error("sendInternalListRegister catch:", e));
}
export function deleteInternalListRegister(uuid: string) {
  return axios
    .delete(`${baseURL}/comandos/eliminar/listas-internas/${uuid}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then((result: any) => {
      return result.data;
    })
    .catch((e) => console.error("sendInternalListRegister catch:", e));
}

export const downloadFile = async () => {
  const { data } = await axios.get(`${baseURL}/listas-internas/descargar`, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    responseType: "blob",
  });
  const newBlob = new Blob([data]);
  downloadLink(newBlob, "ListasInternas.xlsx");
};

export const downloadDocuments = async (dniNumber: string) => {
  const { data } = await axios.get(`${baseURL}/documentos/descargar/${dniNumber}`, {
    headers: {
      Authorization: `Basic b3BlcmFjaW9uOjBwM3I0YzEwbg==`,
    },
    responseType: "blob",
  });
  const newBlob = new Blob([data]);
  if (newBlob.size < 30) throw Error("Blob.size < 30");
  downloadLink(newBlob, `${dniNumber}.zip`);
};
