import { css } from "@emotion/core";
import hexToRgbA from "~utils/hooks/hexToRgbA";
import novaventaTheme from "~styles/theme";
import { fontSizeClamp, width } from "~utils/hooks/useBreakPointTools";

const theme = novaventaTheme;

const resolveCondition = (condition: boolean, trueCondition: any, falseCondition: any) =>
  condition ? trueCondition : falseCondition;

let InformationBoxCssParam: {
  kind: string;
  colorText: string;
  backgColor: string;
  fill: string;
  sizeWidth: number;
  sizeHeight: number;
  device: string;
  position: string;
  icon: string;
  extraCss: any;
};

export const InformationBoxCss = ({
  kind,
  colorText,
  backgColor,
  fill,
  sizeWidth,
  sizeHeight,
  device,
  position,
  icon,
  extraCss,
}: typeof InformationBoxCssParam) => {
  return css`
    background-color: ${hexToRgbA(backgColor, kind)};
    border-radius: 12px;
    margin: auto;
    justify-content: ${position};
    width: 90%;
    max-width: ${width(device, sizeWidth, 1175, 904, 643, 343)};
    padding: 1%;
    ${
      icon === "info"
        ? `padding-left:${width(device, sizeWidth, 256, 124, 38, 11)};
        padding-right:${width(device, sizeWidth, 256, 124, 38, 11)};`
        : null
    }
    display: flex;
    .container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: start;
      padding: ${
        device === "desktop" || device === "desktopHD" || device === "tablet"
          ? "8px"
          : "8px 16px"
      };
      ${device === "desktopHD" ? "margin: 0 12rem;" : ""}
      ${device === "desktop" ? "margin: 0" : ""}
      ${device === "tablet" ? "margin: 0 6rem;" : ""}
      ${device === "mobile" ? "margin: 0" : ""}
      width: 100%;
    }
    .svgContainer {
      align-items: ${kind === "info" ? "flex-start" : "center"};
      margin: 1%;
      float: left;
      clear: both;
      padding-left: ${
        device === "desktop" || device === "desktopHD" || device === "tablet"
          ? "0px"
          : "8px"
      };
    }
    .svgContainer svg {
      fill: ${fill};
      width: ${width(device, sizeWidth, 16, 16, 12, 10)};
      height: ${width(device, sizeWidth, 16, 16, 12, 10)};
      transform: scale(
        ${device === "desktop" ? 1.5 : resolveCondition(device === "tablet", 1.3, 1)}
      );
    }
    #svgContact svg {
      width: ${width(device, sizeWidth, 70, 60, 48, 48)};
      height: ${width(device, sizeHeight, 47, 37, 33, 33)};
      transform: scale(1);
    }
    .svgCircle {
      width: ${width(device, sizeWidth, 36, 36, 30, 27)};
      height: ${width(device, sizeWidth, 36, 36, 30, 27)};
      border: 3px solid ${fill};
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .anticon {
      padding-top: 0px;
      margin-right: 0px;
      display: inline-block;
      clear: both;
    }
    .textontainer {
      display: inline;
      text-align: ${position};
      color: ${colorText};
      font-size: ${fontSizeClamp(device, sizeWidth, 15, 15, 15, 15)};
      align-self: center;
      margin-left: 16px;
      line-height: 20px;
    }
    b {
      color: ${theme.colors.blue400};
      font-family: ${theme.fonts.ProximaNovaSbold};
    }
    ${extraCss}
  `;
};
