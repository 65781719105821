import { css } from "@emotion/core";

/*Ant Design does not allow to use the theme variables inside the Radio element*/
export const radioGroupStyle = css`
  .ant-radio {
    vertical-align: middle;
  }
  .ant-radio-wrapper {
    display: block;
    font-family: "Proxima Nova";
    margin: 10px 0;
  }
  .ant-radio-inner {
    height: 25px;
    width: 25px;
  }
  .ant-radio-inner:after {
    background: #345eea !important;
    height: 17px;
    width: 17px;
    border-radius: 50%;
  }

  .ant-radio-checked .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-wrapper:hover,
  .ant-radio-input:focus .ant-radio-inner {
    border-color: #707070;
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner {
    border-color: #707070;
  }
`;
