import { useState, useEffect } from "react";

export default function useImageSize(
  elementId: string,
  height: number | undefined,
  width: number | undefined,
  refresh?: boolean
) {
  const [imgSizeHeight, setImageSizeHeight] = useState<number>();
  const [imgSizeWidth, setImageSizeWidth] = useState<number>();
  useEffect(() => {
    const target = document.getElementById(elementId);
    handleSizeImgHeight(target);
    handleSizeImgWidth(target);
  }, [height, width, elementId, refresh]);

  function handleSizeImgHeight(target: any) {
    setImageSizeHeight(target?.clientHeight);
  }
  function handleSizeImgWidth(target: any) {
    setImageSizeWidth(target?.clientWidth);
  }

  return { imgSizeHeight, imgSizeWidth };
}
