import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";

const IconCheck = (props: any) => {
  const { customStyle = {} } = props;
  const theme: any = useTheme();
  return (
    <FontAwesomeIcon
      icon={faCheck}
      css={css`
        padding: 6px;
        background-color: ${theme.colors.green50};
        color: ${theme.colors.green300};
        border-radius: 50%;
        font-size: 25px;
        ${customStyle}
      `}
    />
  );
};

export default IconCheck;
