import { SettingTwoTone } from "@ant-design/icons";
import { Spin } from "antd";
import "antd-mobile/dist/antd-mobile.css";
import "antd/dist/antd.css";
import axios from "axios";
import { ThemeProvider } from "emotion-theming";
import { useEffect, useState } from "react";
import { LoadingCss, OutOfService, SpinnerCss } from "~AppCss";
import { usePrismic } from "~hooks/usePrismic";
import { Routes } from "~routing/Routes";
import { fetchPrismicDataIsServerOut } from "~services/prismicService";
import "~utils/datadog";
import "./appTheme.less";
import novaventaTheme from "./styles/theme";

export const App = () => {
  const t = usePrismic();
  const [lineOff, setLineOff] = useState(false);

  useEffect(() => {
    showLoading(false);
    addRequestInterceptor();
    addResponseInterceptor();
    fetchPrismicDataIsServerOut().then(setLineOff).catch(console.error);
  });

  const showLoading = (show: boolean) => {
    const display = show ? "block" : "none";
    const ref = document.querySelector("#loader") as HTMLElement;
    ref.style.display = display;
  };

  const addRequestInterceptor = () => {
    axios.interceptors.request.use(
      (config) => {
        showLoading(true);
        return config;
      },
      (error) => {
        showLoading(false);
        return Promise.reject(error);
      }
    );
  };

  const addResponseInterceptor = () => {
    axios.interceptors.response.use(
      (response) => {
        showLoading(false);
        return response;
      },
      (error) => {
        showLoading(false);
        return Promise.reject(error);
      }
    );
  };

  return (
    <div>
      <div id="loader" data-testid="loader">
        <LoadingCss>
          <SpinnerCss>
            <Spin size="large" />
            <h2>{t.home.loading}</h2>
          </SpinnerCss>
        </LoadingCss>
      </div>
      <div>
        <ThemeProvider theme={novaventaTheme}>
          {lineOff ? (
            <OutOfService>
              <SettingTwoTone style={{ fontSize: "60px" }} spin={true} />
              <h1>{t.home.notFun.h1}</h1>
              <h3>{t.home.notFun.h3}</h3>
            </OutOfService>
          ) : (
            <div>
              <Routes></Routes>
            </div>
          )}
        </ThemeProvider>
      </div>
    </div>
  );
};
