import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import novaventaTheme from "~styles/theme";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";
import { fontSizeClamp } from "~utils/hooks/useBreakPointTools";

const TabTitle = (props: any) => {
  const { tabNumber, tabTitleTxt, theme, activeTab, flex = "column" } = props;
  const { device, sizeWidth } = useWindowBreakPoints();

  const TabTxt = styled.span`
    font-family: ${theme ? theme.fonts.ProximaNovaSbold : ""};
    font-size: ${fontSizeClamp(device, sizeWidth, 20, 14, 14, 14)};
    white-space: pre-line;
    text-align: center;
    color: ${theme ? theme.colors.blue400 : ""};
    margin-top: ${device === "desktop" || device === "desktopHD" ? "0px" : "8px"};
  `;
  const TabLabel = styled.span`
    font-family: ${theme ? theme.fonts.ProximaNovaSbold : ""};
    font-size: ${fontSizeClamp(device, sizeWidth, 30, 24, 24, 24)};
    color: ${theme ? theme.colors.blue400 : ""};
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const TabIcon = styled.div`
    width: clamp(41px, 3vw, 59px);
    height: clamp(41px, 3vw, 59px);
    background-color: white;
    border: 1px solid ${theme ? theme.colors.blue400 : ""};
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <div
      data-testid="tabTitle"
      css={css`
        width: 100%;
        display: flex;
        flex-flow: ${flex};
        align-items: center;
      `}
    >
      <TabIcon
        style={{
          backgroundColor:
            `${tabNumber}` === `${activeTab}`
              ? novaventaTheme.colors.white50
              : novaventaTheme.colors.indigo50,
          border:
            `${tabNumber}` === `${activeTab}`
              ? `2px solid ${novaventaTheme.colors.blue400}`
              : "transparent",
        }}
      >
        <TabLabel
          style={{
            color:
              `${tabNumber}` === `${activeTab}`
                ? novaventaTheme.colors.blueGray900
                : novaventaTheme.colors.blue400,
          }}
        >
          {tabNumber}
        </TabLabel>
      </TabIcon>
      <TabTxt
        style={{
          color:
            tabNumber === activeTab
              ? novaventaTheme.colors.blue400
              : novaventaTheme.colors.blueGray800,
          fontFamily:
            tabNumber === activeTab
              ? novaventaTheme.fonts.ProximaNovaSbold
              : novaventaTheme.fonts.ProximaNovaLight,
        }}
      >
        {tabTitleTxt}
      </TabTxt>
    </div>
  );
};

export default TabTitle;
