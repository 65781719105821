import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import H1Novaventa from "~components/header/H1Novaventa";
import css from "@emotion/css";
import { usePrismic } from "~hooks/usePrismic";
import { Row, Col, Form } from "antd";
import { NovaventaInput } from "~components/formControls/input/NovaventaInput";
import UploadImageRow from "~components/uploadImage/UploadImageRow";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { FormProvider } from "~store/FormContext";
import { regExp } from "~utils/formRegex";
import { Content } from "antd/lib/layout/layout";

const UploadDocuments = (_props: RouteComponentProps) => {
  const t = usePrismic();
  const [form] = Form.useForm();
  const [document, setDocument] = useState();

  const onChange = (e: any) => {
    setDocument(e.target.value);
  };

  const handleClick = () => {
    setDocument(undefined);
    form.resetFields();
  };

  return (
    <FormProvider>
      <H1Novaventa
        text={t.constants.SUBIRDOCUMENTO}
        boldText={"documento"}
        boldFirst={false}
        extraCss={css`
          margin-bottom: 2vh;
          margin-left: 3vw;
        `}
        showBack={false}
      />
      <Content style={{ padding: "0 50px" }}>
        <Row gutter={24} justify="center" align="middle">
          <Col span={16} xs={20} md={16}>
            <Form name="historyDoc" layout="vertical" form={form}>
              <Form.Item
                name="numeroDocumento"
                label={t.constants.NUMERODOCUMENTO2}
                hasFeedback
                rules={[
                  { required: true, message: t.home.rules.required },
                  {
                    pattern: regExp.justNumber,
                    message: t.home.rules.invalid,
                  },
                ]}
              >
                <NovaventaInput
                  onChange={onChange}
                  showiconcheck="false"
                  maxLength={50}
                />
              </Form.Item>
              {document && (
                <React.Fragment>
                  <UploadImageRow type="front" document={document} />
                  <UploadImageRow type="back" document={document} />
                </React.Fragment>
              )}
              <NovaventaButton
                btnText={t.constants.loadDocument}
                handleClick={handleClick}
                htmlType="button"
                extracss={css`
                  margin: 21px auto 34px auto;
                `}
              />
            </Form>
          </Col>
        </Row>
      </Content>
    </FormProvider>
  );
};
export default UploadDocuments;
