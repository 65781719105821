import React from "react";
import { Card, List } from "antd";
import { css } from "@emotion/core";
import novaventaTheme from "~styles/theme";
import IconCheck from "~components/icons/IconCheck";
import cssVars from "~styles/variables";
import cssbreakPoints from "~styles/breakPoints";
import { PxToVx } from "~utils/hooks/VH&VWConvert";

const TaxRegimenCard = ({ data, header, hasAvatar, textAligment }: any) => {
  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        minHeight: "350px",
        borderRadius: "12px",
        border: `solid 1px ${novaventaTheme.colors.blue400}`,
      }}
    >
      <List
        css={css`
          font-family: ${novaventaTheme.fonts.ProximaNova};
          .ant-list-header {
            font-family: ${novaventaTheme.fonts.ProximaNovaSbold};
            border: none;
            text-align: center;
            font-size: clamp(
              ${cssVars.h3FontSizeMin},
              ${PxToVx(26, cssbreakPoints.maxDesktopWith, "vw")},
              ${cssVars.h3FontSizeMax}
            );
          }
          .ant-list-item {
            border: none;
            min-width: 100%;
            text-align: ${textAligment};
          }
        `}
        dataSource={data}
        bordered={false}
        header={header}
        renderItem={(item: any) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                hasAvatar && (
                  <IconCheck
                    customStyle={{
                      backgroundColor: novaventaTheme.colors.blue50,
                      color: novaventaTheme.colors.blue400,
                    }}
                  />
                )
              }
              title={item.title}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default TaxRegimenCard;
