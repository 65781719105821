import { RouteComponentProps } from "@reach/router";
import { Col, Row } from "antd";
import React from "react";
import ConfirmEmail from "~components/forms/emailPhoneForm/ConfirmEmail";
import Email from "~components/forms/emailPhoneForm/Email";
import HomePhone from "~components/forms/emailPhoneForm/HomePhone";
import PrincipalCellPhone from "~components/forms/emailPhoneForm/PrincipalCellPhone";
import { WhatsAppNumber } from "~components/forms/emailPhoneForm/WhatsAppNumber";
import { cellLG, cellMD, rowGutter, xs, xxl } from "~components/forms/formItemsLayout";
import PrefixCellPhone2 from "./PrefixCellPhone2";

const resolveCondition = (condition: boolean, trueCondition: any, falseCondition: any) =>
  condition ? trueCondition : falseCondition;

const EmailPhoneForm = (props: RouteComponentProps | any) => {
  const { registrationType } = props;
  return (
    <React.Fragment>
      {registrationType === "ContactExpert" ? (
        <React.Fragment>
          <Row gutter={rowGutter} justify="start" align="top">
            <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
              <Email />
            </Col>
          </Row>
          <Row gutter={rowGutter} justify="start" align="top">
            <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
              <PrincipalCellPhone registrationType={registrationType} />
            </Col>
          </Row>
          <Row gutter={rowGutter} justify="start" align="top">
            <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
              <HomePhone registrationType={registrationType} />
            </Col>
          </Row>
        </React.Fragment>
      ) : (
        <Row gutter={rowGutter} justify="start" align="top">
          <Col
            flex={"100%"}
            order={resolveCondition(registrationType === "phoneReg", 1, 2)}
          >
            <Row gutter={rowGutter} justify="start" align="top">
              <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                <PrincipalCellPhone registrationType={registrationType} />
              </Col>
              <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                <HomePhone registrationType={registrationType} />
              </Col>
              <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                <PrefixCellPhone2 />
              </Col>
              <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                <WhatsAppNumber />
              </Col>
            </Row>
          </Col>
          <Col
            flex={"100%"}
            order={resolveCondition(registrationType === "phoneReg", 2, 1)}
          >
            <Row gutter={rowGutter} justify="start" align="top">
              <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                <Email />
              </Col>
              <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                <ConfirmEmail />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};
export default EmailPhoneForm;
