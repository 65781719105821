import { css } from "@emotion/core";
import novaventaTheme from "~styles/theme";
import { PxToVx } from "~utils/hooks/VH&VWConvert";

export const NovaventaInputPasswordCss = () => {
  return css`
    height: 48px;
    background: white 0% 0% no-repeat padding-box;
    border: 0.5px solid ${novaventaTheme.colors.blueGray200Light};
    border-radius: 6px;
    opacity: 1;
    .ant-input-group .ant-input {
      height: 48px;
      border: none;
      background-color: transparent;
    }
    .ant-input-group .ant-input-group-addon {
      background-color: transparent;
      border: none;
    }
    .ant-input:focus {
      box-shadow: none;
    }
    height: clamp(
      48px,
      ${PxToVx(48, novaventaTheme.breakPoints.maxDesktopHeight, "vh")},
      20px
    );
    .ant-input-password-icon svg {
      width: 30px;
      height: 26px;
      fill: ${novaventaTheme.colors.blue400};
    }
  `;
};
