import { css } from "@emotion/core";
import { RouteComponentProps, navigate } from "@reach/router";
import { Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import bannerDesktop from "~assets/img/banner-8-desktop.png";
import bannerMobile from "~assets/img/banner-8-mobile.png";
import InformationBox from "~components/InformationBox/InformationBox";
import ModalMessage from "~components/ModalMessage";
import ParagraphNova from "~components/ParagraphNova";
import QuitRegistration from "~components/QuitProcess";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import NovaventaRadio from "~components/formControls/radio/NovaventaRadio";
import H1Novaventa from "~components/header/H1Novaventa";
import H3Novaventa from "~components/header/H3Novaventa";
import { usePrismic } from "~hooks/usePrismic";
import {
  sendUserPaymentMethod,
  validateTransUnion,
} from "~services/paymentMethodServices";
import novaventaTheme from "~styles/theme";
import { errorDictionary } from "~utils/hooks/HandleHttpErrors";
import { PxToVx } from "~utils/hooks/VH&VWConvert";
import { getObject, removeObject, setObject } from "~utils/hooks/localStorage";
import { fontSizeClamp, margin } from "~utils/hooks/useBreakPointTools";
import useImageSize from "~utils/hooks/useImageSize";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";
import useWindowSize from "~utils/hooks/useWindowSize";
import { capitalizaFirstLetter } from "~utils/utils";

type Props = RouteComponentProps & { navigationPath: string };

const ChoosePaymentMethod = ({ navigationPath, path }: Props) => {
  const t = usePrismic();
  const { width, height } = useWindowSize();
  const [refresh, setRefresh] = useState<boolean>();
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [name, setName] = useState<string>();
  const [showError, setShowError] = useState(false);
  const [errorMesage, setErrorMesage] = useState("");
  const [sizeColHeight, setSizeColHeight] = useState<number>();
  const [form] = Form.useForm();
  const { device, sizeWidth, sizeHeight } = useWindowBreakPoints();
  const [showModal, setShowModal] = useState(false);
  const [modalKindText, setModalKindText] = useState("");
  const [modalBtnText, setModalBtnText] = useState("");
  const [modalAlertText, setModalAlertText] = useState("");
  const [modalTextModal, setModalTextModal] = useState("");
  const { imgSizeHeight, imgSizeWidth } = useImageSize(
    "imgPayment",
    height,
    width,
    refresh
  );
  const onPaymentMethodChange = (ev: any) => {
    setPaymentMethod(ev.target.value);
  };

  useEffect(() => {
    if (
      document.querySelector("#chat-bots-webchat-container") &&
      window.location.href.includes("auto-gestion")
    ) {
      window.CBWebChat.setChatVariable("seccion", "4");
    }
  });

  useEffect(() => {
    const prename: string = getObject("name");
    removeObject("PaymentMethod");
    if (prename) setName(prename);
  }, []);

  useEffect(() => {
    if (device === "desktop" && height && imgSizeHeight && height - 190 < imgSizeHeight) {
      setSizeColHeight(height - 190);
    } else {
      setSizeColHeight(imgSizeHeight);
    }
  }, [device, height, imgSizeHeight]);

  useEffect(() => {
    if (path === "metodo-pago") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function () {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  }, []);

  const onFinish = (values: any) => {
    if (values.PaymentMethod === "CO") {
      const postData = {
        estado: values.PaymentMethod,
      };
      setObject("PaymentMethod", values.PaymentMethod);
      submitUserPaymentMethod(postData);
    } else {
      const idNumber = getObject("idNumber");
      const uuid = getObject("uuid");
      validateTransUnion(idNumber)
        .then((result: any) => {
          if (result.continuar) {
            const baseURL: any = process.env.API_DEVELOP;
            window.location.assign(`${baseURL}/redireccionar/${uuid}`);
          } else {
            if (result.codigo == "E-17") {
              setModalTextModal(errorDictionary(`MSG${result.codigo}`, t.errors));
              setModalAlertText(errorDictionary(`ART${result.codigo}`, t.errors));
              setModalBtnText(errorDictionary(`BTN${result.codigo}`, t.errors));
              setModalKindText(errorDictionary(`KND${result.codigo}`, t.errors));
              setShowModal(true);
            }
            const postData = {
              estado: "CO",
            };
            setObject("PaymentMethod", "CO");
            submitUserPaymentMethod(postData);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const submitUserPaymentMethod = (data: any) => {
    sendUserPaymentMethod(data)
      .then((result: any) => {
        if (result?.continuar) {
          navigate(navigationPath);
          removeObject("uuid");
        } else {
          onHandleError(t.constants.LOSENTIMOSERROR);
        }
      })
      .catch((error) => {
        onHandleError(error);
      });

    form.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("ChoosePaymentMethod Failed:", errorInfo);
  };

  const onHandleError = (err: string) => {
    setErrorMesage(err);
    setShowError(true);
  };

  let condition = "";
  if (width) {
    if (width >= novaventaTheme.breakPoints.desktop) {
      condition = bannerDesktop;
    } else {
      condition = bannerMobile;
    }
  } else {
    condition = bannerDesktop;
  }
  let condition2 = "";
  let condition3 = "";
  if (device === "desktop") {
    condition2 = "position:absolute";
    condition3 = "max-width: 40vw";
  }

  return (
    <div
      className="center"
      css={css`
        height: ${height && device === "desktop" ? `${height - 200}px` : "auto"};
      `}
    >
      <Row
        gutter={[8, 8]}
        justify="space-between"
        align="middle"
        css={css`
          width: 100vw;
          margin: 0px;
        `}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={7}
          css={css`
            height: ${sizeColHeight}px;
            img {
              ${condition2}
              top: 0;
              left: 0;
              width: 100%;
              height: auto;
              z-index: 0;
            }
            div {
              background: ${novaventaTheme.colors.blue400} 0% 0% no-repeat padding-box;
              mix-blend-mode: multiply;
              position: absolute;
              opacity: 0.1;
              width: ${imgSizeWidth}px;
              height: ${imgSizeHeight}px;
              z-index: 1;
            }
          `}
        >
          <div></div>
          <img
            id="imgPayment"
            src={condition}
            width="460"
            height="345"
            onLoad={() => setRefresh(!refresh)}
          ></img>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16}>
          <Form
            form={form}
            id="methodOfPayment"
            data-testid="methodOfPayment"
            name="methodOfPayment"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={() => setShowError(false)}
          >
            <H1Novaventa
              text={`${t.constants.HOLA}, `}
              boldText={name ? capitalizaFirstLetter(name) : ""}
              boldFirst={false}
              sizeWidth={sizeWidth}
              showBack={false}
              extraCss={css`
                margin: ${margin({
                  device: device,
                  sizeHeight: sizeHeight,
                  sizeWidth: sizeWidth,
                  deskTopM: 54,
                  deskLeftM: 0,
                  deskBottomM: 53,
                  deskRightM: 49,
                  tablTopM: 28,
                  tablLeftM: 0,
                  tablBottomM: 29,
                  tablRightM: 40,
                  mobTopM: 28,
                  mobLeftM: 0,
                  mobBottomM: 29,
                  mobRightM: 40,
                })};

                ${imgSizeHeight && sizeColHeight && imgSizeHeight !== sizeColHeight
                  ? `margin-bottom:0px;margin-top:0px;`
                  : ""}
                h1 {
                  font-size: ${fontSizeClamp(device, sizeWidth, 44, 34, 24, 24)};
                }
              `}
            />
            <div
              css={css`
                margin: ${margin({
                  device: device,
                  sizeHeight: sizeHeight,
                  sizeWidth: sizeWidth,
                  deskTopM: 0,
                  deskLeftM: 0,
                  deskBottomM: 50,
                  deskRightM: 200,
                  tablTopM: 0,
                  tablLeftM: 0,
                  tablBottomM: 47,
                  tablRightM: 40,
                  mobTopM: 0,
                  mobLeftM: 0,
                  mobBottomM: 47,
                  mobRightM: 40,
                })};
              `}
            >
              <ParagraphNova
                extraCss={css`
                  max-width: ${PxToVx(811, sizeWidth, "vw")};
                  ${device === "desktop" ? `min-width:720px;` : ""}
                  margin: 0;
                  p {
                    text-align: left;
                    font-size: ${fontSizeClamp(device, sizeWidth, 28, 24, 16, 14)};
                    font-family: ${novaventaTheme.fonts.ProximaNovaLight};
                  }
                `}
              />
              <H3Novaventa
                text={t.constants.DESEASOLICITAR}
                sizeWidth={sizeWidth}
                extraCss={css`
                  font-size: ${fontSizeClamp(device, sizeWidth, 28, 28, 24, 22)};
                `}
              />
              <div
                css={css`
                  margin: ${margin({
                    device: device,
                    sizeHeight: sizeHeight,
                    sizeWidth: sizeWidth,
                    deskTopM: 36,
                    deskLeftM: 0,
                    deskBottomM: 45,
                    deskRightM: 38,
                    tablTopM: 0,
                    tablLeftM: 0,
                    tablBottomM: 47,
                    tablRightM: 10,
                    mobTopM: 0,
                    mobLeftM: 0,
                    mobBottomM: 47,
                    mobRightM: 10,
                  })};
                  span {
                    font-size: ${fontSizeClamp(device, sizeWidth, 28, 24, 16, 14)};
                    font-family: ${novaventaTheme.fonts.ProximaNovaLight};
                  }
                `}
              >
                <Form.Item
                  name="PaymentMethod"
                  rules={[
                    {
                      required: true,
                      message: t.home.rules.PaymentMethod,
                    },
                  ]}
                >
                  <NovaventaRadio
                    options={t.options.paymentMethod}
                    onChange={onPaymentMethodChange}
                    value={paymentMethod}
                  ></NovaventaRadio>
                </Form.Item>
              </div>
            </div>
            <Row gutter={24} justify="center" align="stretch">
              <Col xs={24} sm={12} lg={10} css={css``}>
                {device === "mobile" ? (
                  <NovaventaButton
                    showArrow={true}
                    htmlType="submit"
                    extracss={css`
                      margin: 0 10vw 1vh 10vw;
                      align-self: center;
                      max-width: 40vw;
                    `}
                  />
                ) : (
                  <QuitRegistration analyticsClassName={"leave2"} />
                )}
              </Col>
              <Col
                xs={24}
                sm={12}
                lg={10}
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                `}
              >
                <Form.Item
                  css={css`
                    align-self: center;
                  `}
                >
                  {device === "mobile" ? (
                    <QuitRegistration analyticsClassName={"leave2"} />
                  ) : (
                    <NovaventaButton
                      showArrow={true}
                      htmlType="submit"
                      extracss={css`
                        align-self: center;
                        ${condition3};
                      `}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={20}>
                <InformationBox hide={showError} text={errorMesage} kind={"error"} />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <ModalMessage
        refresh={(e: boolean) => setShowModal(e)}
        showModal={showModal}
        text={modalTextModal}
        alertText={modalAlertText}
        btnText={modalBtnText}
        kind={modalKindText}
        handleClickBtn={null}
      />
    </div>
  );
};

export default ChoosePaymentMethod;
