import React from "react";
import { css } from "@emotion/core";
import DocumentFrontPNG from "~assets/img/documento-front.png";
import DocumentBackPNG from "~assets/img/documento-back.png";
import SelfiePNG from "~assets/img/imgSelfieIcon.png";

const resolveImg = (type: string) => {
  switch (type) {
    case "front":
      return DocumentFrontPNG;
    case "back":
      return DocumentBackPNG;
    case "selfie":
      return SelfiePNG;
    default:
      return "";
  }
};

const IdIcon = ({ type }: any) => {
  return (
    <div
      css={css`
        width: auto;
      `}
    >
      <img
        src={resolveImg(type)}
        css={css`
          width: 140px;
        `}
      />
    </div>
  );
};

export default IdIcon;
