import { Col, Form, Row } from "antd";
import React, { useState } from "react";
import SectionTitle from "~components/SectionTitle";
import NovaventaRadio from "~components/formControls/radio/NovaventaRadio";
import {
  cellLG,
  cellMD,
  rowTitleGutter,
  xs,
  xxl,
} from "~components/forms/formItemsLayout";
import HelpLink from "~components/helpLink/HelpLink";
import HelpModal from "~components/helpLink/HelpModal";
import TaxRegimenModalContent from "~components/taxRegimen/TaxRegimenModalContent";
import { usePrismic } from "~hooks/usePrismic";
import { device } from "~utils/NovaventaMedia";

const TaxRegimenForm = () => {
  const t = usePrismic();
  const deviceType = device();
  const modalWidth = deviceType === "mobile" ? "90%" : "80%";
  const [taxRegimen, setTaxRegimen] = useState("0");
  const [openModal, setOpenModal] = useState(false);

  const helpLinkPos: any =
    deviceType === "mobile"
      ? { position: "absolute", right: "2%", top: "57px" }
      : { position: "absolute", right: "5%", top: "65px" };

  const onTaxRegimenChange = (ev: any) => {
    setTaxRegimen(ev.target.value);
  };

  const handleHelpLinkClick = (visible: any) => {
    setOpenModal(visible);
  };

  return (
    <React.Fragment>
      <Row gutter={rowTitleGutter} justify="start" align="middle">
        <Col flex={"100%"}>
          <SectionTitle
            lightText={t.constants.TIPOREGIMEN}
            headerCss={{ width: "130%" }}
          />
          <div css={helpLinkPos}>
            <HelpLink
              handleClick={() => handleHelpLinkClick(true)}
              gray={true}
              analyticsClassName={"help1"}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
          <Form.Item
            name="regimenTributario"
            rules={[{ required: true, message: t.home.rules.required }]}
          >
            <NovaventaRadio
              optionsProps={{ label: "label", id: "id" }}
              options={t.options.TIPOREGIMENOP}
              onChange={onTaxRegimenChange}
              value={taxRegimen}
            ></NovaventaRadio>
          </Form.Item>
        </Col>
      </Row>
      <HelpModal
        modalWidth={modalWidth}
        ModalContent={<TaxRegimenModalContent />}
        visible={openModal}
        handleModalClick={handleHelpLinkClick}
      />
    </React.Fragment>
  );
};

export default TaxRegimenForm;
