import React from "react";
import LogoNovaventa from "~assets/img/logoNovaventaHeader.svg";
import { LogoWrapperCss } from "./HeaderLogoCss";
import BackArrow from "~components/icons/BackArrow";

const LogoWrapper = (props: any) => {
  return <LogoWrapperCss>{props.children}</LogoWrapperCss>;
};

const HeaderLogo = (props: any) => {
  const { device, showBackBtn = false } = props;

  return (
    <LogoWrapper device={device}>
      {showBackBtn && <BackArrow />}
      <LogoNovaventa />
    </LogoWrapper>
  );
};

export default HeaderLogo;
