import { useMediaQuery } from "react-responsive";

const breakpoints = {
  xs: 0,
  sm: 375,
  md: 800,
  lg: 1366,
  xxl: 1920,
};

const IsMobileXS = () => useMediaQuery({ maxWidth: Number(breakpoints.sm) });

const IsMobile = () => useMediaQuery({ maxWidth: Number(breakpoints.md - 1) });

const IsTablet = () =>
  useMediaQuery({
    minWidth: breakpoints.md,
    maxWidth: Number(breakpoints.lg - 1),
  });

const IsDesktop = () => useMediaQuery({ minWidth: breakpoints.lg });

const IsDesktopHD = () => useMediaQuery({ minWidth: breakpoints.xxl });

export const device = () => {
  const mobileXS = IsMobileXS();
  const mobile = IsMobile();
  const tablet = IsTablet();
  const desktop = IsDesktop();
  const desktopHD = IsDesktopHD();
  return (
    (desktopHD && "desktopHD") ||
    (desktop && "desktop") ||
    (tablet && "tablet") ||
    (mobile && "mobile") ||
    (mobileXS && "mobileXS")
  );
};

export const DesktopHD = ({ children }: any) => {
  const desktopHD = IsDesktopHD();
  return desktopHD ? children : null;
};

export const Desktop = ({ children }: any) => {
  const desktop = IsDesktop();
  return desktop ? children : null;
};

export const Tablet = ({ children }: any) => {
  const tablet = IsTablet();
  return tablet ? children : null;
};

export const Mobile = ({ children }: any) => {
  const mobile = IsMobile();
  return mobile ? children : null;
};

export const MobileXS = ({ children }: any) => {
  const mobileXS = IsMobileXS();
  return mobileXS ? children : null;
};
