import Prismic from "prismic-javascript";
import { useEffect, useState } from "react";
import { mergeObjects } from "~utils/megeQbjects";
import dataPrismic from "./dataPrismic";

export type RulesMessages = typeof dataPrismic.home.rules;
export type DP = typeof dataPrismic;

const client = Prismic.client(process.env.PRISMIC_URL ?? "");
let promisePrismic: Promise<any> | null = null;

export const usePrismic = () => {
  const [textsPrismic, setTextsPrismic] = useState(dataPrismic);

  const getTexts = async () => {
    try {
      const query = Prismic.Predicates.at("document.type", "textos-novaventa");
      if (!promisePrismic) promisePrismic = client.query(query);
      const { results } = await promisePrismic;
      const newPrismic = Object.keys(dataPrismic).reduce((acum, key) => {
        try {
          const prismicItem = JSON.parse(results[0].data[key]);
          if (!prismicItem) acum[key] = {};
          acum[key] = prismicItem;
        } catch (error) {
          console.error(`error prismic key ${key}: `, error);
          acum[key] = {};
        }
        return acum;
      }, {} as any);
      setTextsPrismic(mergeObjects(dataPrismic, newPrismic));
    } catch (error) {
      console.error("error usePrismic", error);
    } finally {
      promisePrismic = null;
    }
  };
  useEffect(() => {
    getTexts();
  }, []);

  return textsPrismic;
};
