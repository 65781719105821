import React from "react";
import { useParams } from "@reach/router";
import CreditOnDemandDesktop from "./CreditOnDemandDesktop";

const CreditOnDemand = (_props: any) => {
  const { base64, cicloB64 } = useParams();
  return <CreditOnDemandDesktop data={base64} ciclo={cicloB64} />;
};

export default CreditOnDemand;
