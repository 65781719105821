import axios from "axios";

const baseURL: string = process.env.API_DEVELOP ?? "";
let consultaSugar: Promise<any> | null = null;

export const sendProspectRegistration = async (data: any) => {
  if (!consultaSugar) {
    consultaSugar = axios.post(`${baseURL}/comandos/registrar-sugar`, data);
  }
  try {
    const { continuar, mensaje } = (await consultaSugar).data;
    consultaSugar = null;
    return { continuar: Boolean(continuar), message: String(mensaje) };
  } catch (error) {
    console.error("sendProspectRegistration catch: ", error);
    consultaSugar = null;
    return { continuar: false, message: String(error) };
  }
};
