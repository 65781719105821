import Prismic from "prismic-javascript";
const prismicUrl: any = process.env.PRISMIC_URL;

const Client = Prismic.client(prismicUrl);

export const fetchPrismicDataTermsAndConditions: any = async () => {
  const response = await Client.query(
    Prismic.Predicates.at("document.type", "terminos-y-condiciones")
  );
  return response.results[0].data;
};
export const fetchPrismicDataHowDidYouKnowNova: any = async () => {
  const response = await Client.query(
    Prismic.Predicates.at("document.type", "como-nos-conociste")
  );
  return response.results[0].data;
};

export const fetchPrismicDataLandingOptions: any = async () => {
  const response = await Client.query(
    Prismic.Predicates.at("document.type", "opciones-landing")
  );
  return response.results[0].data;
};

export const fetchPrismicDataIsServerOut = async () => {
  return Client.query(Prismic.Predicates.at("document.type", "servidor-en-mantenimiento"))
    .then((response) => response.results[0]?.data?.["servidor-en-mantenimiento"] === true)
    .catch((error) => {
      console.error(error);
      return false;
    });
};
