import { css } from "@emotion/core";
import { navigate, RouteComponentProps } from "@reach/router";
import { Form } from "antd";
import { useState } from "react";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { NovaventaInput } from "~components/formControls/input/NovaventaInput";
import ModalMessage from "~components/ModalMessage";
import { usePrismic } from "~hooks/usePrismic";
import { forgotPassword } from "~utils/hooks/cognitoAws";
import { errorDictionary } from "~utils/hooks/HandleHttpErrors";

const fomCss = css`
  display: table;
  margin: 0 auto;
  input,
  select {
    max-width: 450px;
    width: 100%;
  }
`;

const ForgotPassword = (props: RouteComponentProps | any) => {
  const { navigationPath } = props;
  const t = usePrismic();
  const [showModal, setShowModal] = useState(false);
  const [modalKindText, setModalKindText] = useState<string>();
  const [modalBtnText, setModalBtnText] = useState<string>(t.constants.CERRAR);
  const [modalAlertText, setModalAlertText] = useState<string>();
  const [modalTextModal, setModalTextModal] = useState<string>();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const postData = {
      username: values.username,
    };
    submitUserForgotPassword(postData);
  };

  const submitUserForgotPassword = async (data: any) => {
    await forgotPassword(data.username)
      .then(() => {
        setModalTextModal(t.constants.HEMOSENVIADOCORREO);
        setModalBtnText(t.constants.IRINICIO);
        setModalAlertText(t.constants.MENSAJEENVIADO);
        setModalKindText("success");
        setShowModal(true);
      })
      .catch((err) => {
        onhandleError(err.message);
        console.error("submitUserForgotPassword:", err.message);
      });

    form.resetFields();
  };
  const onhandleError = (err: string) => {
    setModalKindText("error");
    setModalTextModal(errorDictionary(err, t.errors));
    setModalAlertText(t.constants.LOSENTIMOSERROR);
    setShowModal(true);
  };
  const onhandleEnd = () => {
    navigate(navigationPath);
  };
  return (
    <div className="center">
      <Form
        autoComplete="off"
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onChange={() => setShowModal(false)}
        onFinish={onFinish}
        onFinishFailed={(err: any) => onhandleError(err.message)}
        css={fomCss}
      >
        <Form.Item label={t.constants.INGRESATUCORREO}>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: t.home.rules.required }]}
            noStyle
          >
            <NovaventaInput maxLength={30} type="email" />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <NovaventaButton btnText={t.constants.RECUPERARCONTRASEÑA} htmlType="submit" />
        </Form.Item>
      </Form>
      <ModalMessage
        refresh={(e: boolean) => setShowModal(e)}
        showModal={showModal}
        text={modalTextModal}
        alertText={modalAlertText}
        btnText={modalBtnText}
        kind={modalKindText}
        handleClickBtn={modalKindText === "success" ? onhandleEnd : null}
      />
    </div>
  );
};
export default ForgotPassword;
