import { Select } from "antd";
import React from "react";
import FlechaSvg from "~assets/icons/flecha.svg";
import IconNova from "~components/icons";
import NovaventaSelectCss from "./NovaventaSelectCss";

interface SelectPaisProps {
  id?: string;
  placeholder?: any;
  options: any[];
  optionsProp?: any;
  notFoundContent?: any;
  variant?: string;
  extracss?: any;
  name?: string;
  value?: string;
  onChange?: (value: any) => void;
  filterOption?: any;
  showSearch?: any;
  optionFilterProp?: any;
  loading?: any;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  isdisabled?: boolean;
}

function NovaventaSelect(props: Readonly<SelectPaisProps>) {
  const {
    variant,
    extracss = {},
    options,
    placeholder,
    name,
    value,
    onChange,
    filterOption,
    showSearch = false,
    optionFilterProp,
    optionsProp = { label: "nombre", id: "id" },
    loading = false,
    onFocus,
    isdisabled,
  } = props;
  const { Option }: any = Select;

  const onValueChange = (changedValue: any) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  return (
    <div style={{ position: "relative" }} id="selectContainer">
      <NovaventaSelectCss
        data-testid={name ? `select-${name}` : "nova-select"}
        disabled={isdisabled}
        onFocus={onFocus}
        virtual={false}
        loading={loading}
        value={value}
        onChange={onValueChange}
        placeholder={placeholder}
        allowClear
        showArrow={true}
        extracss={extracss}
        showSearch={showSearch}
        optionFilterProp={optionFilterProp}
        filterOption={filterOption}
        suffixIcon={<FlechaSvg />}
        getPopupContainer={(): any => document.getElementById("selectContainer")}
      >
        {variant === "country"
          ? options.map((option: any, idx) => (
              <Option key={idx} value={option.idValue}>
                <IconNova code={option.idValue} />
                {" " + option.nombre}
              </Option>
            ))
          : options.map((option: any) => (
              <Option key={option[optionsProp.id]}>{option[optionsProp.label]}</Option>
            ))}
      </NovaventaSelectCss>
    </div>
  );
}

export default NovaventaSelect;
