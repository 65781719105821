import { css } from "@emotion/core";
import { RouteComponentProps, navigate } from "@reach/router";
import { Col, Form, Row } from "antd";
import { useState } from "react";
import bannerDesktop from "~assets/img/banner-8-desktop.png";
import bannerMobile from "~assets/img/banner-8-mobile.png";
import ModalMessage from "~components/ModalMessage";
import QuitRegistration from "~components/QuitProcess";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import UploadImageForm from "~components/forms/UploadImageForm";
import { usePrismic } from "~hooks/usePrismic";
import { FormProvider } from "~store/FormContext";
import novaventaTheme from "~styles/theme";
import { DocumentProvider } from "~utils/context/document.provider";
import useWindowSize from "~utils/hooks/useWindowSize";

type Update = { key: "idImageFront" | "idImageBack"; val: boolean };

const UploadImagePage = (_props: RouteComponentProps | any) => {
  const t = usePrismic();
  const [images, setImages] = useState({
    idImageFront: false,
    idImageBack: false,
  });
  const [showError, setShowError] = useState(false);
  const width = useWindowSize().width;
  const showUserImageData = (update: Update) => {
    setImages((s) => ({ ...s, [update.key]: true }));
  };
  const handleClick = () => {
    if (images.idImageBack && images.idImageFront) {
      navigate("metodo-pago");
    } else {
      setShowError(true);
    }
  };

  const resolveBanner = (widthResolveBanner: any) => {
    if (!widthResolveBanner) {
      return bannerDesktop;
    }
    if (widthResolveBanner >= novaventaTheme.breakPoints.maxTabletWith) {
      return bannerDesktop;
    } else {
      return bannerMobile;
    }
  };

  return (
    <DocumentProvider>
      <FormProvider>
        <Row
          gutter={[8, 8]}
          justify="space-between"
          align="stretch"
          css={css`
            width: 100vw;
            margin: 0px;
          `}
        >
          <Col
            xs={24}
            md={8}
            lg={10}
            css={css`
              img {
                width: 100%;
                height: auto;
              }
            `}
          >
            <img src={resolveBanner(width)} width="460" height="345"></img>
          </Col>

          <Col xs={24} md={16} lg={14} style={{ padding: "3rem" }}>
            <Row justify="start" align="middle">
              <UploadImageForm showUserImageData={showUserImageData} />
            </Row>
            <Row justify="start" align="middle">
              <Col xs={24} sm={10}>
                <QuitRegistration />
              </Col>
              <Col xs={24} sm={10}>
                <Form.Item>
                  <NovaventaButton
                    showArrow={true}
                    htmlType="button"
                    btnText={t.constants.CONTINUAR}
                    handleClick={handleClick}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <ModalMessage
          handleClickBtn={() => setShowError(false)}
          showModal={showError}
          alertText={t.constants.errorOperation}
          text={t.constants.errorImages}
          btnText={t.constants.CERRAR}
        />
      </FormProvider>
    </DocumentProvider>
  );
};

export default UploadImagePage;
