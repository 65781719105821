export const rowGutter: any = [24, 16];
export const rowTitleGutter: any = 32;

export const xs: any = { span: 24 };
export const md: any = { span: 9 };
export const lg: any = { span: 9 };
export const xxl: any = { span: 9 };

export const refByXS: any = { span: 24 };
export const refByMD: any = { span: 9 };
export const refByLG: any = { span: 9 };
export const refByXXL: any = { span: 9 };

export const addressXS: any = { span: 24 };
export const addressMD: any = { span: 8 };
export const addressLG: any = { span: 8 };
export const addressXXL: any = { span: 8 };

export const addXS: any = { span: 24 };
export const addMD: any = { span: 4 };
export const addLG: any = { span: 4 };
export const addXXL: any = { span: 5 };

export const cellMD: any = { span: 9 };
export const cellLG: any = { span: 9 };
