import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { usePrismic } from "~hooks/usePrismic";
import {
  HeaderTextDesktopWrapper,
  HeaderTextMobileWrapper,
  HeaderTextTabletWrapper,
} from "./HeaderTextCss";

type Divice = "mobile" | "tablet" | "desktop";

const Wrapper = ({ children, device }: { children: ReactNode; device: Divice }) => {
  if (device === "mobile") {
    return (
      <HeaderTextMobileWrapper data-testid="headerMobile">
        {children}
      </HeaderTextMobileWrapper>
    );
  } else if (device === "tablet") {
    return (
      <HeaderTextTabletWrapper data-testid="headerTablet">
        {children}
      </HeaderTextTabletWrapper>
    );
  } else {
    return (
      <HeaderTextDesktopWrapper data-testid="headerOther">
        {children}
      </HeaderTextDesktopWrapper>
    );
  }
};

const HeaderText = ({
  device,
  headerStr = [],
}: {
  device: Divice;
  headerStr: string[];
}) => {
  const t = usePrismic();
  const [headerText, setHeaderText] = useState(t.constants.REGISTRO);
  const [headerSubtext, setHeaderSubtext] = useState(t.constants.ACTIVATUCUENTA);
  const [headerBoldText, setHeaderBoldText] = useState(t.constants.NOVAVENTA);

  useEffect(() => {
    if (headerStr.length > 0) {
      setHeaderText(headerStr[0]);
      setHeaderSubtext(headerStr[1]);
      setHeaderBoldText(headerStr[2]);
    } else {
      setHeaderText(t.constants.REGISTRO);
      setHeaderSubtext(t.constants.ACTIVATUCUENTA);
      setHeaderBoldText(t.constants.NOVAVENTA);
    }
  }, [t]);

  return (
    <Wrapper device={device}>
      <span className="registerText" data-testid="headerText">
        {headerText + " "} <span className="novaventaText"> {headerBoldText}</span>
      </span>
      <span className="activateAccountTxt">{headerSubtext}</span>
    </Wrapper>
  );
};

export default HeaderText;
