import React from "react";
import styled from "@emotion/styled";
import "react-dropzone-uploader/dist/styles.css";
import cssVars from "~styles/variables";
import cssbreakPoints from "~styles/breakPoints";
import { PxToVx } from "~utils/hooks/VH&VWConvert";

const RemoveCancelBtnCss = styled.div`
  /* align-items: center; */
  background: ${(props: any) => props.theme.colors.blue50} 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px white;
  cursor: pointer;
  border-radius: 6px;
  color: ${(props: any) => props.theme.colors.blue400};
  /* display: inline-block; */
  height: 46px;
  font-family: ${(props: any) => props.theme.fonts.ProximaNovaSbold};
  /* justify-content: center; */
  /* margin: 0 auto; */
  width: 150px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  label {
    font-size: clamp(
      ${cssVars.labelFontSizeMin},
      ${PxToVx(16, cssbreakPoints.maxDesktopWith, "vw")},
      ${cssVars.labelFontSizeMax}
    );
  }
`;

const RemoveCancelBtn = ({ children, handleClick, analyticsClassName }: any) => {
  return (
    <RemoveCancelBtnCss onClick={handleClick} className={analyticsClassName}>
      {children}
    </RemoveCancelBtnCss>
  );
};

export default RemoveCancelBtn;
