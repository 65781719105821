const novaventaVariables = {
  fieldSize: {
    defaultWidth: "90vw",
    defaultMinWidth: "300px",
    defaultMaxWidth: "450px",
  },
  labelFontSizeMax: "18px",
  labelFontSizeMin: "14px",
  pFontSizeMax: "20px",
  pFontSizeMin: "16px",
  h1FontSizeMax: "36px",
  h1FontSizeMin: "24px",
  h2FontSizeMax: "30px",
  h2FontSizeMin: "20px",
  h3FontSizeMax: "28px",
  h3FontSizeMin: "16px",
  headerCardMax: "36px",
  headerCardMin: "16px",
  textInputMax: "20px",
  textInputMin: "16px",
  pCardMax: "28px",
  pCardMin: "14px",
  boxInfoFontSizeMax: "16px",
  boxInfoFontSizeMin: "14px",
  boxInfoIconSizeMax: "56px",
  boxInfoIconSizeMin: "35px",
};

export default novaventaVariables;
