import React, { useState, useContext } from "react";
import { Form } from "antd";
import { formDataList } from "~store/formStore";

const formContext = React.createContext(null);

export const useFormContext = () => {
  const store: any = useContext(formContext);

  if (!store) {
    throw new Error("Cannot use `useFormContext` outside of a FormProvider");
  }

  return store;
};

const FormContextStore = () => {
  const [addressForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [basicDataForm] = Form.useForm();
  const [formValues, setFormValues]: any = useState(
    new Map(Object.entries(formDataList))
  );
  const [mobileActiveTab, setMobileActiveTab] = useState(1);
  const [addressFormObj, setAddressFormObj] = useState(addressForm);
  const [contactFormObj, setContactFormObj] = useState(contactForm);
  const [basicDataFormObj, setBasicDataFormObj] = useState(basicDataForm);
  const [disabledItems, setDisabledItems] = useState([]);

  const updateMobileActiveTab = (newValue: any) => {
    setMobileActiveTab(newValue);
  };

  const updateAddressFormObj = (newForm: any) => {
    setAddressFormObj(newForm);
  };

  const updateDisabledItems = (data: any) => {
    setDisabledItems(data);
  };

  const updateFormValues = (newValue: any) => {
    let valueKey = newValue.key;
    if (valueKey === "addressSummary") {
      valueKey = "direccion";
    } else if (valueKey === "deliveryAddressSummary") {
      valueKey = "direccionEnvio";
    }
    setFormValues(formValues.set(valueKey, `${newValue.val}`));
  };

  return {
    addressFormObj,
    updateAddressFormObj,
    contactFormObj,
    setContactFormObj,
    basicDataFormObj,
    setBasicDataFormObj,
    mobileActiveTab,
    updateMobileActiveTab,
    formValues,
    updateFormValues,
    disabledItems,
    updateDisabledItems,
  };
};

export const FormProvider = (children: any) => {
  return <formContext.Provider value={FormContextStore()} {...children} />;
};
