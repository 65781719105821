import React from "react";
import { RouteComponentProps } from "@reach/router";
import NovaventaLayout from "~components/NovaventaLayout";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";

const ExpertCall = (props: RouteComponentProps | any) => {
  const { children } = props;
  const { device } = useWindowBreakPoints();

  return (
    <NovaventaLayout isLogged={true} deviceType={device} percent={0} showBackBtn={false}>
      {children}
    </NovaventaLayout>
  );
};

export default ExpertCall;
