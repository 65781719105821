import axios from "axios";
import { downloadLink } from "~utils/downloadLink";
const baseURL: any = process.env.API_DEVELOP;

export function getHistorial(cedula: string) {
  return axios
    .get(`${baseURL}/historial/${cedula}/admin`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
    .then(({ data }) => data)
    .catch(console.error);
}

export function excelProcesos(query: string, name: string) {
  return axios
    .get(`${baseURL}/consulta-procesos/descargar${query}`, {
      responseType: "blob",
    })
    .then(({ data }) => downloadLink(new Blob([data]), name))
    .catch(console.error);
}
