import { Col, Form } from "antd";
import type { Rule } from "antd/lib/form";
import React from "react";
import { NovaventaInput } from "~components/formControls/input/NovaventaInput";

type InputTextProps = {
  name: string;
  label: string;
  rules: Rule[];
  type?: string;
  placeholder?: string;
};

export const InputText = ({
  name,
  label,
  rules,
  type = "text",
  placeholder,
}: InputTextProps) => {
  return (
    <Col span={10} xs={20} md={10}>
      <Form.Item name={name} label={label} hasFeedback rules={rules}>
        <NovaventaInput
          placeholder={placeholder}
          type={type}
          showiconcheck="false"
          maxLength={50}
        />
      </Form.Item>
    </Col>
  );
};
