import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { device } from "~utils/NovaventaMedia";
import NovaventaLayout from "~components/NovaventaLayout";
import { removeObject } from "~utils/hooks/localStorage";

type Props = RouteComponentProps & {
  children: React.ReactNode;
};

const SelfRegistration = ({ children }: Props) => {
  const deviceType = device();
  const [isLogged, setIsLogged]: any = useState();
  const [percent, setPercent]: any = useState(0);
  const [showBackBtn, setShowBackBtn]: any = useState(false);

  useEffect(() => {
    removeObject("token");
    removeObject("username");
    removeObject("session");
    if (location.pathname.search("inscripcion-digital") > 0) {
      setIsLogged(true);
      setPercent(25);
      setShowBackBtn(false);
    } else if (location.pathname.search("metodo-pago") > 0) {
      setIsLogged(true);
      setPercent(45);
      setShowBackBtn(false);
    } else if (location.pathname.search("bienvenida") > 0) {
      setIsLogged(true);
      setPercent(100);
      setShowBackBtn(false);
    } else {
      setIsLogged(false);
    }
  }, [children]);

  return (
    <NovaventaLayout
      isLogged={isLogged}
      deviceType={deviceType}
      percent={percent}
      showBackBtn={showBackBtn}
    >
      {children}
    </NovaventaLayout>
  );
};

export default SelfRegistration;
