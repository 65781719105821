import React from "react";
import { Form } from "antd";
import NovaventaInput from "~components/formControls/input/NovaventaInput";
import { usePrismic } from "~hooks/usePrismic";
import { sendHomePhoneValidation } from "~services/selfRegistrationServices";

const HomePhone = ({ registrationType }: any) => {
  const t = usePrismic();
  return (
    <Form.Item
      hasFeedback
      name="telefonoFijo"
      label={`${
        registrationType === "ContactExpert"
          ? t.constants.CUALESTUFIJO
          : t.constants.TELEFONOFIJO
      } (${t.constants.OPCIONAL})`}
      rules={[
        {
          validator: async (_rule, value) => {
            if (!value) return;
            if (!/^60\d+/.exec(value)) throw Error(t.home.rules.prefixCity);
            if (!/^(\d+)$/.exec(value)) throw Error(t.home.rules.onlyNumber);
            if (value.length !== 10) {
              throw Error(t.home.rules.length.replace("${0}", "10"));
            }
            try {
              const { data } = await sendHomePhoneValidation(value);
              if (!data?.continuar) throw Error(t.home.rules.numberExists);
            } catch (error) {
              console.error(error);
              throw Error(t.home.rules.unexpectedError);
            }
          },
        },
      ]}
    >
      <NovaventaInput type="tel" showiconcheck="false" />
    </Form.Item>
  );
};

export default HomePhone;
