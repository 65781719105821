import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import SectionTitle from "~components/SectionTitle";
import { rowTitleGutter } from "~components/forms/formItemsLayout";
import UploadImageRow from "~components/uploadImage/UploadImageRow";
import { usePrismic } from "~hooks/usePrismic";

const UploadImageForm = ({ showUserImageData, onDataChange }: any) => {
  const t = usePrismic();
  const [data, setData] = useState("");

  useEffect(() => {
    setData(onDataChange);
  }, [onDataChange]);

  return (
    <React.Fragment>
      <Row gutter={rowTitleGutter} justify="start" align="middle">
        <Col flex={"100%"}>
          <SectionTitle
            lightText={t.constants.BASICDATA1}
            boldText={t.constants.BASICDATA2}
          />
        </Col>
      </Row>
      <Row gutter={rowTitleGutter} justify="start" align="middle">
        <Col flex={"100%"}>
          <UploadImageRow
            type="front"
            showUserImageData={showUserImageData}
            onDataChange={data}
          />
          <UploadImageRow type="back" showUserImageData={showUserImageData} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UploadImageForm;
