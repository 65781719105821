import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  ExclamationCircleTwoTone,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { css } from "@emotion/core";
import type { RouteComponentProps } from "@reach/router";
import { Button, Popconfirm, Tag } from "antd";
import Search from "antd/lib/input/Search";
import { useTheme } from "emotion-theming";
import React, { useState } from "react";
import { usePrismic } from "~hooks/usePrismic";
import {
  deleteInternalListRegister,
  downloadFile,
  getByCedula,
} from "~services/managementServices";
import InternalListForm from "./forms/InternalListForm";

interface IInternalList {
  uuid: string;
  modeloNegocio: string;
  nombres: string;
  apellidos: string;
  tipoDocumento: string;
  numeroIdentidad: string;
  motivoBloqueo: string;
  estadoBloqueo: boolean;
  fechaBloqueo: string;
  operation?: any;
}
const InternalList = (_props: RouteComponentProps) => {
  const [dataSource, setDataSource] = useState<IInternalList[]>([]);
  const [showForm, setShowForm] = useState(false);
  const theme: any = useTheme();
  const t = usePrismic();

  const handleSearch = (value: string) => {
    if (!value) return setDataSource([]);
    getByCedula(value)
      .then(({ data }) => setDataSource(data))
      .catch(console.error);
  };

  const handleDelete = (uuid: string) => {
    deleteInternalListRegister(uuid)
      .then(() => setDataSource((s) => s.filter((i) => i.uuid !== uuid)))
      .catch(console.error);
  };

  const header = [
    "",
    t.constants.MODELONEGOCIO,
    t.constants.NOMBRE,
    t.constants.APELLIDO,
    t.constants.TIPODOCUMENTO2,
    t.constants.NUMERODEID,
    t.constants.MOTIVOBLOQ,
    t.constants.ESTADOBLOQUEO,
    t.constants.FECHABLOQUEO,
    t.constants.ACCION,
  ];

  return (
    <React.Fragment>
      {showForm ? (
        <InternalListForm
          data-testid="listaInternaForm"
          onBack={() => setShowForm(false)}
        />
      ) : (
        <div
          data-testid="listaInterna"
          css={css`
            margin: 2rem 3% 0% 3%;
            .ant-table-wrapper {
              width: 95vw;
              max-width: 94vw;
            }
            table {
              width: 100%;
            }
            th {
              font-family: ${theme.fonts.ProximaNovaSbold};
              padding: 10px;
              text-align: center;
            }
            thead {
              background: #dadada;
              color: black;
            }
            th span {
              font-family: ${theme.fonts.ProximaNovaSbold};
            }
            tbody > tr {
              background: white;
            }
            tbody > tr > td {
              text-align: center;
              padding: 10px;
            }
            a {
              color: ${theme.colors.blue400Lighter};
              font-family: ${theme.fonts.ProximaNovaSbold};
            }
            button {
              background-color: ${theme.colors.blue400};
            }
            button span {
              font-family: ${theme.fonts.ProximaNovaSbold};
            }
            .ant-btn-primary:hover,
            .ant-btn-primary:focus {
              background-color: ${theme.colors.blue400};
            }
          `}
        >
          <Search
            data-testid="inputSearch"
            placeholder={t.constants.BUSCAR}
            enterButton
            size="large"
            allowClear={true}
            onSearch={handleSearch}
          />
          <Button
            data-testid="btnAddInternalList"
            css={css`
              float: left;
              margin: 15px 0;
            `}
            hidden={showForm}
            type="primary"
            size="middle"
            onClick={() => setShowForm(true)}
            icon={<PlusCircleOutlined />}
          >
            {t.constants.AGREGARREG}
          </Button>
          <Button
            data-testid="btnDownloadExcelInternalList"
            css={css`
              float: right;
              margin: 15px 5px;
            `}
            hidden={showForm}
            type="primary"
            size="middle"
            onClick={() => downloadFile().catch(console.error)}
            icon={<DownloadOutlined />}
          >
            {t.constants.DESCARGARARCHIVO}
          </Button>
          <table>
            <thead>
              <tr>
                {header.map((h, i) => (
                  <th key={"h" + i}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataSource.map((data, i) => {
                const modeloNegocio = t.options.modelBusiness.find((mn) => {
                  return mn.id === data.modeloNegocio;
                });
                return (
                  <tr key={data.uuid}>
                    <td>{i}</td>
                    <td>{modeloNegocio?.nombre ?? data.modeloNegocio}</td>
                    <td>{data.nombres}</td>
                    <td>{data.apellidos}</td>
                    <td>{data.tipoDocumento}</td>
                    <td>{data.numeroIdentidad}</td>
                    <td>{data.motivoBloqueo}</td>
                    <td>
                      <Tag
                        color={data.estadoBloqueo ? "error" : "geekblue"}
                        icon={
                          data.estadoBloqueo ? (
                            <CloseCircleOutlined />
                          ) : (
                            <CheckCircleOutlined />
                          )
                        }
                      >
                        {t.options.lockedStatus.find(
                          (l) => l.id === Boolean(data.estadoBloqueo)
                        )?.label ?? data.estadoBloqueo}
                      </Tag>
                    </td>
                    <td>{data.fechaBloqueo}</td>
                    <td>
                      <Popconfirm
                        icon={
                          <ExclamationCircleTwoTone
                            twoToneColor={theme.colors.blue400Lighter}
                          />
                        }
                        title={t.constants.SEGUROELIMINAR}
                        onConfirm={() => handleDelete(data.uuid)}
                      >
                        <a>{t.constants.ELIMINAR}</a>
                      </Popconfirm>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );
};

export default InternalList;
