const addressFormValuesHandler = (values: any) => {
  const addressSummaryEntries = Object.entries(values);
  const addressSummaryMap = new Map(addressSummaryEntries);
  const addressSummaryArray: any = [];
  const deliveryAddressSummaryArray: any = [];
  const addressSummaryWithoutComplementArray: any = [];

  for (const [key, value] of addressSummaryMap) {
    if (validateKeyAndValue(value, key)) {
      const strValue = assignmentstrValue(value, key);
      if (key.includes("DA")) {
        deliveryAddressSummaryArray.push(strValue);
      } else {
        addressSummaryArray.push(strValue);
      }
      if (key !== "datosAdicionales") {
        addressSummaryWithoutComplementArray.push(strValue);
      }
    }
  }
  return {
    addressSummary: addressSummaryArray.join(" "),
    deliveryAddressSummary: deliveryAddressSummaryArray.join(" "),
    addressSummaryWithoutComplement: addressSummaryWithoutComplementArray.join(" "),
  };
};

const assignmentstrValue = (value: any, key: any) => {
  if (key.includes("numero2")) {
    return `#${value}`;
  } else if (key.includes("numero3")) {
    return `- ${value}`;
  } else {
    return value;
  }
};

const validateKeyAndValue = (value: any, key: any) =>
  value &&
  key !== "idDepartamento" &&
  key !== "idDepartamentoDA" &&
  key !== "idCiudad" &&
  key !== "idCiudadDA" &&
  key !== "idBarrio" &&
  key !== "idBarrioDA" &&
  key !== "deliveryAddress" &&
  key !== "idRelacion" &&
  key !== "complemento";

export default addressFormValuesHandler;
