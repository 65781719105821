import React from "react";
import { Card, Row, Col } from "antd";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { PxToVx } from "~utils/hooks/VH&VWConvert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fontSizeClamp, margin } from "~utils/hooks/useBreakPointTools";
import { faLaptop } from "@fortawesome/free-solid-svg-icons";

const RegistrationChannelCard = (props: any) => {
  const {
    CardCss = {},
    headerText,
    text,
    icon = faLaptop,
    device,
    sizeWidth,
    sizeHeight,
  } = props;
  const theme: any = useTheme();

  const resolveCondition = (
    condition: boolean,
    trueCondition: any,
    falseCondition: any
  ) => (condition ? trueCondition : falseCondition);

  return (
    <div>
      <Row gutter={16}>
        <Col className="gutter-row" span={24}>
          <Card
            css={css`
              padding: ${resolvePositionDevice(device, "0.4% 0% 0.4% 0%", "2% 0% 2% 0%")};

              background-color: ${theme.colors.white50};
              box-shadow: 0px 0px 12px #0000000f;
              border-radius: 14px;
              width: ${device === "mobile"
                ? "94vw"
                : resolvePositionDevice(device, "75vw", "94vw")};
              max-width: ${device === "mobile"
                ? ""
                : resolvePositionDevice(device, "1175px", "846px")};
              opacity: 1;
              margin: ${margin({
                device: device,
                sizeHeight: sizeHeight,
                sizeWidth: sizeWidth,
                deskTopM: 0,
                deskLeftM: 0,
                deskBottomM: 26,
                deskRightM: 0,
                tablTopM: 0,
                tablLeftM: 0,
                tablBottomM: 64,
                tablRightM: 0,
                mobTopM: 0,
                mobLeftM: 0,
                mobBottomM: 22,
                mobRightM: 0,
              })};

              ${device === "desktop"
                ? `margin-bottom:clamp(17px,${PxToVx(26, sizeWidth, "vw")},26px);`
                : ""}
              .ant-card-body {
                padding: 2%;
              }
              .cardBody {
                padding: 0px;
                display: flex;
              }
              .cardText {
                align-self: center;
              }
              header {
                line-height: ${device === "desktop"
                  ? `clamp(29px,${PxToVx(43, sizeWidth, "vw")},43px)`
                  : resolveCondition(device === "tablet", "29px", "24px")};
                font-family: ${theme.fonts.ProximaNovaSbold};
                color: ${theme.colors.blueGray800};
                font-size: ${fontSizeClamp(device, sizeWidth, 36, 24, 24, 20)};
              }
              p {
                margin-top: 0.5vh;
                line-height: ${device === "desktop"
                  ? `clamp(24px,${PxToVx(34, sizeWidth, "vw")},34px)`
                  : resolveCondition(device === "tablet", "24px", "19px")};
                font-family: ${theme.fonts.ProximaNovaLight};
                color: ${theme.colors.blueGray800};
                font-size: ${fontSizeClamp(device, sizeWidth, 28, 20, 20, 16)};
                margin-bottom: ${PxToVx(24.5, sizeHeight, "vh")};
                letter-spacing: 0px;
                padding-right: 4%;
              }
              .cardIcon {
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .cardIcon svg {
                display: flex;
                justify-content: center;
                align-items: center;
                color: ${theme.colors.blue400};
                transform: scale(
                  ${device === "desktop"
                    ? 6
                    : resolveCondition(device === "tablet", 5, 3)}
                );
              }
              ${CardCss}
            `}
          >
            <Row gutter={24} justify="center" align="middle">
              <Col xs={6} lg={6}>
                <div className="cardIcon">
                  <FontAwesomeIcon icon={icon} />
                </div>
              </Col>

              <Col className="cardBody" xs={18} lg={18}>
                <div className="cardText">
                  <header>{headerText}</header>
                  <p>{text}</p>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const resolvePositionDevice = (
  device: any,
  truePorperty: string,
  falseProperty: string
) => (device === "desktop" ? `${truePorperty}` : `${falseProperty}`);

export default RegistrationChannelCard;
