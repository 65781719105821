import { css } from "@emotion/core";
import { Col, Form, Row } from "antd";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { usePrismic } from "~hooks/usePrismic";
import { sendBlackListRegister } from "~services/blackListServices";
import { InputSelect } from "./InputSelect";
import { InputText } from "./InputText";

const EmailBlackListForm = (props: any) => {
  const t = usePrismic();
  const { hidden, refresh, handleClick, showTable, showForm } = props;
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log(values);
    submitBlackListRegistration(values);
  };
  const submitBlackListRegistration = (data: any) => {
    sendBlackListRegister(data)
      .then(function () {
        refresh(true);
        form.resetFields();
        refresh(false);
        showTable();
        showForm();
      })
      .catch(function (error) {
        console.error("submitBlackListRegistration", error);
      });
  };
  return (
    <Form form={form} onFinish={onFinish} name="InternalList" layout="vertical">
      <Row gutter={24} justify="center" align="middle" hidden={hidden}>
        <InputSelect
          name="clave"
          label={t.home.labels.lockedType}
          optionsProp={{ label: "label", id: "id" }}
          rules={[{ required: true, message: t.home.rules.required }]}
          placeholder={t.constants.TIPOBLOCK}
          options={t.options.blackList}
        />
        <InputText
          name="valor"
          label={t.constants.DATOBLOQUEO}
          rules={[{ required: true, message: t.home.rules.required }]}
        />
        <Col span={10} xs={20} md={10}>
          <NovaventaButton
            btnText={t.constants.CANCELAR}
            extracss={css`
              margin: 21px auto 34px auto;
              width: 300px;
              float: right;
            `}
            handleClick={handleClick}
          />
        </Col>
        <Col span={10} xs={20} md={10}>
          <NovaventaButton
            btnText={t.constants.ENVIAR}
            htmlType="submit"
            extracss={css`
              margin: 21px auto 34px auto;
              width: 300px;
            `}
          />
        </Col>
      </Row>
    </Form>
  );
};
export default EmailBlackListForm;
