export interface Selfie {
  cc: string;
  disabledButton: boolean;
}

export interface DocumentState {
  setSelfie: Selfie;
}

export const INITIAL_STATE_DOCUMENT: DocumentState = {
  setSelfie: {
    cc: "",
    disabledButton: true,
  },
};

export interface ValidationFace {
  // eslint-disable-next-line camelcase
  Face_CC: string;
  Rostro: string;
  NroReferencia: string;
}
