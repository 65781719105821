import React, { useEffect } from "react";
import IdForm from "~components/forms/idForm/IdForm";
import QuitRegistration from "~components/QuitProcess";
import { RouteComponentProps } from "@reach/router";
import InformationBox from "./InformationBox/InformationBox";
import { usePrismic } from "~hooks/usePrismic";
import { margin } from "~utils/hooks/useBreakPointTools";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";
import { css } from "@emotion/core";

const SelfRegistrationLogin = (_props: RouteComponentProps) => {
  const t = usePrismic();
  const { device, sizeWidth, sizeHeight } = useWindowBreakPoints();

  useEffect(() => {
    if (document.querySelector("#chat-bots-webchat-container")) {
      if (window.location.pathname.includes("auto-gestion")) {
        window.CBWebChat.setChatVariable("seccion", "1");
      }
    }
  });

  return (
    <div className="center">
      <InformationBox
        hide={true}
        text={t.constants.ADVERTENCIALOGIN}
        boldText={t.constants.TENPRESENTE}
        icon="info"
        extraCss={css`
          padding: 1% 0;
          margin: ${margin({
            device: device,
            sizeHeight: sizeHeight,
            sizeWidth: sizeWidth,
            deskTopM: 45,
            deskLeftM: 0,
            deskBottomM: 60,
            deskRightM: 0,
            tablTopM: 32,
            tablLeftM: 0,
            tablBottomM: 32,
            tablRightM: 0,
            mobTopM: 0,
            mobLeftM: 0,
            mobBottomM: 0,
            mobRightM: 0,
          })};
          .svgContainer {
            margin: ${margin({
              device: device,
              sizeHeight: sizeHeight,
              sizeWidth: sizeWidth,
              deskTopM: 2,
              deskLeftM: 2,
              deskBottomM: 0,
              deskRightM: 0,
              tablTopM: 2,
              tablLeftM: 16,
              tablBottomM: 0,
              tablRightM: 0,
              mobTopM: 2,
              mobLeftM: 8,
              mobBottomM: 0,
              mobRightM: 0,
            })};
          }
        `}
      />

      <IdForm
        quitLink={<QuitRegistration analyticsClassName={"leave1"} />}
        phoneRegister={false}
        navigationPath={"/auto-gestion/inscripcion-digital"}
        center={""}
      />
    </div>
  );
};

export default SelfRegistrationLogin;
