import { Col, Form } from "antd";
import type { Rule } from "antd/lib/form";
import NovaventaRadio from "~components/formControls/radio/NovaventaRadio";

type Option = {
  label: string;
  id: boolean;
};

type InputRadioProps = {
  name: string;
  value: boolean | undefined;
  label: string;
  onChange: any;
  rules: Rule[];
  defaultValue?: boolean;
  options: Option[];
};

export const InputRadio = ({
  name,
  value,
  label,
  onChange,
  options,
  rules,
}: InputRadioProps) => {
  return (
    <Col span={10} xs={20} md={10}>
      <Form.Item name={name} label={label} hasFeedback rules={rules}>
        <NovaventaRadio options={options} value={value} onChange={onChange} />
      </Form.Item>
    </Col>
  );
};
