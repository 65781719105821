import axios from "axios";
const baseURL: any = process.env.API_DEVELOP;

export function validarContadoCredito(cedula: string) {
  return axios
    .get(`${baseURL}/validar/${cedula}/contado-credito`)
    .then((res) => {
      return res.data;
    })
    .catch((res) => res);
}

export function validarCupoDemanda(data: any) {
  return axios
    .post(`${baseURL}/comandos/validar/cupo-demanda`, data)
    .then((res) => {
      return res.data;
    })
    .catch((res) => res);
}

export function getDatosContadoCredito(cedula: string) {
  return axios
    .get(`${baseURL}/recuperar/${cedula}/contado-credito`)
    .then((res) => {
      return res.data;
    })
    .catch((res) => res);
}

export const procesarContadoCredito = (datosUsuario: any) => {
  return axios
    .post(`${baseURL}/comandos/iniciar/contado-credito`, datosUsuario)
    .then((resp) => {
      return resp.data;
    })
    .catch((resp) => console.log("Error Procesar Contado Credito catch:", resp));
};
