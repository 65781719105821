import React from "react";

export function IconCol(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.34em"
      height="1em"
      viewBox="0 0 32 24"
      {...props}
    >
      <defs>
        <path id="flagpackCo0" fill="#fff" d="M0 0h32v24H0z"></path>
      </defs>
      <g fill="none">
        <g clipPath="url(#flagpackCo2)">
          <use href="#flagpackCo0"></use>
          <path
            fill="#2E42A5"
            fillRule="evenodd"
            d="M0 0v24h32V0H0Z"
            clipRule="evenodd"
          ></path>
          <mask
            id="flagpackCo1"
            width="32"
            height="24"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
            //style="mask-type:luminance"
          >
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M0 0v24h32V0H0Z"
              clipRule="evenodd"
            ></path>
          </mask>
          <g fillRule="evenodd" clipRule="evenodd" mask="url(#flagpackCo1)">
            <path fill="#FECA00" d="M0 0v12h32V0H0Z"></path>
            <path fill="#E31D1C" d="M0 18v6h32v-6H0Z"></path>
          </g>
        </g>
        <defs>
          <clipPath id="flagpackCo2">
            <use href="#flagpackCo0"></use>
          </clipPath>
        </defs>
      </g>
    </svg>
  );
}
