import { Col, Form, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import SectionTitle from "~components/SectionTitle";
import NovaventaInput from "~components/formControls/input/NovaventaInput";
import NovaventaSelect from "~components/formControls/select/NovaventaSelect";
import {
  refByMD,
  refByXS,
  refByXXL,
  rowGutter,
  rowTitleGutter,
} from "~components/forms/formItemsLayout";
import { validateDocumentNumber } from "~components/forms/validations/formValidations";
import { usePrismic } from "~hooks/usePrismic";
import { fetchPrismicDataHowDidYouKnowNova } from "~services/prismicService";
import { sendReferredIdNumber } from "~services/selfRegistrationServices";
import { useFormContext } from "~store/FormContext";

const ReferredByForm = () => {
  const t = usePrismic();
  const [referredByOptions, setReferredByOptions] = useState<
    Array<{ id: number; valor: string }>
  >([]);
  const [whenShowInputId, setWhenShowInputId] = useState<string>("");
  const [showInputId, setShowInputId] = useState<boolean>(false);
  const _isMounted = useRef(true);
  const { updateFormValues } = useFormContext();

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchPrismicDataHowDidYouKnowNova()
      .then((response: any) => {
        if (_isMounted.current) {
          setWhenShowInputId(response["cuando-cedula"][0]["text"]);
          const data: Array<{ id: number; valor: string }> = [];
          response[
            location.pathname.replace("/inscripcion-digital", "").replace("/", "")
          ].filter((obj: any, index: number) => {
            data.push({ id: index, valor: obj.text });
          });
          setReferredByOptions(data);
        }
      })
      .catch((error: any) => console.error(error));
  }, []);

  const validateReferred = async (_rule: any, value: string) => {
    await validateDocumentNumber(value, t.home.rules);
    const { data } = await sendReferredIdNumber(value).catch(() => {
      throw Error(t.errors.unexpectedError);
    });
    if (!data?.continuar) return Promise.reject(t.home.rules.cannotRefer);
    return Promise.resolve();
  };

  const onIdTypeChange1 = (id: any) => {
    if (id && whenShowInputId === referredByOptions[id].valor) {
      setShowInputId(true);
    } else {
      updateFormValues({ key: "complemento", val: "" });
      setShowInputId(false);
    }
  };

  return (
    <div data-testid="ReferredByForm">
      <Row gutter={rowTitleGutter} justify="start" align="middle">
        <Col span={24}>
          <SectionTitle
            lightText={t.constants.COMOCONOCISTE}
            boldText={t.constants.NOVAVENTA2}
          />
        </Col>
      </Row>

      <Row gutter={rowGutter} justify="start" align="middle">
        <Col xs={refByXS} md={refByMD} lg={11} xxl={refByXXL}>
          <Form.Item
            name="idRelacion"
            label={t.constants.PORCUALMEDIO}
            rules={[
              {
                required: true,
                message: t.home.rules.idRelation,
              },
            ]}
          >
            <NovaventaSelect
              options={referredByOptions}
              name="idRelacion"
              placeholder={t.constants.SELECCIONAR}
              onChange={onIdTypeChange1}
              optionsProp={{ label: "valor", id: "id" }}
            />
          </Form.Item>
          {showInputId ? (
            <Form.Item
              name="complemento"
              label={t.constants.NUMERODOCUMENTOMAMAEMP}
              rules={[
                {
                  validator: validateReferred,
                },
              ]}
            >
              <NovaventaInput
                id="idMother"
                placeholder={t.home.placeholders.reference}
                showiconcheck={"false"}
              />
            </Form.Item>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default ReferredByForm;
