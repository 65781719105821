import React from "react";

export function IconMex(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.34em"
      height="1em"
      viewBox="0 0 32 24"
      {...props}
    >
      <defs>
        <path id="flagpackMx0" fill="#fff" d="M0 0h32v24H0z"></path>
      </defs>
      <g fill="none">
        <g clipPath="url(#flagpackMx1)">
          <use href="#flagpackMx0"></use>
          <path
            fill="#D9071E"
            fillRule="evenodd"
            d="M22 0h10v24H22V0Z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#006923"
            fillRule="evenodd"
            d="M0 0h12v24H0V0Z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M10 0h12v24H10V0Z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#A8AC71"
            fillRule="evenodd"
            d="M13.056 8.342c-.107.455-.038.562.476.562c.027 0 .07.076.115.158c.05.09.106.188.15.203c-.019.025-.214.378-.215 1c0 .128.059.34.116.542c.063.22.122.431.099.514c-.038.136-.208-.012-.34-.126c-.075-.066-.138-.12-.156-.105c-.094.08-.143-.547-.172-.925c-.013-.169-.022-.288-.03-.274a.968.968 0 0 1-.06-.249c-.028-.191-.056-.377-.282-.377h-.58v.427s.336 0 .336.795c.005.118.162 1.163.543 1.169c-.223.005-.222.05-.22.075v.005c0 .64.534.708 1.033.528c.3-.109.618-.32.857-.581c.336-.369.378-.845-.064-1.107c-.157-.093-.328-.129-.567-.142c.047-.14.003-.265-.032-.363c-.03-.082-.052-.144-.008-.18c.501-.406.546-.78.181-1.116a1.311 1.311 0 0 1-.14-.163c-.088-.115-.182-.236-.3-.27c.055-.11.128-.242.22-.395l-.857-.515c-.156.259-.13.461-.107.638c.012.097.024.186.004.272Zm.932 2.666l-.016.017l.01-.01a.048.048 0 0 1 .006-.007Zm-.454.313h.002l.01-.002l-.01.002h-.002Zm-.435.334a1.8 1.8 0 0 0-.034 0h.034Z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#FCCA3D"
            fillRule="evenodd"
            d="M14.384 7.913s-.687.533-.558 1.1c.128.569 1.482 0 1.318-.55c-.164-.55-.76-.55-.76-.55Z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#8F4620"
            fillRule="evenodd"
            d="M19.266 12.403s.925-2.741-.545-4.16c-1.47-1.42-3.17-1.325-3.17-1.325s-.489.364 0 .641c.49.277.285.543.285.543s-.82-.832-1.364-.346c-.543.487.508.413.397.779c-.111.366-.583 1.99.106 2.811c.69.82-.652.643-.379.643s1.24.19 1.24 0c0-.192.336.742.624.742c.287 0 .51-.328.51-.328s.403.328.649.328c.245 0 1.102-.215 1.102-.215l-1.62-1.364s.098-.602-.131-.732c-.23-.13 1.841 1.154 2.004 1.569c.162.414.292.414.292.414Z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#9FAB2F"
            d="M11.066 11.727s.15-.479.311-.512c.14-.029.432.23.432.23c.52 2.889 1.779 4.018 3.866 4.018c2.112 0 3.392-.858 4.139-3.523c0 0 .397-.406.537-.36c.152.048.343.585.343.585c-.857 3.058-2.476 4.419-5.02 4.419c-2.567 0-4.01-1.54-4.608-4.857Z"
          ></path>
          <path
            fill="#2FC2DC"
            fillRule="evenodd"
            d="M13.334 13.656s3.005.638 4.19.638c1.186 0-1.04.968-1.909.968c-.38 0-.838-.23-1.374-.692c-.096-.082-.003-.267-.296-.27c-.408-.003-.915-.099-.866-.157c.046-.055.64-.072.583-.133c-.095-.1-.328-.354-.328-.354Z"
            clipRule="evenodd"
          ></path>
          <rect
            width="2"
            height="1.071"
            x="14.667"
            y="14.1"
            fill="#F9AA51"
            rx=".167"
          ></rect>
          <path
            fill="#259485"
            d="M12.175 12.136s0-.375.254-.574c.291-.229.565 0 .565 0c1.066 1.518 2.659 2.018 4.887 1.496c0 0 .666.218.7.361c.042.182-.472.613-.472.613c-2.605.61-4.619-.022-5.934-1.896Z"
          ></path>
          <path
            fill="#FCCA3D"
            fillRule="evenodd"
            d="M16.591 12.35s-1.213.64-.809.64c.405 0 1.988.319 1.68 0c-.308-.32-.87-.64-.87-.64Zm-1.435-.762s-.23-.666-.71-.666s-.345.547-.575.547c-.231 0 .215.384.413.384c.199 0 .872-.265.872-.265Z"
            clipRule="evenodd"
          ></path>
        </g>
        <defs>
          <clipPath id="flagpackMx1">
            <use href="#flagpackMx0"></use>
          </clipPath>
        </defs>
      </g>
    </svg>
  );
}

export default IconMex;
