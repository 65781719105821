import { css } from "@emotion/core";
import { RouteComponentProps } from "@reach/router";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import bannerDesktop from "~assets/img/banner-5-desktop.png";
import bannerMobile from "~assets/img/banner-5-mobile.png";
import Estrellas from "~assets/img/estrellas.svg";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import H1Novaventa from "~components/header/H1Novaventa";
import { usePrismic } from "~hooks/usePrismic";
import novaventaTheme from "~styles/theme";
import { PxToVx } from "~utils/hooks/VH&VWConvert";
import { fontSizeClamp } from "~utils/hooks/useBreakPointTools";
import useImageSize from "~utils/hooks/useImageSize";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";
import useWindowSize from "~utils/hooks/useWindowSize";
import { IncreaseCapacityCss } from "./CreditOnDemandCss";

const IncreaseCapacity = (props: RouteComponentProps | any) => {
  const t = usePrismic();
  const { cupo = "000000", redirect } = props;
  const { width, height } = useWindowSize();
  const [refresh, setRefresh] = useState<boolean>();
  const { imgSizeHeight, imgSizeWidth } = useImageSize(
    "imgWelcome",
    height,
    width,
    refresh
  );
  const { device, sizeWidth } = useWindowBreakPoints();
  const [sizeColHeight, setSizeColHeight] = useState<number>();
  const typeOfDivice = device === "desktop" ? "position:absolute" : "";

  const resolveCondition = (
    condition: boolean,
    trueCondition: any,
    falseCondition: any
  ) => (condition ? trueCondition : falseCondition);

  const formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
  useEffect(() => {
    if (device === "desktop" && height && imgSizeHeight && height - 190 < imgSizeHeight) {
      setSizeColHeight(height - 190);
    } else {
      setSizeColHeight(imgSizeHeight);
    }
  }, [device, height, imgSizeHeight]);
  useEffect(() => {
    if (props.path === "bienvenida") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function () {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  }, [props.path]);
  return (
    <div css={IncreaseCapacityCss} className="center" id="welcomeSection">
      {width ? (
        <Row gutter={[8, 8]} justify="space-between" align="stretch">
          <Col
            id="colW"
            xs={24}
            sm={24}
            md={24}
            lg={7}
            css={css`
              height: ${sizeColHeight}px;
              img {
                ${typeOfDivice}
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                z-index: 0;
              }
              div {
                background: ${novaventaTheme.colors.blue400} 0% 0% no-repeat padding-box;
                mix-blend-mode: multiply;
                position: absolute;
                opacity: 0.1;
                width: ${imgSizeWidth}px;
                height: ${imgSizeHeight}px;
                z-index: 1;
              }
            `}
          >
            <div></div>
            <img
              id="imgWelcome"
              data-testid="imgWelcome"
              src={resolveCondition(
                width >= novaventaTheme.breakPoints.desktop,
                bannerDesktop,
                bannerMobile
              )}
              width="100%"
              height="300"
              onLoad={() => setRefresh(!refresh)}
            ></img>
          </Col>

          <Col xs={24} sm={24} md={24} lg={16}>
            <Estrellas
              css={css`
                position: absolute;
                display: inline-table;
                width: 100vw;
                float: right;
                height: auto;
                opacity: 0.5;
                left: 10%;
                ${resolveCondition(
                  device === "desktop",
                  "transform: translate(10%)scale(1.3);",
                  ""
                )}
              `}
            />
            <H1Novaventa
              sizeWidth={sizeWidth}
              color={true}
              showBack={false}
              text={t.constants.wasExpanded.split(">|<")[0]}
              boldText={t.constants.wasExpanded.split(">|<")[1]}
              boldFirst={false}
              extraCss={css`
                display: table;
                margin: 130px auto 25px;
                position: relative;
                top: -18%;
                width: 100%;
                right: -23%;
                left: 25%;
                h1 {
                  text-align: center;
                  font-size: ${fontSizeClamp(device, sizeWidth, 28, 28, 28, 20)};
                }
              `}
            />
            <hr></hr>
            <div
              css={css`${"text-align: center; margin: 0 auto;"}
                  ${resolveCondition(
                    device === "desktop",
                    " max-width: 560px;",
                    "max-width: 90vw;"
                  )}
                  ${resolveCondition(device === "desktop", "min-width:420px;", "")}
                    p {
                    text-align: center;
                    color: #616C95;
                    margin-bottom: 60px;
                    font-size: ${fontSizeClamp(device, sizeWidth, 20, 20, 16, 14)};
                    font-family: ${novaventaTheme.fonts.ProximaNovaLight};
                  }
                `}
            >
              <p>
                {t.constants.youHaveQuota.split("${0}")[0]}
                <b>{formatter.format(cupo)}</b>
                {t.constants.youHaveQuota.split("${0}")[1]}
              </p>
            </div>
            <NovaventaButton
              handleClick={() => redirect()}
              htmlType="submit"
              btnText={t.constants.INICIOSESIONOFICINA}
              extracss={css`
                display: table;
                width: clamp(
                  328px,
                  ${PxToVx(435, novaventaTheme.breakPoints.maxDesktopWith, "vw")},
                  435px
                );
                height: ${resolveCondition(
                  device === "desktop",
                  `clamp(
                  44px,
                  ${PxToVx(63, novaventaTheme.breakPoints.maxDesktopHeight, "vh")},
                  63px
                )`,
                  "44px"
                )};
                margin: 0 auto ${resolveCondition(device === "desktop", "0", "9vh")} auto;
              `}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};
export default IncreaseCapacity;
