import React, { useEffect, useState } from "react";
import type { RouteComponentProps } from "@reach/router";
import { emailBlackListCss } from "./EmailBlackListCss";
import { getCallList } from "~services/callsServices";
import { css } from "@emotion/core";
import { usePrismic } from "~hooks/usePrismic";
import CallsForm from "~components/forms/CallsForm";
import H1Novaventa from "~components/header/H1Novaventa";
import moment from "moment";

interface IEmailBlacklist {
  id: string;
  nombreCompleto: string;
  numeroContacto: string;
  observaciones?: string;
  fechaLlamada?: string;
  llamadaRealizada?: boolean;
}

const Calls = (_props: RouteComponentProps) => {
  const t = usePrismic();
  const [dataSource, setDataSource] = useState<IEmailBlacklist[]>([]);
  const [data, setData] = useState<IEmailBlacklist>();
  const [refresh, setRefresh] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showTable, setShowTable] = useState(true);

  useEffect(() => {
    setShowForm(false);
    getCallList()
      .then(({ data }) => setDataSource(data))
      .catch(console.error);
  }, [refresh]);

  const handleUpdate = (dataHandleUpdate: IEmailBlacklist) => {
    setShowForm(true);
    setShowTable(false);
    setData(dataHandleUpdate);
  };

  const handleClick = () => {
    setShowForm(false);
    setShowTable(true);
  };

  return (
    <React.Fragment>
      <CallsForm
        data-testid="callsForm"
        refresh={(e: boolean) => setRefresh(e)}
        hidden={!showForm}
        handleClick={() => handleClick()}
        showForm={() => setShowForm(false)}
        showTable={() => setShowTable(true)}
        data={data}
      />
      <div data-testid="classList" hidden={!showTable} css={emailBlackListCss}>
        <H1Novaventa
          text={t.constants.LISTA}
          boldText={t.constants.LLAMADAS}
          boldFirst={false}
          extraCss={css`
            margin-bottom: 2vh;
          `}
          showBack={false}
        />
        <table>
          <thead>
            <tr>
              {t.home.labels.call.map((h, i) => (
                <th key={i}>{h}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataSource.map((lowerData, i) => {
              return (
                <tr key={lowerData.id}>
                  <td>{lowerData.id}</td>
                  <td>{lowerData.nombreCompleto}</td>
                  <td>{lowerData.numeroContacto}</td>
                  <td>{lowerData.observaciones}</td>
                  <td>
                    {lowerData.fechaLlamada &&
                      moment(lowerData.fechaLlamada).format("YYYY-MM-DD HH:mm")}
                  </td>
                  <td>
                    {lowerData.llamadaRealizada ? t.constants.yes : t.constants.not}
                  </td>
                  <td>
                    <a
                      data-testid={`btnDeleteCallList${i}`}
                      onClick={() => handleUpdate(lowerData)}
                    >
                      {t.constants.ACTUALIZAR}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default Calls;
