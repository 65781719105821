import React, { useState, useEffect } from "react";
import { Col, Row, List } from "antd";
import IdIcon from "~components/uploadImage/IdIcon";
import IconCheck from "~components/icons/IconCheck";
import novaventaTheme from "~styles/theme";
import { css } from "@emotion/core";
import { usePrismic } from "~hooks/usePrismic";

type Help = { title: string };

const UploadImgHelpModalContent = ({ type }: any) => {
  const [modalData, setModalData] = useState<Help[]>([]);
  const t = usePrismic();
  useEffect(() => {
    if (type === "front") {
      setModalData(t.options.helpTexts.front);
    } else if (type === "back") {
      setModalData(t.options.helpTexts.back);
    }
  }, [type]);
  return (
    <Row gutter={[16, 16]} justify="space-around" align="middle">
      <Col xs={{ span: 24, push: 0 }} md={{ span: 6, push: 1 }} lg={{ span: 6, push: 1 }}>
        <IdIcon type={type} />
      </Col>
      <Col
        xs={{ span: 24, pull: 0 }}
        md={{ span: 13, pull: 1 }}
        lg={{ span: 13, pull: 1 }}
      >
        <List
          css={css`
            font-family: ${novaventaTheme.fonts.ProximaNova};
            .ant-list-header {
              font-weight: bolder;
              border: none;
            }
            .ant-list-item {
              border: none;
              min-width: 100%;
            }
          `}
          dataSource={modalData}
          bordered={false}
          header={"Recomendaciones"}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <IconCheck
                    customStyle={{
                      backgroundColor: novaventaTheme.colors.blue50,
                      color: novaventaTheme.colors.blue400,
                    }}
                  />
                }
                title={item.title}
              />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default UploadImgHelpModalContent;
