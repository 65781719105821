import { datadogRum } from "@datadog/browser-rum";
import type { RouteComponentProps } from "@reach/router";
import { useParams } from "@reach/router";
import { Form } from "antd";
import RetakesDesktop from "./RetakesDesktop";

const RetakesCredit = (_props: RouteComponentProps) => {
  const { base64 } = useParams();
  datadogRum.setUser({
    id: base64,
    funcionario: "user",
    prospecto: atob(base64),
    canal: "contado-credito",
  });
  const onFinish = () => {
    console.log("FINIS");
  };
  return (
    <Form
      autoComplete="off"
      name="idForm"
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <RetakesDesktop base64={base64} />
    </Form>
  );
};

export default RetakesCredit;
