import novaventaColors from "./colors";
import novaventaFonts from "./fonts";
import novaventabreakPoints from "./breakPoints";
import novaventaVariables from "./variables";

const novaventaTheme = {
  colors: novaventaColors,
  fonts: novaventaFonts,
  breakPoints: novaventabreakPoints,
  vars: novaventaVariables,
};

export default novaventaTheme;
