import React from "react";
import { Col } from "antd";
import "react-dropzone-uploader/dist/styles.css";
import StatusBox from "~components/uploadImage/DropzoneImage/StatusBox";
import RemoveCancelBtn from "~components/uploadImage/DropzoneImage/RemoveCancelBtn";
import { device } from "~utils/NovaventaMedia";
import { css } from "@emotion/core";

const UploadContainer = ({ statusBox, removeCancelBtn, analyticsClassName }: any) => {
  const deviceType = device();
  const statusBoxAlignment = deviceType === "mobile" ? "100%" : "150px";
  const removeCancelBtnAlignment = deviceType === "mobile" ? "100%" : "0 1 auto";

  return (
    <React.Fragment>
      <Col
        data-testid="upload-col-event"
        css={css`
          display: inline-flex;
          flex: ${removeCancelBtnAlignment};
        `}
        xs={{ order: 1 }}
        md={{ order: 2 }}
        lg={{ order: 2 }}
        onClick={(e) => {
          const htmlElement = e.target as HTMLElement;
          const deleteBtn =
            htmlElement.classList.value.includes("remove") ||
            htmlElement.tagName == "svg" ||
            htmlElement.tagName == "path";
          if (htmlElement.id !== "txtAdjuntar" && !deleteBtn) {
            const labelAdjuntar = window.document.querySelector(
              "#txtAdjuntar"
            ) as HTMLElement;
            if (labelAdjuntar) {
              labelAdjuntar.click();
            }
          }
        }}
      >
        <RemoveCancelBtn
          children={removeCancelBtn.el}
          handleClick={removeCancelBtn.fn}
          analyticsClassName={analyticsClassName}
        />
      </Col>
      <Col
        css={css`
          display: inline-flex;
          flex: ${statusBoxAlignment};
        `}
        xs={{ order: 2 }}
        md={{ order: 1 }}
        lg={{ order: 1 }}
      >
        <StatusBox>{statusBox}</StatusBox>
      </Col>
    </React.Fragment>
  );
};

export default UploadContainer;
