import { Col, Form, Row } from "antd";
import React, { useState } from "react";
import SectionTitle from "~components/SectionTitle";
import NovaventaRadio from "~components/formControls/radio/NovaventaRadio";
import { rowTitleGutter } from "~components/forms/formItemsLayout";
import { usePrismic } from "~hooks/usePrismic";

const DeliveryAddressOptions = (props: any) => {
  const { isSameAddress } = props;
  const t = usePrismic();
  const [deliveryAddress, setDeliveryAddress] = useState("0");

  const onDeliveryAddressChange = (e: any) => {
    setDeliveryAddress(e.target.value);
    isSameAddress(e);
  };

  return (
    <React.Fragment>
      <Row gutter={rowTitleGutter} justify="start" align="middle">
        <Col flex={"100%"}>
          <SectionTitle
            lightText={t.constants.DONDERECIBIRAS}
            boldText={t.constants.TUPEDIDO}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Item
            name="deliveryAddress"
            rules={[{ required: false, message: t.home.rules.required }]}
          >
            <NovaventaRadio
              optionsProps={{ label: "label", id: "id" }}
              options={t.options.deliveryAddress}
              onChange={onDeliveryAddressChange}
              value={deliveryAddress}
            />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DeliveryAddressOptions;
