import { css } from "@emotion/core";

export const DesktopDigitalRegistrationCss = css`
  width: 100vw;
  min-height: calc(100vh - 86px);
  height: 100%;
  position: relative;
  background-color: #f0f3f8;
`;

export const DesktopDigInscriptionContentCss = css`
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  overflow-x: hidden;
`;

export const marginLeftSectionCss = css`
  @media (min-width: 52.125em) {
    margin-left: 300px;
  }

  @media (min-width: 85.375em) {
    margin-left: 350px;
  }
  @media (min-width: 90em) {
    margin-left: 500px;
  }
`;

export const sectionCss = css`
  width: auto;
  height: 100%;
  section {
    padding: 0 64px;
  }
`;

export const whiteSectionCss = css`
  ${sectionCss};
  padding: 10px 0;
  background-color: white;
`;

export const sidebarCss = css`
  background-color: transparent;
  border-right: solid 1px #d1d9e4;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  overflow: hidden;
`;

export const sidebarContentCss = css`
  height: calc(100vh - 86px);
  display: grid;
  align-items: center;
  align-content: center;

  @media (min-width: 52.125em) {
    width: 300px;
  }

  @media (min-width: 85.375em) {
    width: 350px;
  }

  @media (min-width: 90em) {
    width: 500px;
  }
  div {
    margin: 5% 4%;
  }
`;
