import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import NovaventaButtonCss from "./NovaventaButtonCss";
import { usePrismic } from "~hooks/usePrismic";
import useWindowBreakPoints from "~utils/hooks/useWindowsBreakPoints";

const NovaventaButton = (props: any) => {
  const t = usePrismic();
  const {
    extracss = {},
    htmlType = "button",
    showArrow = false,
    btnText = t.constants.CONTINUAR,
    handleClick,
    readOnly = false,
    disabled,
  } = props;
  const { device, sizeWidth, sizeHeight } = useWindowBreakPoints();

  return (
    <NovaventaButtonCss
      disabled={disabled}
      type="primary"
      htmlType={htmlType}
      extracss={extracss}
      data-testid="nova-btn"
      id="nova-btn"
      onClick={handleClick}
      device={device}
      sizewidth={sizeWidth}
      sizeheight={sizeHeight}
      readOnly={readOnly}
    >
      {btnText}
      {showArrow && <FontAwesomeIcon icon={faArrowRight} />}
    </NovaventaButtonCss>
  );
};

export default NovaventaButton;
