import { Col, Form, Row } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import NovaventaInput from "~components/formControls/input/NovaventaInput";
import NovaventaSelect from "~components/formControls/select/NovaventaSelect";
import { rowGutter } from "~components/forms/formItemsLayout";
import { validateDocumentNumber } from "~components/forms/validations/formValidations";
import { usePrismic } from "~hooks/usePrismic";
import { getDocumentType } from "~services/selfRegistrationServices";

const IdFormItems = ({ isProspectCustomer = null, isdisabled }: any) => {
  const t = usePrismic();
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    getDocumentType(source)
      .then(({ data }) => setDocumentTypeOptions(data))
      .catch(console.error);

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <React.Fragment>
      <Row gutter={rowGutter} justify="start" align="top">
        <Col flex={"460px"}>
          <Form.Item
            label={
              isProspectCustomer
                ? t.constants.TIPODOCUMENTOPROSPECTO
                : t.constants.TIPODOCUMENTO
            }
          >
            <Form.Item
              name="idTipoDocumento"
              rules={[{ required: true, message: t.home.rules.required }]}
              noStyle
            >
              <NovaventaSelect
                isdisabled={isdisabled && isdisabled.isdisabled}
                options={documentTypeOptions}
                placeholder={t.constants.TIPODOCUMENTO2}
                optionsProp={{ label: "nombre", id: "id" }}
              />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        hasFeedback
        label={
          isProspectCustomer
            ? t.constants.NUMERODOCUMENTOPROSPECTO
            : t.constants.NUMERODOCUMENTO
        }
      >
        <Form.Item
          name="numeroDocumento"
          rules={[
            {
              validator: (_rule: any, value: string) => {
                return validateDocumentNumber(value, t.home.rules);
              },
            },
          ]}
          noStyle
        >
          <NovaventaInput
            disabled={isdisabled && isdisabled.isdisabled}
            placeholder={t.constants.NUMERODOCUMENTO2}
          />
        </Form.Item>
      </Form.Item>
    </React.Fragment>
  );
};

export default IdFormItems;
