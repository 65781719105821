import React from "react";
import { css } from "@emotion/core";
import LeftArrow from "~assets/icons/flecha-izq.svg";

const backArrowCss = css`
  display: inline-block;
  padding: 2% 0;

  :hover {
    cursor: pointer;
  }

  svg {
    margin-right: 20px;
    width: 30px;
  }
`;

const BackArrow = () => {
  const onGoBack = () => {
    window.history.back();
  };

  return (
    <div css={backArrowCss} onClick={onGoBack} className="analyticsClassName">
      <LeftArrow />
    </div>
  );
};

export default BackArrow;
