import { css } from "@emotion/core";
import { Col, Form, Row } from "antd";
import { useEffect, useState } from "react";
import NovaventaButton from "~components/formControls/button/NovaventaButton";
import { usePrismic } from "~hooks/usePrismic";
import { sendInternalListRegister } from "~services/managementServices";
import { getDocumentType } from "~services/selfRegistrationServices";
import { regExp } from "~utils/formRegex";
import { InputRadio } from "./InputRadio";
import { InputSelect } from "./InputSelect";
import { InputText } from "./InputText";
import { InputTextArea } from "./InputTextArea";

const InternalListForm = ({ onBack }: { onBack: () => void }) => {
  const t = usePrismic();
  const [form] = Form.useForm();
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [lockStatus, setLockStatus] = useState<boolean>();

  useEffect(() => {
    getDocumentType()
      .then(({ data }) => setDocumentTypeOptions(data))
      .catch(console.error);
  }, []);
  const handleLockStatus = (ev: any) => {
    setLockStatus(ev.target.value);
  };

  const onFinish = (values: any) => {
    submitInternalListRegistration(values);
  };
  const submitInternalListRegistration = (data: any) => {
    sendInternalListRegister(data)
      .then(() => {
        form.resetFields();
        onBack();
      })
      .catch(console.error);
  };

  return (
    <Form form={form} onFinish={onFinish} name="InternalList" layout="vertical">
      <Row gutter={24} justify="center" align="middle" hidden={false}>
        <InputSelect
          name="modeloNegocio"
          label={t.home.labels.modelBusiness}
          rules={[{ required: true, message: t.home.rules.required }]}
          options={t.options.modelBusiness}
          placeholder={t.constants.MODELONEGOCIO}
        />
        <InputText
          name="nombres"
          label={t.home.labels.name}
          rules={[
            { required: true, message: t.home.rules.required },
            {
              pattern: regExp.alphabetWhiteSpaces,
              message: t.home.rules.invalid,
            },
          ]}
        />
        <InputText
          name="apellidos"
          label={t.constants.APELLIDO}
          rules={[
            { required: true, message: t.home.rules.required },
            {
              pattern: regExp.alphabetWhiteSpaces,
              message: t.home.rules.invalid,
            },
          ]}
        />
        <InputSelect
          name="idTipoDocumento"
          label={t.home.labels.dniType}
          rules={[{ required: true, message: t.home.rules.required }]}
          options={documentTypeOptions}
          placeholder={t.constants.TIPODOCUMENTO2}
        />
        <InputText
          name="numeroIdentidad"
          label={t.home.labels.dniNumber}
          rules={[
            { required: true, message: t.home.rules.required },
            {
              pattern: regExp.alphanumeric,
              message: t.home.rules.dniNumber,
            },
          ]}
        />
        <InputTextArea
          name="motivoBloqueo"
          label={t.home.labels.lockedReason}
          rules={[{ required: true, message: t.home.rules.required }]}
        />

        <Col span={10} xs={20}>
          <InputRadio
            name="estadoBloqueo"
            label={t.home.labels.lockedStatus}
            rules={[
              {
                required: true,
                message: t.home.rules.lockedStatus,
              },
            ]}
            options={t.options.lockedStatus}
            value={lockStatus}
            onChange={handleLockStatus}
          />
        </Col>
        <Col span={10} xs={20}>
          <NovaventaButton
            btnText={t.constants.CANCELAR}
            extracss={css`
              margin: 21px auto 34px auto;
              width: 300px;
              float: right;
            `}
            handleClick={onBack}
          />
          <NovaventaButton
            btnText={t.constants.ENVIAR}
            htmlType="submit"
            extracss={css`
              margin: 21px auto 34px auto;
              width: 300px;
            `}
          />
        </Col>
      </Row>
    </Form>
  );
};
export default InternalListForm;
