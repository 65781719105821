import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import H1Novaventa from "~components/header/H1Novaventa";
import css from "@emotion/css";
import { Button, Row, Col, Tag, Divider } from "antd";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { integrationCss } from "./IntegrationCss";
import { Content } from "antd/lib/layout/layout";
import { validateTransUnion } from "~services/paymentMethodServices";
import { usePrismic } from "~hooks/usePrismic";

const Integrations = (_props: RouteComponentProps) => {
  const [statusTransUnion, setStatusTransUnion] = useState(1);
  const [statusDocument] = useState(1);
  const [buttonTransUnion, setbuttonTransUnion] = useState(false);
  const [buttonDocument] = useState(false);
  const t = usePrismic();

  const handleServiceTransUnion = () => {
    setbuttonTransUnion(true);
    setStatusTransUnion(2);
    validateTransUnion(t.home.userTransUnion)
      .then(() => {
        setbuttonTransUnion(false);
        setStatusTransUnion(4);
      })
      .catch((error) => {
        console.error(error);
        setbuttonTransUnion(false);
        setStatusTransUnion(3);
      });
  };

  const getStatus = (status: number) => {
    let htmlStatus: any = "";
    switch (status) {
      case 1:
        htmlStatus = (
          <Tag icon={<ClockCircleOutlined />} color="default">
            {t.constants.expecting}
          </Tag>
        );
        break;
      case 2:
        htmlStatus = (
          <Tag icon={<SyncOutlined spin />} color="processing">
            {t.constants.processing}
          </Tag>
        );
        break;
      case 3:
        htmlStatus = (
          <Tag icon={<CloseCircleOutlined />} color="error">
            {t.constants.error}
          </Tag>
        );
        break;
      case 4:
        htmlStatus = (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {t.constants.success}
          </Tag>
        );
        break;
    }
    return htmlStatus;
  };

  return (
    <React.Fragment>
      <H1Novaventa
        text={`Provador de`}
        boldText={"Integraciones"}
        boldFirst={false}
        extraCss={css`
          margin-bottom: 2vh;
          margin-left: 3vw;
        `}
        showBack={false}
      />
      <Content style={{ padding: "0 50px" }} css={integrationCss}>
        <Row gutter={24} justify="center" align="middle">
          <Col span={16} xs={20} md={16}>
            <div className="itemService">
              <label>{t.constants.trasnUService}</label>
              <Button
                disabled={buttonTransUnion}
                onClick={() => handleServiceTransUnion()}
              >
                {t.constants.execute}
              </Button>
              {getStatus(statusTransUnion)}
            </div>
            <Divider orientation="left" />
          </Col>
          <Col span={16} xs={20} md={16}>
            <div className="itemService">
              <label>{t.constants.documentService}</label>
              <Button htmlType="button" disabled={buttonDocument}>
                {t.constants.execute}
              </Button>
              {getStatus(statusDocument)}
            </div>
            <Divider orientation="left" />
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};
export default Integrations;
