import { css } from "@emotion/core";
import { RouteComponentProps, useLocation } from "@reach/router";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import TabTitle from "~components/TabTitle";
import { usePrismic } from "~hooks/usePrismic";
import AddressPage from "~pages/addressSection/Address";
import BasicDataPage from "~pages/basicDataSection/BasicData";
import ContactPage from "~pages/contactSection/Contact";
import { useFormContext } from "~store/FormContext";
import novaventaTheme from "~styles/theme";

type Props = RouteComponentProps & {
  registrationType: string;
};

const MobileDigitalRegistration = ({ registrationType }: Props) => {
  const t = usePrismic();
  const [activeTab, setActiveTab]: any = useState(1);
  const [sectionOrder, setSectionOrder] = useState([1, 2, 3]);

  const {
    addressFormObj,
    contactFormObj,
    basicDataFormObj,
    mobileActiveTab,
    updateMobileActiveTab,
  } = useFormContext();

  const { state }: any = useLocation();
  const userInfo = state && state.userInfo;

  useEffect(() => {
    if (mobileActiveTab !== activeTab) {
      setActiveTab(mobileActiveTab);
    }
  }, [mobileActiveTab, activeTab]);

  useEffect(() => {
    if (registrationType === "phoneReg") {
      setSectionOrder([2, 1, 3]);
    } else {
      setSectionOrder([1, 2, 3]);
    }
  }, []);

  const validateForm = (formObj: any, tab: any) => {
    formObj
      .validateFields()
      .then(() => {
        setActiveTab(tab);
        updateMobileActiveTab(tab);
      })
      .catch(console.error);
  };

  const tabNavigation = (tab: any) => {
    if (registrationType === "phoneReg") {
      if (activeTab === 1) {
        validateForm(contactFormObj, tab);
      }
      if (activeTab === 2) {
        validateForm(addressFormObj, tab);
      }
    } else {
      if (activeTab === 1) {
        validateForm(addressFormObj, tab);
      }
      if (activeTab === 2) {
        validateForm(contactFormObj, tab);
      }
    }
    if (activeTab === 3) {
      validateForm(basicDataFormObj, tab);
    }
  };

  return (
    <div
      css={css`
        position: relative;
      `}
      id="nav-mobile"
    >
      {/* ------------------------ TAB HEADER -------------------------------- */}
      <div
        css={css`
          width: 100vw;
          background-color: #f0f3f8;
          background-color: ${novaventaTheme.colors.gray50};
          padding: 16px;
          height: 80px;
        `}
      >
        <Row gutter={24}>
          <Col
            flex={"1"}
            data-testId="step-1"
            order={sectionOrder[0]}
            onClick={() => tabNavigation(sectionOrder[0])}
          >
            <TabTitle
              tabNumber={sectionOrder[0]}
              activeTab={activeTab}
              tabTitleTxt={t.constants.ADDRESS}
            />
          </Col>
          <Col
            flex={"1"}
            data-testId="step-2"
            order={sectionOrder[1]}
            onClick={() => tabNavigation(sectionOrder[1])}
          >
            <TabTitle
              tabNumber={sectionOrder[1]}
              activeTab={activeTab}
              tabTitleTxt={t.constants.CONTACT}
            />
          </Col>
          <Col
            flex={"3"}
            data-testId="step-3"
            order={sectionOrder[2]}
            onClick={() => tabNavigation(sectionOrder[2])}
          >
            <TabTitle
              tabNumber={sectionOrder[2]}
              activeTab={activeTab}
              tabTitleTxt={t.constants.BASICDATA}
            />
          </Col>
        </Row>
      </div>

      {/* ------------------------ TAB CONTENT -------------------------------- */}
      <div
        css={css`
          position: relative;
          max-width: 100vw;
          padding: 0 5%;
          margin: 0 auto;
        `}
      >
        <Row gutter={24}>
          {activeTab === sectionOrder[0] && (
            <Col id="addressTabSection" flex={"100%"} order={sectionOrder[0]}>
              <AddressPage registrationType={registrationType} userInfo={userInfo} />
            </Col>
          )}
          {activeTab === sectionOrder[1] && (
            <Col
              id="contactTabSection"
              flex={"100%"}
              order={sectionOrder[1]}
              css={css`
                display: ${activeTab === sectionOrder[1] ? "block" : "none"};
              `}
            >
              <ContactPage registrationType={registrationType} userInfo={userInfo} />
            </Col>
          )}
          {activeTab === sectionOrder[2] && (
            <Col id="basicDataTabSection" flex={"100%"} order={sectionOrder[2]}>
              <BasicDataPage registrationType={registrationType} userInfo={userInfo} />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default MobileDigitalRegistration;
