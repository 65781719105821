import styled from "@emotion/styled";

export const CardsCss = styled.div`
  background: rgb(0, 146, 255);
  padding: 15px 0px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: Proxima Nova Sbold It;
  :hover {
    opacity: 0.6;
  }
  h3 {
    color: white;
    margin-top: 15px;
  }
`;
