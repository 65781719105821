import { css } from "@emotion/core";
import { useLocation } from "@reach/router";
import { Col, Row } from "antd";
import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import Mouse from "~assets/icons/mouse.svg";
import RequiredFieldsLabel from "~components/RequiredFieldsLabel";
import DesktopSidebar from "~components/digitalRegistration/DesktopSideBar";
import ReferredByForm from "~components/forms/ReferredByForm";
import AddressPage from "~pages/addressSection/Address";
import BasicDataPage from "~pages/basicDataSection/BasicData";
import ContactPage from "~pages/contactSection/Contact";
import {
  DesktopDigInscriptionContentCss,
  DesktopDigitalRegistrationCss,
  marginLeftSectionCss,
  sectionCss,
  whiteSectionCss,
} from "./DesktopDigitalRegistrationCss";

type ScrollSectionProps = {
  refObj: any;
  idNumber: number;
  children: ReactNode;
  scrollToSection: (v: number) => void;
};

const ScrollSection = ({
  refObj,
  idNumber,
  children,
  scrollToSection,
}: ScrollSectionProps) => {
  const sectionStyle = idNumber === 2 ? whiteSectionCss : sectionCss;
  return (
    <div css={sectionStyle}>
      <div css={marginLeftSectionCss}>
        <section id={`section${idNumber}`} ref={refObj}>
          {children}
          {idNumber < 3 && (
            <Row>
              <Col
                offset={12}
                data-testid="scrollToSection"
                onClick={() => scrollToSection(idNumber + 1)}
              >
                <Mouse
                  css={css`
                    width: 20px;
                    margin: 10px;
                  `}
                />
              </Col>
            </Row>
          )}
        </section>
      </div>
    </div>
  );
};

const DesktopDigitalRegistration = ({ registrationType = "", path = "", uri = "" }) => {
  const addressFormRef = useRef(null);
  const contactFormRef = useRef(null);
  const basicDataRef = useRef(null);
  const [activeTab, setActiveTab] = useState("1");
  const [sectionOrder, setSectionOrder] = useState([1, 2, 3]);
  const { state }: any = useLocation();
  const userInfo = state && state.userInfo;

  useEffect(() => {
    if (registrationType === "phoneReg") {
      setSectionOrder([2, 1, 3]);
    } else {
      setSectionOrder([1, 2, 3]);
    }
  }, [registrationType]);

  useEffect(() => {
    if (path === "inscripcion-digital") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", () => {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  }, [path]);

  const scrollContent = (node: any, tab: string, pos = "start") => {
    setActiveTab(tab);
    if (typeof node.scrollIntoView === "function") {
      node.scrollIntoView({
        behavior: "smooth",
        block: pos,
      });
    }
  };

  const scrollToSection = (order: number, pos = "start") => {
    let node;
    if (registrationType === "phoneReg" && order === 1) {
      node = contactFormRef.current;
    } else if (registrationType === "phoneReg" && order === 2) {
      node = addressFormRef.current;
      pos = "center";
    } else if (registrationType !== "phoneReg" && order === 1) {
      node = addressFormRef.current;
    } else if (registrationType !== "phoneReg" && order === 2) {
      node = contactFormRef.current;
      pos = "center";
    } else if (order === 3 || order === 4) {
      node = basicDataRef.current;
    }
    scrollContent(node, String(order), pos);
  };

  return (
    <div css={DesktopDigitalRegistrationCss}>
      <div id="content" css={DesktopDigInscriptionContentCss}>
        <Row gutter={24} justify="center" align="middle">
          <Col flex={"100%"}>
            <div
              css={css`
                ${marginLeftSectionCss};
                padding-left: 2%;
              `}
            >
              <RequiredFieldsLabel />
            </div>
          </Col>

          <Col
            id="addressScrollSection"
            data-testid="addressScrollSection"
            flex={"100%"}
            order={sectionOrder[0]}
          >
            <ScrollSection
              scrollToSection={scrollToSection}
              refObj={addressFormRef}
              idNumber={sectionOrder[0]}
            >
              <AddressPage registrationType={registrationType} userInfo={userInfo} />
            </ScrollSection>
          </Col>

          <Col id="contactScrollSection" flex={"100%"} order={sectionOrder[1]}>
            <ScrollSection
              scrollToSection={scrollToSection}
              refObj={contactFormRef}
              idNumber={sectionOrder[1]}
            >
              <ContactPage
                registrationType={registrationType}
                ReferredByFormComponent={
                  registrationType === "phoneReg" && <ReferredByForm />
                }
                userInfo={userInfo}
              />
            </ScrollSection>
          </Col>

          <Col id="basicDataScrollSection" flex={"100%"} order={sectionOrder[2]}>
            <ScrollSection
              scrollToSection={scrollToSection}
              refObj={basicDataRef}
              idNumber={sectionOrder[2]}
            >
              <BasicDataPage
                registrationType={registrationType}
                userInfo={userInfo}
                uri={uri}
              />
            </ScrollSection>
          </Col>
        </Row>
      </div>

      <DesktopSidebar
        sectionOrder={sectionOrder}
        scrollToSection={scrollToSection}
        activeTab={activeTab}
      />
    </div>
  );
};

export default DesktopDigitalRegistration;
