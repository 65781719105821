import { Col, Form, Row } from "antd";
import React from "react";
import NovaventaInput from "~components/formControls/input/NovaventaInput";
import { lg, md, rowGutter, xs, xxl } from "~components/forms/formItemsLayout";
import { usePrismic } from "~hooks/usePrismic";
import { regExp } from "~utils/formRegex";
import { setObject } from "~utils/hooks/localStorage";

const NameForm = ({ isdisabled }: any) => {
  const t = usePrismic();
  const onNameChange = (e: any) => {
    setObject("name", e.target.value);
  };

  return (
    <React.Fragment>
      <Row gutter={rowGutter} justify="start" align="middle">
        <Col xs={xs} md={md} lg={lg} xxl={xxl}>
          <Form.Item
            name="primerNombre"
            hasFeedback
            label={t.constants.PRIMERNOMBRE}
            rules={[
              { required: true, message: t.home.rules.required },
              {
                pattern: regExp.alphabetWhiteSpaces,
                message: t.home.rules.name,
              },
            ]}
          >
            <NovaventaInput
              disabled={isdisabled && isdisabled.isdisabled}
              onChange={onNameChange}
              showiconcheck="false"
            />
          </Form.Item>
        </Col>

        <Col xs={xs} md={md} lg={lg} xxl={xxl}>
          <Form.Item
            name="segundoNombre"
            hasFeedback
            label={t.home.labels.secondNameOp}
            rules={[
              {
                pattern: regExp.alphabetWhiteSpaces,
                message: t.home.rules.name,
              },
            ]}
          >
            <NovaventaInput
              disabled={isdisabled && isdisabled.isdisabled}
              showiconcheck="false"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter} justify="start" align="middle">
        <Col xs={xs} md={md} lg={lg} xxl={xxl}>
          <Form.Item
            data-testid="primerApellido"
            name="primerApellido"
            hasFeedback
            label={t.constants.PRIMERAPELLIDO}
            rules={[
              { required: true, message: t.home.rules.required },
              {
                pattern: regExp.alphabetWhiteSpaces,
                message: t.home.rules.surname,
              },
            ]}
          >
            <NovaventaInput
              disabled={isdisabled && isdisabled.isdisabled}
              showiconcheck="false"
            />
          </Form.Item>
        </Col>

        <Col xs={xs} md={md} lg={lg} xxl={xxl}>
          <Form.Item
            name="segundoApellido"
            hasFeedback
            label={t.constants.SEGUNDOAPELLIDO}
            rules={[
              {
                pattern: regExp.alphabetWhiteSpaces,
                message: t.home.rules.surname,
              },
            ]}
          >
            <NovaventaInput
              disabled={isdisabled && isdisabled.isdisabled}
              showiconcheck="false"
            />
          </Form.Item>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default NameForm;
