import React from "react";
import { Form } from "antd";
import { usePrismic } from "~hooks/usePrismic";
import NovaventaSelect from "~components/formControls/select/NovaventaSelect";

const PrefixCellPhone2 = () => {
  const t = usePrismic();
  return (
    <Form.Item
      name="prefixCellPhone"
      label={t.constants.SELECCIONAPREFIJOPAIS}
      rules={[{ required: true, message: t.home.rules.required }]}
    >
      <NovaventaSelect
        id="selectPrefijo2"
        variant="country"
        placeholder={t.constants.SELECCIONAR}
        options={t.options.countryPrefixe}
        optionsProp={{ label: "nombre", id: "idValue" }}
      />
    </Form.Item>
  );
};

export default PrefixCellPhone2;
