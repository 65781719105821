import React, { useState } from "react";
import { ContentPhoneCallCss, MainPhoneCallCss } from "./PhoneCallCss";
import FormCall from "./formCall";
import FormOurServiceLines from "./formOurServiceLines";
import H1Novaventa from "~components/header/H1Novaventa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import novaventaColors from "~styles/colors";
import { sendCallRequest } from "~services/digitalRegistrationServices";
import { usePrismic } from "~hooks/usePrismic";

const PhoneCallPage = (_props: any) => {
  const [showItemCall, setShowItemCall] = useState(true);
  const [showOurService, setShowOurService] = useState(false);
  const t = usePrismic();

  const onFinish = (values: any) => {
    if (values["fechaCall"]) {
      const time = values["horaCall"];
      const dateTime = values["fechaCall"].set({
        hour: time.get("hour"),
        minute: time.get("minute"),
        second: time.get("second"),
      });
      values["fechaLlamada"] = dateTime;
    }
    sendCallRequest(values).then((response) => {
      console.log("response", response);
    });
  };

  return (
    <MainPhoneCallCss>
      <ContentPhoneCallCss>
        <H1Novaventa
          boldText={t.home.labels.callTel.split(">|<")[0]}
          text={t.home.labels.callTel.split(">|<")[1]}
          boldFirst={true}
          color={true}
          showBack={true}
          extraCss={`padding-bottom: 40px; max-width: 90vw; margin: 0 auto;`}
        />
        <div className="collapse">
          <div className="collapse-item">
            <div className="collapse-item-header">
              <div className="conten-header">
                <h2>Te llamamos</h2>
                <p>
                  Déjanos tu número telefónico y nos comunicaremos contigo lo antes
                  posible o cuando lo prefieras programando la llamada para ayudarte con
                  el registro.
                </p>
              </div>
              <FontAwesomeIcon
                data-testid="clickicon1"
                icon={faChevronDown}
                color={novaventaColors.blue400}
                size="lg"
                className={showItemCall ? "rotation" : "no-rotation"}
                onClick={() => setShowItemCall(!showItemCall)}
              />
            </div>
            {showItemCall && (
              <div className="collapse-item-content">
                <FormCall onFinish={onFinish} />
              </div>
            )}
          </div>
          <div className="collapse-item">
            <div className="collapse-item-header">
              <div className="conten-header">
                <h2>Llámanos a nuestras líneas de atención</h2>
                <p>
                  Te puedes comunicar con nosotros a nuestras líneas de atención de tu
                  ciudad o a la línea nacional gratuita.
                </p>
              </div>
              <FontAwesomeIcon
                icon={faChevronDown}
                color={novaventaColors.blue400}
                size="lg"
                className={showOurService ? "rotation" : "no-rotation"}
                onClick={() => setShowOurService(!showOurService)}
              />
            </div>
            {showOurService && (
              <div className="collapse-item-content">
                <FormOurServiceLines onFinish={onFinish} />
              </div>
            )}
          </div>
        </div>
        <p className="horarios">
          Nuestro horario de atención: de lunes a viernes de 7:30 a.m. a 10:00 p.m. y
          sábados de 8:00 a.m. a 6:00 p.m
        </p>
      </ContentPhoneCallCss>
    </MainPhoneCallCss>
  );
};

export default PhoneCallPage;
