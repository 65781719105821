import styled from "@emotion/styled";
import { Select } from "antd";

const NovaventaSelectCss = styled(Select)`
  position: relative;
  .ant-select-selector {
    height: 48px !important;
    border-radius: 6px !important;
    min-width: 100%;
    border: ${(props: any) =>
      `0.5px solid ${props.theme.colors.blueGray200Light}`}!important;
    ${(props: any) => props.extracss}

    .ant-select-selection-search {
      margin-top: 10px;
    }
  }

  .ant-select-clear {
    right: 50px !important;
    font-size: 15px;
  }

  .ant-select-arrow {
    top: 18px;
    width: 23.86px;
    height: auto;
  }

  .ant-select-selection-item:after {
    margin-top: 9px;
  }
  .ant-select-selection-placeholder {
    margin-top: 8px;
  }
`;

export default NovaventaSelectCss;
