import { Col, Form } from "antd";
import React from "react";
import NovaventaSelect from "~components/formControls/select/NovaventaSelect";
import type { Rule } from "antd/lib/form";

type InputSelectProps = {
  rules: Rule[];
  label: string;
  name: string;
  options: any[];
  optionsProp?: any;
  placeholder: string;
};

export const InputSelect = ({
  rules,
  label,
  name,
  options,
  optionsProp,
  placeholder,
}: InputSelectProps) => {
  return (
    <Col span={10} xs={20} md={10}>
      <Form.Item label={label}>
        <Form.Item name={name} rules={rules} noStyle>
          <NovaventaSelect
            options={options}
            placeholder={placeholder}
            optionsProp={optionsProp}
          />
        </Form.Item>
      </Form.Item>
    </Col>
  );
};
