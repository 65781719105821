import styled from "@emotion/styled";

export const CardStateCss = styled.div`
  background-color: #345eea;
  height: 80px;
  padding: 1em;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  transition: 0.1s ease-in;
  margin-right: 20px;
  margin-bottom: 10px;
  :hover {
    margin-right: 0;
  }
  h3 {
    color: #fff;
  }
`;

export const TableCss = styled.table`
  width: 100%;
  margin-bottom: 2em;
  border-collapse: collapse;
  th,
  td {
    padding: 5px;
  }
  th {
    font-weight: bold;
    color: #345eea;
    font-size: 1.1rem;
  }
  td {
    text-align: right;
  }
  tr {
    border-bottom: 0.5px solid #a79a9a;
  }
`;
