import React from "react";
import banner2 from "~assets/img/banner2.png";
import banner from "~assets/img/bannerx1366.jpg";
import { css } from "@emotion/core";
const HeaderBanner = (props: any) => {
  const {
    device,
    loginBanner = false,
    urlBanner = loginBanner ? banner2 : banner,
  } = props;
  return (
    <div
      css={css`
        background-image: url(${urlBanner});
        background-position: ${device === "mobile" ? "90%" : "right"};
        background-repeat: no-repeat;
        background-size: cover;
        height: ${device === "mobile" ? "137px" : "25vh"};
      `}
    ></div>
  );
};

export default HeaderBanner;
