import { Col, Row } from "antd";
import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import SectionTitle from "~components/SectionTitle";
import IdBirthdayForm from "~components/forms/IdBirthdayForm";
import NameForm from "~components/forms/NameForm";
import { rowTitleGutter } from "~components/forms/formItemsLayout";
import IdFormItems from "~components/forms/idForm/idFormItems";
import { usePrismic } from "~hooks/usePrismic";

const ConfirmImageDataForm = ({ reloadImage }: any) => {
  const t = usePrismic();
  return (
    <React.Fragment>
      <Row gutter={rowTitleGutter} justify="start" align="middle">
        <Col flex={"100%"}>
          <SectionTitle
            lightText={t.constants.BASICDATA3}
            boldText={t.constants.BASICDATA2}
          />
          <p onClick={() => reloadImage(true)}>{t.constants.reloadDocument}</p>
        </Col>
      </Row>
      <IdFormItems isdisabled={{ isdisabled: true }} />
      <NameForm isdisabled={{ isdisabled: true }} />
      <IdBirthdayForm isdisabled={{ isdisabled: true }} showHelpMessage={false} />
    </React.Fragment>
  );
};

export default ConfirmImageDataForm;
