import React, { useState, useEffect } from "react";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { usePrismic } from "~hooks/usePrismic";
import { sendAddressValidation } from "~services/selfRegistrationServices";
import { useFormContext } from "~store/FormContext";
import { Form, Input } from "antd";
import { formData, formDataBarrio, uuidSolicitud as uuid } from "~store/formStore";
import {
  sendAddressForm,
  sendReferredByForm,
} from "~services/digitalRegistrationServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

const resolveConditionAddressSumary = (
  addressSummary: any,
  addressSumaryTemp: any,
  isInvalidAddress: any
) => {
  if (addressSummary !== "") {
    return addressSummary;
  } else if (addressSumaryTemp && !isInvalidAddress) {
    return "";
  }
  return addressSumaryTemp;
};

let addressSumaryTextTemp = "";

type resolveConditionValidateAddressParam = {
  triggerAddressValidation: any;
  addressSummaryText: any;
  addressFormObj: any;
  deliveryAddressSuffix: any;
  otrosValue: any;
  hasError: any;
  touchedField: any;
  validCity: any;
  validateAddress: any;
};

const resolveConditionValidateAddress = (
  params: resolveConditionValidateAddressParam
) => {
  const {
    triggerAddressValidation,
    addressSummaryText,
    addressFormObj,
    deliveryAddressSuffix,
    otrosValue,
    hasError,
    touchedField,
    validCity,
    validateAddress,
  } = params;

  if (triggerAddressValidation && addressSummaryText) {
    const otrosDireccionValue = addressFormObj.getFieldValue(
      `direccionOtros1${deliveryAddressSuffix}`
    );
    addressFormObj.setFieldsValue({
      [`validatorInput${deliveryAddressSuffix}`]: ".",
    });
    if (!otrosValue) {
      setTimeout(() => {
        if (
          hasError(`numero1${deliveryAddressSuffix}`) &&
          touchedField(`numero1${deliveryAddressSuffix}`) &&
          hasError(`direccion1${deliveryAddressSuffix}`) &&
          touchedField(`direccion1${deliveryAddressSuffix}`) &&
          hasError(`numero2${deliveryAddressSuffix}`) &&
          touchedField(`numero2${deliveryAddressSuffix}`) &&
          hasError(`numero3${deliveryAddressSuffix}`) &&
          touchedField(`numero3${deliveryAddressSuffix}`) &&
          hasError(`tipoVivienda${deliveryAddressSuffix}`) &&
          touchedField(`tipoVivienda${deliveryAddressSuffix}`) &&
          validCity(`idBarrio${deliveryAddressSuffix}`)
        ) {
          validateAddress(addressSummaryText);
        }
      }, 1);
    } else if (otrosDireccionValue) {
      validateAddress(otrosDireccionValue);
    }
  }
};

const thenConsultBarrio = (
  result: any,
  setIsInvalidAddress: any,
  savePartialData: any,
  addressFormObj: any,
  setInvalidAddressMsg: any,
  messages: any[]
) => {
  if (result?.data?.continuar) {
    setIsInvalidAddress(false);
    savePartialData(addressFormObj);
  } else if (result && !result.data.continuar) {
    const mensajeObj = messages.find(({ codigo }) => codigo === result.data.codigo);
    if (mensajeObj) {
      setInvalidAddressMsg(mensajeObj.mensaje);
    }
    setIsInvalidAddress(true);
  }
};

const validateDireccion = (
  mFormData: any,
  uniqueUseEffect: any,
  setUniqueUseEffect: any,
  validateAddress: any
) => {
  if (mFormData.direccion !== "" && uniqueUseEffect == 0) {
    validateAddress(mFormData.direccion);
    addressSumaryTextTemp = mFormData.direccion;
    setUniqueUseEffect(1);
  }
};

const AddressSummary = ({
  addressSummaryText,
  deliveryAddressSummary,
  addressSummaryWithoutComplement,
  deliveryAddressSuffix,
  triggerAddressValidation,
  isRequiredValidate,
}: any) => {
  const t = usePrismic();
  const [isInvalidLengthAddress, setIsInvalidLengthAddress]: any = useState(false);

  const theme: any = useTheme();
  const defaultMsg = t.home.rules.errorAddress;
  const { addressFormObj, formValues }: any = useFormContext();
  const [isInvalidAddress, setIsInvalidAddress]: any = useState(true);
  const [invalidAddressMsg, setInvalidAddressMsg]: any = useState(defaultMsg);
  const [uniqueUseEffect, setUniqueUseEffect]: any = useState(0);

  useEffect(() => {
    const mFormData = formDataBarrio(addressFormObj.getFieldsValue());
    validateDireccion(mFormData, uniqueUseEffect, setUniqueUseEffect, validateAddress);
  });

  useEffect(() => {
    if (addressSummaryWithoutComplement && addressSummaryWithoutComplement.length > 90) {
      setInvalidAddressMsg(t.home.rules.max90Chars);
      setIsInvalidLengthAddress(true);
    } else {
      if (
        addressSummaryWithoutComplement &&
        addressSummaryWithoutComplement.length < 13
      ) {
        setIsInvalidLengthAddress(true);
      } else {
        setIsInvalidLengthAddress(false);
        setInvalidAddressMsg(defaultMsg);
      }

      const path = window.location.href;
      if (path.includes("llamada-experto")) {
        setInvalidAddressMsg(true);
      }
    }
  }, [addressSummaryWithoutComplement]);

  const validateAddress = (value: any) => {
    const mFormData = formDataBarrio(addressFormObj.getFieldsValue());
    const idBarrio =
      formValues.get(`idBarrio${deliveryAddressSuffix}`) !== ""
        ? formValues.get(`idBarrio${deliveryAddressSuffix}`)
        : mFormData.idBarrio;
    if (!isInvalidLengthAddress) {
      sendAddressValidation({
        direccion: value,
        uuidBarrio: idBarrio,
      })
        .then((result: any) => {
          thenConsultBarrio(
            result,
            setIsInvalidAddress,
            savePartialData,
            addressFormObj,
            setInvalidAddressMsg,
            t.options.MENSAJESDIRECCION
          );
        })
        .catch((e) => {
          console.error("Invalid Address:", e.message);
          setIsInvalidAddress(true);
        });
    }
  };
  const resolveConditionTestValidator = (condition: any) =>
    condition ? Promise.reject(t.home.rules.invalidAddress) : Promise.resolve();

  const testValidator = async () => {
    return resolveConditionTestValidator(isInvalidAddress);
  };

  const touchedField = (name: string) => {
    return addressFormObj.isFieldTouched(name);
  };

  const validateAddressAction = () => {
    const hasError = (name: string) => {
      return addressFormObj.getFieldError(name)?.length === 0;
    };
    const validCity = (name: string) => {
      return (
        hasError(name) &&
        addressFormObj.getFieldValue(name) &&
        addressFormObj.getFieldValue(name).length > 0
      );
    };
    const otrosValue = addressFormObj.getFieldValue(`otros${deliveryAddressSuffix}`);
    resolveConditionValidateAddress({
      triggerAddressValidation,
      addressSummaryText,
      addressFormObj,
      deliveryAddressSuffix,
      otrosValue,
      hasError,
      touchedField,
      validCity,
      validateAddress,
    });
  };

  const savePartialData = (value: any) => {
    const { referencia, principal, secundario } = formData(value.getFieldsValue());
    sendReferredByForm({
      uuid: uuid(),
      referencia: referencia,
    });

    if (deliveryAddressSummary) {
      sendAddressForm({
        uuid: uuid(),
        vivienda: { ...secundario, direccion: deliveryAddressSummary },
      });
    } else {
      sendAddressForm({
        uuid: uuid(),
        vivienda: { ...principal, direccion: addressSummaryText },
      });
    }
  };

  return (
    resolveConditionAddressSumary(
      addressSummaryText,
      addressSumaryTextTemp,
      isInvalidAddress
    ) && (
      <React.Fragment>
        <p>
          <div
            data-testid="test-click-div"
            onClick={validateAddressAction}
            css={css`
              width: 235px;
              background-color: #345eea;
              padding-top: 3px;
              border-radius: 3px;
              cursor: pointer;
            `}
          >
            <FontAwesomeIcon
              css={css`
                margin-left: 10px;
                width: 100px;
                color: #fefefe;
                content: "Prueba ";
              `}
              icon={faSync}
            />
            {t.constants.VALIDAR_DIRECCION}
          </div>
          {t.constants.DIRECCIONINGRESADA}
        </p>
        <p
          data-testid="test-address-text"
          css={css`
            font-family: ${theme.fonts.ProximaNovaXbold};
          `}
        >
          {resolveConditionAddressSumary(
            addressSummaryText,
            addressSumaryTextTemp,
            isInvalidAddress
          )}
        </p>
        {isInvalidAddress && (
          <p
            data-testid="test-address-complement"
            css={css`
              color: #ff4d4f;
              font-size: 14px;
              line-height: 1.5715;
            `}
          >
            {invalidAddressMsg}
          </p>
        )}
        {isRequiredValidate && (
          <Form.Item
            hasFeedback
            name={`validatorInput${deliveryAddressSuffix}`}
            hidden
            rules={[
              {
                required: isRequiredValidate,
                message: t.home.rules.required,
              },
              {
                validator: testValidator,
              },
            ]}
          >
            <Input disabled={true}></Input>
          </Form.Item>
        )}
      </React.Fragment>
    )
  );
};

export default AddressSummary;
