import { ExclamationCircleTwoTone, PlusCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/core";
import { RouteComponentProps } from "@reach/router";
import { Button, Popconfirm } from "antd";
import { useTheme } from "emotion-theming";
import React, { useEffect, useState } from "react";
import EmailBlackListForm from "~components/forms/EmailBlackListForm";
import { usePrismic } from "~hooks/usePrismic";
import { deleteBlackListRegister, getBlackList } from "~services/blackListServices";
import { emailBlackListCss } from "./EmailBlackListCss";

interface IEmailBlackItem {
  id: string;
  clave: string;
  valor: string;
}

const EmailBlacklist = (_props: RouteComponentProps) => {
  const t = usePrismic();
  const [dataSource, setDataSource] = useState<IEmailBlackItem[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const theme: any = useTheme();

  useEffect(() => {
    setShowForm(false);
    getBlackList()
      .then(({ data }) => setDataSource(data))
      .catch(console.error);
  }, []);

  const handleDelete = (data: IEmailBlackItem) => {
    deleteBlackListRegister(data.id)
      .then(() => {
        setDataSource((s) => s.filter((i) => i.id !== data.id));
      })
      .catch(console.error);
  };

  const handleAdd = () => {
    setShowForm(true);
    setShowTable(false);
  };

  const handleClick = () => {
    setShowForm(false);
    setShowTable(true);
  };

  return (
    <React.Fragment>
      <EmailBlackListForm
        data-testid="emailBlackListForm"
        refresh={() => ({})}
        hidden={!showForm}
        handleClick={() => handleClick()}
        showForm={() => setShowForm(false)}
        showTable={() => setShowTable(true)}
      />
      <div data-testid="emailBlackList" hidden={!showTable} css={emailBlackListCss}>
        <Button
          data-testid="btnAddEmailList"
          css={css`
            float: left;
            margin: 15px 0;
          `}
          hidden={showForm}
          type="primary"
          size="middle"
          onClick={handleAdd}
          icon={<PlusCircleOutlined />}
        >
          {t.constants.AGREGARREG}
        </Button>

        <table>
          <thead>
            <tr>
              {t.home.labels.emailList.map((h, i) => (
                <th key={i}>{h}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataSource.map((data) => {
              return (
                <tr key={data.id}>
                  <td>{data.id}</td>
                  <td>{data.clave}</td>
                  <td>{data.valor}</td>
                  <td>
                    <Popconfirm
                      icon={
                        <ExclamationCircleTwoTone
                          twoToneColor={theme.colors.blue400Lighter}
                        />
                      }
                      title={t.constants.SEGUROELIMINAR}
                      onConfirm={() => handleDelete(data)}
                    >
                      <a data-testid={`btnDeleteEmailList${data.id}`}>
                        {t.constants.ELIMINAR}
                      </a>
                    </Popconfirm>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default EmailBlacklist;
