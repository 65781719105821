import css from "@emotion/css";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, DatePicker, Form, Row, Spin } from "antd";
import moment from "moment";
import { RichText } from "prismic-reactjs";
import { useEffect, useRef, useState } from "react";
import ModalMessage from "~components/ModalMessage";
import SectionTitle from "~components/SectionTitle";
import FooterButtons from "~components/digitalRegistration/FooterButtons";
import { NovaventaInput } from "~components/formControls/input/NovaventaInput";
import NovaventaRadio from "~components/formControls/radio/NovaventaRadio";
import NovaventaSelect from "~components/formControls/select/NovaventaSelect";
import { cellLG, cellMD, rowGutter, xs, xxl } from "~components/forms/formItemsLayout";
import TermsAndConditions from "~components/termAndConditions/TermsAndConditions";
import { usePrismic } from "~hooks/usePrismic";
import {
  getDatosContadoCredito,
  procesarContadoCredito,
  validarContadoCredito,
} from "~services/cashToCredit";
import { fetchPrismicDataTermsAndConditions } from "~services/prismicService";
import { getDocumentType } from "~services/selfRegistrationServices";
import { apiFormattedDate } from "~store/formStore";
import novaventaColors from "~styles/colors";
import { device } from "~utils/NovaventaMedia";
import { regExp } from "~utils/formRegex";
import {
  DesktopDigInscriptionContentCss,
  DesktopDigitalRegistrationCss,
  marginLeftSectionCss,
  sectionCss,
  subTitleCss,
  whiteSectionCss,
} from "./RetakesCss";

const redirect = (url: string) => {
  window.location.assign(url);
};

const ScrollSection = ({ refObj, idNumber, children }: any) => {
  const sectionStyle = idNumber === 2 ? whiteSectionCss : sectionCss;
  return (
    <div css={sectionStyle}>
      <div css={marginLeftSectionCss}>
        <section id={`section${idNumber}`} ref={refObj}>
          {children}
          {idNumber < 3 && (
            <Row>
              <Col offset={12}></Col>
            </Row>
          )}
        </section>
      </div>
    </div>
  );
};

const RetakesDesktop = (props: any) => {
  const t = usePrismic();
  const { base64 } = props;
  const deviceType = device();
  const addressFormRef = useRef(null);
  const [sectionOrder, setSectionOrder] = useState([1, 2, 3]);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [form] = Form.useForm();
  const [isAllowCredit, setIsAllowCredit] = useState(false);
  const [datosUsuario, setDatosUsuario] = useState({});
  const [termAnConditionsText, setTermAndConditionsText] = useState<any>(null);
  const [termAndConditionsVersion, setTermAndConditionsVersion] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalBtnText, setModalBtnText] = useState("");
  const [modalTextModal, setModalTextModal] = useState("");
  useEffect(() => {
    setSectionOrder([1, 2, 3]);
    validateCashToCredit(window.atob(base64));
    getDocumentType()
      .then(({ data }) => setDocumentTypeOptions(data))
      .catch((error) => console.error(error));
  }, []);

  const validateCashToCredit = (document: string) => {
    validarContadoCredito(document)
      .then((response) => {
        if (response.continuar) {
          getDataContadoCredito(document);
          fetchPrismicDataTermsAndConditions().then((responseTermsAndConditions: any) => {
            setTermAndConditionsVersion(responseTermsAndConditions.version);
            setTermAndConditionsText(
              <RichText render={responseTermsAndConditions["texto-canal-telefonico"]} />
            );
          });
        } else {
          if (response.codigo == "E-18") {
            setModalTextModal(response.mensaje);
          } else {
            console.log(response.codigo);
            setModalTextModal(t.constants.notContinue);
          }
          setModalBtnText(t.constants.goVirtualOffice);
          setShowModal(true);
        }
      })
      .catch((error) => console.error(error));
  };

  const getDataContadoCredito = (document: string) => {
    getDatosContadoCredito(document)
      .then((response) => {
        setDatosUsuario({
          idTipoDocumento: response.usuario.idTipoDocumento,
          numeroDocumento: window.atob(base64),
          primerNombre: response.usuario.nombres.split(" ")[0],
          segundoNombre: response.usuario.nombres.split(" ")[1]
            ? response.usuario.nombres.split(" ")[1]
            : "",
          primerApellido: response.usuario.primerApellido,
          segundoApellido: response.usuario.segundoApellido,
          fechaNacimiento: response.usuario.fechaNacimiento
            ? moment(response.usuario.fechaNacimiento)
            : null,
          fechaExpedicionDocumento: response.usuario.fechaExpedicionDocumento
            ? moment(response.usuario.fechaExpedicionDocumento)
            : null,
          sexo: response.usuario.sexo,
          phone: response.contacto.telefonoPrincipal,
          email: response.contacto.correoElectronico,
        });
        setIsAllowCredit(true);
      })
      .catch((error) => console.error("error", error));
  };

  const initialData = () => {
    return datosUsuario;
  };

  const URL_API = "https://api.ipify.org/?format=json";
  let ClientIp = "";
  fetch(URL_API)
    .then((respuestaRaw) => respuestaRaw.json())
    .then((respuesta) => {
      ClientIp = respuesta.ip;
    });

  const onFinish = (values: any) => {
    values.fechaNacimiento = apiFormattedDate(values.fechaNacimiento);
    values.fechaExpedicionDocumento = apiFormattedDate(values.fechaExpedicionDocumento);
    values.nombres = values.primerNombre + " " + values.segundoNombre;
    const registro = {
      firmaTyC: {
        documento: {
          idTipoDocumento: values.idTipoDocumento,
          registro: values.numeroDocumento,
        },
        versionTyC: termAndConditionsVersion,
        ipAddress: ClientIp,
      },
      datosUsuario: values,
      datosContacto: {
        telefonoPrincipal: values.phone,
        correoElectronico: values.email,
      },
    };

    procesarContadoCredito(registro)
      .then((resp: any) => {
        if (resp.continuar) {
          const baseURL: any = process.env.API_DEVELOP;
          window.location.assign(`${baseURL}/redireccionar/${resp.uuid}`);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div css={DesktopDigitalRegistrationCss}>
      {isAllowCredit ? (
        <div id="content" css={DesktopDigInscriptionContentCss}>
          <Row gutter={rowGutter} justify="start" align="top">
            <Form
              name="s3FormDownload"
              layout="vertical"
              form={form}
              onFinish={onFinish}
              initialValues={initialData()}
            >
              <Col flex={"100%"}>
                <ScrollSection refObj={addressFormRef} idNumber={sectionOrder[0]}>
                  {deviceType === "mobile" && (
                    <p css={subTitleCss}>{t.constants.LOSCAMPOSMARCADOS}</p>
                  )}
                  <SectionTitle
                    lightText={t.constants.SOLICITUDDE}
                    boldText={t.constants.CREDITO}
                  />
                  {deviceType === "desktop" ||
                    (deviceType === "desktopHD" && (
                      <p
                        css={css`
                          font-size: 23px;
                        `}
                      >
                        {t.constants.completeData}
                      </p>
                    ))}
                  <Row gutter={rowGutter} justify="start" align="top">
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item label={`${t.constants.TIPODOCUMENTO}`}>
                        <Form.Item
                          name="idTipoDocumento"
                          rules={[
                            {
                              required: true,
                              message: t.home.rules.required,
                            },
                          ]}
                          noStyle
                        >
                          <NovaventaSelect
                            options={documentTypeOptions}
                            placeholder={t.constants.TIPODOCUMENTO2}
                            optionsProp={{ label: "nombre", id: "id" }}
                            isdisabled
                          />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item
                        name="numeroDocumento"
                        label={t.constants.NUMERODOCUMENTO}
                      >
                        <NovaventaInput showiconcheck="false" maxLength={20} disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item name="primerNombre" label={t.constants.PRIMERNOMBRE}>
                        <NovaventaInput showiconcheck="false" maxLength={20} />
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item name="segundoNombre" label={t.constants.SEGUNDONOMBRE}>
                        <NovaventaInput showiconcheck="false" maxLength={20} />
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item name="primerApellido" label={t.constants.PRIMERAPELLIDO}>
                        <NovaventaInput showiconcheck="false" maxLength={20} />
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item
                        name="segundoApellido"
                        label={t.constants.SEGUNDOAPELLIDO}
                      >
                        <NovaventaInput showiconcheck="false" maxLength={20} />
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item
                        name="fechaNacimiento"
                        label={t.constants.FECHANACIMIENTO}
                        required
                        rules={[
                          {
                            required: true,
                            message: t.home.rules.required,
                          },
                        ]}
                      >
                        <DatePicker
                          style={{
                            minWidth: "100px",
                            width: "100%",
                            height: "48px",
                            background: "white 0% 0 % no - repeat padding- box",
                            border: "0.5px solid #879AB9",
                            borderRadius: "6px",
                            opacity: "1",
                          }}
                          suffixIcon={
                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                              color={novaventaColors.blue400}
                              size="lg"
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item
                        name="lugarNacimiento"
                        label={t.constants.LUGARNACIMIENTO}
                        rules={[
                          {
                            required: true,
                            message: t.home.rules.required,
                          },
                        ]}
                      >
                        <NovaventaInput
                          css={css`
                            margin-right: 100px;
                          `}
                          showiconcheck="false"
                          maxLength={20}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item
                        name="fechaExpedicionDocumento"
                        label={t.constants.dateExp}
                        required
                        rules={[
                          {
                            required: true,
                            message: t.home.rules.required,
                          },
                        ]}
                      >
                        <DatePicker
                          style={{
                            minWidth: "100px",
                            width: "100%",
                            height: "48px",
                            background: "white 0% 0 % no - repeat padding- box",
                            border: "0.5px solid #879AB9",
                            borderRadius: "6px",
                            opacity: "1",
                          }}
                          suffixIcon={
                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                              color={novaventaColors.blue400}
                              size="lg"
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item
                        name="lugarExpedicionDocumento"
                        label={t.constants.LUGAREXPEDICION}
                        rules={[
                          {
                            required: true,
                            message: t.home.rules.required,
                          },
                          {
                            pattern: regExp.alphabetWhiteSpaces,
                            message: t.home.rules.invalid,
                          },
                        ]}
                      >
                        <NovaventaInput showiconcheck="false" maxLength={20} />
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item
                        name="email"
                        hasFeedback
                        label={t.constants.CORREO}
                        rules={[
                          { required: true, message: t.home.rules.required },
                          {
                            pattern: regExp.email,
                            message: t.home.rules.invalidEmail,
                          },
                        ]}
                      >
                        <NovaventaInput
                          type="email"
                          showiconcheck="false"
                          maxLength={50}
                          placeholder={t.constants.PLACEHOLDEREMAIL2}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item
                        name="phone"
                        hasFeedback
                        label={t.constants.CELULAR}
                        rules={[
                          { required: true, message: t.home.rules.required },
                          {
                            pattern: regExp.cellPhone,
                            message: t.home.rules.number,
                          },
                        ]}
                      >
                        <NovaventaInput
                          type="tel"
                          showiconcheck="false"
                          placeholder={t.constants.PLACEHOLDERCEL}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={rowGutter} justify="start" align="top">
                    <Col xs={xs} md={cellMD} lg={cellLG} xxl={xxl}>
                      <Form.Item
                        name="sexo"
                        label={t.constants.SEXO}
                        rules={[
                          {
                            required: true,
                            message: t.home.rules.required,
                          },
                        ]}
                      >
                        <NovaventaRadio
                          optionsProps={{ label: "label", id: "id" }}
                          options={t.options.GENERO}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col flex={"1 0 100%"}>
                      <FooterButtons buttonType="submit" uri={""} />
                    </Col>
                  </Row>
                </ScrollSection>
              </Col>
            </Form>
            <TermsAndConditions hide={true} termAnConditionsText={termAnConditionsText} />
          </Row>
        </div>
      ) : (
        <div>
          <div
            css={css`
              font-size: 23px;
              text-align: center;
            `}
          >
            {t.constants.pleaseWait}
            <br />
            <Spin size="large" />
          </div>
          <ModalMessage
            refresh={(flag: boolean) => setShowModal(flag)}
            showModal={showModal}
            alertText={t.constants.notCredit}
            text={modalTextModal}
            btnText={modalBtnText}
            kind={"error"}
            handleClickBtn={() => {
              redirect(t.home.linkCommerce);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default RetakesDesktop;
