import { css } from "@emotion/core";
import styled from "@emotion/styled";

const LogoWrapperCommon = css`
  position: absolute;
  top: 17px;
  left: 10%;
  display: inline-table;

  > svg {
    height: 40px;
    width: auto;
  }
`;

export const LogoWrapperCss = styled.div`
  ${LogoWrapperCommon}
`;
