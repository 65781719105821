import type { RouteComponentProps } from "@reach/router";
import { Redirect } from "@reach/router";
import React, { useEffect, useState } from "react";
import NovaventaLayout from "~components/NovaventaLayout";
import { usePrismic } from "~hooks/usePrismic";
import { device } from "~utils/NovaventaMedia";

type Props = RouteComponentProps & {
  children: React.ReactNode;
};

const Registration = ({ children, location, path }: Props) => {
  const t = usePrismic();
  const deviceType = device();
  const [loginBanner, setLoginBanner] = useState(false);
  const [showBackBtn, setShowBackBtn]: any = useState(false);
  const [headerStr, setHeaderStr]: any = useState([]);
  const [isLogged, setIsLogged]: any = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectTo, setRedirectTo] = useState<string>();
  const [percent, setPercent]: any = useState(0);

  const resolvePathCondition = (token: string | null) =>
    !token &&
    !location?.pathname.includes(`/${path}/login-usuario`) &&
    !location?.pathname.includes("contado-credito") &&
    !location?.pathname.includes("cupo-demanda");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const uuid = localStorage.getItem("uuid");

    if (resolvePathCondition(token)) {
      setRedirect(true);
      setRedirectTo(`/${path}/login-usuario`);
    } else if (location?.pathname.includes("/inscripcion-digital") && uuid) {
      setIsLogged(true);
      setShowBackBtn(false);
    } else if (location?.pathname.includes("/metodo-pago") && uuid) {
      setIsLogged(true);
      setShowBackBtn(false);
      setPercent(15);
    } else if (location?.pathname.includes("/bienvenida")) {
      setIsLogged(true);
      setShowBackBtn(false);
      setPercent(20);
    } else if (location?.pathname.includes("/gestion")) {
      setIsLogged(true);
      setShowBackBtn(true);
      setRedirectTo(`/${path}/login-usuario`);
    } else if (
      location?.pathname.includes("/contado-credito") ||
      location?.pathname.includes("/cupo-demanda")
    ) {
      setIsLogged(true);
      setShowBackBtn(true);
    } else if (location?.pathname.includes("/login-documento")) {
      setLoginBanner(false);
      setShowBackBtn(true);
      setHeaderStr([t.constants.REGISTROFULL, t.constants.ACTIVATUCUENTA, ""]);
    } else if (token && !uuid) {
      setRedirect(true);
      setRedirectTo(`/${path}/login-documento`);
    }

    switch (location?.pathname) {
      case `/registro-telefonico/login-usuario`:
        setLoginBanner(true);
        setHeaderStr([t.constants.INICIOSESION, t.constants.INGRESACOMOASESOR, ""]);
        setShowBackBtn(false);
        break;

      case `/${path}/login-usuario/olvide-contrasena`:
        setLoginBanner(true);
        setHeaderStr([t.constants.OLVIDEMICONTRA, t.constants.INGRESATUCORREOPCREAR, ""]);
        setShowBackBtn(true);
        break;

      case `/${path}/login-usuario/activar-pass`:
        setLoginBanner(true);
        setHeaderStr([t.constants.ACTIVARMICONTRA, t.constants.AVISONUEVOPASS, ""]);
        setShowBackBtn(true);
        break;

      case `/${path}/login-usuario/cambio-contrasena`:
        setLoginBanner(true);
        setHeaderStr([t.constants.NUEVACONTRASEÑA, "", ""]);
        setShowBackBtn(true);
        break;

      case `/${path}/login-usuario`:
        setLoginBanner(true);
        setHeaderStr([t.constants.INICIOSESION, t.constants.INGRESACOMOMAMALIDER, ""]);
        setShowBackBtn(false);
        break;

      default:
        setIsLogged(false);
        break;
    }
  }, [location, path, t]);

  return (
    <React.Fragment>
      {redirect ? (
        <Redirect to={redirectTo} replace={true} />
      ) : (
        <NovaventaLayout
          isLogged={isLogged}
          deviceType={deviceType}
          percent={percent}
          showBackBtn={showBackBtn}
          loginBanner={loginBanner}
          headerStr={headerStr}
        >
          {children}
        </NovaventaLayout>
      )}
    </React.Fragment>
  );
};

export default Registration;
