import React, { useState, useEffect } from "react";
import { Radio } from "antd";
import { radioGroupStyle } from "./NovaventaRadioCss";

const NovaventaRadio = (props: any) => {
  const {
    onChange,
    optionsProps = { label: "label", id: "id" },
    options = [],
    defaultValue,
    value,
  } = props;
  const [radioItems, setRadioItems] = useState([]);

  const onValueChange = (changedValue: any) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  useEffect(() => {
    if (options.length > 0) {
      setRadioItems(
        options.map((opt: any) => ({
          value: opt[optionsProps.id],
          label: opt[optionsProps.label],
        }))
      );
    }
  }, [options, optionsProps.id, optionsProps.label]);

  return (
    <Radio.Group
      options={radioItems}
      name="deliveryAddress"
      value={value}
      data-testid="nova-radio"
      defaultValue={defaultValue}
      onChange={onValueChange}
      css={radioGroupStyle}
    ></Radio.Group>
  );
};

export default NovaventaRadio;
